import { Component, OnDestroy, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { DriverApplicationsService } from '@app/modules/driver-applications/services/driver-applications.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { catchError, Subscription, throwError } from 'rxjs';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'driver-applications-table',
  templateUrl: './driver-applications-table.component.html',
  styleUrls: ['./driver-applications-table.component.scss']
})
export class DriverApplicationsTableComponent implements OnInit, OnDestroy {
  //Date 
  dateObj: any = {
    startDate: moment().startOf('isoWeek').format('YYYY-MM-DD'), 
    endDate: moment().endOf('isoWeek').format('YYYY-MM-DD')
  };

  //Datasource
  dataSource: any[] = [];

  //Filters
  filters: any = {
    isCompleted: true,
    driverSearch: '',
    selectedRecruiters: []
  };

  //Recruiters array
  recruitersArray: any[] = [];

  //Dropdown multiple select
  styleConfig: any = {
    'width': '220px',
    'height': '45px',
    'background-color': '#fff',
    'color': '#1F1F1F',
    'box-shadow': 'none',
    'border-radius': '25px',
    'border-top': 'thin solid #0000001f',
    'arrow-color': '#0856cd',
    'opened-dropdown-shadow': '0px 1px 6.65px 0.35px rgba(13, 39, 80, 0.16)'
  }

  //Drug test
  drugTestArray: any[] = [
    {name: 'Positive', style: 'green-btn'}, 
    {name: 'Negative', style: 'red-btn'}
  ];

  //Backgroun check
  backgroundCheckArray: any[] = [
    {name: 'Clean', style: 'green-btn'}, 
    {name: 'Minnor issues', style: 'green-btn'}, 
    {name: 'Major issues', style: 'red-btn'},
    {name: 'Severe issues', style: 'dark-red-btn'}
  ];

  //Safety approval
  safetyApprovalArray: any[] = [
    {name: 'Approved', style: 'green-btn'}, 
    {name: 'Rejected', style: 'red-btn'}
  ];

  //Orientation
  orientationArray: any[] = [
    {name: 'Completed', style: 'green-btn'}, 
    {name: 'Scheduled', style: 'yellow-btn'}, 
    {name: 'Eligible', style: 'purple-btn'},
    {name: 'Not Eligible', style: 'red-btn'}
  ];

  //Hiring status
  hiringStatusArray: any[] = [
    {name: "Didn't show up", style: 'black-btn'}, 
    {name: 'Left', style: 'black-btn'}, 
    {name: 'Hired', style: 'dark-green-btn'},
    {name: 'In progress', style: 'yellow-btn'},
    {name: 'Rejected', style: 'red-btn'}
  ];

  btnsStyles: any = {
    '': 'blue-btn',
    'Positive': 'green-btn',
    'Negative': 'red-btn',
    'Clean': 'green-btn',
    'Minnor issues': 'green-btn',
    'Major issues': 'red-btn',
    'Severe issues': 'dark-red-btn',
    'Approved': 'green-btn',
    'Rejected': 'red-btn',
    'Completed': 'green-btn',
    'Scheduled': 'yellow-btn',
    'Eligible': 'purple-btn',
    'Not Eligible': 'red-btn',
    "Didn't show up": 'black-btn',
    'Left': 'black-btn', 
    'Hired': 'dark-green-btn',
    'In progress': 'yellow-btn'
  };

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();

  constructor(private transformService: TransformService,
              private dialog: MatDialog,
              private sharedService: SharedService, 
              private driverApplicationsService: DriverApplicationsService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getTableData();
    this.getAllRecruiters();
  };

  //Set date
  setDate(dateObj: any) {
    this.dateObj.startDate = dateObj.startDate;
    this.dateObj.endDate = dateObj.endDate;
    console.log(dateObj)
    this.getTableData();
  };

  //Change driver data
  changeDriverData(element: any, newValue: string, key: string) {
    const loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    const fullName: string = `${loggedUser.first_name} ${loggedUser.last_name}`;
    const nickname: string = `${loggedUser.nickname}`;
    const obj: any = JSON.parse(JSON.stringify(element));
    obj[key] = newValue;
    obj.edited_by = `${nickname.length === 0 ? fullName : nickname?.trim().split(' ')[0]}`;
    obj.edited_date = moment().format('YYYY-MM-DDTHH:mm:ss');
    console.log(obj);
    this.driverApplicationsService.changeDriverApplicationsData(obj)
    .pipe(catchError((err: any) => {
      this.showErrorMessage();
      return throwError(() => err);
    }))
    .subscribe((isSaved: boolean) => {
      if(isSaved) {
        element[key] = newValue;
      }
    });
  };

  //Driver applications list
  getTableData() {
    this.dataSource = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('driver-applications-table');
    this.subscription1 = this.driverApplicationsService.getAllDriverApplications(this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('claims-table');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) =>   {
      this.dataSource = response;
      this.spinner.hide('driver-applications-table');
      this.loaded = true;
      console.log(response);
    }); 
  };

  //Recruiters list
  getAllRecruiters() {
    this.subscription2 = this.sharedService.getAllEmployees().subscribe((response: any) => {
      for(let i = 0; i < response.length; i++) {
        const obj: any = response[i];
        if(obj.position === 'Recruiter') {
          obj.style = 'blue-btn';
          this.recruitersArray.push(obj);
        };
      };
    })
  };

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dataSource));
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return compare(a.name, b.name, isAsc);
        case 'date':
          return compare(new Date(a.date), new Date(b.date), isAsc);
        case 'state':
          return compare(a.state, b.state, isAsc);
        case 'num_of_files':
          return compare(a.num_of_files, b.num_of_files, isAsc);
        case 'recruiter':
          return compare(a.recruiter, b.recruiter, isAsc);
        case 'drug_test':
          return compare(a.drug_test, b.drug_test, isAsc);
        case 'background_check':
          return compare(a.background_check, b.background_check, isAsc);
        case 'safety_approval':
          return compare(a.safety_approval, b.safety_approval, isAsc);
        case 'orientation':
          return compare(a.orientation, b.orientation, isAsc);
        case 'hiring_status':
          return compare(a.hiring_status, b.hiring_status, isAsc);
        default:
          return 0;
      }
    });
  }

  onDriverSearchChange() {
    this.filters = {...this.filters};
  };

  selectUnselectRecruiters(data: any) {
    this.filters.selectedRecruiters = data;
    this.filters = {...this.filters};
  };

  toggleStatus(): void {
    this.filters.isCompleted = !this.filters.isCompleted;
    this.filters = {...this.filters};
  }

  getProgressColor(current: number, total: number): string {
    if (total === 0) return "blue-btn";
    const ratio = current / total;
    if (ratio === 1) {
      return 'green-btn';
    } 
    else if (ratio >= 0.5) {
      return 'yellow-btn';
    } 
    else {
      return 'red-btn'; 
    }
  }

  //Show error message
  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();  
  };
  
}

function compare(a: any, b: any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}