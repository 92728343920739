import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApexAxisChartSeries, ApexChart, ApexFill, ApexLegend, ApexResponsive, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, throwError } from 'rxjs';
import { BreakdownByGroupTrailersDialogComponent } from '../breakdown-by-group-trailers-dialog/breakdown-by-group-trailers-dialog.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { MaintenanceService } from '@app/modules/maintenance/services/maintenance.service';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  yaxis: ApexYAxis | any;
  responsive: ApexResponsive | any;
  labels: string | any;
  colors: string | any;
  legend: ApexLegend | any;
  fill: ApexFill | any;
  tooltip: any;
};

@Component({
  selector: 'app-breakdown-by-group-trailers',
  templateUrl: './breakdown-by-group-trailers.component.html',
  styleUrls: ['./breakdown-by-group-trailers.component.scss']
})
export class BreakdownByGroupTrailersComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild("chart") chart: ChartComponent | any;
  @Input() date: any;

  statisticsData: any;
  prevstatisticsData: any;

  subscription1: any;
  subscription2: any;

  pieData: any;

  days: any;
  dayName: any;

  selectedCards: any[] = [
    {selected: false}, {selected: false}, {selected: false}, {selected: false}, {selected: false}, {selected: false},
  ]

  //Interval
  interval: any;

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  public chartOptions: Partial<ChartOptions> | any;
  public chartOptions2: Partial<ChartOptions> | any;

  constructor(private maintenanceService: MaintenanceService,
  public transformService: TransformService, public dialog: MatDialog, private rulesService: RulesService,
  private spinner: NgxSpinnerService) {  }

  ngOnInit(): void {
    this.spinner.show('breakdown-by-group-2');
    this.dateChanged();
    if(this.rulesService.liveUpdate) {
      this.interval = setInterval(() => {
        this.getData();
      }, this.rulesService.miliseconds);
    }
  }

  ngOnChanges(): void {
    if(this.date) {
      this.getData();
    }
  }

  dateChanged() {
    this.subscription1 = this.maintenanceService.dateChanged.subscribe((response: any) => {
      this.date.startDate = response.startDate;
      this.date.endDate = response.endDate;
      this.statisticsData = undefined;
      this.error = false;
      this.loaded = false;
      this.spinner.show('breakdown-by-group-2');
      this.getData();
    })
  }

  getData() {
    //Previous period calculation
    let start: any = this.date.startDate;
    let end: any = this.date.endDate;

    var startRes = moment(start, "YYYY-MM-DD");
    var endRes = moment(end, "YYYY-MM-DD")
    let difference = Math.abs(moment.duration(startRes.diff(endRes)).asDays());
    this.days = Math.round(difference);
    let obj: any;
    if(this.days === 0) { 
      obj = {startDate: this.transformService.convertDateToTimestamp(moment().subtract(1, 'week').format('ddd, DD/MM YYYY'), 
      '00:00:00'), endDate: this.transformService.convertDateToTimestamp(moment().subtract(1, 'week').format('ddd, DD/MM YYYY'),
      '23:59:59')};
    } else {

      obj = {startDate: this.transformService.convertDateToTimestamp(moment().subtract(difference*2, 'days').format('ddd, DD/MM YYYY'), 
    '00:00:00'), endDate: this.transformService.convertDateToTimestamp(moment().subtract(difference, 'days').format('ddd, DD/MM YYYY'),
    '23:59:59')};
    }
    this.dayName = moment(obj.start).format('dddd');

    this.subscription1 = this.maintenanceService.getBreakDownByGroup2(this.date.startDate, this.date.endDate, 
    obj.startDate, obj.endDate, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('breakdown-by-group-2');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.statisticsData = response[0];
      this.prevstatisticsData = response[1];
      this.chartOptions = this.pieChart();
      this.spinner.hide('breakdown-by-group-2');
      this.loaded = true;
    })
  }

  pieChart() {
    let percent1: string = this.transformService.addCommasDots(this.statisticsData.trl1_drivetrain, 'round');
    let percent2: string = this.transformService.addCommasDots(this.statisticsData.trl2_electrical, 'round');
    let percent3: string = this.transformService.addCommasDots(this.statisticsData.trl3_break_systems, 'round');
    let percent4: string = this.transformService.addCommasDots(this.statisticsData.trl4_bodywork, 'round');
    let percent5: string = this.transformService.addCommasDots(this.statisticsData.trl5_miscellaneous, 'round');
    let percent6: string = this.transformService.addCommasDots(this.statisticsData.trlpm_preventive_maintenance, 'round');

    return {
      colors: ['#003fa2', '#f66c17', '#0030FF', '#008FA9', '#FA0000', '#008000'],
      series:  [this.statisticsData.trl1_drivetrain, this.statisticsData.trl2_electrical,
      this.statisticsData.trl3_break_systems, this.statisticsData.trl4_bodywork, this.statisticsData.trl5_miscellaneous, 
      this.statisticsData.trlpm_preventive_maintenance],
      chart: {
        width: 520,
        type: "donut",
        fontFamily: 'Poppins',
        fontSize: '14px'
        
      },
      labels:  ["Drivetrain " +  percent1, "Electrical " + percent2, "Brake systems " + percent3, "Bodywork " + percent4, "Miscellaneous " + percent5, "Preventive maintenance " + percent6],
      legend: {
        position: 'right'
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ],
      yaxis: {
        labels: {
          formatter: (num: number) => {
            typeof num !== 'number' ? num = 0 : num;
            let result = (num - Math.floor(num)) !== 0;
            let number: any;
            if(result) {
              number = Math.round(num);
            } else {
              number = num;
            }
            return number.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          },
        },
      },
    };
  };

  setDifference(prev: number, current: number) {
    let checkGrowth: any;
    let className: any;
    if (current > prev) {
      checkGrowth = 'more';
      className = 'up';
    } else if (current < prev) {
      checkGrowth = 'less';
      className = 'down';
    } else {
      checkGrowth = '';
      className = 'none'
    }
    let percentage: any;
    if (prev == 0 && current == 0) {
      percentage = 0;
    }

    if (prev == 0 && current !== 0) {
      percentage = (prev * 100 / current);
    }

    if (prev !== 0 && current == 0) {
      percentage = (current * 100 / prev);
    }

    if (prev !== 0 && current !== 0) {
      percentage = (prev / current) * 100;
    }

    let oneRangeMsg: any;
    this.days === 0 ? oneRangeMsg = `${this.dayName}` : oneRangeMsg = `${this.days} days`;

    let alert: string = ` ${checkGrowth} than the previous ${oneRangeMsg}`;
    let alertObj: any = { percent: Math.round(percentage), message: alert, className: className };
    return alertObj;
  }

  showInGraph(obj: any) {
    obj.selected = !obj.selected;
    let dataObj: any = {selectedCards: this.selectedCards, data: this.statisticsData.list};
    this.maintenanceService.breakDownByGroupLineChart2.next(dataObj);
  }

  //Open details dialog
  openDetails(index: number, name: string) {
    this.dialog.open(BreakdownByGroupTrailersDialogComponent, {
      width: '1000px',
      height: '693px',
      autoFocus: false,
      panelClass: 'trailer-group-dialog',
      data: {index: index, card: name, obj: this.statisticsData}
    });
  };

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  }

}
