import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DriversProfileService } from '@app/modules/drivers-profile-page/services/drivers-profile.service';
import { CreateUpdateThreadDialogComponent } from './create-update-thread-dialog/create-update-thread-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { EmailDialogComponent } from '@app/modules/shared/components/email-dialog/email-dialog.component';
import moment = require('moment');
import { SharedService } from '@app/modules/shared/services/shared.service';
import { catchError, throwError } from 'rxjs';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { NoteInfoMsgDialogComponent } from '@app/modules/shared/components/note-info-msg-dialog/note-info-msg-dialog.component';

@Component({
  selector: 'notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent {
  @ViewChild('elTextArea') elTextArea: ElementRef;
  @Input() data: any = {activity: {}, additionalInfo: {}, info: {}, notes: []};
  searchThread: string = '';
  selectedNoteGroupIndex: number = 0;
  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
  fullName: string = `${this.loggedUser.first_name} ${this.loggedUser.last_name}`;
  nickname: string = `${this.loggedUser.nickname}`;
  creator: string = this.nickname.length === 0 ? this.fullName : this.nickname?.trim().split(' ')[0];

  //Note
  note: string = '';
  editMode: boolean = false;
  editedObj: any;
  editedNoteIndex: number;

  //Files
  files: any[] = [];

  multipleClicks: boolean = false;

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      0, 5
    )
  ];

  constructor(private dialog: MatDialog, 
  private driversProfileService: DriversProfileService, private sharedService: SharedService) { }

  getHeight() {
    return `height: ${this.elTextArea.nativeElement.scrollHeight}px;`;
  }

  //Methods
  createUpdateNote() {
    if(!this.multipleClicks) {
      this.multipleClicks = true;
      let obj: any = {  
        id: 0,
        note_id: this.data.notes[this.selectedNoteGroupIndex].id,
        driver_id: this.data.info.id,
        note: this.note,
        note_date: moment().format('YYYY-MM-DDTHH:mm:ss'),
        creator: this.creator,
        creator_department: this.loggedUser.department,
        editor: null,
        editor_department: null,
        edit_date: null,
        files: this.files,
        creator_id: this.loggedUser.id
      };
      if(this.editMode) {
        obj.id = this.editedObj.id;
        obj.note_date = this.editedObj.note_date;
        obj.editor = this.creator;
        obj.editor_department = this.loggedUser.department;
        obj.edit_date = moment().format('YYYY-MM-DDTHH:mm:ss');
      }
      this.driversProfileService.createEditNoteExchange(obj)
      .pipe(catchError((err: any) => {
        this.multipleClicks = false;
        this.showErrorMessage();
        return throwError(() => err);
      }))
      .subscribe((response: any) => {
        if(response?.id) {
          
          if(this.editMode) {
            this.data.notes[this.selectedNoteGroupIndex].notes[this.editedNoteIndex] = response;
          }
          else {
            this.data.notes[this.selectedNoteGroupIndex].notes.unshift(response);
          }

          //Reset
          this.note = '';
          this.files = [];
          this.editedObj = undefined;

          if(this.emailsArray.length > 0) {
            let notificationObj: any = JSON.parse(JSON.stringify(response));
            notificationObj.subject = this.data.notes[this.selectedNoteGroupIndex].subject;
            notificationObj.driver_name = this.data.info.name;
            let notificationsArray: any[] = JSON.parse(JSON.stringify(this.emailsArray));
            notificationsArray.push({id: 27, email: 'nikolab@jdmexpedite.com', name: 'Nikola Beloica'});

            for(let key in notificationsArray) {
              
              if(notificationsArray[key].id !== this.loggedUser.id && notificationsArray[key].id !== 0) {

                let obj: any = {
                  id: null,
                  type: 'NOTE',
                  data: JSON.stringify(notificationObj),
                  date: moment().add(1, 'hour').format('YYYY-MM-DDTHH:mm:ss'),
                  from_user: this.loggedUser.id,
                  for_user: notificationsArray[key].id,
                }
                this.sharedService.sendNotification(obj).subscribe((response: any) => {});
              }
            }
          }
        }
        else {
          this.showErrorMessage();
        };
        this.multipleClicks = false;
      });
    }
  };

  //Email dialog
  emailDialog() {
    let dialogRef = this.dialog.open(EmailDialogComponent, {
      autoFocus: false,
      panelClass: 'email-dialog-container',
      data: {
        selectedEmails: this.emailsArray, 
        deafultAddressArray: [{id: 12, email: 'suzy@tempofreightsystems.com', name: 'Suzana Boric'}]
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.tagUsers(result);
      }
      console.log(result);
    })
  }

  tagUsers(emails: any[]) {
    let obj: any = JSON.parse(JSON.stringify(this.data.notes[this.selectedNoteGroupIndex]));
    obj.editor = this.creator;
    obj.editor_department = this.loggedUser.department;
    obj.edit_date = moment().format('YYYY-MM-DDTHH:mm:ss');
    obj.emails = [JSON.stringify(emails)];
    this.driversProfileService.createThreadNoteExhchange(obj)
    .subscribe((response: any) => {
      if(response) {
        console.log(response);
        this.data.notes[this.selectedNoteGroupIndex].emails = response.emails;
      }
    });
  };

  //Edit note
  editNote(obj: any, index: number) {
    if(this.loggedUser.id === obj.creator_id) {
      let data: any = JSON.parse(JSON.stringify(obj));
      this.editMode = true;
      this.editedObj = data;
      this.editedNoteIndex = index;
      this.note = data.note;
      this.files = JSON.parse(JSON.stringify(data.files));
      obj.isOpenNoteMenu = !obj.isOpenNoteMenu;
    }
    else {
      this.showNoteMessage();
    }
  };

  changeActiveThread(index: number) {
    this.selectedNoteGroupIndex = index;
  };

  //Create update thread
  createUpdateThread(obj: any, editMode: boolean, index: number) {
    if((this.loggedUser.id === obj.creator_id) || !editMode) {
      obj.isOpenThreadMenu = !obj.isOpenThreadMenu;
      let dialogRef = this.dialog.open(CreateUpdateThreadDialogComponent, {
        autoFocus: false,
        panelClass: 'component-dialog-container',
        data: {obj: obj, driverId: this.data.info.id, editMode: editMode}
      });
      dialogRef.afterClosed().subscribe((thread: any) => {
        if(thread) {
          if(editMode) {
            this.data.notes[index] = thread;
          }
          else {
            this.data.notes.unshift(thread);
          }
        }
      });
    }
    else {
      this.showNoteMessage();
    }
  };

  deleteThread(obj: any, index: number) {
    if(this.loggedUser.id === obj.creator_id) {
      obj.isOpenThreadMenu = !obj.isOpenThreadMenu;
      let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
        autoFocus: false,
        panelClass: 'delete-dialog-container',
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.driversProfileService.deleteThreadNoteExchange(obj.id)
          .subscribe((response: any) => {
            if(response) {
              if(this.selectedNoteGroupIndex <= (this.data.notes.length - 1) && this.selectedNoteGroupIndex > 0) {
                this.selectedNoteGroupIndex--;
              }
              this.data.notes.splice(index, 1);
            }
          })
        }
      })
    }
    else {
      this.showNoteMessage();
    }
  };

  deleteNote(obj: any, index: number) {
    if(this.loggedUser.id === obj.creator_id) {
      obj.isOpenNoteMenu = !obj.isOpenNoteMenu;
      let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
        autoFocus: false,
        panelClass: 'delete-dialog-container',
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.driversProfileService.deleteNoteExchange(obj.id)
          .subscribe((response: any) => {
            if(response) {
              this.data.notes[this.selectedNoteGroupIndex].notes.splice(index, 1);
            }
          })
        }
      })
    }
    else {
      this.showNoteMessage();
    }
  };

  openFileInNewTab(obj: any) {
    obj.isOpenFileMenu = !obj.isOpenFileMenu;
    this.sharedService.downloadPreviewFile(obj.filename, obj.data);
  };

  downloadFile(obj: any) {
    obj.isOpenFileMenu = !obj.isOpenFileMenu;
    this.sharedService.downloadFile(obj.data, obj.filename);
  };

  deleteFile(noteObj: any, fileObj: any, index: number) {
    if(this.loggedUser.id === noteObj.creator_id) {
      fileObj.isOpenFileMenu = !fileObj.isOpenFileMenu;
      let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
        autoFocus: false,
        panelClass: 'delete-dialog-container',
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          let obj: any = JSON.parse(JSON.stringify(noteObj));
          obj.editor = this.creator;
          obj.editor_department = this.loggedUser.department;
          obj.edit_date = moment().format('YYYY-MM-DDTHH:mm:ss');
          obj.files.splice(index, 1);
          console.log(obj);
          this.driversProfileService.createEditNoteExchange(obj).subscribe((response: any) => {
            console.log(response);
            if(response) {
              noteObj.files = response.files;
            };
          });
        }
      })
    }
    else {
      this.showNoteMessage();
    }
  };
  
  //Add files
  onFileSelected(event: any) {
    let length: any = Object.keys(event.target.files).length;
    for (let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i);
    }
  }

  addMultipleFiles(event: any, index: number) {
    let fileName = event.target.files[index].name;
    let reader = new FileReader();
    reader.onload = (e: any) => {
      let base64 = e.target.result.split(',')[1];
      let obj: any = {
        driver_id: this.data.info.id,
        note_id: 0,
        filename: fileName,
        ext: null,
        data: base64
      };
      this.files.push(obj);
    };

    reader.readAsDataURL(event.target.files[index]);
  };

  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  showNoteMessage() {
    this.dialog.open(NoteInfoMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'component-dialog-container'
    });
  };

  get emailsArray(): any[] {
    let array: any[] = [];
    if(this.data.notes.length === 0 || this.data.notes[this.selectedNoteGroupIndex].emails.includes('')) {
      array = [];
    }
    else {
      array = JSON.parse(this.data.notes[this.selectedNoteGroupIndex].emails);
    }
    return array;
  };

}
