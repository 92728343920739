import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FleetHealthService } from '@app/modules/fleet-health/services/fleet-health.service';
import moment = require('moment');
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-settings-calendar',
  templateUrl: './settings-calendar.component.html',
  styleUrls: ['./settings-calendar.component.scss']
})
export class SettingsCalendarComponent implements OnInit, OnDestroy {
  //Selected day
  @Input() selectedDay: string | any;
  @Output() changeDate = new EventEmitter<any>();
  
  //Date 
  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DDT00:00:00'),
    endDate:  moment().endOf('month').format('YYYY-MM-DDT23:59:59')
  };

  //Calendar picker
  isOpenFrom: boolean = false;
  fromDate: string = 'Choose a date';
  dateFromServer: any = '';

  isOpenTo: boolean = false;
  toDate: string = 'Choose a date';
  dateToServer: any = '';

  isOpenByDay: boolean = false;
 
  //Calendar
  currentMonth: any[] = [];
  days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  //Available spots
  availableSpots: number | undefined = undefined;

  //Spots array
  spotsArray: any[] = [];

  //Is change allowed
  changeDayAllowed: boolean = true;

  //By range
  range: boolean = false;

  //Show spinner
  showSpinner: boolean = true;

  //Loading
  loading: boolean = false;

  //Subscription
  subscription1: Subscription | any;
  subscription2: Subscription | any;
  subscription3: Subscription | any;
 
  constructor(private fleetHealthService: FleetHealthService) { }

  ngOnInit(): void {
    this.dateObj.startDate = moment(this.selectedDay).startOf('month').format('YYYY-MM-DDT00:00:00');
    this.dateObj.endDate = moment(this.selectedDay).endOf('month').format('YYYY-MM-DDT23:59:59');
    this.getCalendarData();
    this.refreshData();
    this.changeDay();
  }

  refreshData() {
    this.subscription1 = this.fleetHealthService.refreshDataSubject.subscribe((response: any) => {
      this.showSpinner = response;
      this.getCalendarData();
    })
  };

  getCalendarData() {
    this.loading = true;
    this.subscription2 = this.fleetHealthService.getScheduleCalendarData(this.dateObj.startDate, this.dateObj.endDate)
    .subscribe((response: any) => {
      this.spotsArray = response;
      this.setDate(new Date(this.dateObj.startDate));
      this.loading = false;
    });
  }

  changeDay() {
    this.subscription3 = this.fleetHealthService.dataReturnedSubject.subscribe((response: any) => {
      this.changeDayAllowed = response;
    });
  }

  setMonth(prev: boolean) {
    if(prev) {
      this.dateObj.startDate = moment(this.dateObj.startDate).subtract(1, "month").startOf('month').format('YYYY-MM-DDT00:00:00');
      this.dateObj.endDate = moment(this.dateObj.endDate).subtract(1, "month").endOf('month').format('YYYY-MM-DDT23:59:59');
    }
    else {
      this.dateObj.startDate = moment(this.dateObj.startDate).add(1, "month").startOf('month').format('YYYY-MM-DDT00:00:00');
      this.dateObj.endDate = moment(this.dateObj.endDate).add(1, "month").endOf('month').format('YYYY-MM-DDT23:59:59');
    }
    this.getCalendarData();
  }

  setDate(date: any) {
    let dateObj: any = this.getDaysInMonth(date);
    if (dateObj.firstDay === 1) {
      this.currentMonth = dateObj.dateArray;
    } 
    else {
    this.currentMonth = dateObj.dateArray;
    for (let i = 1; i < dateObj.firstDay; i++) {
      this.currentMonth.unshift({date: '-', id: 0, available: -1, created_by: null, created_date: null, edited_by: null, edited_date: null, slots: -1});
    }
    }
  }

  getDaysInMonth(date: any) {
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return { firstDay: this.getLocalDay(firstDay.getDay()), lastDay: lastDay.getDate(), dateArray: this.getDatesInRange(firstDay, lastDay)}
  }

  getDatesInRange(startDate: any, endDate: any) {
    const date = new Date(startDate.getTime());
    const dates = [];
    while (date <= endDate) {
      let d1 = moment(date).format('YYYY-MM-DDT00:00:00');
      let obj: any;

      let result = this.spotsArray.some((currObj, arrIndex) => { 
        obj = currObj
      return currObj.date == d1;
      })
      if(result) {
        obj.date = d1;
        console.log(obj)
        dates.push(obj);
      } else {
        dates.push({date: d1, id: 0, available: -1, created_by: null, created_date: null, edited_by: null, edited_date: null, slots: -1});
      }

      if(this.selectedDay === d1) {
        let slots: number = obj.id ? obj.slots : undefined;
        this.availableSpots = slots;
        this.fleetHealthService.dateSubject.next({obj: obj, showSpinner: this.showSpinner});
      }

      date.setDate(date.getDate() + 1);
    }
    return dates;
  }

  getLocalDay(day: number) {
    return day === 0 ? day = 7 : day;
  }
  
  makeReservation(obj: any) {
    if(this.changeDayAllowed) {
      this.changeDate.emit(obj.date);
      this.selectedDay = moment(new Date(obj.date)).format('MMM DD, yyyy.');
      this.availableSpots = obj.id ? obj.slots : undefined;
      this.fleetHealthService.dateSubject.next({obj: obj, showSpinner: true});
    }
  };

  getToday(obj: any) {
    if(moment(obj.date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
      return true;
    }
    else {
      return false;
    }
  };

  setClass(obj: any) {
    if(obj.date == '-') {
      return '';
    }
    if(moment(obj.date).format('YYYY-MM-DD')=== moment().format('YYYY-MM-DD')) {
      return 'blue-border';
    }
    else if(obj.slots === 0) {
      return 'restricted-color';
    }
    else {
      return '';
    }
  };

  getColorForPast(date: string) {
    if(moment(date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
      return 'grey-color';
    }
    else {
      return 'blue-color';
    }
  }

  dateClicked(event: any, calendarNum: number) {
    if(calendarNum === 1) {
      this.fromDate = moment(event._d).format('MMM DD, yyyy.');
      this.dateFromServer = moment(event._d).format('YYYY-MM-DDT00:00:00');
      this.isOpenFrom = false;
    } 
    else if(calendarNum === 2) {
      this.toDate = moment(event._d).format('MMM DD, yyyy.');
      this.dateToServer = moment(event._d).format('YYYY-MM-DDT00:00:00');
      this.isOpenTo = false;
    }
    else {
      this.selectedDay = moment(event._d).format('YYYY-MM-DDT00:00:00');
      this.isOpenByDay = false;
    }
  }

  //Save changes
  saveChange() {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let creator: string = loggedUser.first_name + ' ' + loggedUser.last_name;
    let createdDate: string = moment().format('YYYY-MM-DDTHH:mm:ss');
    let fromDate: any = this.range ? this.dateFromServer : this.selectedDay;
    let toDate: any = this.range ? this.dateToServer : this.selectedDay;
    let availableSpots: number = this.availableSpots;

    let arrayServer: any[] = [];
    let monthArray: any[] = JSON.parse(JSON.stringify(this.currentMonth));
    monthArray.forEach(element => {
      console.log(fromDate)
      console.log(toDate)

      if(moment(element.date).format('YYYY-MM-DD') >= moment(fromDate).format('YYYY-MM-DD')
      && moment(element.date).format('YYYY-MM-DD') <= moment(toDate).format('YYYY-MM-DD')) {
        element.available = availableSpots;

        if(!element.available) {
          element.slots = 0;
        }

        if(!element.id) {
          element.created_by = creator;
          element.created_date = createdDate;
        }
        else {
          element.edited_by = creator;
          element.edited_date = createdDate;
        }
        arrayServer.push(element);
      }

    });

    if(!this.range && typeof arrayServer[0].available !== 'number' && arrayServer[0].id) {
      this.fleetHealthService.deleteSpot(arrayServer[0].id).subscribe((response: any) => {
        if(response) {
          this.getCalendarData();
        }
        console.log(response);
      });
    }
    else {
      this.fleetHealthService.setUpdateSpots(arrayServer).subscribe((response: any) => {
        console.log(response);
        if(response) {
          if(this.range) {
            this.fromDate = 'Choose a date';  
            this.toDate = 'Choose a date';
            this.dateFromServer = '';
            this.dateToServer = '';
          }
          this.getCalendarData();
        }
      })
    }
    console.log(arrayServer);
  }

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
  }

}
