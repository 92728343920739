import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InspectionsService } from '@app/modules/inspections/services/inspections.service';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { SharedService } from '@app/modules/shared/services/shared.service';
import moment = require('moment');
import { catchError, Subscription, throwError } from 'rxjs';
import { AddNewViolationDialogComponent } from './add-new-violation-dialog/add-new-violation-dialog.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';

@Component({
  selector: 'app-create-update-inspection-dialog',
  templateUrl: './create-update-inspection-dialog.component.html',
  styleUrls: ['./create-update-inspection-dialog.component.scss']
})
export class CreateUpdateInspectionDialogComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[23].data;
  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
  
  //Inspection group
  inspectionFormGroup: any = this._formBuilder.nonNullable.group({
    id: [0],
    driver_name: ['', Validators.required],
    driver_id: [null],
    dispatcher: [''],
    dispatcher_id: [null],
    truck_no: ['', Validators.required],
    trailer_no: ['', Validators.required],
    division: [''],
    division_id: [null],
    report_no: [''],
    inspection_level: [''],
    state: [''],
    city: [''],
    zip_code: [''],
    note: [''],
    inspection_result: [''],
    out_of_service: [false],
    points: [null],
    amount: [null],
    rewards: [null],
    creator: [this.loggedUser.username],
    editor: [''],
    datecreated: [null],
    date: [null],
    violation: this._formBuilder.array([]),
    related: this._formBuilder.array([]),
    files: this._formBuilder.array([]),
    status: [''],
    original: [false],
    sent: [false]
  })

  //Trucks
  autoCompleteData: any[] = [];

  //Time
  isOpenTimeMenu: boolean = false;
  time: string = '';
  hour: any = '';
  minutes: any = '';

  //Location
  locationArray: any[] = [];
  locationSearch: string = '';
  isOpenLocationMenu: boolean = false;

  //Violations
  violationsData: any[] = [];
  isOpenViolationIndex: number | null = null;

  //Related to
  relatedToData: any[] = [
    {name: 'Unsafe driving'},
    {name: 'Non-CSA'},
    {name: 'Crash Indicator'},
    {name: 'Hours of Service compliance'},
    {name: 'Vehicle maintenance'},
    {name: 'Controlled substance and alcohol'},
    {name: 'Hazardous materials compliance'},
    {name: 'Driver fitness'}
  ];
  isOpenRelatedToIndex: number | null = null;

  //Files
  isOpenFileMenuIndex: number | null = null;

  //Multiple clicks
  multipleClicks: boolean = true;

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();
  subscription3: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any, 
              private rulesService: RulesService,
              private inspectionService: InspectionsService,
              private sharedService: SharedService,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<CreateUpdateInspectionDialogComponent>,
              private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    if(this.obj.editMode) {
      console.log(this.obj.data);
      this.inspectionFormGroup.patchValue(this.obj.data);
      this.initArray('violation', this.obj.data.violation);
      this.initArray('related', this.obj.data.related);
      this.initArray('files', this.obj.data.files);
      if(this.obj.data.date) {
        const time: string = this.obj.data.date.split('T')[1].split(':');
        this.time = `${time[0]}:${time[1]}`;
        this.hour = time[0];
        this.minutes = time[1];
      }
      this.locationSearch = `${this.obj.data.city}, ${this.obj.data.state}, USA`;
    }
    else {
      this.addSelect('violation');
      this.addSelect('related');
    }
    this.getTrucksData();
    this.getAllLocations();
    this.getAllViolations();
  }

  selectTruckAutocomplete(obj: any) {
    this.inspectionFormGroup.patchValue({'truck_no': obj.unit_no, 'driver_id': obj.driver_id, 'driver_name': obj.driver,
    'trailer_no': obj.trailer, 'dispatcher_id': obj.dispatcher_id, 'dispatcher': obj.dispatcher, 'division_id': obj.division_id})
  };

  getTrucksData() {
    this.subscription1 = this.inspectionService.getTrucks().subscribe((response: any) => {
      this.autoCompleteData = response;
    });
  };

  getAllLocations() {
    this.subscription2 = this.sharedService.getAllLocationInfo().subscribe((response: any) => {
      this.locationArray = response;
    });
  };

  getAllViolations() {
    this.subscription3 = this.inspectionService.getViolations().subscribe((response: any) => {
      this.violationsData = response;
    });
  };

  //Violations
  openViolationsDialog() {
    let dialogRef = this.dialog.open(AddNewViolationDialogComponent, {
      autoFocus: false,
      panelClass: 'add-new-violation-dialog'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.getAllViolations();
      }
    });
  };

  deleteViolation(id: number, index: number) {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.inspectionService.deleteDotInspection(id)
        .subscribe((response: any) => {
          if(response) {
            this.violationsData.splice(index, 1);
            this.violationsData = [...this.violationsData];
          }
        })
      }
    })
  };

  //Add select
  addSelect(formKey: string) {
    let formArray: any[] = this.formArray(formKey)?.controls;
    const lastEl: any = formArray[formArray.length - 1];
    if(lastEl && lastEl.value[formKey] === '') return;
    const itemGroup = this._formBuilder.group({
      id: 0,
      dot_id: 0,
      [formKey]: '',
      creator: this.loggedUser.username,
      created_date: moment().format('YYYY-MM-DDTHH:mm:ss'),
      edotor: '',
      edited_date: null
    });
    formArray.push(itemGroup);
    this.inspectionFormGroup.controls[formKey].updateValueAndValidity();
  };

  //Remove select
  removeSelect(isViolation: boolean, i: number) {
   if(isViolation) {
    this.formArray('violation').removeAt(i);
    return;
   }
   this.formArray('related').removeAt(i);
  };

  //Violation and related
  selectOption(formGroup: FormGroup, formKey: string, value: string) {
    if(formGroup.value.id) {
      formGroup.patchValue({[formKey]: value, edotor: this.loggedUser.username, edited_date: moment().format('YYYY-MM-DDTHH:mm:ss')});
    }
    else {
      formGroup.patchValue({[formKey]: value});
    }
    this.inspectionFormGroup.controls[formKey].updateValueAndValidity();
    if(formKey === 'violation') {
      this.isOpenViolationIndex = null;
      return;
    }
    this.isOpenRelatedToIndex = null;
  }

  //Select location
  setLocation(obj: any) {
    this.inspectionFormGroup.patchValue({'city': obj.city, 'state': obj.state_long});
    this.locationSearch = `${obj.city}, ${obj.state}, USA`;
    this.isOpenLocationMenu = false;
  };

  //Init array
  initArray(key: string, initArray: any[]) {
    let array: any[] = this.formArray(key)?.controls;
    for(let i = 0; i < initArray.length; i++) {
      const itemGroup = this._formBuilder.group(initArray[i]);
      array.push(itemGroup);
    }
  };

  //Add files
  onFileSelected(event: any) {
    let length: any = Object.keys(event.target.files).length;
    for (let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i);
    }
  }

  addMultipleFiles(event: any, index: number) {
    let fileName = event.target.files[index].name;
    let reader = new FileReader();
    const filesArray: any[] = this.formArray('files')?.controls;
    reader.onload = (e: any) => {
      let base64 = e.target.result.split(',')[1];
      const itemGroup = this._formBuilder.group({
        filename: fileName,
        data: base64
      });
      filesArray.push(itemGroup);
      this.inspectionFormGroup.controls['files'].updateValueAndValidity();
    };
    reader.readAsDataURL(event.target.files[index]);
  };

  openFileInNewTab(obj: any) {
    this.isOpenFileMenuIndex = null;
    this.sharedService.downloadPreviewFile(obj.filename, obj.data);
  };

  downloadFile(obj: any) {
    this.isOpenFileMenuIndex = null;
    this.sharedService.downloadFile(obj.data, obj.filename);
  };

  deleteFile(index: number) {
    this.isOpenFileMenuIndex = null;
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.formArray('files').removeAt(index);
      }
    })
  };

  //Time methods
  leadingZero(hour: boolean) {
    if(hour) {
      this.hour = String(this.hour).padStart(2, '0');
    }
    else {
      this.minutes = String(this.minutes).padStart(2, '0');
    }
  }

  applyTime() {
    if(isNaN(this.hour) || this.hour === '') {
      this.hour = '00';
    }
    
    if(isNaN(this.minutes) || this.minutes === '') {
      this.minutes = '00';
    }
    this.time = `${this.hour}:${this.minutes}`;
    if(this.getDate) {
      this.inspectionFormGroup.patchValue({'date': `${this.getDate}T${this.time}:00`});
    }
    this.isOpenTimeMenu = false;
  };

  //Save - update inspection
  saveUpdateInspection() {
    this.inspectionFormGroup.markAllAsTouched();
    if(this.inspectionFormGroup.valid && this.multipleClicks) {
      let formData: any = this.inspectionFormGroup.value;
      if(formData.violation[formData.violation.length - 1]?.violation === '') {
        formData.violation.pop();
      }
      if(formData.related[formData.related.length - 1]?.related === '') {
        formData.related.pop();
      }
      if(this.obj.editMode) {
        formData.editor = this.loggedUser.username;
        formData.edited_date = moment().format('YYYY-MM-DDTHH:mm:ss');
      }
      else {
        formData.datecreated = moment().format('YYYY-MM-DDTHH:mm:ss');
      }
      this.multipleClicks = false;
      this.inspectionService.saveInspection(formData)
      .pipe(catchError((err: any) => {
        this.showErrorMessage();
        this.multipleClicks = true;
        return throwError(() => err);
      }))
      .subscribe((response: any) => {
        console.log(response)
        if(response) {
          this.dialogRef.close(true);
        }
      });
    }
  };

  //Delete Inspection
  deleteDotInspection() {
    if(this.permissions[0].sectionArray[1].allowed) {
      let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
        autoFocus: false,
        panelClass: 'delete-dialog-container',
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.inspectionService.deleteDotInspection(this.obj.data.id)
          .pipe(catchError((err: any) => {
            this.showErrorMessage();
            return throwError(() => err);
          }))
          .subscribe((response: any) => {
            if(response) {
              this.dialogRef.close(true);
            }
          })
        }
      })
    }
  };

  //Show error message
  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  //Get form value
  formData(formKey: string) {
    return this.inspectionFormGroup.controls[formKey].value;
  };

  //Get form array
  formArray(formKey: string) {
    return this.inspectionFormGroup.controls[formKey];
  };

  //Check validation
  checkValidation(key: string): boolean {
    return !this.inspectionFormGroup.controls[key].valid && this.inspectionFormGroup.controls[key]?.touched; 
  };

  //Get date
  get getDate() {
    const date: string | null = this.formData('date');
    if(date) {
      return date.split('T')[0];
    };
    return null;
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
  };
  
}
