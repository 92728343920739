import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormArray, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WarningMsgDeductionsComponent } from './../../../dashboard-deductions-page/add-edit-deductions-dialog/warning-msg-deductions/warning-msg-deductions.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import moment = require('moment');

@Component({
  selector: 'app-deduction-dialog',
  templateUrl: './deduction-dialog.component.html',
  styleUrls: ['./deduction-dialog.component.scss']
})
export class DeductionDialogComponent implements OnInit {
  permissions: any = this.rulesService.UserData[37].data;
  //Deduction group
  deductionFormGroup: any = this._formBuilder.nonNullable.group({
    id: [0],
    issued_by: [null],
    creator_id: [null],
    driver_name: [''],
    driver_id: [0],
    division: [''],
    division_id: [0],
    unit_no: [''],
    description: [''],
    total_deductions: [null],
    no_of_installments: [1, [Validators.required, Validators.min(1)]],
    date: [null],
    note: [''],
    created_date: [null],
    installements: this._formBuilder.array([]),
    files: this._formBuilder.array([])
  });

  //Date filters
  dateFilters: ((dateObj: any) => boolean)[] = [];
  today = new Date();

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any, 
              public dialogRef: MatDialogRef<DeductionDialogComponent>,
              private dialog: MatDialog,
              private _formBuilder: FormBuilder,
              private rulesService: RulesService) { }

  ngOnInit() {
    this.deductionFormGroup.patchValue({
      'description': this.obj.ReasonForPeyment,
      'total_deductions': this.obj.PaymantAmount
    });
    this.addInstallments('1');
  };

  totalDeductionsValueChange(value: string) {
    const total_deduction: number = Number(value.replace(/[^\d.]/g, ''));
    const installmentsArray = this.formArray('installements');
    if(installmentsArray.length === 1) {
      this.formArray('installements').controls[0].patchValue({'deducted_amount': total_deduction})}
    else {
      installmentsArray.clear();
      this.addInstallments(this.formData('no_of_installments'));
    }
   };

  changeInstallmentDate(form: any, date: string, index: number) {
    form.patchValue({'date': date});
    if(this.dateFilters.length > index) {
      this.dateFilters[index] = (dateObj: any): boolean => {
        if (!dateObj) return false;
        const selectedDate = new Date(dateObj._d);
        const todayUTC = new Date(Date.UTC(
          this.today.getFullYear(),
          this.today.getMonth(),
          this.today.getDate()
        ));
  
        return selectedDate.getUTCDay() === 2 && selectedDate >= todayUTC && selectedDate >= new Date(date);
      }

      const installmentsArray = this.formArray('installements').controls;
      let isOutOfOrder = false;

      for (let i = 1; i < installmentsArray.length; i++) {
        if(index === 1) {
          const prevDate = moment(installmentsArray[i - 1].value.date);
          installmentsArray[i].patchValue({ date: prevDate.add(7, 'days').toISOString()}); 
        }
        else {
          if (!installmentsArray[i].value?.date) continue;
          const prevDate = new Date(installmentsArray[i - 1].value?.date);
          const currentDate = new Date(installmentsArray[i].value?.date);
          if (isOutOfOrder) {
            installmentsArray[i].patchValue({ date: null });
          } 
          else if (currentDate < prevDate) {
            isOutOfOrder = true;
            installmentsArray[i].patchValue({ date: null });
          }
        }
      }
    }
  };

  saveData() {
    this.deductionFormGroup.controls['installements'].updateValueAndValidity();
    this.deductionFormGroup.controls['files'].updateValueAndValidity();
    console.log(this.deductionFormGroup.value);
    this.deductionFormGroup.markAllAsTouched();
    if(this.checkTotalDeductions) {
      this.showErrorDeductionsMessage();
      return
    }
    if(this.deductionFormGroup.valid) {
      this.dialogRef.close(this.deductionFormGroup.value);
    }
  };

  addInstallments(numOfInstallments: string) {
    const installmentsArray = this.formArray('installements');
    installmentsArray.clear();
    this.dateFilters = [];
    const enteredNumber: number = Number(numOfInstallments);
    let total_deduction: number = this.formData('total_deductions');
    let deducted_amount: number = 0;
    if(total_deduction > 0) {
      deducted_amount = total_deduction / enteredNumber;
    }
    for(let i = 0; i < enteredNumber; i++) {
      installmentsArray.push(this._formBuilder.group({id: [null], deduction_id: [null], date: [null, Validators.required], deducted_amount: deducted_amount, name: '', user_id: 0, scheduled_checkbox: false}));
      this.initDateFiltersArray();
    }
    this.deductionFormGroup.controls['installements'].updateValueAndValidity();
  };

  public addTwoDecimals = (value: string | number | undefined | null): string => {
    if (!value) return '';
  
    let num = Number(value);
  
    if (isNaN(num)) return String(value);
  
    return num % 1 === 0 ? num.toFixed(2) : String(value);
  };

  initDateFiltersArray() {
    this.dateFilters.push((dateObj: any): boolean => {
      if (!dateObj) return false;
      const selectedDate = new Date(dateObj._d);
      const todayUTC = new Date(Date.UTC(
        this.today.getFullYear(),
        this.today.getMonth(),
        this.today.getDate()
      ));

      return selectedDate.getUTCDay() === 2 && selectedDate >= todayUTC;
    });
  }

  //Get form value
  formData(formKey: string) {
    return this.deductionFormGroup.controls[formKey].value;
  };

  //Get form array
  formArray(key: string): FormArray {
    return this.deductionFormGroup.controls[key] as FormArray;
  };

  //Validation
  checkValidation(key: string): boolean {
    return !this.deductionFormGroup.controls[key].valid && this.deductionFormGroup.controls[key]?.touched; 
  };

  //Date filter
  dateFilter = (dateObj: any): boolean => {
    if (!dateObj) return false;
    const selectedDate = new Date(dateObj._d);
    const todayUTC = new Date(Date.UTC(
      this.today.getFullYear(),
      this.today.getMonth(),
      this.today.getDate()
    ));
    return selectedDate.getUTCDay() === 2 && selectedDate >= todayUTC;
  };

  //Show error deductions message
  showErrorDeductionsMessage() {
    this.dialog.open(WarningMsgDeductionsComponent, {
      autoFocus: false,
      panelClass: 'warning-deductions-dialog-container'
    });
  };

  get checkTotalDeductions() : boolean {
    const total_deductions: number = this.formData('total_deductions');
    const installementsArray: any[] = this.formData('installements');
    let isGreater: boolean = false;
    let installementsSum: number = 0;
    for(let i = 0; i < installementsArray.length; i++) {
      installementsSum += installementsArray[i].deducted_amount;
    }
    if(installementsSum > total_deductions) {
      isGreater = true;
    }
    return isGreater;
  };
}
