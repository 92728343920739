import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { CreateUpdateRepairComponent } from '../create-update-repair/create-update-repair.component';
import { MaintenanceRepairLogService } from '@app/modules/maintenance/services/maintenance-repair-log.service';
import { catchError, throwError } from 'rxjs';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';

@Component({
  selector: 'maintenance-repair-log-table',
  templateUrl: './maintenance-repair-log-table.component.html',
  styleUrls: ['./maintenance-repair-log-table.component.scss']
})
export class MaintenanceRepairLogTableComponent {
  @Input() dataSource: any[] = [];
  permissions: any = this.rulesService.UserData[60].data;

  //Filters
  filters: any = {
    DateOfLogEntry: null,
    LoggedBy: [],
    TruckNo: [],
    TrailerNo: [],
    LogID: [],
    DivisionName: [],
    DriverName: [],
    Dispatcher: [],
    ReasonForRepair: [],
    DescriptionOfRepair: 'Both',
    notes: 'Both',
    LocationOfRepair: [],
    Status: []
  };

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'start', originY: 'bottom'},
      {overlayX: 'start', overlayY: 'top'},
      0, 5
    ),
    new ConnectionPositionPair(
      { originX: 'start', originY: 'top' },
      { overlayX: 'start', overlayY: 'bottom' },
      0, -5
    )
  ];

  statusArray: any[] = [
    {name: 'Done'}, 
    {name: 'Yard'}, 
    {name: 'Move'}, 
    {name: 'Not Called'}, 
    {name: 'Waiting In Shop'}, 
    {name: 'In Progress'},
    {name: 'Waiting For Adjuster'},
    {name: 'Waiting For Driver'}
  ];
  
  statusBgColors: any = {
    '': 'default-bg', 
    'Done': 'green-bg', 
    'Yard': 'blue-bg',
    'Move': 'red-bg',
    'Not Called': 'red-bg',
    'Waiting In Shop': 'red-bg',
    'In Progress': 'yellow-bg',
    'Waiting For Adjuster': 'red-bg',
    'Waiting For Driver': 'red-bg'
  };
  isOpenStatusMenuIndex: number | null = null;

  //Note
  isOpenNoteMenuIndex: number | null = null;
  note: string = '';
  
  constructor(private dialog: MatDialog, 
              public transformService: TransformService, 
              private rulesService: RulesService,
              private maintenanceRepairLogService: MaintenanceRepairLogService) {  }

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dataSource));
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'DateOfLogEntry':
          return compare(new Date(a.DateOfLogEntry), new Date(b.DateOfLogEntry), isAsc);
        case 'LoggedBy':
          return compare(a.LoggedBy, b.LoggedBy, isAsc);
        case 'TruckNo':
          return compare(a.TruckNo, b.TruckNo, isAsc);
        case 'TrailerNo':
          return compare(a.TrailerNo, b.TrailerNo, isAsc);
        case 'LogID':
          return compare(a.LogID, b.LogID, isAsc);
        case 'DivisionName':
          return compare(a.DivisionName, b.DivisionName, isAsc);
        case 'DriverName':
          return compare(a.DriverName, b.DriverName, isAsc);
        case 'Dispatcher':
          return compare(a.Dispatcher, b.Dispatcher, isAsc);
        case 'ReasonForRepair':
          return compare(a.ReasonForRepair, b.ReasonForRepair, isAsc);
        case 'DescriptionOfRepair':
          return compare(a.DescriptionOfRepair, b.DescriptionOfRepair, isAsc);
        case 'notes':
          return compare(a.notes, b.notes, isAsc);
        case 'LocationOfRepair':
          return compare(a.LocationOfRepair, b.LocationOfRepair, isAsc);
        case 'Status':
          return compare(a.Status, b.Status, isAsc);
        default:
          return 0;
      }
    });
  }

  openRepairLogDialog(element: any) {
    if(this.permissions[0].sectionArray[1].allowed) {
      let dialogRef = this.dialog.open(CreateUpdateRepairComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'component-dialog-container',
        data: {editMode: true, data: element}
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          if(result === 'DELETE REPAIR LOG') {
            let index: number = this.getIndex(element.Id);
            this.dataSource.splice(index, 1);
            this.dataSource = [...this.dataSource];
          }
          else {
            let index: number = this.getIndex(element.Id);
            this.dataSource[index] = result;
            this.dataSource = [...this.dataSource];
          }
        }
      });
    }
    else {
      this.showForbiddenMesage();
    }
  };

  changeLogStatus(element: any, status: string) {
    this.isOpenStatusMenuIndex = null;
    const elementTemp = JSON.parse(JSON.stringify(element));
    elementTemp.Status = status;
    this.maintenanceRepairLogService.changeLogStatus(elementTemp)
    .pipe(catchError((err: any) => {
      this.showErrorMessage();
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      if(response === 'Success') {
        element.Status = status;
      }
    });
  };

  changeNote(element: any) {
    this.isOpenNoteMenuIndex = null;
    const elementTemp = JSON.parse(JSON.stringify(element));
    elementTemp.notes = this.note;
    if(element.notes !== this.note) {
      this.maintenanceRepairLogService.changeNote(elementTemp)
      .pipe(catchError((err: any) => {
        this.showErrorMessage();
        return throwError(() => err);
      }))
      .subscribe((response: any) => {
        console.log(response);
        if(response === 'Success') {
          element.notes = elementTemp.notes;
          console.log(elementTemp.notes)
          this.note = '';
        }
      });
    }
  }

  selectFilter(propertyName: string, value: any) {
    this.filters[propertyName] = value;
    this.filters = {...this.filters};
  };

  clearFilters() {
    this.filters = {
      dateOfLogEntry: null,
      LoggedBy: [],
      TruckNo: [],
      TrailerNo: [],
      LogID: [],
      DivisionName: [],
      DriverName: [],
      Dispatcher: [],
      ReasonForRepair: [],
      DescriptionOfRepair: 'Both',
      notes: 'Both',
      LocationOfRepair: [],
      Status: []
    };
  };

  getIndex(Id: number) {
    for(let i = 0; i < this.dataSource.length; i++) {
      if(this.dataSource[i].Id === Id) {
        return i;
      };
    };
  };

  //Show error message
  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };
  
  showForbiddenMesage() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

}

function compare(a: number | any, b: number | any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
