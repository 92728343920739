import { MediaMatcher } from '@angular/cdk/layout';
import { AfterContentInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WelcomePageComponent } from '../welcome-page/welcome-page.component';
import { SharedService } from '../shared/services/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { RulesService } from '../shared/services/rules.service';
import { CreateUpdateTicketDialogComponent } from '../tickets/components/create-update-ticket-dialog/create-update-ticket-dialog.component';
import { MsgForbbidenAccessComponent } from '../shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { ActiveTrucksDialogComponent } from '../shared/components/active-trucks-dialog/active-trucks-dialog.component';

@Component({
  selector: 'app-main-component',
  templateUrl: './main-component.component.html',
  styleUrls: ['./main-component.component.scss']
})
export class MainComponentComponent implements OnInit, AfterContentInit, OnDestroy { 
  permissionsActiveTrucks: any = this.rulesService.UserData[41].forbidden;
  //New sidebar
  mobileQuery: MediaQueryList;

  //Notifications
  hideNotifications: boolean = true;

  private _mobileQueryListener: () => void;

  menuArray: any[] = [
    {name: 'Quick overview', icon: 'timeline', subMenu: [], routerLink: '/admin/overview', isDialog: false, dialogName: ''},

    {name: 'Departments', icon: 'account_tree', expand: false, subMenu: [
      {name: 'Accounting', subMenu: [
        {name: 'Activity Summary', routerLink: '/admin/accounting/activity-summary', isDialog: false, dialogName: ''},
        {name: 'Invoices', routerLink: '/admin/accounting/invoices-page', isDialog: false, dialogName: ''},
        {name: 'Liabilities', routerLink: '/admin/accounting/liabilities', isDialog: false, dialogName: ''},
        {name: 'Accounting Board', routerLink: '/admin/accounting/accounting-board', isDialog: false, dialogName: ''},
        {name: 'Payments', routerLink: '/admin/accounting/payments', isDialog: false, dialogName: ''},
        {name: 'Deductions', routerLink: '/admin/accounting/deductions', isDialog: false, dialogName: ''},
        {name: 'Ageing Report', routerLink: '/admin/ageing-report', isDialog: false, dialogName: ''},
        {name: 'Shortage Report', routerLink: '/admin/shortage-report', isDialog: false, dialogName: ''}
      ]},
      {name: 'Dispatch', subMenu: [
        {name: 'Live dispatch', routerLink: '/admin/dispatchers/dashboard-dispatch-live-board', isDialog: false, dialogName: ''},
        {name: 'Dispatch reward system', routerLink: '/admin/dispatchers/dispatch-reward-system', isDialog: false, dialogName: ''},
        {name: 'Performance', routerLink: '/admin/time-series/time-series-disp', isDialog: false, dialogName: ''},
        {name: 'Live Tracking', routerLink: '/admin/live-tracking-trucks', isDialog: false, dialogName: ''},
        {name: 'Available Trucks', routerLink: '/admin/maps', isDialog: false, dialogName: ''},
        {name: 'Load Price Estimation', routerLink: '/admin/loads/estimations', isDialog: false, dialogName: ''}
      ]},
      {name: 'Maintenance', subMenu: [
        {name: 'Maintenance', routerLink: '/admin/maintenance/maintenance-page', isDialog: false, dialogName: ''},
        {name: 'Maintenance Repair Log', routerLink: '/admin/maintenance/maintenance-repair-log', isDialog: false, dialogName: ''},
        {name: 'Fleet Health', routerLink : '/admin/fleet-health/overview', isDialog: false, dialogName: ''},
        {name: 'Schedule Trucks', routerLink : '/admin/fleet-health/schedule', isDialog: false, dialogName: ''},
        {name: 'Active Trucks page', routerLink: '/', isDialog: true, dialogName: 'openActiveTrucksDialog'},
        {name: 'Trucks without drivers', routerLink: '/admin/trucks/truck-nodriver', isDialog: false, dialogName: ''}
      ]},
      {name: 'Recruiting', subMenu: [
        {name: 'Recruiting', routerLink: '/admin/recruiting/recruiting-page', isDialog: false, dialogName: ''},
        {name: 'Full App', routerLink : '/admin/full-app', isDialog: false, dialogName: ''},
        {name: 'Driver Search', routerLink : '/admin/drivers/search', isDialog: false, dialogName: ''}
      ]},
      {name: 'Safety', subMenu: [
        {name: 'Safety', routerLink: '/admin/analytics/safety-page', isDialog: false, dialogName: ''},
        {name: 'Accidents', routerLink: '/admin/accidents', isDialog: false, dialogName: ''},
        {name: 'Claims', routerLink: '/admin/claims', isDialog: false, dialogName: ''},
        {name: 'Warnings', routerLink : '/admin/warnings', isDialog: false, dialogName: ''},
        {name: 'Inspections', routerLink : '/admin/inspections', isDialog: false, dialogName: ''},
        {name: 'Live Tracking', routerLink: '/admin/live-tracking-trucks', isDialog: false, dialogName: ''}
      ]}
    ]},
    {name: 'Drivers', icon: 'supervisor_account',  expand: false, subMenu: [
      {name: 'Fleet', subMenu: [
        {name: 'Trucks performance', routerLink: '/admin/trucks/trucks-performance', isDialog: false, dialogName: ''}
      ]},
      {name: 'Drivers performance', routerLink: '/admin/drivers/drivers-performance', subMenu: [], isDialog: false, dialogName: ''},
      {name: 'Hometime', routerLink: '/admin/drivers/hometime', subMenu: [], isDialog: false, dialogName: ''},
      {name: 'Statement', routerLink: '/admin/drivers/statements-page', subMenu: [], isDialog: false, dialogName: ''},
      {name: 'Underperforming drivers', routerLink: '/admin/drivers/underperforming-drivers-page', subMenu: [], isDialog: false, dialogName: ''},
      {name: 'Filter drivers', routerLink : '/admin/drivers/filter-drivers/filter-drivers-page', subMenu: [], isDialog: false, dialogName: ''},
      {name: 'Inspections', routerLink : '/admin/inspections', subMenu: [], isDialog: false, dialogName: ''},
      {name: 'Warnings', routerLink : '/admin/warnings', subMenu: [], isDialog: false, dialogName: ''}
    ]},
    {name: 'Documents', icon: 'document_scanner', expand: false, subMenu: [
      {name: 'Documents', routerLink : '/admin/documents', subMenu: [], isDialog: false, dialogName: ''}
    ]},
    {name: 'Loads', icon: 'route',  expand: false, subMenu: [
      {name: 'Total loads', routerLink: '/admin/total-loads', subMenu: [], isDialog: false, dialogName: ''},
      {name: 'Lost loads', routerLink: '/admin/dispatchers/not-covered-trucks', subMenu: [], isDialog: false, dialogName: ''},
      {name: 'Planning', routerLink: '/admin/dispatchers/prebooked-loads', subMenu: [], isDialog: false, dialogName: ''},
      {name: 'Business partners', routerLink: '/admin/dispatchers/business-partners-page', subMenu: [], isDialog: false, dialogName: ''},
      {name: 'Brokers to avoid', routerLink: '/admin/dispatchers/brokers-to-avoid', subMenu: [], isDialog: false, dialogName: ''},
      {name: 'Load analysis', routerLink: '/admin/dispatchers/load-analysis-page', subMenu: [], isDialog: false, dialogName: ''}
    ]},
    {name: 'Reports', icon: 'insert_chart_outlined',  expand: false, subMenu: [
      {name: 'IRP/IFTA', subMenu: [
        {name: 'IRP/IFTA reports', routerLink: '/admin/accounting/accounting-page', isDialog: false, dialogName: ''}
      ]},
      {name: 'Limited Maintenance', subMenu: [
        {name: 'Limited Maintenance', routerLink: '/admin/limited-maintenance-report', isDialog: false, dialogName: ''}
      ]},
      {name: 'Truck Amortization', routerLink: '/admin/reports/truck-amortization', subMenu: [], isDialog: false, dialogName: ''},
      {name: 'Mileage per truck', routerLink: '/admin/reports/mileage-per-truck', subMenu: [], isDialog: false, dialogName: ''},
      {name: 'Truck Activation', routerLink: '/admin/reports/truck-activation', subMenu: [], isDialog: false, dialogName: ''},
      {name: 'Truck Activity', routerLink: '/admin/reports/truck-activity', subMenu: [], isDialog: false, dialogName: ''},
      {name: 'Truck Profitability', routerLink: '/admin/reports/truck-profitability', subMenu: [], isDialog: false, dialogName: ''},
      {name: 'Ageing Report', routerLink: '/admin/ageing-report', subMenu: [], isDialog: false, dialogName: ''},
      {name: 'Shortage Report', routerLink: '/admin/shortage-report', subMenu: [], isDialog: false, dialogName: ''}
    ]},
    {name: 'Tasks', icon: 'assignment',  expand: false, subMenu: [
      {name: 'Create Task', routerLink : '/create-task', subMenu: [], isDialog: false, dialogName: ''},
      {name: 'Task Calendar', routerLink : '/task-calendar', subMenu: [], isDialog: false, dialogName: ''},
      {name: 'Tasks Management', routerLink : '/tasks-management', subMenu: [], isDialog: false, dialogName: ''},
      {name: 'Tasks Categories', routerLink : '/tasks-categories', subMenu: [], isDialog: false, dialogName: ''}
    ]},
    {name: 'Settings', icon: 'settings', expand: false, subMenu: [
      {name: 'Settings', routerLink : '/admin/settings', subMenu: [], isDialog: false, dialogName: ''},
      {name: 'Notifications Settings', routerLink : '/admin/notifications-alerts-settings', subMenu: [], isDialog: false, dialogName: ''}
    ]},
    {name: 'Support', icon: 'question_mark', expand: false, subMenu: [
      {name: 'Support', routerLink : '/support', subMenu: [], isDialog: false, dialogName: ''},
    ]},
  ];

  //Dispatcher profile route
  userData: any = JSON.parse(localStorage.getItem("currentUser"));
  profileRoute: string = `/admin/dispatchers/dashboard-dispatch-profile-page/${this.userData.pt_id}`;

  constructor(private router: Router, 
              private changeRef: ChangeDetectorRef, 
              media: MediaMatcher,
              private rulesService: RulesService,
              public sharedService: SharedService,
              private dialog: MatDialog) {
    this.mobileQuery = media.matchMedia('(max-width: 800px)');
    this._mobileQueryListener = () => changeRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  welcomeMenu: any;
  loggedClass: any;
  
  showWelcomwMenu(showMenu: boolean) {
    this.welcomeMenu = showMenu;
  }

  onOutletLoaded(component: WelcomePageComponent) {
    if (component instanceof WelcomePageComponent) {
      this.welcomeMenu = "0";
    } else {
      this.welcomeMenu = "1";
    }
  }

	ngOnInit() {
		if (!localStorage.getItem('currentUser')) {
			this.router.navigate(['/login']);
			return
		}
	}

  //New menu
  expandMenu(obj: any, i: number) {
    obj.expand = !obj.expand;
    this.menuArray.forEach((element: any, index: number) => {
      if(i !== index) {
        element.expand = false;
      }
    })
  };

  collapseMenu() {
    if(this.welcomeMenu) {
      for(let i = 0; i < this.menuArray.length; i++) {
        this.menuArray[i].expand = false;

        for(let j = 0; j < this.menuArray[i].subMenu.length; j++) {
          this.menuArray[i].subMenu[j].expand = false;
        }
      }
    }
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['login']);
  }

	ngAfterContentInit() {
		this.changeRef.detectChanges();
	}

  get getMargin(): number {
    if(this.mobileQuery.matches) {
      return 0;
    }
    else if(this.welcomeMenu == '0') {
      return 280;
    }
    else {
      return 78;
    }
  }

  identify(index, item){
    return item.id; 
  };

  openTicketDialog() {
    if(this.rulesService.UserData[28].data[0].allowedAll) {
      this.dialog.open(CreateUpdateTicketDialogComponent, {
        autoFocus: false,
        panelClass: 'create-update-ticket-main-container',
        disableClose: true,
        data: {editMode: false, obj: {}}
      });
    }
    else {
      this.dialog.open(MsgForbbidenAccessComponent, {
        autoFocus: false,
        panelClass: 'forbidden-msg-dialog-container'
      })
    }
  };

  //Active trucks dialog
  openActiveTrucksDialog() {
    if(!this.permissionsActiveTrucks) {
      this.dialog.open(ActiveTrucksDialogComponent, {
        autoFocus: false,
        panelClass: 'active-trucks-dialog-container',
      });
    }
    else {
      this.dialog.open(MsgForbbidenAccessComponent, {
        autoFocus: false,
        panelClass: 'forbidden-msg-dialog-container'
      })
    }
  };

  //Change theme
  changeTheme() {
    localStorage.setItem('dashboard-theme', `${!this.dashboardTheme}`);
  }

  get dashboardTheme(): boolean {
    if(localStorage.getItem('dashboard-theme')) {
      return JSON.parse(localStorage.getItem('dashboard-theme'));
    }
    return false;
  }
 
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  };

}
