import { Pipe, PipeTransform } from '@angular/core';
import moment = require('moment');

@Pipe({
  name: 'startEndWeekDate'
})
export class StartEndWeekDatePipe implements PipeTransform {

  transform(date: string): string {
    let selectedWeeks: string[] = [];
    let currentDate = moment().format('YYYY-MM-DD');
    let weeksDates: any[] = date.split(', ');
    for(let i = 0; i < weeksDates.length; i++) {
      if(currentDate >= moment(weeksDates[i]).startOf('isoWeek').format('YYYY-MM-DD') && currentDate <= moment(weeksDates[i]).endOf('isoWeek').format('YYYY-MM-DD')) {
        selectedWeeks.push('This week');
      }
      else {
        selectedWeeks.push(`${moment(weeksDates[i]).startOf('isoWeek').format('MMM DD')} - ${moment(weeksDates[i]).endOf('isoWeek').format('MMM DD')}`);
      }
    }
    return selectedWeeks.toString();
  }

}
