import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'form-claim-details',
  templateUrl: './form-claim-details.component.html',
  styleUrls: ['./form-claim-details.component.scss']
})
export class FormClaimDetailsComponent {
  @Input() formGroup: FormGroup;

  //Get form value
  formData(formKey: string) {
    return this.formGroup.controls[formKey].value;
  };

  getTypeOfCalm(id: number) {
    const obj: any = {'1': 'Liability – Physical Damage', '2': 'Cargo', '3': 'Physical Damage', '4': 'Liability – Personal Injury',
    '5': 'Liability – Property Damage', '6': 'OCC/ACC'};
    if(id) {
      return obj[id];
    }
    return '';
  };

}
