import { Component, Input } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ClaimsService } from '@app/modules/claims/services/claims.service';

@Component({
  selector: 'form-claim-details',
  templateUrl: './form-claim-details.component.html',
  styleUrls: ['./form-claim-details.component.scss']
})
export class FormClaimDetailsComponent {
  @Input() formGroup: FormGroup;

  constructor(private claimsService: ClaimsService) { }

  selectClaimType(value: number) {
    this.formGroup.patchValue({'type_of_claim': value})
    this.clearValidatorsByType(value);
    if(value === 1) {
      this.setValidators('cr_l_physc_d_our_vehicle', [Validators.required, Validators.minLength(2)]);
      this.setValidators('cr_l_physc_d_other_vehicle', [Validators.required, Validators.minLength(2)]);

      if(this.formData('cr_l_physc_d_our_vehicle') === 'Yes') {
        this.setValidators('cr_l_physc_d_our_vehicle_driveable_condition', [Validators.required, Validators.minLength(2)]);
      }

      if(this.formData('cr_l_physc_d_other_vehicle') === 'Yes') {
        this.setValidators('cr_l_physc_d_other_vehicle_driveable_condition', [Validators.required, Validators.minLength(2)]);
      }

      if(this.formData('cr_l_physc_d_our_vehicle_this_where_vehicle_towed') === 'No') {
        this.setValidators('cr_l_physc_d_our_vehicle_name_of_facility', [Validators.required, Validators.minLength(2)]);
        this.setValidators('cr_l_physc_d_our_vehicle_address', [Validators.required, Validators.minLength(2)]);
        this.setValidators('cr_l_physc_d_our_vehicle_city', [Validators.required, Validators.minLength(2)]);
        this.setValidators('cr_l_physc_d_our_vehicle_state', [Validators.required, Validators.minLength(2)]);
        this.setValidators('cr_l_physc_d_our_vehicle_zipcode', [Validators.required, Validators.minLength(2)]);
      }

    };
    if(value === 3) {
      this.setValidators('cr_pd_our_vehicle', [Validators.required, Validators.minLength(2)]);
      if(this.formData('cr_pd_our_vehicle') === 'Yes') {
        this.setValidators('cr_pd_our_vehicle_driveable_condition', [Validators.required, Validators.minLength(2)]);
      }

      if(this.formData('cr_pd_our_vehicle_this_where_vehicle_taken') === 'No') {
        this.setValidators('cr_pd_our_vehicle_name_of_facility', [Validators.required, Validators.minLength(2)]);
        this.setValidators('cr_pd_our_vehicle_address', [Validators.required, Validators.minLength(2)]);
        this.setValidators('cr_pd_our_vehicle_city', [Validators.required, Validators.minLength(2)]);
        this.setValidators('cr_pd_our_vehicle_state', [Validators.required, Validators.minLength(2)]);
        this.setValidators('cr_pd_our_vehicle_zipcode', [Validators.required, Validators.minLength(2)]);
      }
    };
    if(value === 5) {
      this.setValidators('cr_l_property_d_our_vehicle', [Validators.required, Validators.minLength(2)]);
      if(this.formData('cr_l_property_d_our_vehicle') === 'Yes') {
        this.setValidators('cr_l_property_d_our_vehicle_driveable_condition', [Validators.required, Validators.minLength(2)]);
      }

      if(this.formData('cr_l_property_d_our_vehicle_this_where_vehicle_taken') === 'No') {
        this.setValidators('cr_l_property_d_our_vehicle_name_of_facility', [Validators.required, Validators.minLength(2)]);
        this.setValidators('cr_l_property_d_our_vehicle_address', [Validators.required, Validators.minLength(2)]);
        this.setValidators('cr_l_property_d_our_vehicle_city', [Validators.required, Validators.minLength(2)]);
        this.setValidators('cr_l_property_d_our_vehicle_state', [Validators.required, Validators.minLength(2)]);
        this.setValidators('cr_l_property_d_our_vehicle_zipcode', [Validators.required, Validators.minLength(2)]);
      }

      this.setValidators('cr_pd_name_of_facility', [Validators.required, Validators.minLength(2)]);
      this.setValidators('cr_pd_address', [Validators.required, Validators.minLength(2)]);
      this.setValidators('cr_pd_city', [Validators.required, Validators.minLength(2)]);
      this.setValidators('cr_pd_state', [Validators.required, Validators.minLength(2)]);
      this.setValidators('cr_pd_zipcode', [Validators.required, Validators.minLength(2)]);
    };
  };
  
  chooseClaimant(value: string) {
    this.formGroup.patchValue({'claimant': value});
    if(value === 'Third Party') {
      this.clearValidators('insurance_company_name');
      this.clearValidators('insurance_contact_name');

      this.setValidators('third_party_claimant_full_name', [Validators.required, Validators.minLength(2)]);
    }
    if(value === 'Insurance Company') {
      this.clearValidators('third_party_claimant_full_name');
      this.setValidators('insurance_company_name', [Validators.required, Validators.minLength(2)]);
      this.setValidators('insurance_contact_name', [Validators.required, Validators.minLength(2)]);
    }
  };

  clearValidatorsByType(type: number) {
    if(type === 1) {
      this.clearValidators('cr_pd_our_vehicle');
      this.clearValidators('cr_pd_our_vehicle_driveable_condition');
      this.clearValidators('cr_pd_our_vehicle_name_of_facility');
      this.clearValidators('cr_pd_our_vehicle_address');
      this.clearValidators('cr_pd_our_vehicle_city');
      this.clearValidators('cr_pd_our_vehicle_state');
      this.clearValidators('cr_pd_our_vehicle_zipcode');

      this.clearValidators('cr_l_property_d_our_vehicle');
      this.clearValidators('cr_l_property_d_our_vehicle_driveable_condition');
      this.clearValidators('cr_l_property_d_our_vehicle_name_of_facility');
      this.clearValidators('cr_l_property_d_our_vehicle_address');
      this.clearValidators('cr_l_property_d_our_vehicle_city');
      this.clearValidators('cr_l_property_d_our_vehicle_state');
      this.clearValidators('cr_l_property_d_our_vehicle_zipcode');
      this.clearValidators('cr_pd_name_of_facility');
      this.clearValidators('cr_pd_address');
      this.clearValidators('cr_pd_city');
      this.clearValidators('cr_pd_state');
      this.clearValidators('cr_pd_zipcode');
    
    };
    if(type === 3) {
      this.clearValidators('cr_l_physc_d_our_vehicle');
      this.clearValidators('cr_l_physc_d_other_vehicle');
      this.clearValidators('cr_l_physc_d_our_vehicle_driveable_condition');
      this.clearValidators('cr_l_physc_d_other_vehicle_driveable_condition');
      this.clearValidators('cr_l_physc_d_our_vehicle_name_of_facility');
      this.clearValidators('cr_l_physc_d_our_vehicle_address');
      this.clearValidators('cr_l_physc_d_our_vehicle_city');
      this.clearValidators('cr_l_physc_d_our_vehicle_state');
      this.clearValidators('cr_l_physc_d_our_vehicle_zipcode');

      this.clearValidators('cr_l_property_d_our_vehicle');
      this.clearValidators('cr_l_property_d_our_vehicle_driveable_condition');
      this.clearValidators('cr_l_property_d_our_vehicle_name_of_facility');
      this.clearValidators('cr_l_property_d_our_vehicle_address');
      this.clearValidators('cr_l_property_d_our_vehicle_city');
      this.clearValidators('cr_l_property_d_our_vehicle_state');
      this.clearValidators('cr_l_property_d_our_vehicle_zipcode');
      this.clearValidators('cr_pd_name_of_facility');
      this.clearValidators('cr_pd_address');
      this.clearValidators('cr_pd_city');
      this.clearValidators('cr_pd_state');
      this.clearValidators('cr_pd_zipcode');
    };
    if(type === 5) {
      this.clearValidators('cr_l_physc_d_our_vehicle');
      this.clearValidators('cr_l_physc_d_other_vehicle');
      this.clearValidators('cr_l_physc_d_our_vehicle_driveable_condition');
      this.clearValidators('cr_l_physc_d_other_vehicle_driveable_condition');
      this.clearValidators('cr_l_physc_d_our_vehicle_name_of_facility');
      this.clearValidators('cr_l_physc_d_our_vehicle_address');
      this.clearValidators('cr_l_physc_d_our_vehicle_city');
      this.clearValidators('cr_l_physc_d_our_vehicle_state');
      this.clearValidators('cr_l_physc_d_our_vehicle_zipcode');

      this.clearValidators('cr_pd_our_vehicle');
      this.clearValidators('cr_pd_our_vehicle_driveable_condition');
      this.clearValidators('cr_pd_our_vehicle_name_of_facility');
      this.clearValidators('cr_pd_our_vehicle_address');
      this.clearValidators('cr_pd_our_vehicle_city');
      this.clearValidators('cr_pd_our_vehicle_state');
      this.clearValidators('cr_pd_our_vehicle_zipcode');
    }

  };

  findAccidentIfExist(event: any) {
    this.claimsService.getIncidentByClaimNumber(this.formData('claim')).subscribe((response: any) => {
      console.log(response)
      if(response.id) {
        this.formGroup.patchValue({
          'load_no': response.load_no,
          'truck_no': response.unit_no,
          'trailer_no': response.equipment_no,
          'driver_no': response.driver,
          'accident_id': response.id,
          'date_of_loss': response.date_occured,
          'los_city': response.location_city,
          'los_state': response.location_state,
          'los_zipcode': response.location_zip,
          'fault_for_the_loss': response.fault_for_incident
        });
      }

      if ((event.key === 'Backspace' || event.key === 'Delete') && this.formData('accident_id')) {
        this.resetAutocomplete();
      }
      
    });
  };

  resetAutocomplete() {
    this.formGroup.patchValue({
      'load_no': null,
      'truck_no': null,
      'trailer_no': null,
      'driver_no': null,
      'accident_id': 0,
      'date_of_loss': null,
      'los_city': null,
      'los_state': null,
      'los_zipcode': null,
      'fault_for_the_loss': null
    });
  }

  setValidators(key: string, validators: any[]) {
    this.formGroup.controls[key].setValidators(validators);
    this.formGroup.controls[key].updateValueAndValidity();
  };

  clearValidators(key: string) {
    this.formGroup.controls[key].clearValidators();
    this.formGroup.controls[key].updateValueAndValidity();
  };

  //Check validation
  checkValidation(key: string): boolean {
    return !this.formGroup.controls[key]?.valid && this.formGroup.controls[key]?.touched; 
  };

  //Get form value
  formData(formKey: string) {
    return this.formGroup.controls[formKey].value;
  };

  getTypeOfCalm(id: number) {
    const obj: any = {'1': 'Liability – Physical Damage', '2': 'Cargo', '3': 'Physical Damage', '4': 'Liability – Personal Injury',
    '5': 'Liability – Property Damage', '6': 'OCC/ACC'};
    if(id) {
      return obj[id];
    }
    return '';
  };

}
