import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReportsService } from '@app/modules/reports/services/reports.service';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { catchError, Subscription, throwError } from 'rxjs';

@Component({
  selector: 'app-create-update-amortization',
  templateUrl: './create-update-amortization.component.html',
  styleUrls: ['./create-update-amortization.component.scss']
})
export class CreateUpdateAmortizationComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[59].data;

  //Truck amortization group
  amortizationFormGroup: any = this._formBuilder.nonNullable.group({
    Rbr: [''],
    BankName: [''],
    TruckTrailer: ['', [Validators.required, Validators.minLength(3)]],
    TypeofEquipment: [''],
    CompanyLoan: [''],
    LoanAmount: [null],
    MonthlyPay: [null],
    PurchasedDate: [null],
    EndDate: [null],
    PayOffAmountLeft: [null],
    InterestRate: [null],
    Year: [null],
    Type: [''],
    VINNO: ['']
  });

  //Trucks trailers
  truckTrailersArray: any[] = [];

  //Equipment type
  equipmentTypeArray: any[] = [
    {name: 'Truck'},
    {name: 'Trailer'}
  ];

  //Company loan
  companyLoanArray: any[] = [];

  //Multiple clicks
  multipleClicks: boolean = true;

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<CreateUpdateAmortizationComponent>,
              private reportsService: ReportsService,
              private sharedService: SharedService,
              private _formBuilder: FormBuilder,
              private rulesService: RulesService) { }

  ngOnInit(): void {
    if(this.obj.editMode) {
      this.amortizationFormGroup.patchValue(this.obj.data);
    };
    this.getTrucksAndTrailers();
    this.getDivisions();
    console.log(this.obj)
  };

  getTrucksAndTrailers() {  
    this.subscription1 = this.sharedService.getTrucksAndTrailersData(true).subscribe((response: any) => {
      this.truckTrailersArray = response[0].concat(response[1]);
    });
  };

  getDivisions() {
    this.subscription2 = this.sharedService.getCompanyData().subscribe((response: any) => {
      this.companyLoanArray = response.divisions;
    });
  };

  saveData() {
    this.amortizationFormGroup.markAllAsTouched()
    if(this.amortizationFormGroup.valid && this.multipleClicks) {
      this.multipleClicks = false;
      console.log(this.amortizationFormGroup.value)
      this.reportsService[this.obj.editMode ? 'updateAmortization' : 'createAmortization'](this.amortizationFormGroup.value)
      .pipe(catchError((err: any) => {
          this.showErrorMessage();
          this.multipleClicks = true;
          return throwError(() => err);
        }))
      .subscribe((response: any) => {
        console.log(response);
        if(response?.TruckTrailer) {
          this.dialogRef.close(response);
        }
        else {
          this.showErrorMessage();
        }
      }); 
    }
  };

  deleteAmortization() {
    if(this.permissions[0].sectionArray[2].allowed) {
      let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
        autoFocus: false,
        panelClass: 'delete-dialog-container',
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.reportsService.deleteAmortization(this.formData('TruckTrailer'))
          .pipe(catchError((err: any) => {
            this.showErrorMessage();
            return throwError(() => err);
          }))
          .subscribe((response: boolean) => {
            console.log(response)
            if(response) {
              this.dialogRef.close('DELETE AMORTIZATION');
            }
            else {
              this.showErrorMessage();
            }
          });
        }
      });
    }
    else {
      this.showForbiddenMessage();
    }
  };

  //Show error message
  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  //Show forbidden message
  showForbiddenMessage() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  //Get form value
  formData(formKey: string) {
    return this.amortizationFormGroup.controls[formKey].value;
  };

  checkValidation(key: string): boolean {
    return !this.amortizationFormGroup.controls[key].valid && this.amortizationFormGroup.controls[key]?.touched; 
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  };

}
