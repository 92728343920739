import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, catchError, throwError } from 'rxjs';
import { ReserveSpotDialogComponent } from '../reserve-spot-dialog/reserve-spot-dialog.component';
import moment = require('moment');
import { TruckArriveDialogComponent } from './truck-arrive-dialog/truck-arrive-dialog.component';
import { EditReasonsDialogComponent } from './edit-reasons-dialog/edit-reasons-dialog.component';
import { MessageSpotsDialogComponent } from '../message-spots-dialog/message-spots-dialog.component';
import { AskForApprovalDialogComponent } from '../ask-for-approval-dialog/ask-for-approval-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { SuccessDialogComponent } from '@app/modules/shared/components/success-dialog/success-dialog.component';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { FleetHealthService } from '@app/modules/fleet-health/services/fleet-health.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import * as L from 'leaflet';

@Component({
  selector: 'app-spots',
  templateUrl: './spots.component.html',
  styleUrls: ['./spots.component.scss']
})

export class SpotsComponent implements OnInit, OnDestroy {
  //Select menu
  dropMenuArray: any[] = [
    {name: 'On time'},
    {name: 'Resolved'},
    {name: 'Reschedule'},
    {name: 'Late'},
    {name: 'Arrived'}
  ];

  //Spot cards data
  spotCardsArray: any[] = [];
  availableSpots: number = 0;
  reservedSpots: number = 0;
  availableSpotsArray: number[] = [];

  //Spot date
  spotDate = new Date(moment().format('YYYY-MM-DDT00:00:00'));
  date: any;

  //Map active
  mapActiveIndex: number = -1;
  activeObj: any;
  mapOrder: number = 4;

  //Interval
  routeInterval: any;

  //Timeout
  timeout: any;

  //Section index
  sectionIndex: number = 0;

  //Due date
  dateForServer: string | undefined = undefined;

  //Report menu
  isOpenReportMenu: boolean = false;
  reportDate: string = '';

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      -10, 5
    )
  ];

  //Loader
  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription1: Subscription | any;
  subscription2: Subscription | any;
  subscription3: Subscription | any;

  @ViewChild('mapContainer') mapContainer: ElementRef;

  constructor(private fleetHealthService: FleetHealthService,
              private dialog: MatDialog,
              private transformService: TransformService,
              private spinner: NgxSpinnerService,
              private renderer: Renderer2) { }

  ngOnInit(): void {
    this.reserveOnDate();
  }

  reserveOnDate() {
    this.subscription1 = this.fleetHealthService.dateSubject.subscribe((data: any) => {
      console.log(data);
      this.spotDate = new Date(data.obj.date);
      this.reportDate = data.obj.date;
      if(data.showSpinner) {
        this.mapActiveIndex = -1;
        this.loaded = false;
        this.spinner.show('spots-spinner');
      }
      this.getSpotData(data);
    })
  }

  getSpotData(data: any) {
    let num: number = data.obj.slots === -1 ? 0 : data.obj.slots;
    this.availableSpots = num - data.obj.available;
    this.reservedSpots = data.obj.available;
    this.availableSpotsArray = Array(this.availableSpots).fill(1);

    this.subscription2 = this.fleetHealthService.getSpotsByDay(data.obj.date)
    .pipe(catchError((err: any) => {
      this.spinner.hide('spots-spinner');
      this.loaded = true;
      this.error = true;
      this.fleetHealthService.dataReturnedSubject.next(true);
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      let array: any = [...response];
      for(let i = 0; i < array.length; i++) {
        this.getDistance(array[i]);
      };

      this.spotCardsArray = response;
      if(data.showSpinner) {
        this.spinner.hide('spots-spinner');
        this.loaded = true;
      }
      this.fleetHealthService.dataReturnedSubject.next(true);
    })
  }

  //Maps methods
  private initMap(obj: any, allTrucks: boolean): void {
    let mapDiv = document.createElement('div');
    mapDiv.setAttribute('id', 'map');
    mapDiv.style.borderTopLeftRadius = '15px';
    mapDiv.style.borderBottomLeftRadius = '15px';
    mapDiv.style.height = '100%';
    this.renderer.appendChild(this.mapContainer.nativeElement, mapDiv);

    let zoomLevel: number = allTrucks ? 5 : 14;
    let center: any = allTrucks ? [37.920746, -91.020687] : [obj.latitude, obj.longitude];
    const map = L.map('map', {
      center: center,
      zoom: zoomLevel
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });
    tiles.addTo(map);
    
    if(allTrucks) {
      let coordinatesArray: any[] = [];
      for(let key in this.spotCardsArray) {
        const marker = L.marker([this.spotCardsArray[key].latitude, this.spotCardsArray[key].longitude],
          {
            icon: new L.DivIcon({
              className: 'my-div-icon',
              html: `<div style="background-color: #003fa4; color: #fff; text-align: center; padding: 3px; font-size: 16px; font-weight: bolder; border-radius: 10px; font-family: 'Poppins';">${ this.spotCardsArray[key].unit_no }</div>
              <img style="width: 90px; height: 90px;" src="./assets/img/truck_pin.svg"/>`,
              iconSize: [90, 90],
              iconAnchor: [45, 90]
          })
          }
        );
        marker.addTo(map).on('click', () => {
          this.activeObj = this.spotCardsArray[key];
        });
        coordinatesArray.push([this.spotCardsArray[key].latitude, this.spotCardsArray[key].longitude]);
      }

      map.fitBounds(coordinatesArray)
     }
    else {      
      const marker = L.marker([obj.latitude, obj.longitude],
        {
          icon: new L.DivIcon({
            className: 'my-div-icon',
            html: `<div style="background-color: #003fa4; color: #fff; text-align: center; padding: 3px; font-size: 16px; font-weight: bolder; border-radius: 10px; font-family: 'Poppins';">${ obj.unit_no }</div>
            <img style="width: 90px; height: 90px;" src="./assets/img/truck_pin.svg"/>`,
            iconSize: [90, 90],
            iconAnchor: [45, 90]
        })
        }
      );
      marker.addTo(map);
      this.routeInterval = setInterval(() => {
        this.fleetHealthService.getTruckLocation(obj.unit_no, obj.division_id).subscribe((response: any) => {
        obj.latitude = response[0].latitude;
        obj.longitude = response[0].longitude;
        marker.setLatLng(new L.LatLng(response[0].latitude, response[0].longitude));
        map.setView(marker.getLatLng());
        this.getDistance(obj);
        })
      }, 1000);
    }
    this.activeObj = obj;
	};

  selectOption(obj: any, statusObj: any) {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'))
    let dataObj: any = {id: obj.id, status: statusObj.name, createdBy: loggedUser.first_name + ' ' + loggedUser.last_name, 
    createdDate: moment().format('YYYY-MM-DDTHH:mm:ss')};
    if(statusObj.name === 'Late') {
      let dialogRef = this.dialog.open(TruckArriveDialogComponent, {
        autoFocus: false,
        panelClass: 'truck-arrive-dialog-container'
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          let date: any = result;
          let data: any = {
            id: obj.id, 
            createdBy: loggedUser.first_name + ' ' + loggedUser.last_name,
            date: date, 
            createdDate: moment().format('YYYY-MM-DDTHH:mm:ss')
          };
          this.fleetHealthService.changeTruckStatus(dataObj).subscribe((response: any) => {
            if(response) {
              obj.status = statusObj.name;
            }
            console.log(response);
          });
          
          this.fleetHealthService.rescheduleArriveDateReserveSpot(data).subscribe((response: any) => {
            if(response) {
              obj.date = date;
            }
          })
        }
      });
    }
    else {
      this.fleetHealthService.changeTruckStatus(dataObj).subscribe((response: any) => {
        if(response) {
          obj.status = statusObj.name;
        }
        console.log(response);
      });
    }
    obj.isOpenMenu = false;
  };

  reserveSpotDialog(isEdit: boolean, data: any) {
    if(this.availableSpots > 0 || isEdit) {
      let dialogRef = this.dialog.open(ReserveSpotDialogComponent, {
        autoFocus: false,
        panelClass: 'reserve-spot-dialog-container',
        data: {editMode: isEdit, obj: data, spotDate: this.spotDate},
        disableClose: true
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.fleetHealthService.refreshDataSubject.next(true);
        }
      });
    }
    else {
      this.dialog.open(MessageSpotsDialogComponent, {
        autoFocus: false,
        panelClass: 'message-spot-dialog-container'
      });
    }
  }

  askForApprovalDialog() {
    let dialogRef = this.dialog.open(AskForApprovalDialogComponent, {
      autoFocus: false,
      panelClass: 'ask-for-approval-dialog-container',
      data: {fillForm: false, obj: '', spotDate: this.spotDate},
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.fleetHealthService.refreshDataSubject.next(true);
      }
    });
  }

  editReasonsDialog(obj: any) {
    let dialogRef = this.dialog.open(EditReasonsDialogComponent, {
      autoFocus: false,
      panelClass: 'edit-reasons-dialog-container',
      data: obj
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.fleetHealthService.refreshDataSubject.next(true);
      }
    });
  };

  getDistance(obj: any) {
    let distance = this.transformService.addCommasDots(obj.distance * 0.000621371192) + ' mi' ;
    let duration = this.secondsToDhms(obj.duration);
    obj.distance_from_yard = distance;
    obj.eta = duration;
  }

  secondsToDhms(seconds: number) {
    seconds = Number(seconds)
    let d = Math.floor(seconds / (3600 * 24))
    let h = Math.floor((seconds % (3600 * 24)) / 3600)
    let m = Math.floor((seconds % 3600) / 60)
    let s = Math.floor(seconds % 60)
    let eta: string = '';
    if(d > 0) {
      eta = `${d}d ${h}h ${m}m ${s}s`;
    }
    else if(h > 0) {
      eta = `${h}h ${m}m ${s}s`;
    }
    else if(m > 0) {
      eta = `${m}m ${s}s`;
    }
    else {
      eta = `${s}s`;
    };

    return eta;
  }

  showMap(obj: any, i: number, allTrucks: boolean) {
    const parentElement: HTMLElement = document.getElementById('spot-cards');
    const numOfCards: number = Math.round(parentElement.parentNode.parentElement.offsetWidth / 305);
    for(let j = 0; j < this.spotCardsArray.length; j++) {
      if((i+1) <= (numOfCards * (j+1))) {
        this.mapOrder = numOfCards * (j+1);
        break;
      };
    };
    let mapDiv: any = document.getElementById('map');
    if(mapDiv){
      mapDiv.remove();
    };
    if(this.mapActiveIndex === i && !allTrucks ) {
      this.mapActiveIndex = -1;
      clearInterval(this.routeInterval);
    }
    else {
      this.mapActiveIndex = i;
      let init = () => {
        this.initMap(obj, allTrucks);
      }
      this.timeout = setTimeout(init, 100);
    }
  }

  
  //Set shadow class
  setShadowClass(obj: any) {
    if(obj.status === 'Arrived') {
      return 'status-arrived';
    }
    else if(obj.status === 'Late') {
      return 'status-late';
    }
    else {
      return 'status-driving';
    }
  };

  //Calendar
  dateClicked(event: any, obj: any) {
    obj.newDueDate = moment(event._d).format('MMM DD, yyyy.');
    this.dateForServer = moment(event._d).format('yyyy-MM-DD');
    obj.isOpen = false;
  }

  reschedule(data: any) {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let date: string = `${this.dateForServer}T${data.date.split('T')[1]}`;
    let obj: any = {
      id: data.id, 
      createdBy: loggedUser.first_name + ' ' + loggedUser.last_name,
      date: date, 
      createdDate: moment().format('YYYY-MM-DDTHH:mm:ss')
    };
    this.fleetHealthService.rescheduleArriveDateReserveSpot(obj).subscribe((response: any) => {
      if(response) {
        this.fleetHealthService.refreshDataSubject.next(true);
      }
    })
  }

  //Open file in new tab

  openFileInNewTab(obj: any) {
    let extension: string = obj.filename.substring(obj.filename.length - 3);
    let base64: any = obj.data;
    if(extension === 'pdf') {
      const anchor = document.createElement('a');
      document.body.appendChild(anchor);
      anchor.style.display = 'none';
      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      anchor.href = url; 
      anchor.target = '_blank';
      anchor.click();
      window.URL.revokeObjectURL(url);
    }
    else {
      let image = document.createElement('img');
      image.src = `data:image/${extension};base64,` + base64;
      image.setAttribute('style', 'width: 100%')
      let w = window.open("");
      w.document.write(image.outerHTML);
    }
  }

  sendReport() {
    this.fleetHealthService.sendReport(this.reportDate).subscribe((response: any) => {
      if(response) {
        this.dialog.open(SuccessDialogComponent, {
          autoFocus: false,
          panelClass: 'success-dialog-container'
        });  
      }
      else {
        this.dialog.open(WarningMsgDialogComponent, {
          autoFocus: false,
          panelClass: 'warning-msg-dialog-container'
        });
      }
    });
  };

  ngOnDestroy(): void {
    clearInterval(this.routeInterval);
    clearTimeout(this.timeout);
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
  };

}
