import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IClaims } from '@app/modules/claims/models/claim.model';
import { ClaimsService } from '@app/modules/claims/services/claims.service';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, Subscription, throwError } from 'rxjs';

@Component({
  selector: 'app-claims-dialog',
  templateUrl: './claims-dialog.component.html',
  styleUrls: ['./claims-dialog.component.scss']
})
export class ClaimsDialogComponent implements OnInit, OnDestroy {
  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
  fullName: string = `${this.loggedUser.first_name} ${this.loggedUser.last_name}`;
  claimsFormGroup: FormGroup;
  permissions: any = this.rulesService.UserData[57].data;

  tabsArray: any[] = [
    {name: 'Claim Details'}, {name: 'Claim Resolution'}, {name: 'Files'}
  ];
  activeTab: string = 'Claim Details';

  //Locations array
  locationsArray: any[] = [];

  //Multiple clicks
  multipleClicks: boolean = true;

  //Edit mode
  editMode: boolean = false;

  loaded: boolean = true;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  loading: boolean = false;

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any,
              private transformService: TransformService,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<ClaimsDialogComponent>,
              private _formBuilder: FormBuilder,
              private claimsService: ClaimsService,
              private rulesService: RulesService,
              private spinner: NgxSpinnerService) {
    this.claimsFormGroup = this._formBuilder.nonNullable.group({
      'id': [0],
      'accident_id': [this.obj.accident_id],
      
      //Basic Details
      'load_no': [null, Validators.required],
      'date_of_loss': [null, Validators.required],
      'truck_no': [null, Validators.required],
      'trailer_no': [null, Validators.required],
      'driver_no': [null, Validators.required],
      'los_name_of_facility': [''],
      'los_address': [''],
      'los_city': [''],
      'los_state': [''],
      'los_zipcode': [''],
      'fault_for_the_loss': [null, Validators.required],

      //Claim Details
      'claim': [null, Validators.required],
      'type_of_claim': [null, Validators.required],
      'claim_opened': [null, Validators.required],
      'claim_closed': [null],
      'status': [''],
      'claimant': [null, Validators.required],
      'third_party_claimant_full_name': [''],
      'third_party_address': [''],
      'third_party_phone': [''],
      'third_party_email': [''],
      'third_party_claim_amount': [0],
      'insurance_company_name': [''],
      'insurance_contact_name': [''],
      'insurance_address': [''],
      'insurance_phone': [''],
      'insurance_ext': [''],
      'insurance_email': [''],
      'cd_comments': [null, [Validators.required, Validators.minLength(2)]],
      'cd_contacts': this._formBuilder.array([]),
      'cd_closed': [false],

      //Claim Resolution
      //Liability – Physical Damage - Damage to our vehicle
      'cr_l_physc_d_our_vehicle': [''],
      'cr_l_physc_d_our_vehicle_explanation': [''],
      'cr_l_physc_d_our_vehicle_driveable_condition': [''],
      'cr_l_physc_d_our_vehicle_repair_estimate': [0],
      'cr_l_physc_d_our_vehicle_photos': this._formBuilder.array([]),
      'cr_l_physc_d_our_vehicle_towed': [''],
      'cr_l_physc_d_our_vehicle_towing_company_name': [''],
      'cr_l_physc_d_our_vehicle_contact_name': [''],
      'cr_l_physc_d_our_vehicle_contact_phone': [''],
      'cr_l_physc_d_our_vehicle_contact_email': [''],
      'cr_l_physc_d_our_vehicle_contact_towing_yard_address': [''],
      'cr_l_physc_d_our_vehicle_this_where_vehicle_towed': [''],
      'cr_l_physc_d_our_vehicle_where_vehicle_towed': [''],
      'cr_l_physc_d_our_vehicle_name_of_facility': [''],
      'cr_l_physc_d_our_vehicle_address': [''],
      'cr_l_physc_d_our_vehicle_city': [''],
      'cr_l_physc_d_our_vehicle_state': [''],
      'cr_l_physc_d_our_vehicle_zipcode': [''],
      'cr_l_physc_d_our_vehicle_final_cost': [0],
      'cr_l_physc_d_our_vehicle_driver_charged_for_the_damage': [''],
      'cr_l_physc_d_our_vehicle_details_of_the_charge': [''],
      'cr_what_happened_to_our_vehicle': [''],

      //Liability – Physical Damage - Damage to the other vehicle
      'cr_l_physc_d_other_vehicle': [''],
      'cr_l_physc_d_other_vehicle_explanation': [''],
      'cr_l_physc_d_other_vehicle_driveable_condition': [''],
      'cr_l_physc_d_other_vehicle_repair_estimate': [0],
      'cr_l_physc_d_other_vehicle_photos': this._formBuilder.array([]),
      'cr_l_physc_d_other_vehicle_towed': [''],
      'cr_l_physc_d_other_vehicle_towing_company_name': [''],
      'cr_l_physc_d_other_vehicle_contact_name': [''],
      'cr_l_physc_d_other_vehicle_contact_phone': [''],
      'cr_l_physc_d_other_vehicle_contact_email': [''],
      'cr_l_physc_d_other_vehicle_contact_towing_yard_address': [''],
      'cr_l_physc_d_other_vehicle_this_where_vehicle_taken': [''],
      'cr_l_physc_d_what_happened_to_other_vehicle': [''],

      //Liability – Physical Damage - Cargo Claim
      'cr_cc_reason': [''],
      'cr_cc_attachment_from_receiver': this._formBuilder.array([]),
      'cr_cc_reported_to_insurance': [''],
      'cr_cc_reported_to_insurance_when': [null],
      'cr_cc_adjuster_details': [''],
      'cr_cc_load_invoiced': [''],
      'cr_cc_is_received_payment': [''],
      'cr_cc_claim_amount_deducted': [''],
      'cr_cc_deducted_amount': [0],
      'cr_cc_any_other_payment_this_broker': [''],
      'cr_cc_give_details': [''],

      'cr_cc_overs_noted': this._formBuilder.array([]),
      'cr_cc_overs_details': [''],

      'cr_cc_shortages_slc': [''],
      'cr_cc_shortages_trailer_doors_sealer': [''],
      'cr_cc_shortages_seal_number': [''],
      'cr_cc_shortages_seal_number_on_the_bol': [''],
      'cr_cc_shortages_broker_informed': [false],
      'cr_cc_shortages_sealed_broker_confirmation': [''],
      'cr_cc_shortages_details': [''],

      'cr_cc_damage_discovered_details': [''],
      'cr_cc_damage_when_damage_discovered': [null],
      'cr_cc_damage_who_discovered_damage': [''],
      'cr_cc_damage_is_photo_taken': [''],
      'cr_cc_damage_upload_photos': this._formBuilder.array([]),
      'cr_cc_damage_cargo_status': [''],

      'cr_cc_unloaded_documents_from_receiver': [''],
      'cr_cc_unloaded_provide_details': [''],

      'cr_cc_load_refused_current_load_location': [''],
      'cr_cc_load_refused_instructions_from_the_broker': [''],
      'cr_cc_load_refused_comments_from_the_broker': [''],

      'cr_cc_partillay_unloaded_location_state_of_remaining_cargo': [''],
      'cr_cc_partillay_unloaded_instructions_from_the_broker': [''],
      'cr_cc_partillay_unloaded_brokers_instructions': [''],
      
      'cr_cc_load_overturned': [''],
      'cr_cc_load_overturned_organize': [''],
      'cr_cc_load_overturned_organize_details': [''],
      'cr_cc_load_overturned_cost_restacking_transloading': [''],
      'cr_cc_load_overturned_who_pay_restacking_transloading': [''],
      'cr_cc_load_overturned_pay_details': [''],

      'cr_cc_contamination_contamination_reported': [''],
      'cr_cc_contamination_contamination_reason': [''],
      'cr_cc_contamination_status_of_the_load': [''],
      'cr_cc_contamination_papework': this._formBuilder.array([]),

      'cr_cc_theft_datetime_dicovered_theft': [null],
      'cr_cc_theft_datetime_estimated_theft_happened': [null],
      'cr_cc_theft_driver_explanation': [''],
      'cr_cc_theft_driver_called_police': [''],
      'cr_cc_theft_theft_reported_type': [''],
      'cr_cc_theft_police_report_no': [''],
      'cr_cc_theft_police_dept_contact_name': [''],
      'cr_cc_theft_police_dept_contact_phone': [''],
      'cr_cc_theft_what_was_stolen': [''],
      'cr_cc_theft_broker_informed': [''],
      
      //Physical Damage
      'cr_pd_our_vehicle': [''],
      'cr_pd_our_vehicle_explanation': [''],
      'cr_pd_our_vehicle_driveable_condition': [''],
      'cr_pd_our_vehicle_repair_estimate': [0],
      'cr_pd_our_vehicle_photos': this._formBuilder.array([]),
      'cr_pd_our_vehicle_towed': [''],
      'cr_pd_our_vehicle_towing_company_name': [''],
      'cr_pd_our_vehicle_contact_name': [''],
      'cr_pd_our_vehicle_contact_phone': [''],
      'cr_pd_our_vehicle_contact_email': [''],
      'cr_pd_our_vehicle_contact_towing_yard_address': [''],
      'cr_pd_our_vehicle_this_where_vehicle_taken': [''],
      'cr_pd_our_vehicle_where_vehicle_taken': [''],
      'cr_pd_our_vehicle_name_of_facility': [''],
      'cr_pd_our_vehicle_address': [''],
      'cr_pd_our_vehicle_city': [''],
      'cr_pd_our_vehicle_state': [''],
      'cr_pd_our_vehicle_zipcode': [''],
      'cr_pd_our_vehicle_final_cost': [0],
      'cr_pd_our_vehicle_driver_charged_for_the_damage': [''],
      'cr_pd_our_vehicle_details_of_the_charge': [''],

      //Personal Injury
      'cr_lpi_injuries_in_the_accident': [''],
      'cr_lpi_paramedics': [''],
      'cr_lpi_who_called_paramedics': [''],
      'cr_lpi_is_someone_taken_in_ems_vehicle': [''],
      'cr_lpi_give_details': [''],

      //Property Damage
      'cr_l_property_d_our_vehicle': [''],
      'cr_l_property_d_our_vehicle_explanation': [''],
      'cr_l_property_d_our_vehicle_driveable_condition': [''],
      'cr_l_property_d_our_vehicle_repair_estimate': [0],
      'cr_l_property_d_our_vehicle_photos': this._formBuilder.array([]),
      'cr_l_property_d_our_vehicle_towed': [''],
      'cr_l_property_d_our_vehicle_towing_company_name': [''],
      'cr_l_property_d_our_vehicle_contact_name': [''],
      'cr_l_property_d_our_vehicle_contact_phone': [''],
      'cr_l_property_d_our_vehicle_contact_email': [''],
      'cr_l_property_d_our_vehicle_contact_towing_yard_address': [''],
      'cr_l_property_d_our_vehicle_this_where_vehicle_taken': [''],
      'cr_l_property_d_our_vehicle_where_vehicle_taken': [''],
      'cr_l_property_d_our_vehicle_name_of_facility': [''],
      'cr_l_property_d_our_vehicle_address': [''],
      'cr_l_property_d_our_vehicle_city': [''],
      'cr_l_property_d_our_vehicle_state': [''],
      'cr_l_property_d_our_vehicle_zipcode': [''],
      'cr_l_property_d_our_vehicle_final_cost': [0],
      'cr_l_property_d_our_vehicle_driver_charged_for_the_damage': [''],
      'cr_l_property_d_our_vehicle_details_of_the_charge': [''],

      'cr_pd_damage_explanation': [''],
      'cr_pd_damage_assessment': [''],
      'cr_pd_repair_estimate': [0],
      'cr_pd_attach_papework': this._formBuilder.array([]),
      'cr_pd_name_of_facility': [''],
      'cr_pd_address': [''],
      'cr_pd_city': [''],
      'cr_pd_state': [''],
      'cr_pd_zipcode': [''],
      'cr_pd_contact_name': [''],
      'cr_pd_contact_phone': [''],
      'cr_pd_contact_email': [''],
      'cr_pd_contact_towing_yard_address': [''],
      'cr_pd_any_other_details': [''],

      //Occupational/Accident Claim
      'cr_oa_driver_statement': [''],
      'cr_oa_any_injuries': [''],
      'cr_oa_injuries_details': [''],
      'cr_oa_papework_confirmation': [''],

      //Files tab
      'files': this._formBuilder.array([]),

      'created_date': [null],
      'creator': [''],
      'edited_date': [null],
      'editor': ['']
    })
  }

  ngOnInit(): void {
    if(this.obj.claimId) {
      this.getClaimById();
    }
    if(this.obj.initForm) {
      this.claimsFormGroup.patchValue({
        'load_no': this.obj.initForm.load_no ? this.obj.initForm.load_no : 'Empty',
        'truck_no': this.obj.initForm.unit_no,
        'trailer_no': this.obj.initForm.equipment_no,
        'driver_no': this.obj.initForm.driver,
        'accident_id': this.obj.initForm.id,
        'date_of_loss': this.obj.initForm.date_occured,
        'los_city': this.obj.initForm.location_city,
        'los_state': this.obj.initForm.location_state,
        'los_zipcode': this.obj.initForm.location_zip,
        'fault_for_the_loss': this.obj.initForm.fault_for_incident
      });
    };
    this.getZipCodes();
    console.log(this.obj);
  }

  getClaimById() {
    this.error = false;
    this.loaded = false;
    this.spinner.show('claims-dialog');
    this.subscription1 = this.claimsService.getClaimsById(this.obj.claimId)
    .pipe(catchError((err: any) => {
      this.spinner.hide('claims-dialog');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: IClaims) => {
      console.log(response);
      if(response.id) {
        this.editMode = true;
        this.claimsFormGroup.patchValue(response);
        this.initArray('cd_contacts', response.cd_contacts);
        this.initArray('cr_l_physc_d_our_vehicle_photos', response.cr_l_physc_d_our_vehicle_photos);
        this.initArray('cr_l_physc_d_other_vehicle_photos', response.cr_l_physc_d_other_vehicle_photos);
        this.initArray('cr_cc_attachment_from_receiver', response.cr_cc_attachment_from_receiver);
        this.initArray('cr_cc_overs_noted', response.cr_cc_overs_noted);
        this.initArray('cr_cc_damage_upload_photos', response.cr_cc_damage_upload_photos);
        this.initArray('cr_cc_contamination_papework', response.cr_cc_contamination_papework);
        this.initArray('cr_pd_our_vehicle_photos', response.cr_pd_our_vehicle_photos);
        this.initArray('cr_l_property_d_our_vehicle_photos', response.cr_l_property_d_our_vehicle_photos);
        this.initArray('cr_pd_attach_papework', response.cr_pd_attach_papework);
        this.initArray('files', response.files);
      }
      this.spinner.hide('claims-dialog');
      this.loaded = true;
    });
  };

  getZipCodes() {
    this.subscription2 = this.claimsService.getAllLocationInfo().subscribe((response: any[]) => {
      this.locationsArray = response;
    });
  };

  //Init array
  initArray(key: string, initArray: any[]) {
    let array: any[] = this.formArray(key)?.controls;
    for(let i = 0; i < initArray.length; i++) {
      const itemGroup = this._formBuilder.group(initArray[i]);
      array.push(itemGroup);
    }
  };

  saveUpdateData() {
    this.claimsFormGroup.markAllAsTouched();
    const formGroup: IClaims = this.claimsFormGroup.value;
    if(this.editMode) {
      formGroup.editor = this.fullName;
      formGroup.edited_date = moment().format('YYYY-MM-DDTHH:mm:ss');
    }
    else {
      formGroup.creator = this.fullName;
      formGroup.created_date = moment().format('YYYY-MM-DDTHH:mm:ss');
    }
    console.log(formGroup);
    console.log(this.claimsFormGroup)
    if(this.claimsFormGroup.valid && this.multipleClicks) {
      this.loading = true;
      this.multipleClicks = false;
      this.claimsService.createUpdateClaim(this.claimsFormGroup.value)
      .pipe(catchError((err: any) => {
        this.showErrorMessage();
        this.loading = false;
        this.multipleClicks = true;
        return throwError(() => err);
      }))
      .subscribe((response: any) => {
        if(response) {
          this.loading = true;
          this.dialogRef.close(true);
        }
        else {
          this.loading = false;
          this.showErrorMessage();
        }
      });
    }
  };
  
  deleteClaim() {
    if(this.permissions[0].sectionArray[2].allowed) { 
      let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
        autoFocus: false,
        panelClass: 'delete-dialog-container',
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.claimsService.deleteClaim(this.claimsFormGroup.value.id, this.fullName, moment().format('YYYY-MM-DDTHH:mm:ss'))
          .pipe(catchError((err: any) => {
            this.showErrorMessage();
            return throwError(() => err);
          }))
          .subscribe((response: boolean) => {
            if(response) {
              this.dialogRef.close(true);
            }
            else {
              this.showErrorMessage();
            }
          });
        }
      })
    }
    else {
      this.showForbiddenMessage()
    }
  };
  
  //Show error message
  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  //Show forbidden message
  showForbiddenMessage() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  //Get form value
  formData(formKey: string) {
    return this.claimsFormGroup.controls[formKey].value;
  };

  //Get form array
  formArray(formKey: string): FormArray {
    return this.claimsFormGroup.controls[formKey] as FormArray;
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();  
    this.subscription2?.unsubscribe();  
  };

}
