import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexStroke, ApexXAxis, ChartComponent } from 'ng-apexcharts';
import { Subscription } from 'rxjs';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { MaintenanceService } from '@app/modules/maintenance/services/maintenance.service';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  stroke: ApexStroke | any;
  markers: any;
  colors: any;
};

@Component({
  selector: 'app-breakdown-by-group-line-chart2',
  templateUrl: './breakdown-by-group-line-chart2.component.html',
  styleUrls: ['./breakdown-by-group-line-chart2.component.scss']
})
export class BreakdownByGroupLineChart2Component implements OnInit, OnDestroy {
  @Input() date: any;
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;

  selectPeriod: FormControl | any;

  selectedCards: any[] = [];
  graphData: any[] = [];

  //Period
  isOpenPeriodMenu: boolean = false;
  menuValue: string = 'By day';
  selectPeriodArray: any[] = [
    {name: 'By year', value: 'year'},
    {name: 'By quarter', value: 'quarter'},
    {name: 'By month', value: 'month'},
    {name: 'By week', value: 'week'},
    {name: 'By day', value: 'day'},
  ];

  subscription: Subscription | any;

  constructor(private maintenanceService: MaintenanceService, private transformService: TransformService) {
    this.selectPeriod = new FormControl(['day']);
  }

  ngOnInit(): void {
    this.dataChanged();
  }

  dataChanged() {
    this.subscription = this.maintenanceService.breakDownByGroupLineChart2.subscribe((response: any) => {
      this.selectedCards = response.selectedCards;
      this.graphData = response.data;
      this.initGraph(this.transformService.countDataParts2(response.data, this.selectPeriod.value[0]));
    })
  }

  initGraph(data: any) {
    let array: any[] = [
      {name: 'DRIVETRAIN', data: data.totalT1, selected: this.selectedCards[0].selected},
      {name: 'ELECTRICAL', data: data.totalT2, selected: this.selectedCards[1].selected},
      {name: 'BRAKE SYSTEMS', data: data.totalT3, selected: this.selectedCards[2].selected},
      {name: 'BODYWORK', data: data.totalT4, selected: this.selectedCards[3].selected},
      {name: 'MISCELLANEOUS', data: data.totalT5, selected: this.selectedCards[4].selected},
      {name: 'PREVENTIVE MAINTENANCE', data: data.totalT5, selected: this.selectedCards[5].selected},
    ];
    let seriesArray: any[] = [];
    for(let key in array) {
      if(array[key].selected) {
        seriesArray.push(array[key]);
      }
    }
    this.graphData = seriesArray.length === 0 ? this.graphData = [] : this.graphData
    this.chartOptions = {
      series: seriesArray,
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
          }
        },
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        },
        fontFamily: 'Poppins'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight",
        width: 1,
      },
      grid: {
        row: {
          colors: ["transparent", "transparent"],
          opacity: 0.5
        }
      },
      xaxis: {
        categories: data.categories
      },
      yaxis: {
        tooltip: {
          enabled: true
        },
        labels: {
          formatter: (num: number) => {
            let roundedNumber: any = Math.round(num);
            return roundedNumber.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          },
        },
      },
      markers: {
        size: 5
      },
      colors: ['#0030FF', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'],
    };
  }

  onlyOneSelect(value: string, obj: any) {
    this.menuValue = obj.name;
    this.selectPeriod = new FormControl([value]);
    this.initGraph(this.transformService.countDataParts2(this.graphData, this.selectPeriod.value[0]));
    this.isOpenPeriodMenu = false;
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
