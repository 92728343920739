import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccidentsService } from '@app/modules/accidents/services/accidents.service';
import { RemoveDuplicatesPipe } from '@app/modules/shared/pipes/remove-duplicates.pipe';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-claims-dialog',
  templateUrl: './claims-dialog.component.html',
  styleUrls: ['./claims-dialog.component.scss']
})
export class ClaimsDialogComponent implements OnInit, OnDestroy {
  claimsFormGroup: FormGroup;

  tabsArray: any[] = [
    {name: 'Claim Details'}, {name: 'Claim Resolution'}, {name: 'Files'}
  ];
  activeTab: string = 'Claim Details';

  //Locations array
  locationsArray: any[] = [];

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any,
              private _formBuilder: FormBuilder,
              private accidentsService: AccidentsService,
              private removeDuplicates: RemoveDuplicatesPipe) {
    this.claimsFormGroup = this._formBuilder.nonNullable.group({
      'id': [0],
      'accident_id': [0],
      
      //Basic Details
      'load_no': [''],
      'date_of_loss': [null],
      'truck_no': [''],
      'trailer_no': [''],
      'driver_no': [''],
      'los_name_of_facility': [''],
      'los_address': [''],
      'los_city': [''],
      'los_state': [''],
      'los_zipcode': [''],
      'fault_for_the_loss': [''],

      //Claim Details
      'claim': [''],
      'type_of_claim': [0],
      'claim_opened': [null],
      'claim_closed': [null],
      'claimant': [''],
      'third_party_claimant_full_name': [''],
      'third_party_address': [''],
      'third_party_phone': [''],
      'third_party_email': [''],
      'third_party_claim_amount': [0],
      'insurance_company_name': [''],
      'insurance_contact_name': [''],
      'insurance_address': [''],
      'insurance_phone': [''],
      'insurance_ext': [''],
      'insurance_email': [''],
      'cd_comments': [''],
      'cd_contacts': this._formBuilder.array([]),
      'cd_closed': [false],

      //Claim Resolution
      //Liability – Physical Damage - Damage to our vehicle
      'cr_l_physc_d_our_vehicle': [''],
      'cr_l_physc_d_our_vehicle_explanation': [''],
      'cr_l_physc_d_our_vehicle_driveable_condition': [''],
      'cr_l_physc_d_our_vehicle_repair_estimate': [0],
      'cr_l_physc_d_our_vehicle_photos': this._formBuilder.array([]),
      'cr_l_physc_d_our_vehicle_towed': [''],
      'cr_l_physc_d_our_vehicle_towing_company_name': [''],
      'cr_l_physc_d_our_vehicle_contact_name': [''],
      'cr_l_physc_d_our_vehicle_contact_phone': [''],
      'cr_l_physc_d_our_vehicle_contact_email': [''],
      'cr_l_physc_d_our_vehicle_contact_towing_yard_address': [''],
      'cr_l_physc_d_our_vehicle_this_where_vehicle_towed': [''],
      'cr_l_physc_d_our_vehicle_where_vehicle_towed': [''],
      'cr_l_physc_d_our_vehicle_name_of_facility': [''],
      'cr_l_physc_d_our_vehicle_address': [''],
      'cr_l_physc_d_our_vehicle_city': [''],
      'cr_l_physc_d_our_vehicle_state': [''],
      'cr_l_physc_d_our_vehicle_zipcode': [''],
      'cr_l_physc_d_our_vehicle_final_cost': [0],
      'cr_l_physc_d_our_vehicle_driver_charged_for_the_damage': [''],
      'cr_l_physc_d_our_vehicle_details_of_the_charge': [''],
      'cr_what_happened_to_our_vehicle': [''],

      //Liability – Physical Damage - Damage to the other vehicle
      'cr_l_physc_d_other_vehicle': [''],
      'cr_l_physc_d_other_vehicle_explanation': [''],
      'cr_l_physc_d_other_vehicle_driveable_condition': [''],
      'cr_l_physc_d_other_vehicle_repair_estimate': [0],
      'cr_l_physc_d_other_vehicle_photos': this._formBuilder.array([]),
      'cr_l_physc_d_other_vehicle_towed': [''],
      'cr_l_physc_d_other_vehicle_towing_company_name': [''],
      'cr_l_physc_d_other_vehicle_contact_name': [''],
      'cr_l_physc_d_other_vehicle_contact_phone': [''],
      'cr_l_physc_d_other_vehicle_contact_email': [''],
      'cr_l_physc_d_other_vehicle_contact_towing_yard_address': [''],
      'cr_l_physc_d_other_vehicle_this_where_vehicle_taken': [''],
      'cr_l_physc_d_what_happened_to_other_vehicle': [''],

      //Liability – Physical Damage - Cargo Claim
      'cr_cc_reason': [''],
      'cr_cc_attachment_from_receiver': this._formBuilder.array([]),
      'cr_cc_reported_to_insurance': [''],
      'cr_cc_reported_to_insurance_when': [null],
      'cr_cc_adjuster_details': [''],
      'cr_cc_load_invoiced': [''],
      'cr_cc_is_received_payment': [''],
      'cr_cc_claim_amount_deducted': [''],
      'cr_cc_deducted_amount': [0],
      'cr_cc_any_other_payment_this_broker': [''],
      'cr_cc_give_details': [''],

      'cr_cc_overs_noted': this._formBuilder.array([]),
      'cr_cc_overs_details': [''],

      'cr_cc_shortages_slc': [''],
      'cr_cc_shortages_trailer_doors_sealer': [''],
      'cr_cc_shortages_seal_number': [''],
      'cr_cc_shortages_seal_number_on_the_bol': [''],
      'cr_cc_shortages_broker_informed': [false],
      'cr_cc_shortages_sealed_broker_confirmation': [''],
      'cr_cc_shortages_details': [''],

      'cr_cc_damage_discovered_details': [''],
      'cr_cc_damage_when_damage_discovered': [null],
      'cr_cc_damage_who_discovered_damage': [''],
      'cr_cc_damage_is_photo_taken': [''],
      'cr_cc_damage_upload_photos': this._formBuilder.array([]),
      'cr_cc_damage_cargo_status': [''],

      'cr_cc_unloaded_documents_from_receiver': [''],
      'cr_cc_unloaded_provide_details': [''],

      'cr_cc_load_refused_current_load_location': [''],
      'cr_cc_load_refused_instructions_from_the_broker': [''],
      'cr_cc_load_refused_comments_from_the_broker': [''],

      'cr_cc_partillay_unloaded_location_state_of_remaining_cargo': [''],
      'cr_cc_partillay_unloaded_instructions_from_the_broker': [''],
      'cr_cc_partillay_unloaded_brokers_instructions': [''],
      
      'cr_cc_load_overturned': [''],
      'cr_cc_load_overturned_organize': [''],
      'cr_cc_load_overturned_organize_details': [''],
      'cr_cc_load_overturned_cost_restacking_transloading': [''],
      'cr_cc_load_overturned_who_pay_restacking_transloading': [''],
      'cr_cc_load_overturned_pay_details': [''],

      'cr_cc_contamination_contamination_reported': [''],
      'cr_cc_contamination_contamination_reason': [''],
      'cr_cc_contamination_status_of_the_load': [''],
      'cr_cc_contamination_papework': this._formBuilder.array([]),

      'cr_cc_theft_datetime_dicovered_theft': [null],
      'cr_cc_theft_datetime_estimated_theft_happened': [null],
      'cr_cc_theft_driver_explanation': [''],
      'cr_cc_theft_driver_called_police': [''],
      'cr_cc_theft_theft_reported_type': [''],
      'cr_cc_theft_police_report_no': [''],
      'cr_cc_theft_police_dept_contact_name': [''],
      'cr_cc_theft_police_dept_contact_phone': [''],
      'cr_cc_theft_what_was_stolen': [''],
      'cr_cc_theft_broker_informed': [''],
      
      //Physical Damage
      'cr_pd_our_vehicle': [''],
      'cr_pd_our_vehicle_explanation': [''],
      'cr_pd_our_vehicle_driveable_condition': [''],
      'cr_pd_our_vehicle_repair_estimate': [0],
      'cr_pd_our_vehicle_photos': this._formBuilder.array([]),
      'cr_pd_our_vehicle_towed': [''],
      'cr_pd_our_vehicle_towing_company_name': [''],
      'cr_pd_our_vehicle_contact_name': [''],
      'cr_pd_our_vehicle_contact_phone': [''],
      'cr_pd_our_vehicle_contact_email': [''],
      'cr_pd_our_vehicle_contact_towing_yard_address': [''],
      'cr_pd_our_vehicle_this_where_vehicle_taken': [''],
      'cr_pd_our_vehicle_where_vehicle_taken': [''],
      'cr_pd_our_vehicle_name_of_facility': [''],
      'cr_pd_our_vehicle_address': [''],
      'cr_pd_our_vehicle_city': [''],
      'cr_pd_our_vehicle_state': [''],
      'cr_pd_our_vehicle_zipcode': [''],
      'cr_pd_our_vehicle_final_cost': [0],
      'cr_pd_our_vehicle_driver_charged_for_the_damage': [''],
      'cr_pd_our_vehicle_details_of_the_charge': [''],

      //Personal Injury
      'cr_lpi_injuries_in_the_accident': [''],
      'cr_lpi_paramedics': [''],
      'cr_lpi_who_called_paramedics': [''],
      'cr_lpi_is_someone_taken_in_ems_vehicle': [''],
      'cr_lpi_give_details': [''],

      //Property Damage
      'cr_l_property_d_our_vehicle': [''],
      'cr_l_property_d_our_vehicle_explanation': [''],
      'cr_l_property_d_our_vehicle_driveable_condition': [''],
      'cr_l_property_d_our_vehicle_repair_estimate': [0],
      'cr_l_property_d_our_vehicle_photos': this._formBuilder.array([]),
      'cr_l_property_d_our_vehicle_towed': [''],
      'cr_l_property_d_our_vehicle_towing_company_name': [''],
      'cr_l_property_d_our_vehicle_contact_name': [''],
      'cr_l_property_d_our_vehicle_contact_phone': [''],
      'cr_l_property_d_our_vehicle_contact_email': [''],
      'cr_l_property_d_our_vehicle_contact_towing_yard_address': [''],
      'cr_l_property_d_our_vehicle_this_where_vehicle_taken': [''],
      'cr_l_property_d_our_vehicle_where_vehicle_taken': [''],
      'cr_l_property_d_our_vehicle_name_of_facility': [''],
      'cr_l_property_d_our_vehicle_address': [''],
      'cr_l_property_d_our_vehicle_city': [''],
      'cr_l_property_d_our_vehicle_state': [''],
      'cr_l_property_d_our_vehicle_zipcode': [''],
      'cr_l_property_d_our_vehicle_final_cost': [0],
      'cr_l_property_d_our_vehicle_driver_charged_for_the_damage': [''],
      'cr_l_property_d_our_vehicle_details_of_the_charge': [''],

      'cr_pd_damage_explanation': [''],
      'cr_pd_damage_assessment': [''],
      'cr_pd_repair_estimate': [0],
      'cr_pd_attach_papework': this._formBuilder.array([]),
      'cr_pd_name_of_facility': [''],
      'cr_pd_address': [''],
      'cr_pd_city': [''],
      'cr_pd_state': [''],
      'cr_pd_zipcode': [''],
      'cr_pd_contact_name': [''],
      'cr_pd_contact_phone': [''],
      'cr_pd_contact_email': [''],
      'cr_pd_contact_towing_yard_address': [''],
      'cr_pd_any_other_details': [''],

      //Occupational/Accident Claim
      'cr_oa_driver_statement': [''],
      'cr_oa_any_injuries': [''],
      'cr_oa_injuries_details': [''],
      'cr_oa_papework_confirmation': [''],

      //Files tab
      'files': this._formBuilder.array([]),

      'created_date': [null],
      'creator': [''],
      'edited_date': [null],
      'editor': ['']
    })
  }

  ngOnInit(): void {
    this.getZipCodes();
  }

  getZipCodes() {
    this.subscription = this.accidentsService.getAllLocationInfo().subscribe((response: any[]) => {
      this.locationsArray = response;
    });
  };


  saveUpdateData() {
    //Creator, Editor, Created Date, Edited Date
    console.log(this.claimsFormGroup.value);
  };

  //Get form value
  formData(formKey: string) {
    return this.claimsFormGroup.controls[formKey].value;
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();  
  };

}
