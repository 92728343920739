import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EmployeeDataDialogComponent } from './employee-data-dialog/employee-data-dialog.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { RequestsDialogComponent } from './requests-dialog/requests-dialog.component';

@Component({
  selector: 'app-employee-activity',
  templateUrl: './employee-activity.component.html',
  styleUrls: ['./employee-activity.component.scss']
})
export class EmployeeActivityComponent {
  permissions: any = this.rulesService.UserData[24].data;

  constructor(private dialog: MatDialog, private rulesService: RulesService) { }

  openEmployeeDataDialog() {
    if(this.permissions[1].allowedAll) {
      this.dialog.open(EmployeeDataDialogComponent, {
        autoFocus: false,
        panelClass: 'employee-data-main-container',
        data: {editMode: false, obj: {}}
      });
    }
  }

  openRequests() {
    this.dialog.open(RequestsDialogComponent, {
      autoFocus: false,
      panelClass: 'component-dialog-container'
    });
  };
  
}
