import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'claimType'
})
export class ClaimTypePipe implements PipeTransform {

  transform(array: any[], key: string): any[] {
    const transformedArray: string[] = [];
    for(let i = 0; i < array.length; i++) {
      if(!transformedArray.includes(this.getTypeOfClaim(array[i][key]))) {
        transformedArray.push(this.getTypeOfClaim(array[i][key]));
      }
    }
    return transformedArray;
  }
  
  getTypeOfClaim(id: number) {
    const obj: any = {'1': 'Liability – Physical Damage', '2': 'Cargo', '3': 'Physical Damage', '4': 'Liability – Personal Injury',
    '5': 'Liability – Property Damage', '6': 'OCC/ACC'};
    if(id) {
      return obj[id];
    }
    return '';
  };

}
