import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'driverApplications'
})
export class DriverApplicationsPipe implements PipeTransform {

  transform(array: any[], filterObj: any): any[] {
    if (!array) {
      return [];
    }
    let transformedArray: any[] = [];
    for(let i = 0; i < array.length; i++) {
      let condition1: boolean = array[i].status === 'Completed' && filterObj.isCompleted || array[i].status === 'In Progress' && !filterObj.isCompleted;
      let condition2: boolean = `${array[i].name}`.toLocaleLowerCase().includes(`${filterObj.driverSearch}`.toLocaleLowerCase());
      let condition3: boolean = filterObj.selectedRecruiters.length === 0 ? true : filterObj.selectedRecruiters.includes(array[i].recruiter);
 
      if(condition1 && condition2 && condition3) {
        transformedArray.push(array[i]);
      }
    };
    return transformedArray;
  }

}
