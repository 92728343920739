import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription, catchError, throwError } from 'rxjs';
import { ReasonsDialogComponent } from '../reasons-dialog/reasons-dialog.component';
import moment = require('moment');
import { FleetHealthService } from '@app/modules/fleet-health/services/fleet-health.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'app-ask-for-approval-dialog',
  templateUrl: './ask-for-approval-dialog.component.html',
  styleUrls: ['./ask-for-approval-dialog.component.scss']
})
export class AskForApprovalDialogComponent implements OnInit, OnDestroy {
  //Autocomplete data
  autoCompleteData: any[] = [];

  //Trucks
  isOpenTruckAutocomplete: boolean = false;
  truckSearch: string = '';

  //Drivers
  driver: string = '';

  //Trailers
  trailer: string = '';

  //Calendar value
  date: string = 'Choose a date';
  dateForServer: any;
  isOpenCalendar: boolean = false;

  //Time
  isOpenTimeMenu: boolean = false;
  time: string = '';
  hour: any;
  minutes: any;

  //Reasons
  reasonsArray: any[] = [
    {reasonValue: 'Category', isOpenReasonMenu: false, value: ''}
  ];
  
  reasonsObj: any;
  isOpenReasonMenu: boolean = false;
  reasonValue: string = 'Category';

  reasonsArraySelectedValues: any[] = [];
  reasonsDataWithoutGroup: any[] = [];

  categoryAdded: boolean = true;

  //Note
  note: string = '';

  //Files
  fileNames: any[] = [];
  files: any[] = [];
  isOpenFileMenuIndex: number | null = null;

  //Selected obj
  obj: any;

  //Emails
  emailArray: any[] = [];
  isOpenEmailMenu: boolean = false;
  selectedEmails: string[] = [];
  searchEmail: string = '';

  //Validation
  unitNoAdded: boolean = true;

  //Data saved
  loading: boolean = false;
  error: boolean = false;

  //Multiple clicks
  multipleClicks: boolean = true;

  //Subscription
  subscription1: Subscription | any;
  subscription2: Subscription | any;
  subscription3: Subscription | any;
  subscription4: Subscription | any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private fleetService: FleetHealthService,
              private sharedService: SharedService) { }

  ngOnInit(): void { 
    if(this.data.fillForm) {
      this.initForm();
    }
    else {
      this.date = moment(this.data.spotDate).format('MMM DD, yyyy');
    }
    this.getTrucks();
    this.getAllCategories();
  }

  initForm() {
    let initObj: any = JSON.parse(JSON.stringify(this.data.obj));

    this.obj = initObj;
    this.truckSearch = initObj.unit_no;
    this.driver = initObj.driver;
    this.trailer = initObj.trailer;
    this.reasonsArraySelectedValues = initObj.reasons;
    let reasonsArray: any[] = [];
    for(let key in initObj.reasons) {
      reasonsArray.push({reasonValue: initObj.reasons[key].subcategory, isOpenReasonMenu: false, value: initObj.reasons[key].subcategory})
    }
    this.reasonsArray = reasonsArray.length > 0 ? reasonsArray : this.reasonsArray;
    this.note = initObj.note;
    this.fileNames = JSON.parse(JSON.stringify(this.data.obj.files));
    this.files = initObj.files;
    this.selectedEmails = initObj.emails && !initObj.emails.includes('') ? initObj.emails : [];              
  };

  getTrucks() {
    this.subscription1 = this.fleetService.getTrucks().subscribe((response: any) => {
      this.autoCompleteData = response;
    });
  }

  getAllCategories() {
    this.subscription2 = this.fleetService.getAllCategories().subscribe((response: any) => {
      this.reasonsDataWithoutGroup = response;
      this.reasonsObj = this.groupCategories(response)
      console.log(this.groupCategories(response));
      console.log(response);
    })
  };

  groupCategories(array: any[]) {
    const groups: any = array.reduce((acc: any, o: any) => {

      o['category'] = o['category'];
    
      if (!acc[o['category']]) {
        acc[o['category']] = [];
      }
          
      acc[o['category']].push(o);
  
      return acc;
  
    }, {});

    return groups;
  };

  //Select due date
  isSelected = (event: any) => {
    let dueDate: string = this.data.obj.date;
    let date: string = moment(event._d).format('YYYY-MM-DDT00:00:00');
    return dueDate === date ? 'selected' : null;
  };

  //Date
  dateClicked(event: any) {
    this.date = moment(event._d).format('MMM DD, yyyy');
    this.dateForServer = moment(event._d).format('YYYY-MM-DDT00:00:00');
    this.isOpenCalendar = false;
  }

  //Select option
  selectOption(obj: any) {
    this.truckSearch = obj.unit_no;
    this.driver = obj.driver;
    this.trailer = obj.trailer;
    this.obj = obj;
    this.selectedEmails = [];
    this.emailArray = [];
    this.searchEmail = '';
    this.getEmailsByDivision(obj.division_id);
    //this.getAllDepartments(obj.division_id);
    this.isOpenTruckAutocomplete = false;
    console.log(obj);
  }

  //Get all emails
  getEmailsByDivision(id: number) {
    this.subscription3 = this.sharedService.getEmailsByDivison(id).subscribe((response: any) => {
      if(response.length > 0) {
        this.emailArray = this.emailArray.concat(response);
      }
    })
  }  

  //Get all departments
  getAllDepartments(division_id: number) {
    this.subscription4 = this.sharedService.getAllEmployeeDepartments().subscribe((response: any) => {
      for(let i = 0; i < response.length; i++) {
        if(response[i].division_id === division_id && 
        (response[i].email.startsWith('safety') || response[i].email.startsWith('maintenance'))) {
          this.selectedEmails.push(response[i].email);
          this.emailArray.push({employee_id: undefined, pt_id: 0, name: response[i].name, email: response[i].email})
        }
      };
    })
  };

  //Select reasons
  selectReason(array: any[], index: number, obj: any, index2: number) {
    obj.isOpenReasonMenu = !obj.isOpenReasonMenu; 
    obj.reasonValue = array[index].subcategory; 
    obj.value = array[index].subcategory;
    this.reasonsArraySelectedValues[index2] = array[index];
  }

  //Add select
  addSelect() {
    this.reasonsArray.push({reasonValue: 'Category', isOpenReasonMenu: false, value: ''});
  };

  //Remove select
  removeSelect(index: number) {
    this.reasonsArray.splice(index, 1);
    this.reasonsArraySelectedValues.splice(index, 1);
  }

  //Open reasons dialog
  openReasonsDialog() {
    let dialogRef: any = this.dialog.open(ReasonsDialogComponent, {
      autoFocus: false,
      panelClass: 'reasons-dialog-main-container',
      data: this.reasonsDataWithoutGroup
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      this.getAllCategories();
    });
  }

  //Add files
  onFileSelected(event: any) {  
    let length: any = Object.keys(event.target.files).length;
    for(let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i);
    }
  }

  addMultipleFiles(event: any, index: number) {
    let fileName = event.target.files[index].name;
    this.fileNames.push({filename: fileName});
    let reader = new FileReader();
    reader.onload = (e: any) => {
    var uint8 = new Uint8Array(e.target.result);
    var result = [];
    for (var i = 0; i < uint8.length; i++) {
      result.push(uint8[i]);
    }
    let obj: any = {
      filename: fileName,
      data: result
    };
    this.files.push(obj);
  }

  reader.readAsArrayBuffer(event.target.files[index])
  };

  //Files methods
  openFileInNewTab(obj: any) {
    this.isOpenFileMenuIndex = null;
    this.sharedService.downloadPreviewFile(obj.filename, this.arrayBufferToBase64(obj.data));
  };

  downloadFile(obj: any) {
    this.isOpenFileMenuIndex = null;
    let source: string = `data:application/octet-stream;base64,${this.arrayBufferToBase64(obj.data)}`;
    const downloadLink = document.createElement('a');
    const fileName = obj.filename;
    downloadLink.href = source;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  deleteFile(index: number) {
    this.isOpenFileMenuIndex = null;
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.fileNames.splice(index, 1);
        this.files.splice(index, 1);
      }
    })
  };

  //Select email
  selectEmail(obj: any) {
    let isExist = this.selectedEmails.indexOf(obj.email);
    if(isExist === -1) {
      this.selectedEmails.push(obj.email);
    }
  };

  //Discard files
  discard(i : number) {
    this.fileNames.splice(i, 1);
    this.files.splice(i, 1);
  };

  //Discard emails
  discardEmails(i: number) {
    this.selectedEmails.splice(i, 1);
  };

  //Time methods
  leadingZero(hour: boolean) {
    if(hour) {
      this.hour = String(this.hour).padStart(2, '0');
    }
    else {
      this.minutes = String(this.minutes).padStart(2, '0');
    }
  }

  applyTime() {
    if(isNaN(this.hour) || this.hour === '') {
      this.hour = '00';
    }
    
    if(isNaN(this.minutes) || this.minutes === '') {
      this.minutes = '00';
    }
    this.time = `${this.hour}:${this.minutes}`;
    this.isOpenTimeMenu = false;
  };

  askForApproval() {
    this.unitNoAdded = this.truckSearch ? true : false;
    this.categoryAdded = this.reasonsArraySelectedValues.length === 0 ? false : true;

    if(this.truckSearch && this.multipleClicks && this.categoryAdded) {
      this.loading = true;
      
      let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
      let time: string = this.time ? this.time : '00:00';
      let date: any = moment(this.date).format(`YYYY-MM-DDT${time}:00`);

      let obj: any =  {id: null, unit_no: this.obj.unit_no, model: this.obj.model, odometers: null, loaded: null, truck_id: this.obj.truck_id, 
        driver: this.obj.driver, driver_phone_number: this.obj.driver_phone_number, driver_id: this.obj.driver_id, dispatcher: this.obj.dispatcher, 
        dispatcher_id: this.obj.dispatcher_id, trailer: this.obj.trailer, trailer_id: this.obj.trailer_id, reasons: this.reasonsArraySelectedValues, 
        date: date, status: 'On time', note: this.note, created_by: loggedUser.first_name + ' ' + loggedUser.last_name, longitude: null, latitude: null,  distance_from_yard: null, 
        eta: null, fuel_percent: null, def: null, created_date: moment().format('YYYY-MM-DDTHH:mm:ss'),
        ask: loggedUser.first_name + ' ' + loggedUser.last_name, data_history: null, status_history: null, 
        files: this.files, division_id: this.obj.division_id, emails: [
        'peter@tempofreightsystems.com', 
        'vic@tempofreightsystems.com', 
        'kevin@tempofreightsystems.com', 
        'tim@tempofreightsystems.com', 
        'ross@tempofreightsystems.com',
        'luke@tempofreightsystems.com',
        'john@tempofreightsystems.com'], id_demand: 0};
 
      this.fleetService.askForApproval(obj)
        .pipe(catchError((err: any) => {
          this.loading = false;
          this.error = true;
          return throwError(() => err);
        }))
        .subscribe((response: any) => {
          console.log(response);
          if(response) {
            this.multipleClicks = false;
            this.dialogRef.close(true);
          }
          else {
            this.error = true;
          }
          this.loading = false;
        })
      }
  };

  arrayBufferToBase64(data: any) {
    const base64Pattern = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
    if(base64Pattern.test(data)) return data;
    const bytes = new Uint8Array(data);
    let binary: string = '';
    for(let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  };

  ngOnDestroy() {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
    this.subscription4?.unsubscribe();
  };

}
