import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'dropdown-btn',
  templateUrl: './dropdown-btn.component.html',
  styleUrls: ['./dropdown-btn.component.scss']
})
export class DropdownBtnComponent {
  @Input() config: any = {
    array: [],
    btnValue: '',
    btnKey: '',
    btnDefaultStyle: ''
  };
  @Output() triggerEvent = new EventEmitter<string>();

  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      0, 1
    ),
    new ConnectionPositionPair(
      { originX: 'end', originY: 'top' },
      { overlayX: 'end', overlayY: 'bottom' },
      0, 1
    )
  ];
  isOpenDropdownBtn: boolean = false;

  selectValue(option: any) {
    this.triggerEvent.emit(option[this.config.btnKey]);
    this.isOpenDropdownBtn = false;
  };  

}
