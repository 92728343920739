import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { ClaimsDialogComponent } from '../claims-dialog/claims-dialog.component';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { IClaims } from '@app/modules/claims/models/claim.model';

@Component({
  selector: 'claims-table',
  templateUrl: './claims-table.component.html',
  styleUrls: ['./claims-table.component.scss']
})
export class ClaimsTableComponent {
  @Input() dataSource: IClaims[] = [];
  @Output() triggerEvent = new EventEmitter<boolean>();
  permissions: any = this.rulesService.UserData[57].data;

  //Filters
  filters: any = {
    claim: [],
    adjuster: [],
    claim_opened: null,
    claim_closed: null,
    status: [],
    date_of_loss: null,
    truck_no: [],
    driver_no: [],
    type_of_claim: [],
    claimant: [],
    last_update: null,

    claimantStatus: 'Total',
    stTypeOfClaiment: 0
  };

  filterMetaData: any = {
    countObj: {
      total: 0,
      active: 0,
      closed: 0,
      on_ice: 0,
      pending_check: 0,

      cargo: 0,
      liability_phys_damage: 0,
      liability_per_injury: 0,
      liability_property_damage: 0,
      phys_damage: 0,
    }
  }

  constructor(private dialog: MatDialog, 
              public transformService: TransformService, 
              private rulesService: RulesService) { }

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dataSource));
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'claim':
          return compare(a.claim, b.claim, isAsc);
        case 'adjuster':
          return compare(a.adjuster, b.adjuster, isAsc);
        case 'claim_opened':
          return compare(new Date(a.claim_opened), new Date(b.claim_opened), isAsc);
        case 'claim_closed':
          return compare(new Date(a.claim_closed), new Date(b.claim_closed), isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'date_of_loss':
          return compare(new Date(a.date_of_loss), new Date(b.date_of_loss), isAsc);
        case 'truck_no':
          return compare(a.truck_no, b.truck_no, isAsc);
        case 'driver_no':
          return compare(a.driver_no, b.driver_no, isAsc);
        case 'type_of_claim':
          return compare(this.getTypeOfClaim(a.type_of_claim), this.getTypeOfClaim(b.type_of_claim), isAsc);
        case 'claimant':
          return compare(a.claimant, b.claimant, isAsc);
        case 'last_update':
          return compare(new Date(a.edited_date ? a.edited_date : a.created_date), new Date(b.edited_date ? b.edited_date : b.created_date), isAsc);
        default:
          return 0;
      }
    });
  }

  openClaimsDialog(element: IClaims) {
    if(this.permissions[0].sectionArray[1].allowed) {
      let dialogRef = this.dialog.open(ClaimsDialogComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'component-dialog-container',
        data: {claimId: element.id, accident_id: element.accident_id, initForm: null}
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.triggerEvent.emit(true);
        }
      });
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  selectFilter(propertyName: string, value: any) {
    this.filters[propertyName] = value;
    this.filters = {...this.filters};
  };

  selectStFilter(propertyName: string, value: any, unselectValue: string | number) {
    if(this.filters[propertyName] === value) {
      this.filters[propertyName] = unselectValue;
    }
    else {
      this.filters[propertyName] = value;
    }
    this.filters = {...this.filters};
  };

  clearFilters() {
    this.filters = {
      claim: [],
      adjuster: [],
      claim_opened: null,
      claim_closed: null,
      status: [],
      date_of_loss: null,
      truck_no: [],
      driver_no: [],
      type_of_claim: [],
      claimant: [],
      last_update: null,
      claimantStatus: 'Total',
      stTypeOfClaiment: 0
    };
  };

  getTypeOfClaim(id: number) {
    const obj: any = {'1': 'Liability – Physical Damage', '2': 'Cargo', '3': 'Physical Damage', '4': 'Liability – Personal Injury',
    '5': 'Liability – Property Damage', '6': 'OCC/ACC'};
    if(id) {
      return obj[id];
    }
    return '';
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

}

function compare(a: number | any, b: number | any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
