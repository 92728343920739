import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, catchError, throwError } from 'rxjs';
import { DispatcherProfileService } from '../../services/dispatcher-profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import moment = require('moment');
import { TransformService } from '@app/modules/shared/services/transform.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { NgxSpinnerService } from 'ngx-spinner';
import { TitleCasePipe } from '@angular/common';
import { StatusDialogComponent } from '@app/modules/shared/components/status-dialog/status-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { LoadsDocumentationDialogComponent } from '@app/modules/shared/components/loads-documentation-dialog/loads-documentation-dialog.component';
import { RulesService } from '@app/modules/shared/services/rules.service';

@Component({
  selector: 'activity-table',
  templateUrl: './activity-table.component.html',
  styleUrls: ['./activity-table.component.scss']
})
export class ActivityTableComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[15].data;

  //Date
  dateObj: any = {
    startDate: moment().subtract(2, 'week').startOf('isoWeek').format('YYYY-MM-DD'),
    endDate: moment().endOf('isoWeek').format('YYYY-MM-DD')
  };
  
  dateRangeDays: any = {
    week1: {mon: null, tue: null, wed: null, thu: null, fri: null, sat: null, sun: null}, 
    week2: {mon: null, tue: null, wed: null, thu: null, fri: null, sat: null, sun: null}, 
    week3: {mon: null, tue: null, wed: null, thu: null, fri: null, sat: null, sun: null}
  }

  currentDate: string = moment().format('YYYY-MM-DDT00:00:00');

  tableData: any[] = [];
  currentWeekTitle: string = 'Current Week';
  previousWeekTitle: string = 'Previous Week';
  twoWeeksAgoTitle: string = '2 weeks ago';

  //Statistics
  metaObj: any = {
    countObj: {
      totalGross1: 0, totalRpm1: 0, totalMileage1: 0, totalGrossPerDay1: 0, totalMilesPerDay1: 0,
      totalGross2: 0, totalRpm2: 0, totalMileage2: 0, totalGrossPerDay2: 0, totalMilesPerDay2: 0,
      totalGross3: 0, totalRpm3: 0, totalMileage3: 0, totalGrossPerDay3: 0, totalMilesPerDay3: 0,
  
      avgGross1: 0, avgRpm1: 0, avgMileage1: 0, avgGrossPerDay1: 0, avgMilesPerDay1: 0,
      avgGross2: 0, avgRpm2: 0, avgMileage2: 0, avgGrossPerDay2: 0, avgMilesPerDay2: 0,
      avgGross3: 0, avgRpm3: 0, avgMileage3: 0, avgGrossPerDay3: 0, avgMilesPerDay3: 0
    }
  }

  //Deal
  paySign: string[] = ['SOLO COMPANY', 'TEAM COMPANY'];

  driverKeysArray: string[] = [];

  //Popup
  isOpened: boolean = false;

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private dispatchProfileService: DispatcherProfileService, 
              private route: ActivatedRoute,
              private transformService: TransformService,
              private rulesService: RulesService,
              private router: Router,
              private sharedService: SharedService,
              private spinner: NgxSpinnerService,
              private clipboard: Clipboard,
              private titleCasePipe: TitleCasePipe,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getTableData();
  };

  getTableData() {
    this.setDays('week1', [0, 1, 2, 3, 4, 5, 6]);
    this.setDays('week2', [7, 8, 9, 10, 11, 12, 13]);
    this.setDays('week3', [14, 15, 16, 17, 18, 19, 20]);
    this.weeksTitle(this.dateRangeDays.week1.sun, this.dateRangeDays.week2.sun, this.dateRangeDays.week3.sun);
    this.tableData = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('activity-table');
    let id: number = +this.route.snapshot.paramMap.get('id');
    this.subscription = this.dispatchProfileService.getActivityTableData(id, this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('activity-table');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any[]) => {
      this.tableData = response;
      this.spinner.hide('activity-table');
      this.loaded = true;
      this.driverKeysArray = new Array(response.length).fill('driver_1');
      console.log(response)
    });
  };

  getWeeksData(isLeftBtn:  boolean) {
    if(isLeftBtn) {
      this.dateObj.startDate = moment(this.dateObj.startDate).subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD');
      this.dateObj.endDate = moment(this.dateObj.endDate).subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD');
    }
    else {
      this.dateObj.startDate = moment(this.dateObj.startDate).add(1, 'week').startOf('isoWeek').format('YYYY-MM-DD');
      this.dateObj.endDate = moment(this.dateObj.endDate).add(1, 'week').endOf('isoWeek').format('YYYY-MM-DD');
    };
    this.getTableData();
  };

  //Open documentation dialog
  openFilesForDownload(loadNo: any, loadId: number) {
    this.sharedService.getAllFilesLoads(loadNo).subscribe((response) => {
      this.dialog.open(LoadsDocumentationDialogComponent, {
        width: '500px',
        height: '500px',
        panelClass: 'download-files-dialog',
        autoFocus: false,
        data: {fileNames: response, loadNo: loadNo, loadId: loadId}
      });
    });
  };

  downloadStatement(obj: any, index: number) {
    console.log(obj);
    if(obj.statemetns.length >= (index + 1)) {
      obj.statemetns[index].pdfLoading = true;
      this.sharedService.downloadStatementFile(obj.driver_1.id, obj.statemetns[index].id)
      .pipe(catchError((err: any) => {
        obj.statemetns[index].pdfLoading = false;
        return throwError(() => err);
      }))
      .subscribe((response: any) => {
        obj.statemetns[index].pdfLoading = false;
        let fileURL = window.URL.createObjectURL(response);
        let tab = window.open();
        if(this.permissions[6].sectionArray[0].allowed) {
          tab.location.href = fileURL;
        }
        else {
          tab.location.href = fileURL + '#toolbar=0';
        }
      }) 
    }
  }

  //Driver type
  getDriverType(data: any) {
    let companyType: string[] = ['SOLO COMPANY', 'TEAM COMPANY'];
    let rentType: string[] = ['SOLO RENT', 'TEAM RENT'];
    let ownerType: string[] = ['OWNER OPERATOR'];
    if(companyType.includes(data.status)) {
      return 'Company driver';
    }
    if(rentType.includes(data.status)) {
      return 'Rent driver';
    }
    if(ownerType.includes(data.status)) {
      return 'Owner';
    } 
  };

  soloOrTeam(data: any) {
    if(data.status) {
      return data.status.split(' ')[0];
    }
    return '';
  }

  getPayValue(data: any) {
    if(this.paySign.includes(data.status)) {
      return '$'+ this.transformService.addCommasDots(data.deal)
    } 
    else {
      return this.transformService.addCommasDots(data.deal * 100, 'round') + '%';
    }
  };

  //Open in new tab
  openInNewTab(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  };

  getDayByIndex(index: number, format: string) {
    return moment(this.dateObj.startDate).add(index, 'd').format(format)
  };

  //Copy driver info
  copyDriverInfo(obj: any) {
    let driverFullName: string = this.titleCasePipe.transform(`${obj.first_name} ${obj.last_name}`);
    let content: string = `Driver:
${driverFullName}
Truck: 
${obj.truck_no}
VIN#:
${obj.vin}
Trailer:
${obj.trailer_no}
Phone:
${obj.phone}`;
    this.clipboard.copy(content);
  };

  setDays(weekKey: string, arrayOfIndex: number[]) {
    this.dateRangeDays[weekKey].mon = this.getDayByIndex(arrayOfIndex[0], 'YYYY-MM-DDT00:00:00');
    this.dateRangeDays[weekKey].tue = this.getDayByIndex(arrayOfIndex[1], 'YYYY-MM-DDT00:00:00');
    this.dateRangeDays[weekKey].wed = this.getDayByIndex(arrayOfIndex[2], 'YYYY-MM-DDT00:00:00');
    this.dateRangeDays[weekKey].thu = this.getDayByIndex(arrayOfIndex[3], 'YYYY-MM-DDT00:00:00');
    this.dateRangeDays[weekKey].fri = this.getDayByIndex(arrayOfIndex[4], 'YYYY-MM-DDT00:00:00');
    this.dateRangeDays[weekKey].sat = this.getDayByIndex(arrayOfIndex[5], 'YYYY-MM-DDT00:00:00');
    this.dateRangeDays[weekKey].sun = this.getDayByIndex(arrayOfIndex[6], 'YYYY-MM-DDT00:00:00');
  };

  weeksTitle(date1: string, date2: string, date3: string) {
    let currentWeek: string = moment().endOf('isoWeek').format('YYYY-MM-DDT00:00:00');
    let previousWeek: string = moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DDT00:00:00');
    let twoWeeksAgo: string = moment().subtract(2, 'week').endOf('isoWeek').format('YYYY-MM-DDT00:00:00');

    this.getTitle({currentWeek: currentWeek, previousWeek: previousWeek, twoWeeksAgo: twoWeeksAgo, date: date3, key : 'currentWeekTitle'}); 
    this.getTitle({currentWeek: currentWeek, previousWeek: previousWeek, twoWeeksAgo: twoWeeksAgo, date: date2, key : 'previousWeekTitle'}); 
    this.getTitle({currentWeek: currentWeek, previousWeek: previousWeek, twoWeeksAgo: twoWeeksAgo, date: date1, key : 'twoWeeksAgoTitle'}); 
  }

  getTitle(configObj: any) {
    if(configObj.currentWeek === configObj.date) {
      this[configObj.key] = 'Current Week';
    }
    else if(configObj.previousWeek === configObj.date) {
      this[configObj.key] = 'Previous Week';
    }
    else if(configObj.twoWeeksAgo === configObj.date) {
      this[configObj.key] = '2 weeks ago';
    }
    else {
      this[configObj.key] = `${moment(configObj.date).startOf('isoWeek').format('MMM DD')}-${moment(configObj.date).endOf('isoWeek').format('DD, yyyy')}`;
    }
  };

  addStatus(element: any, date: string, index: number) {
    let obj: any = element[this.driverKeysArray[index]];
    console.log(element.weeks)
    if(obj.hiring_date <= moment(date).format('YYYY-MM-DDTHH:mm:ss') && !obj.termination_date) {
      let data: any = {
        driverInfo: {info: {id: obj.id, name: `${obj.first_name} ${obj.last_name}`, truck_no: obj.truck_no, trailer_no: obj.trailer_no }},
        date: new Date(date), 
        allStatusArray: element.status,
        startDate: this.dateObj.startDate,
        endDate: element.status.length > 0 && new Date(element.status[element.status.length-1].status_date).getTime() > new Date(date).getTime() ? 
        element.status[element.status.length-1].status_date.split('T')[0] : moment(date).format('YYYY-MM-DD')
      };
      let dialogRef: any = this.dialog.open(StatusDialogComponent, {
        autoFocus: false,
        panelClass: 'status-dialog-container',
        data: data
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result)  {
          this.sharedService.getDriverStatusesByDriver(obj.id, this.dateObj.startDate, this.dateObj.endDate)
          .subscribe((response: any) => {
            element.status = response;
            const newArray: any[] = [...this.tableData];
            this.tableData = [];
            this.tableData = newArray;
          });
        }
      })
    }
  };

  changeKey(index: number) {
    if(this.driverKeysArray[index] === 'driver_1') {
      this.driverKeysArray[index] = 'driver_2';
    }
    else {
      this.driverKeysArray[index] = 'driver_1';
    };
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}