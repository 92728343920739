import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddNewViolationDialogComponent } from './components/inspections-page/create-update-inspection-dialog/add-new-violation-dialog/add-new-violation-dialog.component';
import { SharedModule } from '../shared/shared.module';
import { InspectionsRoutingModule } from './inspections.routing';
import { InspectionsService } from './services/inspections.service';
import { InspectionsPageComponent } from './components/inspections-page/inspections-page.component';
import { InspectionsTableComponent } from './components/inspections-page/inspections-table/inspections-table.component';
import { CreateUpdateInspectionDialogComponent } from './components/inspections-page/create-update-inspection-dialog/create-update-inspection-dialog.component';
import { InspectionTablePipePipe } from './pipes/inspection-table-pipe.pipe';
import { InspectionsLinechartComponent } from './components/inspections-page/inspections-linechart/inspections-linechart.component';
import { InspectionsPiechartsComponent } from './components/inspections-page/inspections-piecharts/inspections-piecharts.component';
import { InspectionsColumnChartComponent } from './components/inspections-page/inspections-column-chart/inspections-column-chart.component';

@NgModule({
  declarations: [
    AddNewViolationDialogComponent,
    InspectionsPageComponent,
    InspectionsTableComponent,
    CreateUpdateInspectionDialogComponent,
    InspectionTablePipePipe,
    InspectionsLinechartComponent,
    InspectionsPiechartsComponent,
    InspectionsColumnChartComponent
  ],
  imports: [
    CommonModule,
    InspectionsRoutingModule,
    SharedModule,
  ],
  exports: [
    InspectionTablePipePipe
  ],
  providers: [InspectionsService, InspectionTablePipePipe]
})
export class InspectionsModule { }
