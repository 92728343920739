import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inspectionTablePipe'
})
export class InspectionTablePipePipe implements PipeTransform {

  transform(array: any[], inspectionTypeFilter: string, inspectionLevelFilter: string, metaObj: any): any[] {
    let numOfAllInspections: number = array.length;
    let numOfPassedInspections: number = 0;
    let numOfFailedInspections: number = 0;

    let allInspectionsLevelI: number = 0;
    let allInspectionsLevelII: number = 0;
    let allInspectionsLevelIII: number = 0;

    let passedInspectionsLevelI: number = 0;
    let passedInspectionsLevelII: number = 0;
    let passedInspectionsLevelIII: number = 0;

    let failedInspectionsLevelI: number = 0;
    let failedInspectionsLevelII: number = 0;
    let failedInspectionsLevelIII: number = 0;

    let sumCharge: number = 0;
    let sumRewards: number = 0;
    let sumPoints: number = 0;

    let transformedArray: any[] = [];
    for(let i = 0; i < array.length; i++) {
      let inspectionType: boolean = (inspectionTypeFilter.toLowerCase() === array[i].inspection_result.toLowerCase()) || inspectionTypeFilter === 'All Inspections';
      let inspectionLevel: boolean = (inspectionLevelFilter === array[i].inspection_level) || inspectionLevelFilter === 'All Levels';
      if(inspectionType && inspectionLevel) {
        //Statistics
        sumCharge += array[i].amount;
        sumRewards += array[i].rewards;
        sumPoints += array[i].points;
        transformedArray.push(array[i]);
      };

      if(array[i].inspection_result.toLowerCase() === 'passed') {
        numOfPassedInspections++;
      }
      if(array[i].inspection_result.toLowerCase() === 'failed') {
        numOfFailedInspections++;
      }

      if(array[i].inspection_level === 'Level I') {
        allInspectionsLevelI++;

        if(array[i].inspection_result.toLowerCase() === 'passed') {
          passedInspectionsLevelI++;
        }
        if(array[i].inspection_result.toLowerCase() === 'failed') {
          failedInspectionsLevelI++;
        }
      };
      if(array[i].inspection_level === 'Level II') {
        allInspectionsLevelII++;

        if(array[i].inspection_result.toLowerCase() === 'passed') {
          passedInspectionsLevelII++;
        }
        if(array[i].inspection_result.toLowerCase() === 'failed') {
          failedInspectionsLevelII++;
        }
      };
      if(array[i].inspection_level === 'Level III') {
        allInspectionsLevelIII++;

        if(array[i].inspection_result.toLowerCase() === 'passed') {
          passedInspectionsLevelIII++;
        }
        if(array[i].inspection_result.toLowerCase() === 'failed') {
          failedInspectionsLevelIII++;
        }
      };
    };

    metaObj.countObj.numOfAllInspections = numOfAllInspections;
    metaObj.countObj.numOfAllInspectionsPercent = 100;

    metaObj.countObj.numOfPassedInspections = numOfPassedInspections;
    metaObj.countObj.numOfPassedInspectionsPercent = numOfPassedInspections / numOfAllInspections * 100;

    metaObj.countObj.numOfFailedInspections = numOfFailedInspections;
    metaObj.countObj.numOfFailedInspectionsPercent = numOfFailedInspections / numOfAllInspections * 100;

    metaObj.countObj.allInspectionsLevelI = allInspectionsLevelI;
    metaObj.countObj.allInspectionsLevelIPercent = allInspectionsLevelI / numOfAllInspections * 100;

    metaObj.countObj.allInspectionsLevelII = allInspectionsLevelII;
    metaObj.countObj.allInspectionsLevelIIPercent = allInspectionsLevelII / numOfAllInspections * 100;

    metaObj.countObj.allInspectionsLevelIII = allInspectionsLevelIII;
    metaObj.countObj.allInspectionsLevelIIIPercent = allInspectionsLevelIII / numOfAllInspections * 100;

    metaObj.countObj.passedInspectionsLevelI = passedInspectionsLevelI;
    metaObj.countObj.passedInspectionsLevelIPercent = passedInspectionsLevelI / numOfPassedInspections * 100;

    metaObj.countObj.passedInspectionsLevelII = passedInspectionsLevelII;
    metaObj.countObj.passedInspectionsLevelIIPercent = passedInspectionsLevelII / numOfPassedInspections * 100;

    metaObj.countObj.passedInspectionsLevelIII = passedInspectionsLevelIII;
    metaObj.countObj.passedInspectionsLevelIIIPercent = passedInspectionsLevelIII / numOfPassedInspections * 100;

    metaObj.countObj.failedInspectionsLevelI = failedInspectionsLevelI;
    metaObj.countObj.failedInspectionsLevelIPercent = failedInspectionsLevelI / numOfFailedInspections * 100;

    metaObj.countObj.failedInspectionsLevelII = failedInspectionsLevelII;
    metaObj.countObj.failedInspectionsLevelIIPercent = failedInspectionsLevelII / numOfFailedInspections * 100;

    metaObj.countObj.failedInspectionsLevelIII = failedInspectionsLevelIII;
    metaObj.countObj.failedInspectionsLevelIIIPercent = failedInspectionsLevelIII / numOfFailedInspections * 100;

    metaObj.countObj.sumCharge = sumCharge;
    metaObj.countObj.sumRewards = sumRewards;
    metaObj.countObj.sumPoints = sumPoints;

    metaObj.countObj.avgCharge = sumCharge / transformedArray.length;
    metaObj.countObj.avgRewards = sumRewards / transformedArray.length;
    metaObj.countObj.avgPoints = sumPoints / transformedArray.length;

    return transformedArray;
  }

}
