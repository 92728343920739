import { Component, Input } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'form-liability-property-damage',
  templateUrl: './form-liability-property-damage.component.html',
  styleUrls: ['./form-liability-property-damage.component.scss']
})
export class FormLiabilityPropertyDamageComponent {
  @Input() formGroup: FormGroup;
  @Input() locationsArray: any[] = [];

  constructor() { }
  
  uploadFiles(obj: any, key: string) {
    if(obj.name === 'UPLOAD') {
      const filesArray: any[] = this.formArray(key)?.controls;
      filesArray.push(obj.itemGroup);
      this.formGroup.controls[key].updateValueAndValidity();
    }
    else {
      this.formArray(key).removeAt(obj.index);
    }
  };

  autocompleteLocation(element: any, isVehicleTaken: boolean) {
    if(isVehicleTaken) {
      this.formGroup.patchValue({
        'cr_l_property_d_our_vehicle_city': element.city, 
        'cr_l_property_d_our_vehicle_state': element.state, 
        'cr_l_property_d_our_vehicle_zipcode': element.zip_code
      });
    }
    else {
      this.formGroup.patchValue({
        'cr_pd_city': element.city, 
        'cr_pd_state': element.state, 
        'cr_pd_zipcode': element.zip_code
      });
    }
  };

  //Get form value
  formData(formKey: string) {
    return this.formGroup.controls[formKey].value;
  };

  //Get form array
  formArray(formKey: string): FormArray {
    return this.formGroup.controls[formKey] as FormArray;
  };
  
}
