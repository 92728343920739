import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { IClaims } from '../models/claim.model';

@Injectable()

export class ClaimsService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  constructor(private http: HttpClient) { }

  //Claims page
  getAllClaims(startDate: string, endDate: string) {
    return this.http.get(`${_G.extendApiUrl}accident/claims?start=${startDate}&end=${endDate}`, this.requestOptions);
  };

  getClaimsById(claimId: number) {
    return this.http.get(`${_G.extendApiUrl}accident/claims/${claimId}`, this.requestOptions);
  };

  getClaimsFiles(claimId: number, propertyName?: string) {
    return this.http.get(`${_G.extendApiUrl}accident/claimsfiles?id=${claimId}&property_name=${propertyName}`, this.requestOptions);
  };

  createUpdateClaim(obj: IClaims) {
    let json: string = JSON.stringify(obj);
    console.log(json)
    return this.http.post(`${_G.extendApiUrl}accident/claims`, json, this.requestOptions);
  };

  deleteClaim(id: number, user: string, date: string) {
    return this.http.delete(`${_G.extendApiUrl}accident/claims?id=${id}&user=${user}&date=${date}`, this.requestOptions);
  };

  getAllLocationInfo() {
    return this.http.get(`${_G.extendApiUrl}accident/zips`, this.requestOptions);;
  };

  getAllAccidentLoads() {
    return this.http.get(`${_G.extendApiUrl}accident/loads`, this.requestOptions);
  };

  getIncidentByClaimNumber(claimNumber: string) {
    return this.http.get(`${_G.extendApiUrl}accident/bynumber?number=${claimNumber}`, this.requestOptions);
  };

}
