import { Component, HostListener, OnInit } from '@angular/core';

import moment = require('moment');
import { Router } from '@angular/router';
import * as _G from '../app/_config/globals';
import { TitleService } from './modules/shared/services/title.service';
import { TransformService } from './modules/shared/services/transform.service';
import { SharedService } from './modules/shared/services/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event: any): void {
    let pageReloaded = window.performance.getEntriesByType('navigation').map((nav) => (nav as any).type).includes('reload');
    if(!pageReloaded) { 
      let id: any = JSON.parse(localStorage.getItem('currentUser'))?.id;
      let obj = {
        "id": id,
        "status": false,
        "date": this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss')),
        "history": false
    }

    fetch('http://extend.jdmexpedite.com/api/login/online', {
      keepalive: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
      },
      body: JSON.stringify(obj),
    });
      localStorage.setItem('status', 'offline');
    }
  }
  
 // @HostListener('window:mousemove')
  @HostListener('window:keydown')
  @HostListener('window:mousedown')
  checkUserActivity(): void {
    if(localStorage.getItem('status') === 'offline' && JSON.parse(localStorage.getItem('currentUser'))) {
      let id: any = JSON.parse(localStorage.getItem('currentUser'))?.id;
      this.sharedService.sendStatus(id, true, this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss')), false);
      localStorage.setItem('status', 'online')
    }
    clearTimeout(this.timeoutId);
    this.checkTimeOut();
  }

  timeoutId: any;

  constructor(private titleService: TitleService,
              private transformService: TransformService, 
              private sharedService: SharedService,
              private router: Router) {
    this.checkTimeOut();
  }

  ngOnInit(){
    this.titleService.init();
    this.userLogout();
  }

  checkTimeOut() {
    this.timeoutId = setInterval(() => {
      let id: any = JSON.parse(localStorage.getItem('currentUser')).id;
      this.sharedService.sendStatus(id, false, this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss')), false);
    }, 3600000);
  }

  userLogout() {
    setInterval(() => {
      let id: any = JSON.parse(localStorage.getItem('currentUser'))?.id;
      if(id) {
        this.sharedService.getUserLogoutStatus(id)
        .subscribe((response: any) => {
          if(response) {
            localStorage.clear();
            this.router.navigate(['login']);
          };
        });
      }
    }, 60000);
  }

}
