import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { CreateUpdateInspectionDialogComponent } from '../create-update-inspection-dialog/create-update-inspection-dialog.component';

@Component({
  selector: 'inspections-table',
  templateUrl: './inspections-table.component.html',
  styleUrls: ['./inspections-table.component.scss']
})
export class InspectionsTableComponent {
  //Data source
  @Input() config: any = {dataSource: [], stObj: {
    numOfAllInspections: 0,
    sumCharge: 0,
    sumRewards: 0,
    sumPoints: 0,
    avgCharge: 0,
    avgRewards: 0,
    avgPoints: 0}
  };
  @Output() refreshData = new EventEmitter<boolean>();

  constructor(public transformService: TransformService, private dialog: MatDialog) { }

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.config.dataSource));
    if (!sort.active || sort.direction === '') {
      this.config.dataSource = data;
      return;
    }
    this.config.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'driver_name':
          return compare(a.driver_name, b.driver_name, isAsc);
        case 'truck_no':
          return compare(a.truck_no, b.truck_no, isAsc);
        case 'inspection_result':
          return compare(a.inspection_result, b.inspection_result, isAsc);
        case 'inspection_level':
          return compare(a.inspection_level, b.inspection_level, isAsc);
        case 'amount':
          return compare(a.amount, b.amount, isAsc);
        case 'rewards':
          return compare(a.rewards, b.rewards, isAsc);
        case 'points':
          return compare(a.points, b.points, isAsc);
        case 'date':
          return compare(a.date, b.date, isAsc);
        case 'out_of_service':
          return compare(a.out_of_service, b.out_of_service, isAsc);
        case 'city':
          return compare(a.city, b.city, isAsc);
        case 'state':
          return compare(a.state, b.state, isAsc);
        case 'original':
          return compare(a.original, b.original, isAsc);
        case 'sent':
          return compare(a.sent, b.sent, isAsc);
        case 'related':
          const aValue = a.related && a.related.length > 0 ? a.related[0].related : null;
          const bValue = b.related && b.related.length > 0 ? b.related[0].related : null;
          return compare(aValue, bValue, isAsc);
        default:
          return 0;
      }
    });
  }



  createUpdateDotInspectionDialog(obj: any) {
    let dialogRef: any = this.dialog.open(CreateUpdateInspectionDialogComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: 'component-dialog-container',
      data: {editMode: true, data: obj}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.refreshData.emit(true);
      }
    })
  };

}

function compare(a: any, b: any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}