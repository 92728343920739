import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MaintenanceRepairLogTableComponent } from './maintenance-repair-log-table/maintenance-repair-log-table.component';
import moment = require('moment');
import { catchError, Subscription, throwError } from 'rxjs';
import { MaintenanceRepairLogService } from '../../services/maintenance-repair-log.service';
import { TitleCasePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { CreateUpdateRepairComponent } from './create-update-repair/create-update-repair.component';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-maintenance-repair-log-page',
  templateUrl: './maintenance-repair-log-page.component.html',
  styleUrls: ['./maintenance-repair-log-page.component.scss']
})
export class MaintenanceRepairLogPageComponent implements OnInit, OnDestroy {
  @ViewChild(MaintenanceRepairLogTableComponent) childComponent: any;
  excelPermissions: any = this.rulesService.UserData[56].data;
  permissions: any = this.rulesService.UserData[60].data;

  //Excel config
  xlsxConfig: any = [
    {columnName: 'Date of Log Entry', selected: true},
    {columnName: 'Logged by', selected: true},
    {columnName: 'Truck Number', selected: true},
    {columnName: 'Trailer Number', selected: true},
    {columnName: 'Log ID', selected: true},
    {columnName: 'Division', selected: true},
    {columnName: 'Driver Name', selected: true},
    {columnName: 'Dispatcher', selected: true},
    {columnName: 'Reason for Repair', selected: true},
    {columnName: 'Description of Repair', selected: true},
    {columnName: 'Location of Repair', selected: true},
    {columnName: 'Status', selected: true}
  ];

  //Date 
  dateObj: any = {
    startDate: moment().startOf('isoWeek').format('YYYY-MM-DD'),
    endDate: moment().endOf('isoWeek').format('YYYY-MM-DD')
  };
  
  //Datasource
  maintenanceRepairLogData: any[] = [];

  //Day in the week
  dayInTheWeekArray: any[] = [
    {name: 'All Days', dayNum: -1},
    {name: 'Monday', dayNum: 2},
    {name: 'Tuesday', dayNum: 3},
    {name: 'Wednesday', dayNum: 4},
    {name: 'Thursday', dayNum: 5},
    {name: 'Friday', dayNum: 6},
    {name: 'Saturday', dayNum: 7},
    {name: 'Sunday', dayNum: 1}
  ];
  dayNumFilter: number = -1;

  loaded: boolean = true;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private transformService: TransformService, 
              private maintenanceRepairLogService: MaintenanceRepairLogService,
              private titleCase: TitleCasePipe,
              private dialog: MatDialog,
              private spinner: NgxSpinnerService,
              private rulesService: RulesService) { } 

  ngOnInit(): void {
    this.getMaintenanceRepairLogTableData();
  };

  getMaintenanceRepairLogTableData() {
    this.maintenanceRepairLogData = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('maintenance-repair-log-table');
    this.subscription = this.maintenanceRepairLogService.getMaintenanceRepairLogData(this.dateObj.startDate, this.dateObj.endDate, this.dayNumFilter)
    .pipe(catchError((err: any) => {
      this.spinner.hide('maintenance-repair-log-table');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any[]) => {
      this.maintenanceRepairLogData = response;
      this.spinner.hide('maintenance-repair-log-table');
      this.loaded = true;
      console.log(this.maintenanceRepairLogData)
    });
  };

  openRepairLogDialog() {
    if(this.permissions[0].sectionArray[0].allowed) {
      const dialogRef = this.dialog.open(CreateUpdateRepairComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'component-dialog-container',
        data: {editMode: false, data: {}}
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.maintenanceRepairLogData.push(result);
          this.maintenanceRepairLogData = [...this.maintenanceRepairLogData];
        }
      });
    }
    else {
      this.showForbiddenMesage();
    }
  };

  exportToExcel(columnsConfig: any[]) {
    if(this.excelPermissions[0].sectionArray[51].allowed) {
      const excelTable: any[] = [];
      for(let i = 0; i < this.maintenanceRepairLogData.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, this.transformService.transformDateFormat(this.maintenanceRepairLogData[i].DateOfLogEntry, 'YYYY-MM-DD'), columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.maintenanceRepairLogData[i].LoggedBy, columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, this.maintenanceRepairLogData[i].TruckNo, columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, this.maintenanceRepairLogData[i].TrailerNo, columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, this.maintenanceRepairLogData[i].LogID, columnsConfig[4].selected);
        this.transformService.selectedColumn(obj, columnsConfig[5].columnName, this.maintenanceRepairLogData[i].DivisionName, columnsConfig[5].selected);
        this.transformService.selectedColumn(obj, columnsConfig[6].columnName, this.titleCase.transform(this.maintenanceRepairLogData[i].DriverName), columnsConfig[6].selected);
        this.transformService.selectedColumn(obj, columnsConfig[7].columnName, this.maintenanceRepairLogData[i].Dispatcher, columnsConfig[7].selected);
        this.transformService.selectedColumn(obj, columnsConfig[8].columnName, this.maintenanceRepairLogData[i].ReasonForRepair, columnsConfig[8].selected);
        this.transformService.selectedColumn(obj, columnsConfig[9].columnName, this.maintenanceRepairLogData[i].DescriptionOfRepair, columnsConfig[9].selected);
        this.transformService.selectedColumn(obj, columnsConfig[10].columnName, this.maintenanceRepairLogData[i].LocationOfRepair, columnsConfig[10].selected);
        this.transformService.selectedColumn(obj, columnsConfig[11].columnName, this.maintenanceRepairLogData[i].Status, columnsConfig[11].selected);
        excelTable.push(obj); 
      };
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'maintenance-repair-log.xlsx');
    }
    else {
      this.showForbiddenMesage();
    }
  }

  clearFilters() {
    this.childComponent.clearFilters();
  };

  //Show forbidden message
  showForbiddenMesage() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}
