import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { SharedService } from '@app/modules/shared/services/shared.service';
import * as JSZip from 'jszip';  
import * as FileSaver from 'file-saver';  
import { ClaimsService } from '@app/modules/claims/services/claims.service';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'claims-files-tab',
  templateUrl: './claims-files-tab.component.html',
  styleUrls: ['./claims-files-tab.component.scss']
})
export class ClaimsFilesTabComponent implements OnInit {
  @Input() data: any = {accidentId: 0, formGroup: FormGroup};
  files: any[] = [];
  pdfLoading: boolean = false;

  constructor(private sharedService: SharedService,
              private claimsService: ClaimsService,
              private _formBuilder: FormBuilder,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.mergeFormFiles();
  }

  mergeFormFiles() {
    const mergeArray: any[] = [];
    this.files = mergeArray.concat(
      this.formData('cr_cc_attachment_from_receiver'), 
      this.formData('cr_cc_contamination_papework'), 
      this.formData('cr_cc_damage_upload_photos'), 
      this.formData('cr_cc_overs_noted'), 
      this.formData('cr_l_physc_d_other_vehicle_photos'),
      this.formData('cr_l_physc_d_our_vehicle_photos'), 
      this.formData('cr_l_property_d_our_vehicle_photos'), 
      this.formData('cr_pd_attach_papework'),
      this.formData('cr_pd_our_vehicle_photos'), 
      this.formData('files')
    );
  }

  downloadAllFiles() {
    this.pdfLoading = true;
    this.claimsService.getClaimsFiles(this.formData('id'), '', this.data.accidentId)
    .pipe(catchError((err: any) => {
      this.pdfLoading = false;
      this.showWarningMsg();
      return throwError(() => err);
    }))
    .subscribe((files: any) => {
      this.pdfLoading = false;
      let filesArray: any[] = [];
      if(typeof files === 'object') {
        filesArray = files.response1.concat(files.response2);
      }
      const zip = new JSZip();
      filesArray.forEach((file: any) => {
        zip.file(file.filename, file.data, { base64: true });
      });
      // Generate the zip file asynchronously
      zip.generateAsync({ type: 'blob' }).then((content: any) => {
        // Save the zip file using FileSaver.js
        FileSaver.saveAs(content, 'claims-files.zip');
      });
    });
  };

  openFileInNewTab(obj: any) {
    if(obj.data) {
      this.sharedService.downloadPreviewFile(obj.filename, obj.data);
    }
    else if(obj.ext !== 'accident_file') {
      obj.pdfLoading = true;
      this.claimsService.getClaimsFiles(this.formData('id'), obj.group_name)
      .pipe(catchError((err: any) => {
        obj.pdfLoading = false;
        this.showWarningMsg();
        return throwError(() => err);
      }))
      .subscribe((response: any) => {
        console.log(response);
        for(let i = 0; i < response?.length; i++) {
          if(response[i].filename === obj.filename) {
            obj.pdfLoading = false;
            this.sharedService.downloadPreviewFile(response[i].filename, response[i].data);
            break;
          }
        }
      });
    }
    else {
      obj.pdfLoading = true;
      this.sharedService.getAccidentFileById(this.data.accidentId, obj.filename)
      .pipe(catchError((err: any) => {
        obj.pdfLoading = false;
        this.showWarningMsg();
        return throwError(() => err);
      }))
      .subscribe((base64: any) => {
        obj.pdfLoading = false;
        this.sharedService.downloadPreviewFile(obj.filename, base64);
      });
    }
  };

  downloadFile(obj: any) {
    if(obj.data) {
      this.sharedService.downloadFile(obj.data, obj.filename);
    }
    else if(obj.ext !== 'accident_file') {
      obj.pdfLoading = true;
      this.claimsService.getClaimsFiles(this.formData('id'), obj.group_name)
      .pipe(catchError((err: any) => {
        obj.pdfLoading = false;
        this.showWarningMsg();
        return throwError(() => err);
      }))
      .subscribe((response: any) => {
        for(let i = 0; i < response?.length; i++) {
          if(response[i].filename === obj.filename) {
            obj.pdfLoading = false;
            this.sharedService.downloadFile(response[i].data, response[i].filename);
            break;
          }
        }
      });
    }
    else {
      obj.pdfLoading = true;
      this.sharedService.getAccidentFileById(this.data.accidentId, obj.filename)
      .pipe(catchError((err: any) => {
        obj.pdfLoading = false;
        this.showWarningMsg();
        return throwError(() => err);
      }))
      .subscribe((base64: any) => {
        obj.pdfLoading = false;
        let source: string = `data:application/octet-stream;base64,${base64}`;
        const downloadLink = document.createElement('a');
        const fileName = obj.filename;
        downloadLink.href = source;
        downloadLink.download = fileName;
        downloadLink.click();
      });
    }
  };

  deleteFile(obj: any) {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        let array: any[] = this.formData(obj.group_name);
        let index: number = 0;
        for(let i = 0; i < array.length; i++) {
          if(obj.filename === array[i].filename) {
            index = i;
            break;
          }
        }
        this.formArray(obj.group_name).removeAt(index);
        this.data.formGroup.controls[obj.group_name].updateValueAndValidity();
        this.mergeFormFiles();
      }
    })
  };

  //Add files
  onFileSelected(event: any) {
    let length: any = Object.keys(event.target.files).length;
    for (let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i);
    }
  }

  addMultipleFiles(event: any, index: number) {
    let array: any[] = this.formArray('files')?.controls;
    let fileName = event.target.files[index].name;
    let reader = new FileReader();
    reader.onload = (e: any) => {
      let base64 = e.target.result.split(',')[1];
      const itemGroup = this._formBuilder.group({
        filename: fileName,
        data: base64,
        group_name: 'files'
      });
      array.push(itemGroup)
      this.data.formGroup.controls['files'].updateValueAndValidity();
      this.mergeFormFiles();
    };
    reader.readAsDataURL(event.target.files[index]);
  };

  showWarningMsg() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  }

  //Get form value
  formData(formKey: string) {
    return this.data.formGroup.controls[formKey].value;
  };

  //Get form array
  formArray(formKey: string): FormArray {
    return this.data.formGroup.controls[formKey] as FormArray;
  };
  
}
