import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import moment = require('moment');
import { catchError, Subscription, throwError } from 'rxjs';
import { NotificationType, Notification } from './notification';
import { SharedService } from '../../services/shared.service';
import { TransformService } from '../../services/transform.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateUpdatePaymentDialogComponent } from '@app/modules/accounting/components/dashboard-payments-page/create-update-payment-dialog/create-update-payment-dialog.component';
import { WarningMsgDialogComponent } from '../warning-msg-dialog/warning-msg-dialog.component';
import { RequestDaysDenyDialogComponent } from '../request-days-deny-dialog/request-days-deny-dialog.component';

@Component({
  selector: 'app-notifications-dialog',
  templateUrl: './notifications-dialog.component.html',
  styleUrls: ['./notifications-dialog.component.scss']
})
export class NotificationsDialogComponent implements OnDestroy {
  private subscriptions: Subscription = new Subscription();
  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
  fullName: string = `${this.loggedUser.first_name} ${this.loggedUser.last_name}`;
  nickname: string = `${this.loggedUser.nickname}`;

  @Input() notifications: Notification[] = [];
  @Input() idsArray: number[] = [];
  @Output() loadMore = new EventEmitter<any>();
  numOfNotifications: number = 10;

  get NotificationType() {
    return NotificationType;
  }

  seeMoreId: number | undefined = undefined;

  constructor(private sharedService: SharedService, 
              public transformService: TransformService,
              private dialog: MatDialog) { }

  // Approve/read notification
  approveNotification(notificationId: number, approve: boolean) {
    let readedDate = this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'), moment(new Date()).format('HH:mm:ss'));
    const foundNotification = this.notifications.find(item => item.id === notificationId);
    foundNotification.readded = true;

    let approveNotificationSub = this.sharedService.approveNotification(notificationId, approve, this.loggedUser.id, readedDate)
      .subscribe((response) => {
        console.log(response);
        if (response) {
          this.loadMore.emit(this.numOfNotifications);
        }
      })
    this.subscriptions.add(approveNotificationSub);
  };

  // Extract name
  getName(input: string): string {
    const index = input.lastIndexOf('/');
    if (index !== -1) {
      return input.substring(0, index).trim();
    } else {
      return input.trim();
    }
  }

  calculateTimeDifference(date: string) {
    if(date.includes('.')) {
      return moment(date).add(7, 'hours').format('YYYY-MM-DDTHH:mm:ss');
    }
    return date;
  };

  //Open in new tab
  returnRoute(obj: any) {
    let route: string = '';
    if(obj?.data?.driver_id) {
      route = `/admin/drivers/filter-drivers/driver-profile/${obj.data.driver_id}`;
    }
    else {
      route = `/admin/dispatchers/dashboard-dispatch-profile-page/${obj.data.dispatcher_id}`;
    }
    return route;
  };

  approveDenyRequest(isApproved: boolean, notification: any) {
    const data: any = notification.data
    console.log(data);
    let objData: any = {
      id: data.id,
      approved_by: `${this.nickname.length === 0 ? this.fullName : this.nickname?.trim().split(' ')[0]}`,
      approved_id: this.loggedUser.id,
      approved: 0,
      reason: '',
      approved_date: '',
      email: this.loggedUser.username,
      department: this.loggedUser.department
    };

    if(isApproved) {
      objData.approved = 1;
      objData.approved_date = moment().format('YYYY-MM-DDTHH:mm:ss');
      this.responseForRequest(objData, notification.id, isApproved, notification);
    }
    else {
      const dialogRef = this.dialog.open(RequestDaysDenyDialogComponent, {
        disableClose: true,
        autoFocus: false,
        panelClass: 'component-dialog-container'
      });
      dialogRef.afterClosed().subscribe((reasonForDenial: string | undefined) => {
        if(reasonForDenial) {
          objData.approved = 2;
          objData.reason = reasonForDenial;
          objData.approved_date = moment().format('YYYY-MM-DDTHH:mm:ss');
          this.responseForRequest(objData, notification.id, isApproved, notification);
        }
        else {
          return;
        }
      })
    }
  };

  responseForRequest(objData: any, id: number, isApproved: boolean, notification: any) {
    this.sharedService.approveDenyRequest(objData)
    .pipe(catchError((err: any) => {
        this.showErrorMessage();
        return throwError(() => err);
    }))
    .subscribe((response: any) => {
      if(response) {
        this.approveNotification(id, false);
        if(isApproved) {
          const statusNames: any = {
            'Vacation': 'Vacation',
            'Complimentary Day Off': 'Day off',
            'Day Off': 'Day Off',
            'Slava': 'Family tradition',
            'Birthday': 'Day Off',
            'Personal': 'Day Off'
          };

          const iconNames: any = {
            'Vacation': 'vacation.png',
            'Complimentary Day Off': 'day-off-yellow.svg',
            'Day Off': 'day-off-yellow.svg',
            'Slava': 'family-tradition.png',
            'Birthday': 'day-off-yellow.svg',
            'Personal': 'day-off-yellow.svg'
          };

          const colorNames: any = {
            'Vacation': 'blue',
            'Complimentary Day Off': 'orange',
            'Day Off': 'orange',
            'Slava': 'blue',
            'Birthday': 'orange',
            'Personal': 'orange'
          };
          let statusObj: any = {
            id: 0,
            employee_id: this.loggedUser.id,
            employee:  this.loggedUser.first_name + ' ' + this.loggedUser.last_name,
            icon_id: 0,
            icon: iconNames[notification.data.request_type],
            color: colorNames[notification.data.request_types],
            craetor: 'System',
            creator_id: 0,
            creator_department: '',
            status_date: moment().format('YYYY-MM-DDTHH:mm:ss'),
            datecreated: moment().format('YYYY-MM-DDTHH:mm:ss'),
            editor: '',
            edit_date: null,
            terminated: false,
            className: '',
            no_className: '',
            dispatcher_className: '',
            total_className: '',
            hours: 0,
            approved: true,
            firstday: notification.data.date_from,
            backtowork: notification.data.return_to_work,
            files: [],
            departments: '',
            is_active: true,
            city: '',
            inactive_date: null,
            nickname: '',
            holiday_from: notification.data.date_from,
            holiday_to: notification.data.date_to,
            status_group_name: 'Out of work',
            status: statusNames[notification.data.request_type],
            note: notification.data.request_type === 'Personal' ? notification.data.request_reason : notification.data.request_type
          };
          this.sharedService.createUpdateVacationStatus(statusObj).subscribe((response: any) => {console.log(response)})
        }
      };
    });
  };

  //Call api
  onScroll(event: any) {
    if(event.target.scrollHeight <= Math.round(event.target.scrollTop + event.target.offsetHeight + 1)) {
      this.numOfNotifications += 10;
      this.loadMore.emit(this.numOfNotifications);
    } 
  }

  countDays(date1: string, date2: string) {
    let date_1 = new Date(date1);
    let date_2 = new Date(date2);

    let difference = date_1.getTime() - date_2.getTime();
    let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
    let value: string = totalDays === 1 ? totalDays + ' day' : totalDays + ' days';
    return value
  }

  checkDueDate(dueDate: string) {
    let date1 = new Date(moment().format('YYYY-MM-DDT00:00:00'));
    let date2 = new Date(dueDate);
    let Difference_In_Time = date2.getTime() - date1.getTime();
    let value: number = Math.round(Difference_In_Time / (1000 * 3600 * 24));
    if(value < 0) {
      return 'Expired'
    }
    else if(value === 1) {
      return `${value} day left`;
    }
    else {
      return `${value} days left`;
    }
  };

  getNote(obj: any) {
    if(obj.hasOwnProperty('notes')) {
      return obj.notes;
    }
    else {
      return obj.note;
    }
  }

  identify(index, item){
    return item.id; 
  };

  openPayment(element: any) {
    const obj: any = JSON.parse(element);
    this.dialog.open(CreateUpdatePaymentDialogComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: 'component-dialog-container',
      data: {editMode: true, data: obj}
    });
  };

  //Show error message
  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  };

}
