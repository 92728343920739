import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatementRoutingModule } from './statements.routing';
import { SharedModule } from '../shared/shared.module';
import { StatementPageComponent } from './components/statement-page.component';
import { StatementsTableComponent } from './components/statements-table/statements-table.component';
import { StatementsService } from './services/statements.service';
import { StatementsTablePipe } from './pipes/statements-table.pipe';

@NgModule({
  declarations: [
    StatementPageComponent,
    StatementsTableComponent,
    StatementsTablePipe
  ],
  imports: [
    CommonModule,
    StatementRoutingModule,
    SharedModule
  ],
  providers: [StatementsService]
})
export class StatementsModule { }
