import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccidentsPageComponent } from './components/accidents-page/accidents-page.component';
import { AuthGuard } from '../core/services/auth/auth.guard';

const routes: Routes = [
	{
		path: '',
		component: AccidentsPageComponent,
		canActivate: [AuthGuard],
		data: { roles: 43 }
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AccidentsRoutingModule { }