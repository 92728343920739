import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, forkJoin } from 'rxjs';

@Injectable()

export class SafetyService {

  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy',
    }),
    responseType: 'blob' as 'json',
  };

  constructor(private http: HttpClient) { }

  truckTableSubject: any = new Subject<any>();

  getTrucksTableData() {
    let response1 = this.http.get(`${_G.extendApiUrl}dochub`, this.requestOptions);
		let response2 = this.http.get(`${_G.extendApiUrl}dochub/getCategories`, this.requestOptions);
    return forkJoin([response1, response2]);
  };

  uploadFile(array: any[]) {
    return this.http.post(`${_G.extendApiUrl}dochub/uploadFile`, array, {
      headers: this.headers,
      reportProgress: true,
      observe: 'events'
    });
  };

  downloadFile(fileId: number) {
    return this.http.get(`${_G.extendApiUrl}dochub/getFile/${fileId}`, this.httpOptions);
  };

  deleteFile(data: any) {
    let obj: any = {fileName: data.fileName, truckIDs: data.filteredArray};
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}dochub/deleteFileForTrucks`, json, this.requestOptions);
  };

  getAllFilesByTruck(unitNo: number) {
    return this.http.get(`${_G.extendApiUrl}dochub/getAllFiles/${unitNo}`, this.requestOptions);
  };
  
  uploadMergedFile(obj: any) {
    return this.http.post(`${_G.extendApiUrl}dochub/uploadMergedFile`, obj, {
      headers: this.headers,
      reportProgress: true,
      observe: 'events'
    });
  };

  //Categories
  getAllCategories() {
    return this.http.get(`${_G.extendApiUrl}dochub/getCategories`, this.requestOptions);
  };

  changeCategoryPosition(array: any[]) {
    return this.http.post(`${_G.extendApiUrl}dochub/uploadCategoryChanges`, array, this.requestOptions);
  };

  deleteCategory(categoryId: number) {
    return this.http.post(`${_G.extendApiUrl}dochub/deleteCategory?ID=${categoryId}`, '', this.requestOptions);
  };

  //Truck devices
  newTruckDeviceAdded: any = new Subject<any>();

  getTruckDevicesTable() {
    return this.http.get(`${_G.extendApiUrl}dochub/getTruckDeviceTable`, this.requestOptions);
  };

  changeTruckStatus(truckID: string, status: number) {
    return this.http.post(`${_G.extendApiUrl}dochub/changeTruckDeviceStatus?ID=${truckID}&status=${status}`, '', this.requestOptions);
  };

  getDropDownMenuData() {
    let response1 = this.http.get(`${_G.extendApiUrl}dochub/getTrucksForSearch`, this.requestOptions);
		let response2 = this.http.get(`${_G.extendApiUrl}drivers`, this.requestOptions);
    return forkJoin([response1, response2]);
  };

  addTruckDevice(obj: any) {
    return this.http.post(`${_G.extendApiUrl}dochub/addTruckDevice`, obj, this.requestOptions);
  };

  editTruckDevice(obj: any) {
    return this.http.post(`${_G.extendApiUrl}dochub/editTruckDevice`, obj, this.requestOptions);
  };

  getMergedFile(truckID: any) {
    return this.http.get(`${_G.extendApiUrl}dochub/getMergedFile/${truckID}`, this.httpOptions);
  };

  deleteTruckDevice(id: number) {
    return this.http.post(`${_G.extendApiUrl}dochub/deleteTruckDevice/${id}`, '', this.requestOptions);
  };

  createCategory(categoryName: string) {
    return this.http.post(`${_G.extendApiUrl}dochub/addCategory?categoryName=${categoryName}`, '', this.requestOptions);
  };

  //Contract section
  contractTableSubject: any = new Subject<any>();

  getContractTableData() {
    let response1 = this.http.get(`${_G.extendApiUrl}dochubcontract`, this.requestOptions);
		let response2 = this.http.get(`${_G.extendApiUrl}dochubcontract/getCategories`, this.requestOptions);
    return forkJoin([response1, response2]);
  };

  getContractCategories() {
    return this.http.get(`${_G.extendApiUrl}dochubcontract/getCategories`, this.requestOptions);
  };

  createContractCategory(categoryName: string) {
    return this.http.post(`${_G.extendApiUrl}dochubcontract/addCategory?categoryName=${categoryName}`, '', this.requestOptions);
  };

  changeContractCategoryPosition(array: any[]) {
    return this.http.post(`${_G.extendApiUrl}dochubcontract/uploadCategoryChanges`, array, this.requestOptions);
  };

  deleteContractCategory(categoryId: number) {
    return this.http.post(`${_G.extendApiUrl}dochubcontract/deleteCategory?ID=${categoryId}`, '', this.requestOptions);
  };

  //Upload category files

  getContractFile(fileId: number) {
    return this.http.get(`${_G.extendApiUrl}dochubcontract/getFile/${fileId}`, this.httpOptions);
  };

  getAllContractFiles(driverID: number) {
    return this.http.get(`${_G.extendApiUrl}dochubcontract/getAllFiles/${driverID}`, this.requestOptions);
  };

  uploadContractFiles(array: any[]) {
    return this.http.post(`${_G.extendApiUrl}dochubcontract/uploadFile`, array, {
      headers: this.headers,
      reportProgress: true,
      observe: 'events'
    });
  };

  uploadContractMergedFile(obj: any) {
    return this.http.post(`${_G.extendApiUrl}dochubcontract/uploadMergedFile`, obj, {
      headers: this.headers,
      reportProgress: true,
      observe: 'events'
    });
  };

  getMergedContractFile(driverID: any) {
    return this.http.get(`${_G.extendApiUrl}dochubcontract/getMergedFile/${driverID}`, this.httpOptions);
  };

  deleteContractFile(fileId: number) {
    return this.http.post(`${_G.extendApiUrl}dochubcontract/deleteFile/${fileId}`, '', this.requestOptions);
  };

  //Upload files
  getTrucksForSearchCategories() {
    let response1 = this.http.get(`${_G.extendApiUrl}dochub/getTrucksForSearch`, this.requestOptions);
    let response2 = this.http.get(`${_G.extendApiUrl}dochub/getCategories`, this.requestOptions);
    return forkJoin([response1, response2]);
  };

  getTrucksForSearch() {
    return this.http.get(`${_G.extendApiUrl}dochub/getTrucksForSearch`, this.requestOptions);
  };

  uploadFilesForTrucks(obj: any) {
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}dochub/uploadFileToTrucks`, json, {
      headers: this.headers,
      reportProgress: true,
      observe: 'events'
    });
  };

  //Contract editor
  templateSubject: any = new Subject<any>();

  getDivisionsContract() {
    return this.http.get(`${_G.extendApiUrl}dochubcontract`, this.requestOptions);
  };

  getAllVariables() {
    return this.http.get(`${_G.extendApiUrl}contract/values`, this.requestOptions);
  };

  createEditVariable(array: any[]) {
    let json: any = JSON.stringify(array);
    return this.http.post(`${_G.extendApiUrl}contract/values`, json, this.requestOptions);
  };

  getAllTemplates() {
    return this.http.get(`${_G.extendApiUrl}contract/templates`, this.requestOptions);
  };

  createEditTemplate(obj: any) {
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}contract/templates`, json, this.requestOptions);
  };

  deleteTemplate(id: number) {
    return this.http.get(`${_G.extendApiUrl}contract/deletetemplate?id=${id}`, this.requestOptions);
  };

  deleteVariable(id: number) {
    return this.http.get(`${_G.extendApiUrl}contract/deletevalue?id=${id}`, this.requestOptions);
  };

  getTemplateByDriverId(id: number) {
    return this.http.get(`${_G.extendApiUrl}contract/templates/${id}`, this.requestOptions);
  };

  getTemplatePdf(id: number, templateId: number, date: string) {
    return this.http.get(`${_G.extendApiUrl}contract/pdf?id=${id}&template_id=${templateId}&date=${date}&signature=`, this.requestOptions);
  };

  getAllTemplatesByDriver(driverId: number, date: string) {
    return this.http.get(`${_G.extendApiUrl}contract/allpdf?id=${driverId}&date=${date}&signature=`, this.requestOptions);
  };

  //Policy
  getAllDrivers() {
    let response1: any = this.http.get(`${_G.extendApiUrl}drivers/getdrivers`, this.requestOptions);
    let response2: any = this.http.get(`${_G.extendApiUrl}Maintenance/getCompanyData`, this.requestOptions);
    return forkJoin({response1: response1, response2: response2});
  };

  sendPolicy(truckIds: string, obj: any) {
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}routingapp/sendPolicyToDrivers?truckIDs=${truckIds}`, json, this.requestOptions);
  };

}
