import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { ApexAxisChartSeries, ApexChart, ApexXAxis, ApexYAxis, ApexDataLabels, ApexGrid, ApexStroke } from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  yaxis: ApexYAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  stroke: ApexStroke | any;
  markers: any;
  colors: any;
  tooltip: any;
};

@Component({
  selector: 'inspections-linechart',
  templateUrl: './inspections-linechart.component.html',
  styleUrls: ['./inspections-linechart.component.scss']
})
export class InspectionsLinechartComponent {
  public chartOptions: Partial<ChartOptions> | any;

  dateObj: any = {startDate: '', endDate: ''};
  graphData: any[] = [];

  @Input() set updateGraphs(obj: any) {
    this.dateObj = obj.dateObj;
    this.graphData = obj.graphData;
    this.initGraph(this.transformService.countAllDataByPeriodAndKey(this.graphData, this.selectPeriod.value[0], 'points', 'Points', 'Inspections'));
  };

  //Period
  isOpenPeriodMenu: boolean = false;
  menuValue: string = 'By week';
  selectPeriodArray: any[] = [
    { name: 'By year', value: 'year' },
    { name: 'By quarter', value: 'quarter' },
    { name: 'By month', value: 'month' },
    { name: 'By week', value: 'week' },
    { name: 'By day', value: 'day' },
  ];
  selectPeriod: FormControl;

  constructor(private transformService: TransformService) { 
    this.selectPeriod = new FormControl(['week']);
  }

  initGraph(data: any) {
    let arrayData: any[] = JSON.parse(JSON.stringify(data));
    let seriesData: any[] = [];

    for (let key in arrayData) {
      let obj: any = {
        name: arrayData[key].name,
        data: arrayData[key].total
      }
      seriesData.push(obj)
    }

    this.chartOptions = {
      series: seriesData,
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          }
        },
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        },
        fontFamily: 'Poppins'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight",
        width: 1,
      },
      grid: {
        row: {
          colors: ["transparent", "transparent"],
          opacity: 0.5
        }
      },
      xaxis: {
        categories: arrayData[0].categories
      },
      yaxis: this.addYAxis(seriesData),
      markers: {
        size: 5
      },
      colors: ['#0030FF', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'],
      tooltip: {
        enabled: true,
        inverseOrder: false,
        style: {
          fontSize: '12px'
        },
      }
    };
  }

  addYAxis(array: any) {
    let yAxisArray: any[] = [];
    let colors: string[] = ['#0030FF', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'];
    for (let i = 0; i < array.length; i++) {
      let obj: any;
      if (i === 0) {
        obj = {
          seriesName: array[i].name![0], axisTicks: { show: true }, axisBorder: { show: false, color: colors[i] },
          labels: {
            style: { colors: colors[i] }, formatter: (num: number) => {
              return num?.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          }
        }
      }
      else {
        obj = {
          seriesName: array[i].name[0], opposite: true, axisTicks: { show: true }, axisBorder: { show: false, color: colors[i] },
          labels: {
            style: { colors: colors[i] }, formatter: (num: number) => {
              return num?.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          }
        }
      }

      yAxisArray.push(obj);
    }
    return yAxisArray;
  }

  onlyOneSelect(value: string, obj: any) {
    this.menuValue = obj.name;
    this.selectPeriod = new FormControl([value]);
    this.initGraph( this.transformService.countAllDataByPeriodAndKey(this.graphData, this.selectPeriod.value[0], 'points', 'Points', 'Inspections'));
    this.isOpenPeriodMenu = false;
  };

}
