import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import moment = require('moment');

@Component({
  selector: 'range-calendar',
  templateUrl: './range-calendar.component.html',
  styleUrls: ['./range-calendar.component.scss']
})
export class RangeCalendarComponent implements OnInit {
  @Input() config: any = {
    startDate: '',
    endDate: '',
    formatToShow: 'MM/dd/yyyy',
    formatToReturn: 'YYYY-MM-DD'
  };
  @Output() selectedRangeValueChange = new EventEmitter<any>();
  selectedRangeValue: any;
  isOpenCalendar: boolean = false;

  public positions = [
    new ConnectionPositionPair(
      {originX: 'start', originY: 'bottom'},
      {overlayX: 'start', overlayY: 'top'},
      0, 10
    ),
    new ConnectionPositionPair(
      { originX: 'start', originY: 'top' },
      { overlayX: 'start', overlayY: 'bottom' },
      0, -10
    )
  ];

  ngOnInit(): void {
    this.setDateSelection();
  }

  dateRangeClicked(m: any) {
    if (!this.selectedRangeValue?.start || this.selectedRangeValue?.end) {
      this.selectedRangeValue = new DateRange<Date>(m, null);
      this.config.startDate = moment(this.selectedRangeValue.start._d).format(this.config.formatToReturn);
      this.config.endDate = '';
    } 
    else {
      const start = this.selectedRangeValue.start;
      const end = m;
      if (end < start) {
        this.selectedRangeValue = new DateRange<Date>(end, start);
      } 
      else {
        this.selectedRangeValue = new DateRange<Date>(start, end);
      }
      if(this.selectedRangeValue.start) {
        this.config.startDate = moment(this.selectedRangeValue.start._d).format(this.config.formatToReturn);
      }
      if(this.selectedRangeValue.end) {
        this.config.endDate = moment(this.selectedRangeValue.end._d).format(this.config.formatToReturn);
      }
    }
  }

  apply() {
    this.isOpenCalendar = false;
    const mmddyyyyRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
    if(mmddyyyyRegex.test(this.config.startDate) || mmddyyyyRegex.test(this.config.endDate)) {
      this.selectedRangeValueChange.emit({startDate: moment(this.config.startDate, 'MM/DD/YYYY', true).format(this.config.formatToReturn), endDate: moment(this.config.endDate, 'MM/DD/YYYY', true).format(this.config.formatToReturn)});
      this.setDateSelection();
      return;
    }
    this.selectedRangeValueChange.emit({startDate: this.config.startDate, endDate: this.config.endDate});
  };

  setDateSelection() {
    if (this.config.startDate && this.config.endDate) {
      const start = moment(this.config.startDate);
      const end = moment(this.config.endDate);
  
      if (start.isValid() && end.isValid()) {
        this.selectedRangeValue = new DateRange(start, end);
      }
    }
  }

}
