import { Component } from '@angular/core';

@Component({
  selector: 'app-warning-msg-deductions',
  templateUrl: './warning-msg-deductions.component.html',
  styleUrls: ['./warning-msg-deductions.component.scss']
})
export class WarningMsgDeductionsComponent {

}
