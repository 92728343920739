import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'form-liability-personal-injury',
  templateUrl: './form-liability-personal-injury.component.html',
  styleUrls: ['./form-liability-personal-injury.component.scss']
})
export class FormLiabilityPersonalInjuryComponent {
  @Input() formGroup: FormGroup;

  //Get form value
  formData(formKey: string) {
    return this.formGroup.controls[formKey].value;
  };

}
