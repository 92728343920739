import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'dropdown-one-select',
  templateUrl: './dropdown-one-select.component.html',
  styleUrls: ['./dropdown-one-select.component.scss']
})
export class DropdownOneSelectComponent {
  @Input() config: any = {
    array: [],
    defaultValue: '',
    selectedValue: '',
    keyToShow: '',
    keyToReturn: '',
    indexToReturn: false,
    search: false,
    titleCase: false
  };
  @Input() styleConfig: any = {
    'width': '240px',
    'height': '45px',
    'background-color': '#f4f8ff',
    'color': '#1F1F1F',
    'box-shadow': 'none',
    'border-radius': '25px',
    'border-top': 'thin solid #0000001f',
    'arrow-color': '#0856cd',
    'opened-dropdown-shadow': '0px 1px 6.65px 0.35px rgba(13, 39, 80, 0.16)'
  };

  @Output() triggerEvent = new EventEmitter<string | number>();
  isOpenOneSelectDropdown: boolean = false;
  dropdownSearch: string = '';

  constructor(private titleCase: TitleCasePipe) { console.log(this.config.array) }

  selectOption(obj: any, index: number) {
    this.config.selectedValue = this.config.keyToShow === '' ? obj : obj[this.config.keyToShow]; 
    if(this.config.indexToReturn) {
      this.triggerEvent.emit(index);
    }
    else {
      this.triggerEvent.emit(this.config.keyToReturn === '' ? obj : obj[this.config.keyToReturn]);
    }
    this.isOpenOneSelectDropdown = false;
  };

  get getSelectValue() {
    if(this.config.selectedValue === '') {
      return this.config.defaultValue;
    };
    if(this.config.titleCase) {
      return this.titleCase.transform(this.config.selectedValue);
    };
    return this.config.selectedValue;
  };

  getDropdownValue(element: any) {
    if(this.config.keyToShow === '') {
      return element;
    };
    return element[this.config.keyToShow];
  };

}
