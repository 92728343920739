import { Component, EventEmitter, Input, Output } from '@angular/core';
import moment = require('moment');

@Component({
  selector: 'calendar-with-time',
  templateUrl: './calendar-with-time.component.html',
  styleUrls: ['./calendar-with-time.component.scss']
})
export class CalendarWithTimeComponent {
  @Input() config: any = {
    date: '',
    defaultValue: '',
    dateFormatToShow: 'MMM dd, yyyy.',
    dateFormatToReturn: 'YYYY-MM-DD'
  };
  @Input() styleConfig: any = {
    'width': '240px',
    'height': '45px',
    'background-color': '#f4f8ff',
    'color': '#1F1F1F',
    'box-shadow': 'none',
    'border-radius': '25px',
    'border-top': 'thin solid #0000001f',
    'opened-dropdown-shadow': '0px 1px 6.65px 0.35px rgba(13, 39, 80, 0.16)'
  };
  @Output() triggerEvent = new EventEmitter<string | number>();

  //Date
  isOpenCalendar: boolean = false;
  calendarView: boolean = true;

  //Timepicker
  isOpenTimeMenu: boolean = false;
  hour: any = '';
  minutes: any = '';

  dateClicked(event: any) {
    this.isOpenCalendar = false;
    this.triggerEvent.emit(moment(event._d).format('YYYY-MM-DDT00:00:00'));
  }

  //Time methods
  leadingZero(hour: boolean) {
    if(hour) {
      this.hour = String(this.hour).padStart(2, '0');
    }
    else {
      this.minutes = String(this.minutes).padStart(2, '0');
    }
  }

  applyTime() {
    if(isNaN(this.hour) || this.hour === '') {
      this.hour = '00';
    }
    
    if(isNaN(this.minutes) || this.minutes === '') {
      this.minutes = '00';
    }
    if(this.config.date) {
      this.triggerEvent.emit(moment(this.config.date).format(`YYYY-MM-DDT${this.hour}:${this.minutes}:00`));
    }
    else {
      this.triggerEvent.emit(moment().format(`YYYY-MM-DDT${this.hour}:${this.minutes}:00`));
    }
    this.isOpenCalendar = false;
  };

}
