import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { catchError, Subscription, throwError } from 'rxjs';
import { TitleCasePipe } from '@angular/common';
import { IDeduction } from '@app/modules/accounting/models/deduction.model';
import moment = require('moment');
import { DeductionsService } from '@app/modules/accounting/services/deductions.service';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { WarningMsgDeductionsComponent } from './warning-msg-deductions/warning-msg-deductions.component';

@Component({
  selector: 'app-add-edit-deductions-dialog',
  templateUrl: './add-edit-deductions-dialog.component.html',
  styleUrls: ['./add-edit-deductions-dialog.component.scss']
})
export class AddEditDeductionsDialogComponent implements OnInit, OnDestroy {
  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
  fullName: string = `${this.loggedUser.first_name} ${this.loggedUser.last_name}`;
  nickname: string = `${this.loggedUser.nickname}`;
  permissions: any = this.rulesService.UserData[58].data;

  //Deduction group
  deductionFormGroup: any = this._formBuilder.nonNullable.group({
    id: [0],
    issued_by: [`${this.nickname.length === 0 ? this.fullName : this.nickname?.trim().split(' ')[0]}`, [Validators.required, Validators.minLength(2)]],
    creator_id: [this.loggedUser.id],
    driver_name: [''],
    driver_id: [0],
    division: [''],
    division_id: [0],
    unit_no: [''],
    description: [''],
    total_deductions: [null],
    no_of_installments: [1, [Validators.required, Validators.min(1)]],
    note: [''],
    created_date: [null],
    installements: this._formBuilder.array([]),
    files: this._formBuilder.array([])
  });

  today = new Date();

  //Drivers
  driversArray: any[] = [];

  //Divisions
  divisionsArray: any[] = [];
  divisionById: any = {};

  //Units
  unitsArray: any[] = [];

  //Files
  filesLoaded: boolean = true;

  //Multiple clicks
  multipleClicks: boolean = true;

  //Date filters
  dateFilters: ((dateObj: any) => boolean)[] = [];

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();
  subscription3: Subscription = new Subscription();
  subscription4: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any, 
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<AddEditDeductionsDialogComponent>,
              private sharedService: SharedService,
              private deductionsService: DeductionsService,
              private titleCasePipe: TitleCasePipe,
              private _formBuilder: FormBuilder,
              private rulesService: RulesService) { }

  ngOnInit(): void {
    if(this.obj.editMode) {
      const initData: IDeduction = this.obj.data;
      this.deductionFormGroup.patchValue({
        id: initData.id,
        issued_by: initData.issued_by,
        creator_id: initData.creator_id,
        driver_name: initData.driver_name,
        driver_id: initData.driver_id,
        division: initData.division,
        division_id: initData.division_id,
        unit_no: initData.unit_no,
        description: initData.description,
        total_deductions: initData.total_deductions,
        no_of_installments: initData.no_of_installments,
        note: initData.note,
        created_date: initData.created_date,
      });
      this.initInstallmentsArray(initData.installements);
      this.getDeductionFiles();
    }
    else {
      this.addInstallments('1');
    }
    this.getDrivers();
    this.getDivisions();
    this.getTrucksAndTrailers();
  };

  totalDeductionsValueChange(value: string) {
   const total_deduction: number = Number(value.replace(/[^\d.]/g, ''));
   const installmentsArray = this.formArray('installements');
   if(installmentsArray.length === 1) {
     this.formArray('installements').controls[0].patchValue({'deducted_amount': total_deduction})}
   else {
     this.addInstallments(this.formData('no_of_installments'));
   }
  };

  getDrivers() {
    this.subscription1 = this.sharedService.getDriversData().subscribe((response: any) => {
      console.log(response);
      for(let i = 0; i < response.length; i++) {
        response[i].full_name = this.titleCasePipe.transform(`${response[i].first_name} ${response[i].last_name}`);
        response[i].full_name_upper_case = `${response[i].first_name} ${response[i].last_name}`;
      }
      this.driversArray = response;
      this.driversArray.unshift({full_name: 'N/A', full_name_upper_case: 'N/A'});
    });
  };

  getDivisions() {
    this.subscription2 = this.sharedService.getCompanyData().subscribe((response: any) => {
      this.divisionsArray = response.divisions;
      for(let i = 0; i < this.divisionsArray.length; i++) {
        this.divisionById[this.divisionsArray[i].id] = this.divisionsArray[i].name;
      }
    });
  };

  getTrucksAndTrailers() {  
    this.subscription3 = this.sharedService.getTrucksAndTrailersData(true).subscribe((response: any) => {
      this.unitsArray = response[0].concat(response[1]);
      this.unitsArray.unshift({unit_no: 'N/A'});
    });
  };

  getDeductionFiles() {
    this.subscription4 = this.deductionsService.getAllDeductionsFiles(this.obj.data.id)
    .pipe(catchError((err: any) => {
      this.filesLoaded = false;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      if(response?.length > 0) {
        this.initArray('files', response);
      }
      this.filesLoaded = true;
    });
  };

  addInstallments(numOfInstallments: string) {
    const installmentsArray = this.formArray('installements');
    installmentsArray.clear();
    this.dateFilters = [];
    const enteredNumber: number = Number(numOfInstallments);
    let total_deduction: number = this.formData('total_deductions');
    let deducted_amount: number = 0;
    if(total_deduction > 0) {
      deducted_amount = total_deduction / enteredNumber;
    }
    for(let i = 0; i < enteredNumber; i++) {
      installmentsArray.push(this._formBuilder.group({id: [null], deduction_id: [null], date: [null, Validators.required], deducted_amount: deducted_amount, name: '', user_id: 0, scheduled_checkbox: false}));
      this.initDateFiltersArray();
    }
    this.deductionFormGroup.controls['installements'].updateValueAndValidity();
  };

  changeInstallmentDate(form: any, date: string, index: number) {
    form.patchValue({'date': date});
    if(this.dateFilters.length > index) {
      this.dateFilters[index] = (dateObj: any): boolean => {
        if (!dateObj) return false;
        const selectedDate = new Date(dateObj._d);
        const todayUTC = new Date(Date.UTC(
          this.today.getFullYear(),
          this.today.getMonth(),
          this.today.getDate()
        ));
  
        return selectedDate.getUTCDay() === 2 && selectedDate >= todayUTC && selectedDate >= new Date(date);
      }

      const installmentsArray = this.formArray('installements').controls;
      let isOutOfOrder = false;

      for (let i = 1; i < installmentsArray.length; i++) {
        if(index === 1) {
          const prevDate = moment(installmentsArray[i - 1].value.date);
          installmentsArray[i].patchValue({ date: prevDate.add(7, 'days').toISOString()}); 
        }
        else {
          if (!installmentsArray[i].value?.date) continue;
          const prevDate = new Date(installmentsArray[i - 1].value?.date);
          const currentDate = new Date(installmentsArray[i].value?.date);
          if (isOutOfOrder) {
            installmentsArray[i].patchValue({ date: null });
          } 
          else if (currentDate < prevDate) {
            isOutOfOrder = true;
            installmentsArray[i].patchValue({ date: null });
          }
        }
      }
    }
  };

  multipleUploadMethod(obj: any) {
    if(obj.action === 'UPLOAD') {
      const array: any[] = this.formArray('files')?.controls;
      array.push(this._formBuilder.group({filename: obj.data.filename, data: obj.data.base64}));
      this.deductionFormGroup.controls['files'].updateValueAndValidity();
    };
    if(obj.action === 'NEW TAB') {
      this.sharedService.downloadPreviewFile(obj.data.value.filename, obj.data.value.data);
    };
    if(obj.action === 'DOWNLOAD') {
      this.sharedService.downloadFile(obj.data.value.data, obj.data.value.filename);
    };
    if(obj.action === 'DELETE') {
      let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
        autoFocus: false,
        panelClass: 'delete-dialog-container',
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.formArray('files').removeAt(obj.data);
          this.deductionFormGroup.controls['files'].updateValueAndValidity();
        }
      })
    };
  };

  selectDriver(data: any) {
    if(typeof data === 'object') {
      let division: string = this.divisionById[data.division_id];
      let division_id: number = data.division_id;
      this.deductionFormGroup.patchValue({
        'driver_name': data.full_name, 
        'driver_id': data.id,
        'division': division,
        'division_id': division_id
      })
    }
    else {
      this.deductionFormGroup.patchValue({'driver_name': data});
    }
  };

  saveData() {
    this.deductionFormGroup.controls['installements'].updateValueAndValidity();
    this.deductionFormGroup.controls['files'].updateValueAndValidity();
    console.log(this.deductionFormGroup.value);
    this.deductionFormGroup.markAllAsTouched();
    if(this.checkTotalDeductions) {
      this.showErrorDeductionsMessage();
      return
    }

    if(this.deductionFormGroup.valid && this.multipleClicks) {
      this.multipleClicks = false;
      const formData: IDeduction = this.deductionFormGroup.value;
      console.log(formData); 
      if(formData.id === 0) {
        formData.created_date = moment().format('YYYY-MM-DDTHH:mm:ss');
      }
      this.deductionsService.createUpdateDeduction(formData).subscribe((response: any) => {
        console.log(response);
        if(response?.id) {
          this.dialogRef.close(response);
        }
        else {
          this.showErrorMessage();
        }
      }); 
    }
  };

  deleteDeduction() {
    if(this.permissions[0].sectionArray[3].allowed) { 
      let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
        autoFocus: false,
        panelClass: 'delete-dialog-container',
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.deductionsService.deleteDeduction(this.obj.data.id, `${this.nickname.length === 0 ? this.fullName : this.nickname?.trim().split(' ')[0]}`, moment().format('YYYY-MM-DDTHH:mm:ss'))
          .pipe(catchError((err: any) => {
              this.showErrorMessage();
              return throwError(() => err);
          }))
          .subscribe((response: any) => {
            console.log(response);
            if(response) {
              this.dialogRef.close('DELETE DEDUCTION');
            }
          });
        }
      })
    }
    else {
      this.showForbiddenMesage();
    }
  };

  public addTwoDecimals = (value: string | number | undefined | null): string => {
    if (!value) return '';
  
    let num = Number(value);
  
    if (isNaN(num)) return String(value);
  
    return num % 1 === 0 ? num.toFixed(2) : String(value);
  };
  
  //Show error deductions message
  showErrorDeductionsMessage() {
    this.dialog.open(WarningMsgDeductionsComponent, {
      autoFocus: false,
      panelClass: 'warning-deductions-dialog-container'
    });
  };

  //Show error message
  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  //Show forbidden message
  showForbiddenMesage() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  get checkTotalDeductions() : boolean {
    const total_deductions: number = this.formData('total_deductions');
    const installementsArray: any[] = this.formData('installements');
    let isGreater: boolean = false;
    let installementsSum: number = 0;
    for(let i = 0; i < installementsArray.length; i++) {
      installementsSum += installementsArray[i].deducted_amount;
    }
    if(installementsSum > total_deductions) {
      isGreater = true;
    }
    return isGreater;
  };

  initArray(key: string, initArray: any[]) {
    let array: any[] = this.formArray(key)?.controls;
    for(let i = 0; i < initArray.length; i++) {
      const itemGroup = this._formBuilder.group(initArray[i]);
      array.push(itemGroup);
    }
  };

  initInstallmentsArray(initArray: any[]) {
    let array: any[] = this.formArray('installements')?.controls;
    for(let i = 0; i < initArray.length; i++) {
      const itemGroup = this._formBuilder.group(initArray[i]);
      array.push(itemGroup);
      this.initDateFiltersArray();
    }
  };

  //Get form value
  formData(formKey: string) {
    return this.deductionFormGroup.controls[formKey].value;
  };

  get getSelectedUnits(): any[] {
    const selectedUnits: string = this.formData('unit_no');
    if(selectedUnits?.length > 0) {
      return selectedUnits.split(', ');
    }
    return [];
  };

  initDateFiltersArray() {
    this.dateFilters.push((dateObj: any): boolean => {
      if (!dateObj) return false;
      const selectedDate = new Date(dateObj._d);
      const todayUTC = new Date(Date.UTC(
        this.today.getFullYear(),
        this.today.getMonth(),
        this.today.getDate()
      ));

      return selectedDate.getUTCDay() === 2 && selectedDate >= todayUTC;
    });
  }

  //Get form array
  formArray(key: string): FormArray {
    return this.deductionFormGroup.controls[key] as FormArray;
  };

  //Validation
  checkValidation(key: string): boolean {
    return !this.deductionFormGroup.controls[key].valid && this.deductionFormGroup.controls[key]?.touched; 
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
    this.subscription4?.unsubscribe();
  };

}
