import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { catchError, Subscription, throwError } from 'rxjs';
import { SharedService } from '../../services/shared.service';
import { ITaskNotes } from '../../models/task-notes-model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment = require('moment');
import { ITaskNoteFiles } from '../../models/task-note-files';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { ClosingWithoutNoteDialogComponent } from './closing-without-note-dialog/closing-without-note-dialog.component';
import { WarningMsgDialogComponent } from '../warning-msg-dialog/warning-msg-dialog.component';

@Component({
  selector: 'app-task-note-dialog',
  templateUrl: './task-note-dialog.component.html',
  styleUrls: ['./task-note-dialog.component.scss']
})
export class TaskNoteDialogComponent implements OnInit, OnDestroy {
  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
  fullName: string = `${this.loggedUser.first_name} ${this.loggedUser.last_name}`;
  nickname: string = `${this.loggedUser.nickname}`;
  creator: string = this.nickname.length === 0 ? this.fullName : this.nickname?.trim().split(' ')[0];

  formGroup: FormGroup;

  //Notes
  notesArray: ITaskNotes[] = [];
  editedNoteIndex: number | undefined = undefined;

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      0, 5
    ),
    new ConnectionPositionPair(
      { originX: 'end', originY: 'top' },
      { overlayX: 'end', overlayY: 'bottom' },
      0, 5
    )
  ];

  //Note added
  isNoteAdded: boolean = false;

  //Avoid multiple clicks
  multipleClicks: boolean = false;

  //Task files
  isOpenTaskFilesMenuIndex: number | null = null;
  taskFilesArray: any[] = [];

  //Note files
  isOpenNoteFileMenuIndex: number | null = null;

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
              public dialogRef: MatDialogRef<TaskNoteDialogComponent>,
              private dialog: MatDialog,
              private sharedService: SharedService,
              private formBuilder: FormBuilder) {
                this.formGroup = this.formBuilder.nonNullable.group({
                  'id': [0],
                  'task_id': [this.data.element.id],
                  'note': [null, Validators.minLength(1)],
                  'crator_id': [this.loggedUser.id],
                  'creator': [''],
                  'creator_department': [''],
                  'created_date': [null],
                  'editor': [''],
                  'editor_department': [''],
                  'editor_date': [null],
                  'files': [[]]
                })
            }

  ngOnInit(): void {
    this.getNotes();
    this.getTaskFiles();
  };

  getNotes() {
    this.subscription1 = this.sharedService.getTaskNotes(this.data.element.id).subscribe((response: ITaskNotes[]) => {
      console.log(response);
      this.notesArray = response;
    });
  };

  getTaskFiles() {
    this.subscription2 = this.sharedService.getFilesByTaskId(this.data.element.id)
    .pipe(catchError((err: any) => {
      return throwError(() => err);
    }))
    .subscribe((files: any[]) => {
      console.log(files);
      if(!files || files.length === 0) return;
      this.taskFilesArray = files;
    });
  };

  //Edit note
  editNote(obj: any, index: number) {
    obj.isOpenNoteMenu = !obj.isOpenNoteMenu;
    this.editedNoteIndex = index;
    this.formGroup.setValue({
      'id': obj.id,
      'task_id': obj.task_id,
      'note': obj.note,
      'creator': obj.creator,
      'creator_department': obj.creator_department,
      'created_date': obj.created_date,
      'editor': obj.editor,
      'editor_department': obj.editor_department,
      'editor_date': obj.editor_date,
      'files': obj.files
    });
  };

  //Delete note
  deleteNote(obj: any, index: number) {
    obj.isOpenNoteMenu = !obj.isOpenNoteMenu;
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.sharedService.deleteTaskNote(obj.id).subscribe((response: boolean) => {
          console.log(response)
          if(response) {
            this.notesArray.splice(index, 1);
          };
        });
      }
    })
  };

  openFileInNewTab(obj: any, isTaskFile: boolean) {
    this.sharedService.downloadPreviewFile(obj.name, obj.data);
    if(isTaskFile) {
      this.isOpenTaskFilesMenuIndex = null; 
      return;
    }
    this.isOpenNoteFileMenuIndex = null;
  };

  downloadFile(obj: any, isTaskFile: boolean) {
    this.sharedService.downloadFile(obj.data, obj.name);
    if(isTaskFile) {
      this.isOpenTaskFilesMenuIndex = null; 
      return;
    }
    this.isOpenNoteFileMenuIndex = null;
  };

  deleteFileFromTask(taskObj: any, index: number) {
    this.isOpenTaskFilesMenuIndex = null;
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        let obj: any = JSON.parse(JSON.stringify(taskObj));
        obj.edited_by = this.creator;
        obj.edited_date = moment().format('YYYY-MM-DDTHH:mm:ss');
        obj.files.splice(index, 1);
        this.sharedService.createUpdateTask([obj]).subscribe((array: any) => {
          if(array.length > 0) {
            let response: any = array[0];
            taskObj.files = response.files;
          }
        });
      }
    })
  }

  deleteFile(noteObj: any, index: number) {
    this.isOpenNoteFileMenuIndex = null;
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        let obj: any = JSON.parse(JSON.stringify(noteObj));
        obj.editor = this.creator;
        obj.editor_department = this.loggedUser.department;
        obj.editor_date = moment().format('YYYY-MM-DDTHH:mm:ss');
        obj.files.splice(index, 1);
        this.sharedService.createUpdateTaskNote(obj).subscribe((response: any) => {
          console.log(response);
          if(response) {
            noteObj.files = response.files;
          };
        });
      }
    })
  };

  //Add files
  onFileSelected(event: any) {
    let length: any = Object.keys(event.target.files).length;
    for (let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i);
    }
  }

  addMultipleFiles(event: any, index: number) {
    let fileName = event.target.files[index].name;
    let reader = new FileReader();
    reader.onload = (e: any) => {
      let base64 = e.target.result.split(',')[1];
      let obj: any = {
        name: fileName,
        data: base64
      };
      this.files.push(obj);
      this.formGroup.patchValue({'files': this.files});
    };
    reader.readAsDataURL(event.target.files[index]);
  };

  createUpdateNote() {
    if(!this.multipleClicks) {
      this.multipleClicks = true;
      if(this.formGroup.value.id) {
        this.formGroup.patchValue({
          'editor': this.creator,
          'editor_department': this.loggedUser.department,
          'editor_date': moment().format('YYYY-MM-DDTHH:mm:ss')
        });
      }
      else {
        this.formGroup.patchValue({
          'creator': this.creator,
          'creator_department': this.loggedUser.department,
          'created_date': moment().format('YYYY-MM-DDTHH:mm:ss')
        });
      }

      this.sharedService.createUpdateTaskNote(this.formGroup.value)
      .pipe(catchError((err: any) => {
        this.multipleClicks = false;
        this.showErrorMessage();
        return throwError(() => err);
      }))
      .subscribe((response: any) => {
        if(response?.id && this.formGroup.value.id) {
          this.notesArray[this.editedNoteIndex] = response;
          this.editedNoteIndex = undefined;
        };
        if(response?.id && !this.formGroup.value.id) {
          this.notesArray.push(response);
          this.isNoteAdded = true;
        };
        if(response?.id) {
          this.formGroup.reset();
        }
        this.multipleClicks = false;
      });
    }
  };

  closeDialog() {
    if(this.isNoteAdded || !this.data.noteRequired) {
      this.dialogRef.close(this.notesArray.length);
    }
    else {
      let dialogRef = this.dialog.open(ClosingWithoutNoteDialogComponent, {
        autoFocus: false,
        panelClass: 'closing-without-note-dialog-container',
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.dialogRef.close(this.notesArray.length);
        }
      })
    }
  };

  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  };

  get files(): ITaskNoteFiles[] {
    return this.formGroup.controls['files'].value;
  };

}
