import { Component, EventEmitter, Input, Output } from '@angular/core';
import moment = require('moment');

@Component({
  selector: 'calendar-year',
  templateUrl: './calendar-year.component.html',
  styleUrls: ['./calendar-year.component.scss']
})
export class CalendarYearComponent {
  @Input() year: number | null;
  @Input() styleConfig: any = {
    'width': '240px',
    'height': '45px',
    'background-color': '#f4f8ff',
    'color': '#1F1F1F',
    'box-shadow': 'none',
    'border-radius': '25px',
    'border-top': 'thin solid #0000001f',
    'opened-dropdown-shadow': '0px 1px 6.65px 0.35px rgba(13, 39, 80, 0.16)'
  };
  @Output() triggerEvent = new EventEmitter<string | number>();
  
  //Date
  isOpenCalendar: boolean = false;

  yearClicked(event: any) {
    this.year = event._i.year;
    this.triggerEvent.emit(this.year);
    this.isOpenCalendar = false;
  };

}
