import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boolean'
})
export class BooleanPipe implements PipeTransform {

  transform(array: any[], booleanKey: string, active: boolean): any {
    if (!array) {
      return [];
    }

    return array.filter((obj: any) => {
      if((obj[booleanKey] === active && active === true) || !active) {
        return obj;
      }; 
    });
  }

}
