import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { RulesService } from '@app/modules/shared/services/rules.service';

import moment = require('moment');
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-employee-status-dialog',
  templateUrl: './employee-status-dialog.component.html',
  styleUrls: ['./employee-status-dialog.component.scss']
})
export class EmployeeStatusDialogComponent implements OnInit {
  permissions: any = this.rulesService.UserData[24].data;

  isOpenStatusMenu: boolean = false;
  statusArray: any[] = [
    {
      category: 'Working', 
      statusList: [
        {status_group_name: 'Working', status: 'Extra shift', icon: 'user-white.png', color: 'green'},
        {status_group_name: 'Working', status: 'Regular shift', icon: 'user-green.png', color: 'green'},
        {status_group_name: 'Working', status: 'Early leave', icon: 'leave.png', color: 'green'},
        {status_group_name: 'Working', status: 'Late arrival', icon: 'leave.png', color: 'green'},
        {status_group_name: 'Working', status: 'Working weekend', icon: 'user-green.png', color: 'green'},
        {status_group_name: 'Working', status: 'Night shift', icon: 'night-shift.png', color: 'black'},
        {status_group_name: 'Working', status: 'Morning shift', icon: 'morning-shift.png', color: 'orange'},
      ]
    },
    {
      category: 'Out of work',
      statusList: [
        {status_group_name: 'Out of work', status: 'Sick Leave', icon: 'health-issue.png', color: 'blue'},
        {status_group_name: 'Out of work', status: 'Slava', icon: 'family-tradition.png', color: 'blue'},
        {status_group_name: 'Out of work', status: 'National holiday', icon: 'national-holiday.png', color: 'blue'},
        {status_group_name: 'Out of work', status: 'Vacation', icon: 'vacation.png', color: 'blue'},
        {status_group_name: 'Out of work', status: 'Regular rest day', icon: 'regular-rest-day.png', color: 'grey'},
        {status_group_name: 'Out of work', status: 'Day off', icon: 'day-off-yellow.svg', color: 'orange'}
      ]
    }
  ];
  activeStatus: string = '';

  //Note
  note: string = '';

  //Files
  files: any[] = [];

  //Vacation status
  isOpenVacationStartCalendar: boolean = false;
  vacationStartDate: string = '';

  isOpenVacationEndCalendar: boolean = false;
  vacationEndDate: string = '';

  //Time
  isOpenTimeMenu: boolean = false;
  time: string = '';
  hour: any;
  minutes: any;
  miliseconds: number | undefined = undefined;

  //Approved
  approved: boolean = false;

  //Selected status
  statusObj: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private rulesService: RulesService,
              private _snackBar: MatSnackBar,
              private sharedService: SharedService) { }

  ngOnInit(): void {
    if(this.data.id) {
      this.activeStatus = this.data.status;
      this.note = this.data.note;
      this.files = this.data.files;
      this.vacationStartDate = this.data.firstday;
      this.vacationEndDate = this.data.backtowork;
      this.hour = String(Math.floor(this.data.hours / 3600000)).padStart(2, '0');
      this.minutes = String(Math.floor((this.data.hours % 3600000) / 60000)).padStart(2, '0');
      this.time = `${this.hour}:${this.minutes}`;
      this.approved = !this.data.approved;
      this.statusObj = this.data;
    }
  }

  selectStatus(obj: any) {
    this.resetInputs();
    this.activeStatus = obj.status;
    this.statusObj = obj;
    this.isOpenStatusMenu = false;
  }

  //Add files
  onFileSelected(event: any) {  
    let length: any = Object.keys(event.target.files).length;
    for(let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i);
    }
  }

  addMultipleFiles(event: any, index: number) {
    let fileName = event.target.files[index].name;
    let reader = new FileReader();
    reader.onload = (e: any) => {
      var uint8 = new Uint8Array(e.target.result);
      var result = [];
      for (var i = 0; i < uint8.length; i++) {
        result.push(uint8[i]);
      }
      let obj: any = {
        filename: fileName,
        data: result
      };
      this.files.push(obj);
    }

    reader.readAsArrayBuffer(event.target.files[index])
  };

  //Discard files
  discard(i : number) {
    this.files.splice(i, 1);
  };

  //Calendar method
  dateClicked(event: any, firstDay: boolean) {
    if(firstDay) {
      this.vacationStartDate = moment(event._d).format('YYYY-MM-DDT00:00:00');
      this.isOpenVacationStartCalendar = false;
    }
    else {
      this.vacationEndDate = moment(event._d).format('YYYY-MM-DDT00:00:00');
      this.isOpenVacationEndCalendar = false;
    }
  };

  //Time methods
  leadingZero(hour: boolean) {
    if(hour) {
      this.hour = String(this.hour).padStart(2, '0');
    }
    else {
      this.minutes = String(this.minutes).padStart(2, '0');
    }
  }

  applyTime() {
    if(isNaN(this.hour) || this.hour === '') {
      this.hour = '00';
    }
    
    if(isNaN(this.minutes) || this.minutes === '') {
      this.minutes = '00';
    }
    this.time = `${this.hour}:${this.minutes}`;
    this.miliseconds = (Number(this.hour) * 60 * 60 + Number(this.minutes) * 60)*1000;
    console.log(this.miliseconds);
    this.isOpenTimeMenu = false;
  };

  //Add update status
  addUpdateStatus() {
    console.log(this.statusObj);
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let obj: any = {
      id: this.data.id ? this.data.id : 0, 
      employee_id: this.data.employee_id, 
      employee: this.data.employee, 
      status_group_name: this.statusObj.status_group_name, 
      status: this.statusObj.status, 
      icon_id: '', 
      icon: this.statusObj.status === 'Early leave' && this.approved ? 'early-leave-red.png': this.statusObj.icon, 
      color: this.statusObj.color, 
      craetor: this.data.id ? this.data.craetor : loggedUser.first_name + ' ' + loggedUser.last_name,
      creator_id: this.data.id ? this.data.creator_id : loggedUser.id, 
      creator_department: this.data.id ? this.data.creator_department : loggedUser.department, 
      status_date: this.data.date, 
      datecreated: this.data.id ? this.data.datecreated : moment().format('YYYY-MM-DDTHH:mm:ss'), 
      editor: this.data.id ? loggedUser.first_name + ' ' + loggedUser.last_name : '', 
      edit_date: this.data.id ? moment().format('YYYY-MM-DDTHH:mm:ss') : '', 
      terminated: '', 
      className: '', 
      no_className: '', 
      dispatcher_className: '', 
      total_className: '', 
      hours: this.miliseconds, 
      note: this.note, 
      approved: !this.approved, 
      firstday: this.vacationStartDate, 
      backtowork: this.vacationEndDate,  
      files: this.files
    }
    
    console.log(obj)
    console.log(JSON.stringify(obj))
    if(this.activeStatus === 'Vacation' || this.activeStatus === 'Regular shift'
    || this.activeStatus === 'Morning shift' || this.activeStatus === 'Night shift') {
      obj.id = 0;
      obj.holiday_from = this.vacationStartDate;
      obj.holiday_to = this.vacationEndDate;
      obj.editor = loggedUser.first_name + ' ' + loggedUser.last_name;
      obj.edit_date = moment().format('YYYY-MM-DDTHH:mm:ss');
      console.log(obj)
      console.log(JSON.stringify(obj));
      this.sharedService.createUpdateVacationStatus(obj)
      .subscribe((response: any) => {
        if(response) {
          this.dialogRef.close('Refresh')
        }
        console.log(response);
      })
    }
    else {
      this.sharedService.createUpdateEmployeeStatus(obj)
      .subscribe((response: any) => {
        if(response.id) {
          this.dialogRef.close(response)
        }
        console.log(response);
      })
    }
  };

  //Get file
  getFile(obj: any) {
    if(this.permissions[2].sectionArray[0].allowed && !obj.data) {
      this.sharedService.getFile(this.data.employee_id, this.data.id, obj.filename).subscribe((base64: any) => {
        console.log(base64)
        let extension: string = obj.filename.substring(obj.filename.length - 3);
        if(extension === 'pdf') {
          const byteCharacters = atob(base64);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url, '_blank');
        }
        else {
          let image = document.createElement('img');
          image.src = `data:image/${extension};base64,` + base64;
          image.setAttribute('style', 'display: block; width: 50%; margin: 0px auto;')
          let w = window.open("");
          w.document.write(image.outerHTML);
        }
      })
    }
    else if(obj.data) {
      let extension: string = obj.filename.substring(obj.filename.length - 3);
      if(extension === 'pdf') {
        const byteArray = new Uint8Array(obj.data);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
      }
      else {
        let base64 = '';
        let bytes = new Uint8Array(obj.data);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          base64 += String.fromCharCode(bytes[i]);
        }
        let image = document.createElement('img');
        image.src = `data:image/${extension};base64,` + window.btoa( base64 );
        image.setAttribute('style', 'display: block; width: 50%; margin: 0px auto;')
        let w = window.open("");
        w.document.write(image.outerHTML);
      }
    }
    else {
      this._snackBar.open("You don't have permission for this action!", 'Close', { duration: 3000 });
    }
  };

  //Set color
  setClass(statusName: string) {
    if(statusName === 'Extra shift') {
      return 'extra-shift';
    }
    else if(statusName === 'Working weekend') {
      return 'working-weekend';
    }
    else {
      return 'regular-icon-container';
    }

  };

  //Delete employee status
  deleteEmployeeStatus() {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        let obj: any = JSON.parse(JSON.stringify(this.data));
        this.sharedService.deleteEmployeeStatus(this.data.id).subscribe((response: any) => {
           if(response) {
            this.dialogRef.close({id: 0, date: obj.date, employee_id: obj.employee_id, employee: obj.employee, status_group_name: null, status: null, icon_id: null, 
              icon: null, color: null, craetor: null, creator_id: null, creator_department: null, status_date: null, datecreated: null,
              editor: null, edit_date: null, terminated: null, className: null, no_className: null, dispatcher_className: null, total_className: null,
              hours: null, note: null, approved: null, firstday: null, backtowork: null, files: []
            });
           }
        });
      }
    })
  };

  resetInputs() {
    this.note = '';
    this.files = [];
    this.vacationStartDate = '';
    this.vacationEndDate = '';
    this.time = '';
    this.hour = undefined;
    this.minutes = undefined;
    this.statusObj = undefined;
  };

}
