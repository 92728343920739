import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClaimsPageComponent } from './components/claims-page/claims-page.component';
import { AuthGuard } from '../core/services/auth/auth.guard';

const routes: Routes = [
	{
		path: '',
		component: ClaimsPageComponent,
		canActivate: [AuthGuard],
		data: { roles: 0 }
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ClaimsRoutingModule { }