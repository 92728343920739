import { Pipe, PipeTransform } from '@angular/core';
import moment = require('moment');

@Pipe({
  name: 'activityTransform'
})
export class ActivityTransformPipe implements PipeTransform {

  transform(array: any[], startDate: string, metaData: any): any {
    let sumTotalGross1: number = 0; 
    let sumTotalMileage1: number = 0; 
    
    let sumTotalGross2: number = 0; 
    let sumTotalMileage2: number = 0; 

    let sumTotalGross3: number = 0; 
    let sumTotalMileage3: number = 0; 

    let sumTotalGrossByDriver1: number = 0; 
    let sumTotalMileageByDriver1: number = 0; 
    
    let sumTotalGrossByDriver2: number = 0; 
    let sumTotalMileageByDriver2: number = 0; 

    let sumTotalGrossByDriver3: number = 0; 
    let sumTotalMileageByDriver3: number = 0; 

    let numOfDriversFirstWeek: number = 0;
    let numOfDriversSecondWeek: number = 0;
    let numOfDriversThirdWeek: number = 0;

    const countObj: any = {
      totalGross1: 0, totalRpm1: 0, totalMileage1: 0, totalGrossPerDay1: 0, totalMilesPerDay1: 0,
      totalGross2: 0, totalRpm2: 0, totalMileage2: 0, totalGrossPerDay2: 0, totalMilesPerDay2: 0,
      totalGross3: 0, totalRpm3: 0, totalMileage3: 0, totalGrossPerDay3: 0, totalMilesPerDay3: 0,
  
      avgGross1: 0, avgRpm1: 0, avgMileage1: 0, avgGrossPerDay1: 0, avgMilesPerDay1: 0,
      avgGross2: 0, avgRpm2: 0, avgMileage2: 0, avgGrossPerDay2: 0, avgMilesPerDay2: 0,
      avgGross3: 0, avgRpm3: 0, avgMileage3: 0, avgGrossPerDay3: 0, avgMilesPerDay3: 0
    }

    for(let i = 0; i < array.length; i++) {
      //Statistics num of drivers
      let hiring_date: string = array[i].driver_1.id ? array[i].driver_1.hiring_date : array[i].driver_2.hiring_date;
      if(hiring_date <= moment(startDate).endOf('isoWeek').format('YYYY-MM-DDT00:00:00')) {
        numOfDriversFirstWeek++;
      }
      if(hiring_date <= moment(startDate).add(7, 'd').endOf('isoWeek').format('YYYY-MM-DDT00:00:00')) {
        numOfDriversSecondWeek++;
      }
      if(hiring_date <= moment(startDate).add(14, 'd').endOf('isoWeek').format('YYYY-MM-DDT00:00:00')) {
        numOfDriversThirdWeek++;
      }

      let statusArray: any = array[i].status;
      let statusIndex: number = 0;
      let weeks: any = {};
      for(let i = 0; i < 21; i++) {
        if(statusArray[statusIndex]?.status_date === moment(startDate).add(i, 'd').format('YYYY-MM-DDT00:00:00')) {
          weeks[`day${i + 1}Obj`] = {statusObj: statusArray[statusIndex], date: statusArray[statusIndex].status_date, 
          driverName: statusArray[statusIndex].driver_name, dispatcherName: statusArray[statusIndex].dispatcher, 
          truck: statusArray[statusIndex].unit_no, trailer: statusArray[statusIndex].trailer, 
          icon_name: statusArray[statusIndex].icon_name, notesLength: statusArray[statusIndex].notes.length};
          statusIndex++;
        }
        else {
          weeks[`day${i + 1}Obj`] = {statusObj: null, date: null, driverName: null, dispatcherName: null, truck: null, trailer: null,
          icon_name: 'no-status.png', notesLength: 0};
        }
      };
      array[i].weeks = weeks;

      array[i].showPaycheck1 = true;
      array[i].showPaycheck2 = true;
      array[i].showPaycheck3 = true;
      this.statementDate(array[i], moment(array[i].weeks.day1Obj.date), 'showPaycheck1');
      this.statementDate(array[i], moment(array[i].weeks.day8Obj.date), 'showPaycheck2');
      this.statementDate(array[i], moment(array[i].weeks.day15Obj.date), 'showPaycheck3');

      //Statistics
      sumTotalGrossByDriver1 = 0;
      sumTotalGrossByDriver2 = 0;
      sumTotalGrossByDriver3 = 0;
      sumTotalMileageByDriver1 = 0;
      sumTotalMileageByDriver2 = 0;
      sumTotalMileageByDriver3 = 0;
      
      for(let j = 0; j < array[i].loads.length; j++) {
       
        if(array[i].loads[j].pickup_date <=  moment(startDate).add(6, 'd').format('YYYY-MM-DDT00:00:00')) {
          sumTotalGross1 += array[i].loads[j].amount;
          sumTotalMileage1 += array[i].loads[j].mileage;

          sumTotalGrossByDriver1 += array[i].loads[j].amount;
          sumTotalMileageByDriver1 += array[i].loads[j].mileage;
        }

        if(array[i].loads[j].pickup_date >  moment(startDate).add(6, 'd').format('YYYY-MM-DDT00:00:00') 
          && array[i].loads[j].pickup_date <=  moment(startDate).add(13, 'd').format('YYYY-MM-DDT00:00:00')) {
          sumTotalGross2 += array[i].loads[j].amount;
          sumTotalMileage2 += array[i].loads[j].mileage;

          sumTotalGrossByDriver2 += array[i].loads[j].amount;
          sumTotalMileageByDriver2 += array[i].loads[j].mileage;
        }

        if(array[i].loads[j].pickup_date >  moment(startDate).add(13, 'd').format('YYYY-MM-DDT00:00:00') 
          && array[i].loads[j].pickup_date <=  moment(startDate).add(20, 'd').format('YYYY-MM-DDT00:00:00')) {
          sumTotalGross3 += array[i].loads[j].amount;
          sumTotalMileage3 += array[i].loads[j].mileage;

          sumTotalGrossByDriver3 += array[i].loads[j].amount;
          sumTotalMileageByDriver3 += array[i].loads[j].mileage;
        }

      };

      array[i].sumGross1 = sumTotalGrossByDriver1;
      array[i].sumGross2 = sumTotalGrossByDriver2;
      array[i].sumGross3 = sumTotalGrossByDriver3;

      array[i].sumMileage1 = sumTotalMileageByDriver1;
      array[i].sumMileage2 = sumTotalMileageByDriver2;
      array[i].sumMileage3 = sumTotalMileageByDriver3;
    }

      countObj.totalGross1 = `$${addCommasDots(sumTotalGross1, 'round')}`;
      countObj.totalRpm1 = `$${addCommasDots(sumTotalGross1 / sumTotalMileage1)}`;
      countObj.totalMileage1 = `${addCommasDots(sumTotalMileage1, 'round')}`;
      countObj.totalGrossPerDay1 = `$${addCommasDots(sumTotalGross1 / 7, 'round')}`;
      countObj.totalMilesPerDay1 = `${addCommasDots(sumTotalMileage1 / 7, 'round')}`;

      countObj.totalGross2 = `$${addCommasDots(sumTotalGross2, 'round')}`;
      countObj.totalRpm2 = `$${addCommasDots(sumTotalGross2 / sumTotalMileage2)}`;
      countObj.totalMileage2 = `${addCommasDots(sumTotalMileage2, 'round')}`;
      countObj.totalGrossPerDay2 = `$${addCommasDots(sumTotalGross2 / 7, 'round')}`;
      countObj.totalMilesPerDay2 = `${addCommasDots(sumTotalMileage2 / 7, 'round')}`;

      countObj.totalGross3 = `$${addCommasDots(sumTotalGross3, 'round')}`;
      countObj.totalRpm3 = `$${addCommasDots(sumTotalGross3 / sumTotalMileage3)}`;
      countObj.totalMileage3 = `${addCommasDots(sumTotalMileage3, 'round')}`;
      countObj.totalGrossPerDay3 = `$${addCommasDots(sumTotalGross3 / 7, 'round')}`;
      countObj.totalMilesPerDay3 = `${addCommasDots(sumTotalMileage3 / 7, 'round')}`;

      countObj.avgGross1 = `$${addCommasDots(sumTotalGross1 / numOfDriversFirstWeek, 'round')}`;
      countObj.avgRpm1 = `$${addCommasDots((sumTotalGross1 / sumTotalMileage1) / numOfDriversFirstWeek)}`;
      countObj.avgMileage1 = `${addCommasDots(sumTotalMileage1 / numOfDriversFirstWeek, 'round')}`;
      countObj.avgGrossPerDay1 =  `$${addCommasDots((sumTotalGross1 / 7) / numOfDriversFirstWeek, 'round')}`;
      countObj.avgMilesPerDay1 = `${addCommasDots((sumTotalMileage1 / 7) / numOfDriversFirstWeek, 'round')}`;

      countObj.avgGross2 = `$${addCommasDots(sumTotalGross2 / numOfDriversSecondWeek, 'round')}`;
      countObj.avgRpm2 = `$${addCommasDots((sumTotalGross2 / sumTotalMileage2) / numOfDriversSecondWeek)}`;
      countObj.avgMileage2 = `${addCommasDots(sumTotalMileage2 / numOfDriversSecondWeek, 'round')}`;
      countObj.avgGrossPerDay2 =  `$${addCommasDots((sumTotalGross2 / 7) / numOfDriversSecondWeek, 'round')}`;
      countObj.avgMilesPerDay2 = `${addCommasDots((sumTotalMileage2 / 7) / numOfDriversSecondWeek, 'round')}`;

      countObj.avgGross3 = `$${addCommasDots(sumTotalGross3 / numOfDriversThirdWeek, 'round')}`;
      countObj.avgRpm3 = `$${addCommasDots((sumTotalGross3 / sumTotalMileage3) / numOfDriversThirdWeek)}`;
      countObj.avgMileage3 = `${addCommasDots(sumTotalMileage3 / numOfDriversThirdWeek, 'round')}`;
      countObj.avgGrossPerDay3 =  `$${addCommasDots((sumTotalGross3 / 7) / numOfDriversThirdWeek, 'round')}`;
      countObj.avgMilesPerDay3 = `${addCommasDots((sumTotalMileage3 / 7) / numOfDriversThirdWeek, 'round')}`;

      function addCommasDots(num: any, roundNumber?: any)  {
        typeof num !== 'number' ? num = 0 : num;
        roundNumber === 'round' ? num = Math.round(num) : num;
        let result = (num - Math.floor(num)) !== 0;
        let number: any;
        if(result) {
          number = num.toFixed(2);
        } else {
          number = num;
        }
        return number.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      };

      metaData.countObj = countObj;

    return array;
  }

  statementDate(obj: any, paymentDate: any, key: string) {
    const currentDate = moment();
    const daysDifference: number = currentDate.diff(paymentDate, 'days');
    if(daysDifference <= 15 && daysDifference > 8) {
      const date = new Date();
      const optionsDateTime: any = {timeZone: 'America/Chicago', year: 'numeric', month: '2-digit', day: '2-digit' };
      const currentDateInChicago = new Intl.DateTimeFormat('en-US', optionsDateTime).format(date);
      const otherDateInChicago = moment(paymentDate).format('MM/DD/YYYY');
      const optionsTime: any = {timeZone: 'America/Chicago', hour: 'numeric', hour12: false};
      const chicagoHour = new Intl.DateTimeFormat('en-US', optionsTime).format(date);
      if(!(currentDateInChicago === otherDateInChicago && parseInt(chicagoHour, 10) >= 8)) {
        obj[key] = false;
      } 
    }
    
  }

}
