import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppRoutes } from './app.routing';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { MainModule } from './modules/main.module';
import * as moment from 'moment-timezone';
import { APP_INITIALIZER } from '@angular/core';

export function initializeApp() {
  return (): Promise<any> => {
    moment.tz.setDefault("Europe/Belgrade");
    return Promise.resolve();
  };
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    MainModule,
    RouterModule.forRoot(AppRoutes)
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
