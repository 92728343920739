import { Pipe, PipeTransform } from '@angular/core';
import { fail } from 'node:assert';

@Pipe({
  name: 'employeeStFilter'
})
export class EmployeeStFilterPipe implements PipeTransform {

  transform(array: any[], filterObj: any): any[] {
    if (!array) {
      return [];
    }
    
    const employeeStatusObj: any = {'Active': false, 'Inactive': true};

    let transformedArray: any[] = [];
    for(let i = 0; i < array.length; i++) {
      const data: any = array[i];
      let condition1: boolean = filterObj.searchValue?.length === 0 ? true : filterObj.searchValue === data.employee;
      let condition2: boolean = filterObj.departmentValue === 'All Departments' ? true : filterObj.departmentValue === data.department;
      let condition3: boolean = filterObj.emloyeeStatusValue === 'All Employees' ? true : employeeStatusObj[filterObj.emloyeeStatusValue] === data.is_active;
      let condition4: boolean = filterObj.officeValue === 'All Offices' ? true : filterObj.officeValue === data.city;
      let condition5: boolean = filterObj.employee.length === 0 ? true : filterObj.employee.includes(data.employee);
      let condition6: boolean = filterObj.nickname.length === 0 ? true : filterObj.nickname.includes(data.nickname);
    
      if(condition1 && condition2 && condition3 && condition4 && condition5 && condition6) {
        transformedArray.push(array[i]);
      }
    };
    return transformedArray;
  }

}
