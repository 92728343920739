import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccidentsRoutingModule } from './accidents.routing';
import { SharedModule } from '../shared/shared.module';
import { AccidentsPageComponent } from './components/accidents-page/accidents-page.component';
import { AddEditAccidentsDialogComponent } from './components/accidents-page/add-edit-accidents-dialog/add-edit-accidents-dialog.component';
import { AddContactDialogComponent } from './components/accidents-page/add-edit-accidents-dialog/add-contact-dialog/add-contact-dialog.component';
import { AccidentsTableComponent } from './components/accidents-page/accidents-table/accidents-table.component';
import { AccidentsService } from './services/accidents.service';
import { AccidentTableFilterPipe } from './pipes/accident-table-filter.pipe';
import { ClaimsDialogComponent } from './components/accidents-page/claims-dialog/claims-dialog.component';
import { FormPhysicalDamageComponent } from './components/accidents-page/claims-dialog/form-physical-damage/form-physical-damage.component';
import { FormCargoComponent } from './components/accidents-page/claims-dialog/form-cargo/form-cargo.component';
import { FormLiabilityPhysicalDamageComponent } from './components/accidents-page/claims-dialog/form-liability-physical-damage/form-liability-physical-damage.component';
import { FormLiabilityPersonalInjuryComponent } from './components/accidents-page/claims-dialog/form-liability-personal-injury/form-liability-personal-injury.component';
import { FormLiabilityPropertyDamageComponent } from './components/accidents-page/claims-dialog/form-liability-property-damage/form-liability-property-damage.component';
import { FormOccAccComponent } from './components/accidents-page/claims-dialog/form-occ-acc/form-occ-acc.component';
import { ClaimsFilesTabComponent } from './components/accidents-page/claims-dialog/claims-files-tab/claims-files-tab.component';
import { FormBasicDetailsComponent } from './components/accidents-page/claims-dialog/form-basic-details/form-basic-details.component';
import { FormClaimDetailsComponent } from './components/accidents-page/claims-dialog/form-claim-details/form-claim-details.component';
import { ContactsTableComponent } from './components/accidents-page/claims-dialog/contacts-table/contacts-table.component';
import { FormCommentsComponent } from './components/accidents-page/claims-dialog/form-comments/form-comments.component';
import { FormClosedComponent } from './components/accidents-page/claims-dialog/form-closed/form-closed.component';
import { UploadFilesComponent } from './components/accidents-page/claims-dialog/upload-files/upload-files.component';

@NgModule({
  declarations: [
    AccidentsPageComponent,
    AddEditAccidentsDialogComponent,
    AddContactDialogComponent,
    AccidentsTableComponent,
    AccidentTableFilterPipe,
    ClaimsDialogComponent,
    FormPhysicalDamageComponent,
    FormCargoComponent,
    FormLiabilityPhysicalDamageComponent,
    FormLiabilityPersonalInjuryComponent,
    FormLiabilityPropertyDamageComponent,
    FormOccAccComponent,
    ClaimsFilesTabComponent,
    FormBasicDetailsComponent,
    FormClaimDetailsComponent,
    ContactsTableComponent,
    FormCommentsComponent,
    FormClosedComponent,
    UploadFilesComponent
  ],
  imports: [
    CommonModule,
    AccidentsRoutingModule,
    SharedModule
  ],
  providers: [AccidentsService, AccidentTableFilterPipe]
})
export class AccidentsModule { }
