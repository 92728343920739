import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DriversProfileService } from '@app/modules/drivers-profile-page/services/drivers-profile.service';
import { CreateUpdateInspectionDialogComponent } from '@app/modules/inspections/components/inspections-page/create-update-inspection-dialog/create-update-inspection-dialog.component';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-dot-inspection-dialog',
  templateUrl: './dot-inspection-dialog.component.html',
  styleUrls: ['./dot-inspection-dialog.component.scss']
})
export class DotInspectionDialogComponent implements OnInit, OnDestroy {
  cardsStatisticsObj: any = {
    passed: 0,
    passedLevel1: 0,
    passedLevel2: 0,
    passedLevel3: 0,
    failed: 0,
    failedLevel1: 0,
    failedLevel2: 0,
    failedLevel3: 0
  }

  filterMetaData: any = {
    countObj: {
      totalPoints: 0,
      totalCharge: 0,
      totalRewards: 0,
      avgPoints: 0,
      avgCharge: 0,
      avgRewards: 0,
      arrayLength: 0
    }
  };
  inspectionType: string = '';
  dataSource: any[] = [];

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";
  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any, 
  private dialog: MatDialog,
  private driversProfileService: DriversProfileService,
  public transformService: TransformService,
  private sharedService: SharedService,
  private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.inspectionType = this.obj.inspectionType;
    this.getTableData();
  };

  getTableData() {
    this.dataSource = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('dot-inspection-driver');
    this.subscription = this.driversProfileService.getDotInspectionByDriver(this.obj.driverId)
    .pipe(catchError((err: any) => {
      this.spinner.hide('dot-inspection-driver');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any[]) => {
      this.dataSource = response;
      this.countStatisticsData(response);
      this.spinner.hide('dot-inspection-driver');
      this.loaded = true;
      console.log(response);
    });
  };

  //Count statistics 
  countStatisticsData(array: any[]) {
    for(let i = 0; i < array.length; i++) {
      if(array[i].inspection_result.toLocaleLowerCase() === 'passed') {
        this.cardsStatisticsObj.passed += 1;
        this.cardsStatisticsObj.passedLevel1 += array[i].inspection_level === 'Level I' ? 1 : 0;
        this.cardsStatisticsObj.passedLevel2 += array[i].inspection_level === 'Level II' ? 1 : 0;
        this.cardsStatisticsObj.passedLevel3 += array[i].inspection_level === 'Level III' ? 1 : 0;
      }
      else {
        this.cardsStatisticsObj.failed += 1;
        this.cardsStatisticsObj.failedLevel1 += array[i].inspection_level === 'Level I' ? 1 : 0;
        this.cardsStatisticsObj.failedLevel2 += array[i].inspection_level === 'Level II' ? 1 : 0;
        this.cardsStatisticsObj.failedLevel3 += array[i].inspection_level === 'Level III' ? 1 : 0;
      }
    };
  };

  createUpdateInspectionDialog(editMode: boolean, data: any) {
    this.dialog.open(CreateUpdateInspectionDialogComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: 'component-dialog-container',
      data: {editMode: editMode, data: data}
    });
  }

  getRelatedTooltipText(element: any): string {
    return element.related.map(item => item.related).join(', ');
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}
