export const permissionsData: any[] = [
    {component: 'Welcome', forbidden: false,
    data: []},
    {component: 'Quick Overview', forbidden: true,
    data: [
      {
        name: 'Dashboard - Cards',
        allowedAll: false,
        sectionArray: [
          {name: 'Gross', allowed: false},
          {name: 'Rate Per Mile', allowed: false},
          {name: 'Mileage Tms', allowed: false},
          {name: 'Company Benefit Miles', allowed: false},
          {name: 'Margin', allowed: false},
          {name: 'Margin %', allowed: false},
          {name: 'Length Per Load', allowed: false},
          {name: 'Avg Weight', allowed: false},
          {name: 'Maintenance', allowed: false},
          {name: 'Active Trucks', allowed: false},
          {name: 'Empty Trucks', allowed: false},
          {name: 'Active Trailers', allowed: false},
          {name: 'Total Loads', allowed: false},
        ],
      },
      {
        name: 'Fleet status',
        allowedAll: false,
        sectionArray: [
          {name: 'Orientation Breakdown', allowed: false},
          {name: 'Driver Turnover', allowed: false},
          {name: 'Hired vs Terminated Drivers', allowed: false},
        ],
      },
      {
        name: 'Top 20% dispatchers',
        allowedAll: false,
        color: 'primary',
        sectionArray: [],
      },
      {
        name: 'Dispatch table',
        allowedAll: false,
        sectionArray: [],
      }
    ]},
    {component: 'Live dispatch board', forbidden: true,
    data: [
      {
        name: 'Live dispatch board - Cards',
        allowedAll: false,
        sectionArray: [
          {name: 'Gross', allowed: false},
          {name: 'Miles', allowed: false},
          {name: 'Rate Per Mile', allowed: false},
          {name: 'Not covered', allowed: false},
          {name: 'Margin %', allowed: false},
          {name: 'Weight', allowed: false},
          {name: 'Rank', allowed: false},
        ],
      },
      {
        name: 'Live activity table',
        allowedAll: false,
        color: 'primary',
        sectionArray: [],
      },
      {
        name: 'Loads table',
        allowedAll: false,
        sectionArray: [],
      }
    ]},
    {component: 'Dispatcher performances', forbidden: true,
    data: [
      {
        name: 'Dispatcher performances - Cards',
        allowedAll: false,
        sectionArray: [
          {name: 'Gross', allowed: false},
          {name: 'Rate Per Mile', allowed: false},
          {name: 'Mileage - tms', allowed: false},
          {name: 'Mileage per truck', allowed: false},
          {name: 'Company benefit miles', allowed: false},
          {name: 'Margin', allowed: false},
          {name: 'Margin %', allowed: false},
          {name: 'Length per load', allowed: false},
          {name: 'Total loads', allowed: false},
          {name: 'Avg Weight', allowed: false},
          {name: 'Active trucks', allowed: false},
          {name: 'Empty trucks', allowed: false},
          {name: 'Active Trailers', allowed: false},
          {name: 'Avg gross per load', allowed: false},
          {name: 'Preplanning', allowed: false},
          {name: 'Tonu', allowed: false},
        ],
      },
      {
        name: 'Top 20% dispatchers',
        allowedAll: false,
        color: 'primary',
        sectionArray: [],
      },
      {
        name: 'Dispatch table',
        allowedAll: false,
        sectionArray: [],
      },
      {
        name: 'Drivers table',
        allowedAll: false,
        sectionArray: [],
      }
    ]},
    {component: 'Trucks without drivers', forbidden: true,
    data: [
      {
        name: 'Trucks without drivers - Cards',
        allowedAll: false,
        sectionArray: [
          {name: 'Empty trucks', allowed: false},
          {name: 'Empty trucks %', allowed: false},
          {name: 'Loss per truck', allowed: false},
          {name: 'Total loss', allowed: false},
        ],
      },
      {
        name: 'Trucks table',
        allowedAll: false,
        color: 'primary',
        sectionArray: [],
      }
    ]},
    {component: 'Lost loads', forbidden: true,
    data: [
      {
        name: 'Lost loads - Cards',
        allowedAll: false,
        sectionArray: [
          {name: 'Lost load', allowed: false},
          {name: 'Lost load percentage', allowed: false},
          {name: 'Estimated loss', allowed: false}
        ],
      },
      {
        name: 'Lost loads vs Booked loads - Pie chart',
        allowedAll: false,
        color: 'primary',
        sectionArray: [],
      },
      {
        name: 'Lost load per driver',
        allowedAll: false,
        color: 'primary',
        sectionArray: [],
      },
      {
        name: 'Lost loads per dispatcher',
        allowedAll: false,
        color: 'primary',
        sectionArray: [],
      }
    ]},
    {component: 'Planning', forbidden: true,
      data: [
        {
          name: 'Planning',
          allowedAll: false,
          sectionArray: [],
        }
      ],},
    {component: 'Business Partners', forbidden: true,
    data: []},
    {component: 'Recruiting', forbidden: true,
    data: [
      {
        name: 'Recruiting',
        allowedAll: false,
        sectionArray: [],
      },
    ]},
    {component: 'Accounting', forbidden: true,
    data: [
      {
        name: 'Maintenance & Ifta table',
        allowedAll: false,
        sectionArray: [],
      },
      {
        name: 'Income - maintenance & ifta graph',
        allowedAll: false,
        color: 'primary',
        sectionArray: [],
      }
    ]},
    {component: 'Safety', forbidden: true,
    data: [
      {
        name: 'Truck file table',
        allowedAll: false,
        sectionArray: [],
      },
      {
        name: 'Truck devices table',
        allowedAll: false,
        sectionArray: [],
      },
      {
        name: 'Contract',
        allowedAll: false,
        sectionArray: [],
      },
      {
        name: 'Document Editor',
        allowedAll: false,
        sectionArray: [],
      }
    ]},
    {component: 'Maintenance', forbidden: true,
    data: [
      {
        name: 'Maintenance - Cards',
        allowedAll: false,
        sectionArray: [
          {name: 'Maintenance cost', allowed: false},
          {name: 'Maintenance cost per mile', allowed: false},
          {name: 'Total mileage - gps', allowed: false},
          {name: 'Maintenance account', allowed: false},
          {name: 'Units', allowed: false},
          {name: 'Fuel cost per mile', allowed: false},
          {name: 'Idling', allowed: false},
        ],
      },
      {
        name: 'Maintenance trucks - section',
        allowedAll: false,
        sectionArray: [
          {name: 'Break down by mileage', allowed: false},
          {name: 'Break down by group', allowed: false},
          {name: 'Trucks table', allowed: false}
        ],
      },
      {
        name: 'Maintenance trailers - section',
        allowedAll: false,
        sectionArray: [
          {name: 'Break down by year', allowed: false},
          {name: 'Break down by group', allowed: false},
          {name: 'Trailers table', allowed: false}
        ],
      },
    ]},
    {component: 'Truck profile', forbidden: true,
    data: [
      {
        name: 'Truck profile - Cards',
        allowedAll: false,
        sectionArray: [
          {name: 'Truck info', allowed: false},
          {name: 'Fuel consumption', allowed: false},
          {name: 'Assigned drivers - days', allowed: false},
          {name: 'Gross', allowed: false},
          {name: 'Rate per mile', allowed: false},
          {name: 'Maintenance cost', allowed: false},
          {name: 'Idling', allowed: false},
          {name: 'Net profit', allowed: false},
          {name: 'Miles', allowed: false},
          {name: 'Otr/Without driver/Repair - pie chart', allowed: false},
        ],
      },
      {
        name: 'Truck profitability table',
        allowedAll: false,
        sectionArray: [],
      },
    ]},
    {component: 'Truck performances', forbidden: true,
    data: [
      {
        name: 'Truck performance - Cards',
        allowedAll: false,
        sectionArray: [
          {name: 'Active trucks in the fleet', allowed: false},
          {name: 'Mileage tms', allowed: false},
          {name: 'Gross', allowed: false},
          {name: 'Fuel', allowed: false},
          {name: 'Length per load', allowed: false},
          {name: 'Maintenance', allowed: false},
          {name: 'Loaded trucks daily average', allowed: false},
          {name: 'Idling per active truck daily', allowed: false},
        ],
      },
      {
        name: 'Truck analysis table',
        allowedAll: false,
        sectionArray: [],
      },
      {
        name: 'Truck profitability table',
        allowedAll: false,
        sectionArray: [],
      },
    ]},
    {component: 'Driver profile', forbidden: true,
    data: [
      {
        name: 'Driver info - Edit data',
        allowedAll: false,
        sectionArray: [],
      },
      {
        name: 'Driver status (Active, Inactive) - Change status',
        allowedAll: false,
        sectionArray: [],
      },
      {
        name: 'Assign truck',
        allowedAll: false,
        sectionArray: [],
      },
      {
        name: 'Assign trailer',
        allowedAll: false,
        sectionArray: [],
      },
      {
        name: 'Additional information - Change additional information',
        allowedAll: false,
        sectionArray: [],
      },
      {
        name: 'Statements table',
        allowedAll: false,
        sectionArray: [
          {name: 'Download statement', allowed: false},
          {name: 'Last Statement - Availability', allowed: false}
        ],
      }
    ]},
    {component: 'Dispatch profile', forbidden: true,
    data: [
      {
        name: 'Dispatch profiles (All profiles)',
        allowedAll: false,
        sectionArray: [],
      },
      {
        name: 'Dispatch info - Cards - Calendar- Notes',
        allowedAll: false,
        sectionArray: [
          {name: 'Dispatch info section', allowed: false},
          {name: 'Calendar activity (Add, Edit, Delete - Status)', allowed: false},
          {name: 'Notes (Add, Edit, Delete - Note)', allowed: false},
        ],
      },
      {
        name: 'Dispatch - cards',
        allowedAll: false,
        sectionArray: [
          {name: 'Gross', allowed: false},
          {name: 'Rate per mile', allowed: false},
          {name: 'Mileage tms', allowed: false},
          {name: 'Mileage per truck', allowed: false},
          {name: 'Company benefit miles', allowed: false},
          {name: 'Margin', allowed: false},
          {name: 'Margin %', allowed: false},
          {name: 'Avg gross per load', allowed: false},
          {name: 'Avg weight', allowed: false},
          {name: 'Prebooked loads', allowed: false},
          {name: 'Length per load', allowed: false},
          {name: 'Total loads', allowed: false},
        ],
      },
      {
        name: 'Asset Utilization table',
        allowedAll: false,
        sectionArray: [],
      },
      {
        name: 'Drivers table',
        allowedAll: false,
        sectionArray: [],
      },
      {
        name: 'Loads table',
        allowedAll: false,
        sectionArray: [],
      },
      {
        name: 'Statement',
        allowedAll: false,
        sectionArray: [
          {name: 'Download statement', allowed: false},
          {name: 'Last Statement - Availability', allowed: false}
        ],
      }
    ]},
    {component: 'Drivers performances', forbidden: true,
    data: [
      {
        name: 'Drivers performance - Cards',
        allowedAll: false,
        sectionArray: [
          {name: 'Current active trucks', allowed: false},
          {name: 'Company driver deal', allowed: false},
          {name: 'Rent to run deal', allowed: false},
          {name: 'Owner operator deal', allowed: false},
          {name: 'Gross', allowed: false},
          {name: 'Rate per mile', allowed: false},
          {name: 'Mileage tms', allowed: false},
          {name: 'Margin', allowed: false},
          {name: 'Number of loads', allowed: false},
          {name: 'Length per load', allowed: false},
          {name: 'Daily trucks with load', allowed: false},
          {name: 'Weekly mileage', allowed: false},
        ],
      },
      {
        name: 'Utilization',
        allowedAll: false,
        sectionArray: [],
      },
      {
        name: 'Average cards',
        allowedAll: false,
        sectionArray: [
          {name: 'Fuel', allowed: false},
          {name: 'Idling', allowed: false},
          {name: 'Paycheck', allowed: false},
        ],
      },
    ]},
    {component: 'Filter drivers', forbidden: true,
    data: [
      {
        name: 'Filter drivers search - table',
        allowedAll: false,
        sectionArray: [],
      }
    ]},
    {component: 'Available trucks', forbidden: true,
    data: [
      {
        name: 'Available trucks map',
        allowedAll: false,
        sectionArray: [],
      }
    ]},
    {component: 'Load price estimation', forbidden: true,
    data: [
      {
        name: 'Load price estimation map',
        allowedAll: false,
        sectionArray: [],
      }
    ]},
    {component: 'Driver search', forbidden: true,
    data: [
      {
        name: 'Drivers search - section',
        allowedAll: false,
        sectionArray: [],
      }
    ]},
    {component: 'Contract app', forbidden: true,
    data: [
      {
        name: 'Contract app - section',
        allowedAll: false,
        sectionArray: [],
      }
    ]},
    {component: 'Fleet Health', forbidden: true,
    data: [
      {
        name: 'Overview',
        allowedAll: false,
        sectionArray: [],
      },
      {
        name: 'Dealer & Shop',
        allowedAll: false,
        sectionArray: [],
      },
      {
        name: 'Schedule',
        allowedAll: false,
        sectionArray: [],
      }
    ]},
    {component: 'Inspections', forbidden: true,
    data: [
      {
        name: 'Inspections - section',
        allowedAll: false,
        sectionArray: [
          {name: 'Edit Inspection', allowed: false},
          {name: 'Delete Inspection', allowed: false}
        ],
      }
    ]},
    {component: 'Employee Activity', forbidden: true,
    data: [
      {
        name: 'Employee Statistics',
        allowedAll: false,
        sectionArray: []
      },
      {
        name: 'Employee dialog',
        allowedAll: false,
        sectionArray: [
          {name: 'Basic info', allowed: false},
          {name: 'Notes', allowed: false},
          {name: 'Documentation', allowed: false},
          {name: 'Account', allowed: false},
          {name: 'Activity stats', allowed: false}
        ]
      },
      {
        name: 'Employee status',
        allowedAll: false,
        sectionArray: [
          {name: 'Files preview and download', allowed: false}
        ]
      },
      {
        name: 'Request List',
        allowedAll: false,
        sectionArray: [
          {name: 'View list', allowed: false}
        ]
      }
    ]},
    {component: 'Hometime', forbidden: true,
    data: [
      {
        name: 'Hometime section',
        allowedAll: false,
        sectionArray: [],
      },
    ]},
    {component: 'Total loads', forbidden: true,
    data: [
      {
        name: 'Change load status',
        allowedAll: false,
        sectionArray: [],
      }
    ]},
    {component: 'Documents', forbidden: true,
    data: [
      {
        name: 'Documents section',
        allowedAll: false,
        sectionArray: [],
      },
    ]},
    {component: 'Tickets', forbidden: true,
    data: [
      {
        name: 'Ticket dialog (Add, Edit, Delete)',
        allowedAll: false,
        sectionArray: [],
      }
    ]},
    {component: 'Warnings', forbidden: true,
    data: [
      {
        name: 'Warning dialog (Add, Edit, Delete)',
        allowedAll: false,
        sectionArray: [],
      }
    ]},
    {component: 'Brokers to avoid', forbidden: true,
    data: [
      {
        name: 'Brokers to avoid dialog (Add, Edit, Delete)',
        allowedAll: false,
        sectionArray: [],
      }
    ]},
    {component: 'Tasks', forbidden: true,
    data: [
      {
        name: 'Tasks section',
        allowedAll: false,
        sectionArray: [
          {name: 'Edit all tasks', allowed: false},
          {name: 'Edit only own tasks ', allowed: false},
          {name: 'Delete all tasks', allowed: false},
          {name: 'Delete only own task', allowed: false},
        ],
      },
    ]},
    {component: 'Dispatch reward system', forbidden: true,
      data: [
        {
          name: 'Set Target dialog',
          allowedAll: false,
          sectionArray: [
            {name: 'Add target', allowed: false},
            {name: 'Edit target', allowed: false},
            {name: 'Delete target', allowed: false}
          ],
        },
        {
          name: 'Bonus Achieved Actions',
          allowedAll: false,
          sectionArray: [
            {name: 'Transfer target, earnings, points', allowed: false},
            {name: 'Change Target Status', allowed: false}
          ],
        }
      ]
    },
    {component: 'Activity Summary', forbidden: true,
      data: [
        {
          name: 'Activity Summary - actions',
          allowedAll: false,
          sectionArray: [
            {name: 'Export reports', allowed: false}
          ]
        },
      ]
    },
    {component: 'Accounting - Invoices', forbidden: true,
      data: [
        {
          name: 'Invoices',
          allowedAll: false,
          sectionArray: [
            {name: 'Create Invoice', allowed: false},
            {name: 'Download Invoice', allowed: false}
          ]
        },
      ]
    },
    {component: 'Liabilities', forbidden: true,
      data: [
        {
          name: 'Assign Trucks - actions',
          allowedAll: false,
          sectionArray: [
            {name: 'Assign Trucks', allowed: false}
          ]
        },
      ]
    },
    {component: 'Accounting Board', forbidden: true,
      data: []
    },
    {component: 'Payments', forbidden: true,
      data: [
        {
          name: 'Payments - actions',
          allowedAll: false,
          sectionArray: [
            {name: 'Add Payment', allowed: false},
            {name: 'Update Payment', allowed: false},
            {name: 'Check/uncheck - Reviewed', allowed: false},
            {name: 'Modify - Issued by', allowed: false},
            {name: 'Check/uncheck - Driver Charged', allowed: false},
            {name: 'Check/uncheck - Charge the Driver', allowed: false},
            {name: 'Add Deduction', allowed: false},
            {name: 'Installment - Show Scheduled checkbox', allowed: false},
            {name: 'Delete Payments (All)', allowed: false},
            {name: 'Delete Payments (Only own)', allowed: false}
          ]
        },
        {
          name: 'Payments Reasons - actions',
          allowedAll: false,
          sectionArray: [
            {name: 'Add Reason', allowed: false},
            {name: 'Update Reason', allowed: false},
            {name: 'Delete Reason', allowed: false}
          ]
        },
      ]
    },
    {component: 'Ageing Report', forbidden: true,
      data: []
    },
    {component: 'Shortage Report', forbidden: true,
      data: []
    },
    {component: 'Live Tracking Trucks', forbidden: true,
      data: []
    },
    {component: 'Active trucks', forbidden: true,
      data: []
    },
    {component: 'Full App', forbidden: true,
      data: []
    },
    {component: 'Accidents/Incidents', forbidden: true,
      data: [       
        { 
          name: 'Accidents/Incidents - actions',
          allowedAll: false,
          sectionArray: [
            {name: 'Add Accident/Incident', allowed: false},
            {name: 'Update Accident/Incident', allowed: false}
          ]
        }  
      ]
    },
    {component: 'Statements', forbidden: true,
      data: [         
        { 
          name: 'Statements - actions',
          allowedAll: false,
          sectionArray: [
            {name: 'Download statement', allowed: false},
            {name: 'Last Statement - Availability', allowed: false}
          ]
        } 
      ]
    },
    {component: 'Underperforming drivers', forbidden: true,
      data: []
    },
    {component: 'Load analysis', forbidden: true,
      data: []
    },
    {component: 'Limited Maintenance', forbidden: true,
      data: []
    },
    {component: 'Report - Mileage per truck', forbidden: true,
      data: []
    },
    {component: 'Report - Truck Activation', forbidden: true,
      data: []
    },
    {component: 'Report - Truck Activity', forbidden: true,
      data: []
    },
    {component: 'Report - Truck Profitability', forbidden: true,
      data: []
    },
    {component: 'Create Task', forbidden: true,
      data: []
    },
    {component: 'Task Calendar', forbidden: true,
      data: []
    },
    {component: 'Tasks Management', forbidden: true,
      data: []
    },
    {component: 'Tasks Categories', forbidden: true,
      data: []
    },
    {component: 'Excel Export', forbidden: true,
      data: [
        {
          name: 'List',
          allowedAll: false,
          sectionArray: [
            {name: 'Overview - Dispatch table', allowed: false},
            {name: 'Overview - Top 3 dispatchers', allowed: false},
            {name: 'Liabilities - Liabilities table', allowed: false},
            {name: 'Accounting Board - Accounting Board table', allowed: false},
            {name: 'Payments - Payments table', allowed: false},
            {name: 'Live dispatch board - Rank table', allowed: false},
            {name: 'Live dispatch board - Live activity', allowed: false},
            {name: 'Live dispatch board - Loads table', allowed: false},
            {name: 'Dispatchers performances - Dispatch table', allowed: false},
            {name: 'Dispatchers performances - Drivers table', allowed: false},
            {name: 'Dispatchers performances - Top 3 dispatchers', allowed: false},
            {name: 'Maintenance - Trucks table', allowed: false},
            {name: 'Maintenance - Trailers table', allowed: false},
            {name: 'Fleet Health - Yard Schedule History', allowed: false},
            {name: 'Active trucks - Active trucks table', allowed: false},
            {name: 'Trucks without drivers - Trucks without drivers table', allowed: false},
            {name: 'Full App - Full App table', allowed: false},
            {name: 'Safety - Truck file table', allowed: false},
            {name: 'Safety - Truck devices table', allowed: false},
            {name: 'Safety - Contract table', allowed: false},
            {name: 'Accidents - Accidents table', allowed: false},
            {name: 'Warnings - Warnings table', allowed: false},
            {name: 'Inspections - Inspections table', allowed: false},
            {name: 'Truck Performance - Truck analysis table', allowed: false},
            {name: 'Active trailers - Active trailers table', allowed: false},
            {name: 'Drivers Performance - Utilization table', allowed: false},
            {name: 'Hometime - Drivers and dispatchers table', allowed: false},
            {name: 'Hometime - By day table', allowed: false},
            {name: 'Hometime - By dispatcher table', allowed: false},
            {name: 'Hometime - By drivers table', allowed: false},
            {name: 'Statements - Statements table', allowed: false},
            {name: 'Underperforming drivers - Underperforming drivers table', allowed: false},
            {name: 'Filter drivers - Filter drivers table', allowed: false},
            {name: 'Total loads - Total loads table', allowed: false},
            {name: 'Lost loads - Lost loads per driver table', allowed: false},
            {name: 'Lost loads - Lost loads per dispatcher table', allowed: false},
            {name: 'Business partners - Business partners table', allowed: false},
            {name: 'Brokers to avoid - Brokers to avoid table', allowed: false},
            {name: 'Load analysis - Load analysis table', allowed: false},
            {name: 'IRP/IFTA report - IRP/IFTA report table', allowed: false},
            {name: 'Mileage per truck - Mileage per truck table', allowed: false},
            {name: 'Tickets - Tickets table', allowed: false},
            {name: 'Assign task - Tasks table', allowed: false},
            {name: 'Company Benefit Miles - Company Benefit Miles table', allowed: false},
            {name: 'Dispatch reward system - Dispatchers target table', allowed: false},
            {name: 'Driver Profile - Statements table', allowed: false},
            {name: 'Dispatch Profile - Loads table', allowed: false},
            {name: 'Claims - Claims table', allowed: false},
            {name: 'Employee Activity - Employee Activity table', allowed: false},
            {name: 'Deductions - Deductions table', allowed: false},
            {name: 'Truck Amortization - Truck Amortization table', allowed: false},
            {name: 'Maintenance Repair Log - Maintenance Repair Log table', allowed: false}
          ],
        }
      ]
    },
    {component: 'Claims', forbidden: true,
      data: [       
        { 
          name: 'Claims - actions',
          allowedAll: false,
          sectionArray: [
            {name: 'Add Claim', allowed: false},
            {name: 'Update Claim', allowed: false},
            {name: 'Delete Claim', allowed: false}
          ]
        }  
      ]
    },
    {component: 'Deductions', forbidden: true,
      data: [       
        { 
          name: 'Deductions - actions',
          allowedAll: false,
          sectionArray: [
            {name: 'Add Deduction', allowed: false},
            {name: 'Update Deduction', allowed: false},
            {name: 'Installment - Show Scheduled checkbox', allowed: false},
            {name: 'Delete Deduction', allowed: false}
          ]
        }  
      ]
    },
    {component: 'Truck Amortization', forbidden: true,
      data: [
        { 
          name: 'Truck Amortization - actions',
          allowedAll: false,
          sectionArray: [
            {name: 'Add Amortization', allowed: false},
            {name: 'Update Amortization', allowed: false},
            {name: 'Delete Amortization', allowed: false}
          ]
        }  
      ]
    },
    {component: 'Maintenance Repair Log', forbidden: true,
      data: [       
        { 
          name: 'Maintenance Repair Log - actions',
          allowedAll: false,
          sectionArray: [
            {name: 'Add Repair Log', allowed: false},
            {name: 'Update Repair Log', allowed: false},
            {name: 'Delete Repair Log', allowed: false}
          ]
        }  
      ]
    },
    {component: 'Settings', forbidden: true,
    data: [
      {
        name: 'Settings Conditions',
        allowedAll: false,
        sectionArray: [],
      },
      {
        name: 'Settings User & Departments',
        allowedAll: false,
        sectionArray: [],
      }
    ]},
    {component: 'Notifications & Alerts Settings', forbidden: true,
    data:[
      {
        name: 'Notifications & Alerts - actions',
        allowedAll: false,
        sectionArray: [
          {name: 'Add Notifications & Alerts', allowed: false},
          {name: 'Update Notifications & Alerts', allowed: false},
          {name: 'Delete Notifications & Alerts', allowed: false}
        ]
      },
      {
        name: 'Mail Settings for Divisions', 
        allowedAll: false, 
        sectionArray:
        [
          {name: 'Add Mail Settings', allowed: false},
          {name: 'Update Mail Settings', allowed: false},
          {name: 'Delete Mail Settings', allowed: false}
        ]
      }
    ]}
  ];