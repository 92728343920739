import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linechartFilter'
})
export class LinechartFilterPipe implements PipeTransform {

  transform(array: any[], filterArray: any[]): any[] {
    if (!array) {
      return [];
    };

    const typesObj: any = {'SOLO COMPANY': 'Company', 'TEAM COMPANY': 'Company', 'SOLO RENT' : 'Rent to run', 'TEAM RENT': 'Rent to run', 'OWNER OPERATOR': 'Owner'};
    const driverTypes: any = {'SOLO COMPANY': 'Solo', 'TEAM COMPANY': 'Team', 'SOLO RENT': 'Solo', 'TEAM RENT': 'Team', 'OWNER OPERATOR': 'Solo'};

    return array.filter((obj: any) => {
      let condition1: boolean = filterArray[0].length === 0 ? true : filterArray[0].includes(obj.dispatcher?.split('/ ')[1]?.split(' ')[0]);
      let condition2: boolean = filterArray[1].length === 0 ? true : filterArray[1].includes(driverTypes[obj.status]);
      let condition3: boolean = filterArray[2].length === 0 ? true : filterArray[2].includes(typesObj[obj.status]);
      let condition4: boolean = filterArray[3].length === 0 ? true : filterArray[3].includes((obj.recruiter || obj.recruiter1))
      let condition5: boolean = filterArray[4].length === 0 ? true : (filterArray[4].includes('Active drivers') && obj.active === true) || (filterArray[4].includes('Inactive drivers') && obj.active === false);
      let condition6: boolean = filterArray[5].length === 0 ? true : `${obj.first_name} ${obj.last_name}`?.toLocaleLowerCase().includes(filterArray[5].toLocaleLowerCase());

      if(condition1 && condition2 && condition3 && condition4 && condition5 && condition6) {
        return obj;
      };
   
    });
  }

}
