import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadsTableComponent } from './components/safety-page/loads-driver-app/loads-table/loads-table.component';
import { SharedModule } from '../shared/shared.module';
import { AddContractCategoryDialogComponent } from './components/safety-page/contract/add-contract-category-dialog/add-contract-category-dialog.component';
import { ContractComponent } from './components/safety-page/contract/contract.component';
import { DeleteCategoryFileDialogComponent } from './components/safety-page/contract/delete-category-file-dialog/delete-category-file-dialog.component';
import { DeleteContractConfirmationDialogComponent } from './components/safety-page/contract/edit-contract-category-dialog/delete-contract-confirmation-dialog/delete-contract-confirmation-dialog.component';
import { EditContractCategoryDialogComponent } from './components/safety-page/contract/edit-contract-category-dialog/edit-contract-category-dialog.component';
import { MergeFilesConfirmationDialogComponent } from './components/safety-page/contract/merge-files-confirmation-dialog/merge-files-confirmation-dialog.component';
import { PolicyDialogComponent } from './components/safety-page/contract/policy-dialog/policy-dialog.component';
import { SuccessActionComponent } from './components/safety-page/contract/policy-dialog/success-action/success-action.component';
import { DeleteTemplateDialogComponent } from './components/safety-page/contract/templates-list-dialog/delete-template-dialog/delete-template-dialog.component';
import { TemplatesListDialogComponent } from './components/safety-page/contract/templates-list-dialog/templates-list-dialog.component';
import { WarningContractDialogComponent } from './components/safety-page/contract/warning-contract-dialog/warning-contract-dialog.component';
import { AddEditTemplateDialogComponent } from './components/safety-page/document-editor/add-edit-template-dialog/add-edit-template-dialog.component';
import { DocumentEditorComponent } from './components/safety-page/document-editor/document-editor.component';
import { AddEditVariableDialogComponent } from './components/safety-page/document-editor/variables-dialog/add-edit-variable-dialog/add-edit-variable-dialog.component';
import { DeleteVariableConfirmationDialogComponent } from './components/safety-page/document-editor/variables-dialog/delete-variable-confirmation-dialog/delete-variable-confirmation-dialog.component';
import { VariablesDialogComponent } from './components/safety-page/document-editor/variables-dialog/variables-dialog.component';
import { LoadsDriverAppComponent } from './components/safety-page/loads-driver-app/loads-driver-app.component';
import { FilesComponent } from './components/safety-page/loads-driver-app/loads-info-dialog/files/files.component';
import { LoadInfoComponent } from './components/safety-page/loads-driver-app/loads-info-dialog/load-info/load-info.component';
import { LoadsInfoDialogComponent } from './components/safety-page/loads-driver-app/loads-info-dialog/loads-info-dialog.component';
import { RequestComponent } from './components/safety-page/loads-driver-app/loads-info-dialog/request/request.component';
import { NewCategoryDialogComponent } from './components/safety-page/new-category-dialog/new-category-dialog.component';
import { SafetyPageComponent } from './components/safety-page/safety-page.component';
import { TruckDeviceConfirmationDeleteDialogComponent } from './components/safety-page/truck-devices/truck-device-confirmation-delete-dialog/truck-device-confirmation-delete-dialog.component';
import { TruckDevicesAddEditDialogComponent } from './components/safety-page/truck-devices/truck-devices-add-edit-dialog/truck-devices-add-edit-dialog.component';
import { TruckDevicesComponent } from './components/safety-page/truck-devices/truck-devices.component';
import { CreateNewTruckFileDialogComponent } from './components/safety-page/truck-file/create-new-truck-file-dialog/create-new-truck-file-dialog.component';
import { DeleteFileConfirmationDialogComponent } from './components/safety-page/truck-file/delete-file-confirmation-dialog/delete-file-confirmation-dialog.component';
import { DeleteCategoryDialogComponent } from './components/safety-page/truck-file/edit-category-dialog/delete-category-dialog/delete-category-dialog.component';
import { EditCategoryDialogComponent } from './components/safety-page/truck-file/edit-category-dialog/edit-category-dialog.component';
import { TruckFileComponent } from './components/safety-page/truck-file/truck-file.component';
import { UploadFilesDialogComponent } from './components/safety-page/truck-file/upload-files-dialog/upload-files-dialog.component';
import { WarningFileDialogComponent } from './components/safety-page/truck-file/warning-file-dialog/warning-file-dialog.component';
import { FilterDriversPipe } from './pipes/filter-drivers.pipe';
import { SafetyRoutingModule } from './safety.routing';
import { SafetyService } from './services/safety.service';
import { DocumentStatusPipe } from './pipes/document-status.pipe';
import { LoadNoteDialogComponent } from './components/safety-page/loads-driver-app/loads-info-dialog/load-info/load-note-dialog/load-note-dialog.component';

@NgModule({
  declarations: [
    SafetyPageComponent,
    ContractComponent,
    AddContractCategoryDialogComponent,
    DeleteCategoryFileDialogComponent,
    EditContractCategoryDialogComponent,
    DeleteContractConfirmationDialogComponent,
    MergeFilesConfirmationDialogComponent,
    PolicyDialogComponent,
    SuccessActionComponent,
    DeleteTemplateDialogComponent,
    TemplatesListDialogComponent,
    WarningContractDialogComponent,
    DocumentEditorComponent,
    AddEditTemplateDialogComponent,
    VariablesDialogComponent,
    AddEditVariableDialogComponent,
    DeleteVariableConfirmationDialogComponent,
    NewCategoryDialogComponent,
    TruckDevicesComponent,
    TruckDeviceConfirmationDeleteDialogComponent,
    TruckDevicesAddEditDialogComponent,
    TruckFileComponent,
    CreateNewTruckFileDialogComponent,
    DeleteFileConfirmationDialogComponent,
    EditCategoryDialogComponent,
    DeleteCategoryDialogComponent,
    UploadFilesDialogComponent,
    WarningFileDialogComponent,
    FilterDriversPipe,
    LoadsDriverAppComponent,
    LoadsTableComponent,
    LoadsInfoDialogComponent,
    RequestComponent,
    FilesComponent,
    LoadInfoComponent,
    DocumentStatusPipe,
    LoadNoteDialogComponent
  ],
  imports: [
    CommonModule,
    SafetyRoutingModule,
    SharedModule
  ],
  providers: [SafetyService]
})
export class SafetyModule { }
