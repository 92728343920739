import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, Input } from '@angular/core';
import { AddEditBrokersToAvoidDialogComponent } from '../add-edit-brokers-to-avoid-dialog/add-edit-brokers-to-avoid-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RemoveFromListConfirmationDialogComponent } from '../remove-from-list-confirmation-dialog/remove-from-list-confirmation-dialog.component';
import { IBrokersToAvoid } from '@app/modules/brokers-to-avoid/models/brokers-to-avoid.model';
import { BrokersToAvoidService } from '@app/modules/brokers-to-avoid/services/brokers-to-avoid.service';
import { Sort } from '@angular/material/sort';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import moment = require('moment');
import * as XLSX from 'xlsx';
import { TitleCasePipe } from '@angular/common';
import { DateDifferencePipe } from '@app/modules/shared/pipes/date-difference.pipe';
import { DateAsAgoPipe } from '@app/modules/shared/pipes/date-as-ago.pipe';
import { TransformService } from '@app/modules/shared/services/transform.service';

@Component({
  selector: 'brokers-to-avoid-table',
  templateUrl: './brokers-to-avoid-table.component.html',
  styleUrls: ['./brokers-to-avoid-table.component.scss']
})
export class BrokersToAvoidTableComponent {
  userData: any = JSON.parse(localStorage.getItem('currentUser'));
  permissions: any = this.rulesService.UserData[30].data;
  searchBrokers: string = '';
  @Input() dataSource: IBrokersToAvoid[] = [];
  @Input() divisionId: number = -1;

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      0, 5
    )
  ];

  //Excel config
  xlsxConfig: any = [
    {columnName: 'No.', selected: true},
    {columnName: 'Business partner', selected: true},
    {columnName: 'MC #', selected: true},
    {columnName: 'Reason', selected: true},
    {columnName: 'From', selected: true},
    {columnName: 'In avoid list', selected: true},
    {columnName: 'Last check', selected: true},
    {columnName: 'Created by', selected: true},
    {columnName: 'Status', selected: true},
    {columnName: 'Checked by', selected: true},
    {columnName: 'Note', selected: true}
  ];

  constructor(private rulesService: RulesService,
              private dialog: MatDialog, 
              private brokersToAvoidService: BrokersToAvoidService,
              private transformService: TransformService,
              private dateDifference: DateDifferencePipe,
              private dateAsAgo: DateAsAgoPipe,
              private titleCase: TitleCasePipe) { }

  //Remove from the list
  removeFromListDialog(element: any, index: number) {
    element.isOpenMenu = !element.isOpenMenu;
    if(this.permissions[0].allowedAll) {
      let dialogRef = this.dialog.open(RemoveFromListConfirmationDialogComponent, {
        autoFocus: false,
        panelClass: 'remove-from-the-list-dialog-container',
        data: element.bp_name
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          let obj: any = {...element};
          obj.edited_by = `${this.userData.first_name} ${this.userData.last_name}`;
          obj.edited_date = moment().format('YYYY-MM-DDTHH:mm:ss');
          obj.deleted = true;
          this.brokersToAvoidService.createUpdateBrokerToAvoid(obj).subscribe((response: IBrokersToAvoid) => {
            if(response?.deleted) {
              this.dataSource.splice(index, 1);
            };
          });
        }
      });
    }
    else {
      this.msgForbbidenAccess()
    }
  };

  //Add/Edit Brokers
  openAddEditBrokersToAvoidDialog(element: any, index: number, readOnly: boolean) {
    element.isOpenMenu = false;
    if(this.permissions[0].allowedAll) {
      let dialogRef = this.dialog.open(AddEditBrokersToAvoidDialogComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'brokers-to-avoid-dialog-container',
        data: {obj: element, editMode: true, readOnly: readOnly}
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.dataSource[index] = result;
          this.dataSource = [...this.dataSource];
        }
      });
    }
    else {
      this.msgForbbidenAccess()
    }
  };

  //Mark as checked
  markAsChecked(element: any) {
    element.isOpenMenu = !element.isOpenMenu;
    if(this.permissions[0].allowedAll) {
      let obj: any = {...element};
      obj.last_check = moment().format('YYYY-MM-DDTHH:mm:ss');
      obj.checked_by = `${this.userData.first_name} ${this.userData.last_name}`;
      obj.edited_by = `${this.userData.first_name} ${this.userData.last_name}`;
      obj.edited_date = moment().format('YYYY-MM-DDTHH:mm:ss');
      this.brokersToAvoidService.createUpdateBrokerToAvoid(obj).subscribe((response: IBrokersToAvoid) => {
        console.log(response);
        if(response?.id) {
          element.last_check = response.last_check;
          element.checked_by = response.checked_by;
          element.edited_by = response.edited_by;
          element.edited_date = response.edited_date;
        };
      });
    }
    else {
      this.msgForbbidenAccess()
    }
  };

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dataSource));
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'bp_name':
          return compare(a.bp_name, b.bp_name, isAsc);
        case 'mc':
          return compare(a.mc, b.mc, isAsc);
        case 'reason':
          return compare(a.reason, b.reason, isAsc);
        case 'from':
          return compare(a.in_current_status, b.in_current_status, isAsc);
        case 'in_current_status':
          return compare(a.in_current_status, b.in_current_status, isAsc);
        case 'last_check':
          return compare(a.last_check, b.last_check, isAsc);
        case 'created_by':
          return compare(a.created_by, b.created_by, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'checked_by':
          return compare(a.checked_by, b.checked_by, isAsc);
        case 'note':
          return compare(a.note, b.note, isAsc);
        default:
          return 0;
      }
    });
  }

  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[37].allowed) {
      const tableData: any[] = this.dataSource;
      const excelTable: any[] = [];
      for(let i = 0; i < tableData.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, i + 1, columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, tableData[i].bp_name, columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, tableData[i].mc, columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, tableData[i].reason, columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, this.transformService.transformDateFormat(tableData[i].in_current_status, 'MMM DD, YYYY.'), columnsConfig[4].selected);
        this.transformService.selectedColumn(obj, columnsConfig[5].columnName, this.dateDifference.transform(tableData[i].in_current_status), columnsConfig[5].selected);
        this.transformService.selectedColumn(obj, columnsConfig[6].columnName, this.dateAsAgo.transform(tableData[i].last_check), columnsConfig[6].selected);
        this.transformService.selectedColumn(obj, columnsConfig[7].columnName, tableData[i].created_by, columnsConfig[7].selected);
        this.transformService.selectedColumn(obj, columnsConfig[8].columnName, tableData[i].status, columnsConfig[8].selected);
        this.transformService.selectedColumn(obj, columnsConfig[9].columnName, tableData[i].checked_by, columnsConfig[9].selected);
        this.transformService.selectedColumn(obj, columnsConfig[10].columnName, tableData[i].note.length > 0, columnsConfig[10].selected);
        excelTable.push(obj);
      };
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'brokers-to-avoid.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }   
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  transformDateFormat(date: any, format: string) {
    if(date) {
      return moment(date).format(format);
    }
    return '';
  };

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}