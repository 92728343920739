import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { IDeduction } from '../models/deduction.model';

@Injectable()
export class DeductionsService {
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
  });
  requestOptions = { headers: this.headers };

  constructor(private http: HttpClient) { }

  getDeductions(startDate: string, endDate: string, isScheduled: boolean | null) {
    return this.http.get(`${_G.extendApiUrl}payments/deduction?start=${startDate}&end=${endDate}&scheduled=${isScheduled}`, this.requestOptions);
  };

  getAllDeductionsFiles(id: number) {
    return this.http.get(`${_G.extendApiUrl}payments/deductionfiles?id=${id}`, this.requestOptions);
  };

  createUpdateDeduction(obj: IDeduction) {
    const json: string = JSON.stringify(obj);
    console.log(json);
    return this.http.post(`${_G.extendApiUrl}payments/deduction`, json, this.requestOptions);
  };

  checkUncheckDeductionSchedule(id: number, isChecked: boolean) {
    return this.http.get(`${_G.extendApiUrl}payments/installment?id=${id}&value=${isChecked}`, this.requestOptions);
  };

  deleteDeduction(id: number, user: string, data: string) {
    return this.http.delete(`${_G.extendApiUrl}payments/deduction?id=${id}&user=${user}&date=${data}`, this.requestOptions);
  };

}
