import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { SharedService } from '@app/modules/shared/services/shared.service';

@Component({
  selector: 'upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss']
})
export class UploadFilesComponent {
  @Input() files: any[] = [];
  @Output() triggerEvent = new EventEmitter<any>();

  //Files
  isOpenFilesIndex: number | null = null;

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      0, 5
    )
  ];

  constructor(private sharedService: SharedService, private dialog: MatDialog, private _formBuilder: FormBuilder) { }

  //Add files
  onFileSelected(event: any) {
    let length: any = Object.keys(event.target.files).length;
    for (let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i);
    }
  }

  addMultipleFiles(event: any, index: number) {
    let fileName = event.target.files[index].name;
    let reader = new FileReader();
    reader.onload = (e: any) => {
      let base64 = e.target.result.split(',')[1];
      const itemGroup = this._formBuilder.group({
        filename: fileName,
        data: base64
      });
      this.triggerEvent.emit({name: 'UPLOAD', itemGroup: itemGroup});
    };
    reader.readAsDataURL(event.target.files[index]);
  };

  openFileInNewTab(obj: any) {
    this.isOpenFilesIndex = null;
    this.sharedService.downloadPreviewFile(obj.filename, obj.data);
  };

  downloadFile(obj: any) {
    this.isOpenFilesIndex = null;
    this.sharedService.downloadFile(obj.data, obj.filename);
  };

  deleteFile(index: number) {
    this.isOpenFilesIndex = null;
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.triggerEvent.emit({name: 'DELETE', index: index});
      }
    })
  };

}
