import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/services/auth/auth.guard';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';

const routes: Routes = [
    {
		path: '',
        redirectTo: '/admin/welcome',
		pathMatch: 'full',
    },
    { 
        path: 'welcome',
        canActivate: [AuthGuard],
        component: WelcomePageComponent,
        data: { roles: 0 }
    },
    //Lazy loading
    {
        path: 'accounting',
        loadChildren: () => import('./accounting/accounting.module').then(m => m.AccountingModule)
	},	
    {
        path: 'tasks',
        loadChildren: () => import('./tasks/tasks.module').then(m => m.TasksModule)
	},	
    {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
	},
    {
        path: 'load-board',
        loadChildren: () => import('./load-board/load-board.module').then(m => m.LoadBoardModule)
	},	
    {
        path: 'driver-chat/:id',
        loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule)
	},	
    {
        path: 'dispatchers',
        loadChildren: () => import('./business-partners/business-partners.module').then(m => m.BusinessPartnersModule)
	},	
    {
        path: 'dispatchers',
        loadChildren: () => import('./brokers-to-avoid/brokers-to-avoid.module').then(m => m.BrokersToAvoidModule)
	},	
    {
        path: 'dispatchers',
        loadChildren: () => import('./dispatcher-profile-page/dispatcher-profile-page.module').then(m => m.DispatcherProfilePageModule)
    },
    {
        path: 'dispatchers',
        loadChildren: () => import('./dispatchers-live-board/dispatchers-live-board.module').then(m => m.DispatchersLiveBoardModule)
    },
    {
        path: 'dispatchers',
        loadChildren: () => import('./dispatch-reward-system/dispatch-reward-system.module').then(m => m.DispatchRewardSystemModule)
    },
    {
        path: 'time-series',
        loadChildren: () => import('./dispatchers-performances/dispatchers-performances.module').then(m => m.DispatchersPerformancesModule)
    },
    {
        path: 'documents',
        loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsModule)
    },
    {
        path: 'drivers',
        loadChildren: () => import('./drivers-filter/drivers-filter.module').then(m => m.DriversFilterModule)
    },
    {
        path: 'drivers',
        loadChildren: () => import('./drivers-performance-page/drivers-performance-page.module').then(m => m.DriversPerformancePageModule)
    },
    {
        path: 'drivers',
        loadChildren: () => import('./drivers-profile-page/drivers-profile-page.module').then(m => m.DriversProfilePageModule)
    },
    {
        path: 'drivers',
        loadChildren: () => import('./drivers-search-page/drivers-search-page.module').then(m => m.DriversSearchPageModule)
    },
    {
        path: 'drivers',
        loadChildren: () => import('./drivers-underperforming/drivers-underperforming.module').then(m => m.DriversUnderperformingModule)
    },
    {
        path: 'employee-activity',
        loadChildren: () => import('./employee/employee.module').then(m => m.EmployeeModule)
    },
    {
        path: 'loads',
        loadChildren: () => import('./estimations/estimations.module').then(m => m.EstimationsModule)
    },
    {
        path: 'fleet-health',
        loadChildren: () => import('./fleet-health/fleet-health.module').then(m => m.FleetHealthModule)
    },
    {
        path: 'full-app',
        loadChildren: () => import('./full-app/full-app.module').then(m => m.FullAppModule)
    },
    {
        path: 'drivers',
        loadChildren: () => import('./hometime/hometime.module').then(m => m.HometimeModule)
    },
    {
        path: 'inspections',
        loadChildren: () => import('./inspections/inspections.module').then(m => m.InspectionsModule)
    },
    {
        path: 'dispatchers',
        loadChildren: () => import('./load-analysis/load-analysis.module').then(m => m.LoadAnalysisModule)
    },
    {
        path: 'maintenance',
        loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenanceModule)
    },
    {
        path: 'maps',
        loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule)
    },
    {
        path: 'live-tracking-trucks',
        loadChildren: () => import('./trucks-map/trucks-map.module').then(m => m.TrucksMapModule)
	},	
    {
        path: 'dispatchers',
        loadChildren: () => import('./not-covered-trucks/not-covered-trucks.module').then(m => m.NotCoveredTrucksModule)
    },
    {
        path: 'overview',
        loadChildren: () => import('./overview/overview.module').then(m => m.OverviewModule)
    },
    {
        path: 'dispatchers',
        loadChildren: () => import('./prebooked-loads/prebooked-loads.module').then(m => m.PrebookedLoadsModule)
    },
    {
        path: 'dispatchers',
        loadChildren: () => import('./prebooked-upcoming/prebooked-upcoming.module').then(m => m.PrebookedUpcomingModule)
    },
    {
        path: 'recruiting',
        loadChildren: () => import('./recruiting/recruiting.module').then(m => m.RecruitingModule)
    },
    {
        path: 'analytics',
        loadChildren: () => import('./safety/safety.module').then(m => m.SafetyModule)
    },
    {
        path: 'accidents',
        loadChildren: () => import('./accidents/accidents.module').then(m => m.AccidentsModule)
    },
    {
        path: 'claims',
        loadChildren: () => import('./claims/claims.module').then(m => m.ClaimsModule)
    },
    {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
    },
    {
        path: 'drivers',
        loadChildren: () => import('./statements/statements.module').then(m => m.StatementsModule)
    },
    {
        path: 'tickets',
        loadChildren: () => import('./tickets/tickets.module').then(m => m.TicketsModule)
    },
    {
        path: 'total-loads',
        loadChildren: () => import('./total-loads/total-loads.module').then(m => m.TotalLoadsModule)
    },
    {
        path: 'trucks',
        loadChildren: () => import('./trucks/trucks.module').then(m => m.TrucksModule)
    },
    {
        path: 'warnings',
        loadChildren: () => import('./warnings/warnings.module').then(m => m.WarningsModule)
    }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class MainRoutingModule { }
