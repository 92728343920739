import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import moment = require('moment');
import { EmployeeService } from '../../services/employee.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-requests-dialog',
  templateUrl: './requests-dialog.component.html',
  styleUrls: ['./requests-dialog.component.scss']
})
export class RequestsDialogComponent {
  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
  fullName: string = `${this.loggedUser.first_name} ${this.loggedUser.last_name}`;
  nickname: string = `${this.loggedUser.nickname}`;

  //Form group
  formGroup: any = this._formBuilder.nonNullable.group({
    date_of_request: [moment().format('YYYY-MM-DD')],
    employee: [`${this.nickname.length === 0 ? this.fullName : this.nickname?.trim().split(' ')[0]}`],
    employee_id: [this.loggedUser.id],
    department: [this.loggedUser.department],
    email: [this.loggedUser.username],
    request_type: ['', [Validators.required, Validators.minLength(2)]],
    request_reason: [''],
    date_from: [null, Validators.required],
    date_to: [null, Validators.required],
    return_to_work: [null, Validators.required]
  });

  calendarStyleConfig: any = {
    'width': '260px',
    'height': '45px',
    'background-color': '#f4f8ff',
    'color': '#1F1F1F',
    'box-shadow': 'none',
    'border-radius': '25px',
    'border-top': 'thin solid #0000001f',
    'opened-dropdown-shadow': '0px 1px 6.65px 0.35px rgba(13, 39, 80, 0.16)'
  };

  dropdownStyleConfig: any = {
    'width': '260px',
    'height': '45px',
    'background-color': '#f4f8ff',
    'color': '#1F1F1F',
    'box-shadow': 'none',
    'border-radius': '25px',
    'border-top': 'thin solid #0000001f',
    'arrow-color': '#0856cd',
    'opened-dropdown-shadow': '0px 1px 6.65px 0.35px rgba(13, 39, 80, 0.16)'
  };

  requestsArray: any[] = [
    {name: 'Vacation'},
    {name: 'Complimentary Day Off'},
    {name: 'Day Off'},
    {name: 'Slava'},
    {name: 'Birthday'},
    {name: 'Personal'}
  ];

  //Multiple clicks
  multipleClicks: boolean = true;

  constructor(private dialog: MatDialog,
              public dialogRef: MatDialogRef<RequestsDialogComponent>,
              private _formBuilder: FormBuilder, 
              private employeeService: EmployeeService) { }

  sendRequest() {
    this.formGroup.markAllAsTouched();
    if(this.formGroup.valid && this.multipleClicks) {
      this.multipleClicks = false;
      this.employeeService.sendRequest(this.formGroup.value)
      .pipe(catchError((err: any) => {
        this.showErrorMessage();
        this.multipleClicks = true;
        return throwError(() => err);
      }))
      .subscribe((response: any) => {
        console.log(response);
        if(response) {
          this.dialogRef.close(response);
          this.employeeService.refreshDataSubject.next(true);
        }
        else {
          this.showErrorMessage();
        }
      });
    }
  };

  //Show error message
  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  //Get form value
  formData(formKey: string) {
    return this.formGroup.controls[formKey].value;
  };

}
