import { Component, OnDestroy, OnInit } from '@angular/core';
import { InspectionsService } from '../../services/inspections.service';
import { catchError, Subscription, throwError } from 'rxjs';
import moment = require('moment');
import { TransformService } from '@app/modules/shared/services/transform.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from 'xlsx';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { TitleCasePipe } from '@angular/common';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateUpdateInspectionDialogComponent } from './create-update-inspection-dialog/create-update-inspection-dialog.component';
import { InspectionTablePipePipe } from '../../pipes/inspection-table-pipe.pipe';
import { SearchPipe } from '@app/modules/shared/pipes/search.pipe';

@Component({
  selector: 'app-inspections-page',
  templateUrl: './inspections-page.component.html',
  styleUrls: ['./inspections-page.component.scss']
})
export class InspectionsPageComponent implements OnInit, OnDestroy {
  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  };

  //Table data
  tableData: any[] = [];
  dataSource: any[] = [];
  inspectionType: string = 'All Inspections';
  inspectionLevel: string = 'All Levels';

  //Excel config
  xlsxConfig: any[] = [
    {columnName: 'No.', selected: true},
    {columnName: 'Driver', selected: true},
    {columnName: 'Dispatcher', selected: true},
    {columnName: 'Truck', selected: true},
    {columnName: 'Outcome', selected: true},
    {columnName: 'Level', selected: true},
    {columnName: 'Charge', selected: true},
    {columnName: 'Safety reward', selected: true},
    {columnName: 'Points', selected: true},
    {columnName: 'Date', selected: true},
    {columnName: 'Out of Service', selected: true},
    {columnName: 'State', selected: true},
    {columnName: 'Original', selected: true},
    {columnName: 'Sent', selected: true},
    {columnName: 'City', selected: true},
    {columnName: 'Related', selected: true}
  ];

  metaObj: any = {
    countObj: {
      numOfAllInspections: 0,
      numOfAllInspectionsPercent: 0,
      numOfPassedInspections: 0,
      numOfPassedInspectionsPercent: 0,
      numOfFailedInspections: 0,
      numOfFailedInspectionsPercent: 0,
      allInspectionsLevelI: 0,
      allInspectionsLevelIPercent: 0,
      allInspectionsLevelII: 0,
      allInspectionsLevelIIPercent: 0,
      allInspectionsLevelIII: 0,
      allInspectionsLevelIIIPercent: 0,
      passedInspectionsLevelI: 0,
      passedInspectionsLevelIPercent: 0,
      passedInspectionsLevelII: 0,
      passedInspectionsLevelIIPercent: 0,
      passedInspectionsLevelIII: 0,
      passedInspectionsLevelIIIPercent: 0,
      failedInspectionsLevelI: 0,
      failedInspectionsLevelIPercent: 0,
      failedInspectionsLevelII: 0,
      failedInspectionsLevelIIPercent: 0,
      failedInspectionsLevelIII: 0,
      failedInspectionsLevelIIIPercent: 0,
      sumCharge: 0,
      sumRewards: 0,
      sumPoints: 0,
      avgCharge: 0,
      avgRewards: 0,
      avgPoints: 0
    }
  };

  //Search
  searchDriver: string = '';

  //Interval
  interval: any;

  loaded: boolean = true;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private transformService: TransformService,
              private inspectionService: InspectionsService,
              private dialog: MatDialog,
              private titleCase: TitleCasePipe,
              private rulesService: RulesService,
              private spinner: NgxSpinnerService,
              private inspectionPipe: InspectionTablePipePipe,
              private searchPipe: SearchPipe) { }

  ngOnInit(): void {
    this.getInspectionTableData();  
    if(this.rulesService.liveUpdate) {
      this.interval = setInterval(() => {
        this.getInspectionTableData();
      }, this.rulesService.miliseconds);
    }
  } 

  changeDate(date: any) {
    this.dateObj.startDate = date.startDate;
    this.dateObj.endDate = date.endDate;
    this.getInspectionTableData();
  };

  getInspectionTableData() {
    this.resetData();
    this.error = false;
    this.loaded = false;
    this.spinner.show('inspections-table');
    this.subscription = this.inspectionService.getInspectionData(this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('inspections-table');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      this.dataSource = response;
      this.tableData = this.inspectionPipe.transform(response, this.inspectionType, this.inspectionLevel, this.metaObj);
      this.spinner.hide('inspections-table');
      this.loaded = true;
    });
  };

  changeFilter(inspectionTypeValue: string, inspectionLevelValue: string) {
    this.inspectionType = inspectionTypeValue;
    this.inspectionLevel = inspectionLevelValue;
    this.tableData = [];
    this.tableData = this.inspectionPipe.transform(this.dataSource, inspectionTypeValue, inspectionLevelValue, this.metaObj);
    this.tableData = [...this.tableData];
  };

  onValueChange(value: string) {
    this.tableData = this.inspectionPipe.transform(this.searchPipe.transform(this.dataSource, value, 'driver_name'), this.inspectionType, this.inspectionLevel, this.metaObj);
  };

  createUpdateDotInspectionDialog() {
    let dialogRef: any = this.dialog.open(CreateUpdateInspectionDialogComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: 'component-dialog-container',
      data: {editMode: false, data: {}}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.getInspectionTableData();
      }
    })
  };

  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[22].allowed) {
      const excelTable: any[] = [];
      for(let i = 0; i < this.tableData.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, i + 1, columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.titleCase.transform(this.tableData[i].driver), columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, this.titleCase.transform(this.tableData[i].dispatcher), columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, this.tableData[i].truck_no, columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, this.tableData[i].inspection_result, columnsConfig[4].selected);
        this.transformService.selectedColumn(obj, columnsConfig[5].columnName, this.tableData[i].inspection_level, columnsConfig[5].selected);
        this.transformService.selectedColumn(obj, columnsConfig[6].columnName, `$${this.transformService.addCommasDots(this.tableData[i].amount, 'round')}`, columnsConfig[6].selected);
        this.transformService.selectedColumn(obj, columnsConfig[7].columnName, `$${this.transformService.addCommasDots(this.tableData[i].rewards, 'round')}`, columnsConfig[7].selected);
        this.transformService.selectedColumn(obj, columnsConfig[8].columnName, this.tableData[i].points, columnsConfig[8].selected);
        this.transformService.selectedColumn(obj, columnsConfig[9].columnName, this.transformService.transformDateFormat(this.tableData[i].date, 'MMM DD, YYYY'), columnsConfig[9].selected);
        this.transformService.selectedColumn(obj, columnsConfig[10].columnName, this.tableData[i].out_of_service ? "OOS" : "Driving" , columnsConfig[10].selected);
        this.transformService.selectedColumn(obj, columnsConfig[11].columnName, this.tableData[i].state, columnsConfig[11].selected);
        this.transformService.selectedColumn(obj, columnsConfig[12].columnName, this.tableData[i].original ? 'Yes' : 'No', columnsConfig[12].selected);
        this.transformService.selectedColumn(obj, columnsConfig[13].columnName, this.tableData[i].sent ? 'Yes' : 'No', columnsConfig[13].selected);
        this.transformService.selectedColumn(obj, columnsConfig[14].columnName, this.tableData[i].city, columnsConfig[14].selected);
        this.transformService.selectedColumn(obj, columnsConfig[15].columnName, `${this.tableData[i].related[0].related}...`, columnsConfig[15].selected);
        excelTable.push(obj);
      };
      let footerTotalObj: any = {};
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[0].columnName, this.metaObj.countObj.numOfAllInspections, columnsConfig[0].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[1].columnName, '', columnsConfig[1].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[2].columnName, '', columnsConfig[2].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[3].columnName, '', columnsConfig[3].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[4].columnName, '', columnsConfig[4].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[5].columnName, '', columnsConfig[5].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[6].columnName, `Sum: ${this.transformService.addCommasDots(this.metaObj.countObj.sumCharge, "round")}`, columnsConfig[6].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[7].columnName, `Sum: ${this.transformService.addCommasDots(this.metaObj.countObj.sumRewards, "round")}`, columnsConfig[7].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[8].columnName, `Sum: ${this.transformService.addCommasDots(this.metaObj.countObj.sumPoints)}`, columnsConfig[8].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[9].columnName, '', columnsConfig[9].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[10].columnName, '' , columnsConfig[10].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[11].columnName, '', columnsConfig[11].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[12].columnName, '', columnsConfig[12].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[13].columnName, '', columnsConfig[13].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[14].columnName, '', columnsConfig[14].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[15].columnName, '', columnsConfig[15].selected);
      let footerAvgObj: any = {};
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[0].columnName, '', columnsConfig[0].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[1].columnName, '', columnsConfig[1].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[2].columnName, '', columnsConfig[2].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[3].columnName, '', columnsConfig[3].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[4].columnName, '', columnsConfig[4].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[5].columnName, '', columnsConfig[5].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[6].columnName, `Avg: ${this.transformService.addCommasDots(this.metaObj.countObj.avgCharge, "round")}`, columnsConfig[6].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[7].columnName, `Avg: ${this.transformService.addCommasDots(this.metaObj.countObj.avgRewards, "round")}`, columnsConfig[7].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[8].columnName, `Avg: ${this.transformService.addCommasDots(this.metaObj.countObj.avgPoints)}`, columnsConfig[8].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[9].columnName, '', columnsConfig[9].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[10].columnName, '' , columnsConfig[10].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[11].columnName, '', columnsConfig[11].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[12].columnName, '', columnsConfig[12].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[13].columnName, '', columnsConfig[13].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[14].columnName, '', columnsConfig[14].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[15].columnName, '', columnsConfig[15].selected);
      excelTable.push(footerTotalObj, footerAvgObj);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'inspection-list.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  resetData() {
    this.tableData = [];
    this.dataSource = [];
    this.metaObj.countObj = {
      numOfAllInspections: 0,
      numOfAllInspectionsPercent: 0,
      numOfPassedInspections: 0,
      numOfPassedInspectionsPercent: 0,
      numOfFailedInspections: 0,
      numOfFailedInspectionsPercent: 0,
      allInspectionsLevelI: 0,
      allInspectionsLevelIPercent: 0,
      allInspectionsLevelII: 0,
      allInspectionsLevelIIPercent: 0,
      allInspectionsLevelIII: 0,
      allInspectionsLevelIIIPercent: 0,
      passedInspectionsLevelI: 0,
      passedInspectionsLevelIPercent: 0,
      passedInspectionsLevelII: 0,
      passedInspectionsLevelIIPercent: 0,
      passedInspectionsLevelIII: 0,
      passedInspectionsLevelIIIPercent: 0,
      failedInspectionsLevelI: 0,
      failedInspectionsLevelIPercent: 0,
      failedInspectionsLevelII: 0,
      failedInspectionsLevelIIPercent: 0,
      failedInspectionsLevelIII: 0,
      failedInspectionsLevelIIIPercent: 0,
      sumCharge: 0,
      sumRewards: 0,
      sumPoints: 0,
      avgCharge: 0,
      avgRewards: 0,
      avgPoints: 0
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription?.unsubscribe();
  };

}
