import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StartEndWeekDatePipe } from '@app/modules/dispatch-reward-system/pipes/start-end-week-date.pipe';

@Component({
  selector: 'filter-dropdown-multiple-select',
  templateUrl: './filter-dropdown-multiple-select.component.html',
  styleUrls: ['./filter-dropdown-multiple-select.component.scss']
})
export class FilterDropdownMultipleSelectComponent {
  @Input() config: any = {
    array: [],
    selectedValuesArray:[],
    selectAllValue: '',
    defaultValue: '',
    key: '',
    search: false
  };
  @Input() datePipe: boolean = false;
  @Output() triggerEvent = new EventEmitter<string>();

  isOpenFilterDropdown: boolean = false;
  dropdownSearch: string = '';

  constructor(private titleCase: TitleCasePipe, private startEndWeekPipe: StartEndWeekDatePipe) { }

  //Select all
  selectUnselectAll() {
    if(this.config.selectedValuesArray.length >= this.config.array.length) {
      this.config.selectedValuesArray = [];
    }
    else {
      for(let i = 0; i < this.config.array.length; i++) {
        if(this.config.selectedValuesArray.includes(this.config.array[i][this.config.key])) continue;
        this.config.selectedValuesArray.push(this.config.array[i][this.config.key]);
      }
    }
    this.triggerEvent.emit(this.config.selectedValuesArray);
  };

  //Check uncheck value
  checkUncheckValue(value: string) {
    let index = this.config.selectedValuesArray.indexOf(value);
    if (index === -1) {
      this.config.selectedValuesArray.push(value);
    } 
    else {
      this.config.selectedValuesArray.splice(index, 1);
    }
    this.triggerEvent.emit(this.config.selectedValuesArray);
  };

  get getSelectedValues() {
    if(this.config.selectedValuesArray.length === 0) {
      return this.config.defaultValue;
    }
    else if(this.config.selectedValuesArray.length === this.config.array.length) {
      return this.config.selectAllValue;
    }
    else if(this.datePipe) {
      return this.startEndWeekPipe.transform(this.config.selectedValuesArray.join(', '));
    }
    else {
      return this.titleCase.transform(this.config.selectedValuesArray.join(', '));
    }
  };
  
}
