import { Component, OnDestroy, OnInit } from '@angular/core';
import moment = require('moment');
import { Subscription } from 'rxjs';

import { DeleteRoleDialogComponent } from './delete-role-dialog/delete-role-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RoleHistoryComponent } from './role-history/role-history.component';
import { SettingsService } from '@app/modules/settings/services/settings.service';
import { permissionsData } from '@app/modules/core/permissions-data/permissions.data';
import { TransformService } from '@app/modules/shared/services/transform.service';

@Component({
  selector: 'app-roles-dialog',
  templateUrl: './roles-dialog.component.html',
  styleUrls: ['./roles-dialog.component.scss']
})
export class RolesDialogComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['position', 'role_name', 'date', 'actions'];
  dataSource: any = [];

  roleCreated: boolean | undefined = undefined;
  editPermission: boolean = false;
  addPermission: boolean = false;

  roleName: string = '';
  valuesArray: any[] = [
    {viewValue: 'Quick Overview', route: 'admin/overview'},
    {viewValue: 'Live dispatch board', route: 'admin/dispatchers/dashboard-dispatch-live-board'},
    {viewValue: 'Dispatch profile', route: 'admin/dispatchers/dashboard-dispatch-profile-page/'},
    {viewValue: 'Dispatch performances', route: 'admin/time-series/time-series-disp'},
    {viewValue: 'Trucks without drivers', route: 'admin/trucks/truck-nodriver'},
    {viewValue: 'Lost loads', route: 'admin/dispatchers/not-covered-trucks'},
    {viewValue: 'Planning', route: 'admin/dispatchers/prebooked-loads'},
    {viewValue: 'Brokers', route: 'admin/dispatchers/dashboard-brokers-page'},
    {viewValue: 'Safety', route: 'admin/analytics/safety-page'},
    {viewValue: 'Maintenance', route: 'admin/maintenance/maintenance-page'},
    {viewValue: 'Truck performances', route: 'admin/trucks/trucks-performance'},
    {viewValue: 'Drivers performances', route: 'admin/drivers/drivers-performance'},
  ];

  permissionArray: any[] = [];
  editedObj: any = {};

  subscription: Subscription | any;

  isOpen: boolean = false;
  selectedPage: string = 'Quick Overview';
  route: string = 'admin/overview';

  constructor(private transformService: TransformService,
              private settingsService: SettingsService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getAllRoles();
  }

  getAllRoles() {
    this.subscription = this.settingsService.getAllRoles().subscribe((response: any) => {
      console.log(response[0].value);
      console.log(response)
      this.dataSource = response;
    });
  }

  //Select page
  selectValue(obj: any) {
    this.selectedPage = obj.viewValue;
    this.route = obj.route;
    this.isOpen = false;
  };

  //Select all slider in section
  setAll(obj: any) {
    let allowed: boolean = obj.allowedAll;
    if (obj.sectionArray == null) {
      return;
    }
    obj.sectionArray.forEach(t => (t.allowed = allowed));
  };

  //Select all slider in page
  setEntryPage(obj: any) {
    if (obj.data == null) {
      return;
    }
    obj.data.forEach((allSection: any) => {
      allSection.allowedAll = obj.forbidden;

      allSection.sectionArray.forEach((section: any) => {
        section.allowed = obj.forbidden;
      })

    })
  };

  //Create role
  setPermission() {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let obj: any = {
      id: 0,
      created_by: loggedUser.first_name + ' ' + loggedUser.last_name, 
      created_date: this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss')),
      role_name: this.roleName,
      value:  JSON.stringify({route: this.route, page: this.selectedPage, rules: this.permissionArray}),
      edited_by: '',
      edited_date:  ''
    };

    if(this.editPermission) {
      obj.id = this.editedObj.id;
      obj.created_by = this.editedObj.created_by; 
      obj.created_date = this.editedObj.created_date;
      obj.edited_by = loggedUser.first_name + ' ' + loggedUser.last_name;
      obj.edited_date = this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss'))
    }
    
    this.settingsService.createUpdateRole(obj).subscribe((response: any) => {
      console.log(response);
      if(response.is_ok) {
        this.roleCreated = true;
        this.getAllRoles();
      }  
      else {
        this.roleCreated = false;
      }
    })
  };

  //Create, Edit, Delete role
  createNewRole() {
    this.roleCreated = undefined;
    this.roleName = '';
    this.selectedPage = 'Quick Overview';
    this.route = 'admin/overview';
    this.permissionArray = JSON.parse(JSON.stringify(permissionsData));
    this.addPermission = true;
  };

  editRolePermission(obj: any) {
    this.roleCreated = undefined;
    let data: any = JSON.parse(obj.value);
    this.roleName = obj.role_name;
    this.route = data.route;
    this.selectedPage = data.page;
    this.permissionArray = data.rules;
    this.editedObj = obj;
    this.editPermission = !this.editPermission;
  };

  deleteRole(id: number) {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let userName: string = loggedUser.first_name + ' ' + loggedUser.last_name;
    let date: string = this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss'));
    let dialogRef: any = this.dialog.open(DeleteRoleDialogComponent, {
      width: '300px',
      height: '300px',
      autoFocus: false,
      panelClass: 'delete-role-confirmation-dialog-container'
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.settingsService.deleteRole(id, userName, date).subscribe((response: any) => {
          if(response) {
            this.getAllRoles();
          }
        })
      }
    });

  }

  //Role history
  roleHistory() {
    this.dialog.open(RoleHistoryComponent, {
      maxWidth: '1000px',
      autoFocus: false,
      panelClass: 'role-history-dialog-container'
    })
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}