import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeeRoutingModule } from './employee.routing';
import { SharedModule } from '../shared/shared.module';
import { EmployeeActivityTableComponent } from './components/employee-activity-table/employee-activity-table.component';
import { EmployeeActivityComponent } from './components/employee-activity.component';
import { BasicInfoComponent } from './components/employee-data-dialog/basic-info/basic-info.component';
import { EmployeeCitiesComponent } from './components/employee-data-dialog/basic-info/employee-cities/employee-cities.component';
import { EmployeeDepartmentsComponent } from './components/employee-data-dialog/basic-info/employee-departments/employee-departments.component';
import { EmployeePositionsComponent } from './components/employee-data-dialog/basic-info/employee-positions/employee-positions.component';
import { EmployeeAccountComponent } from './components/employee-data-dialog/employee-account/employee-account.component';
import { EmployeeActivityStatsComponent } from './components/employee-data-dialog/employee-activity-stats/employee-activity-stats.component';
import { EmployeeDataDialogComponent } from './components/employee-data-dialog/employee-data-dialog.component';
import { EmployeeDocumentationComponent } from './components/employee-data-dialog/employee-documentation/employee-documentation.component';
import { EmployeeNoteCreateUpdateDialogComponent } from './components/employee-data-dialog/employee-notes/employee-note-create-update-dialog/employee-note-create-update-dialog.component';
import { EmployeeNotesComponent } from './components/employee-data-dialog/employee-notes/employee-notes.component';
import { DayPipe } from './pipes/day.pipe';
import { EmployeeFilterPipe } from './pipes/employee-filter.pipe';
import { EmployeeService } from './services/employee.service';


@NgModule({
  declarations: [
    EmployeeActivityComponent,
    EmployeeDataDialogComponent,
    EmployeeNotesComponent,
    EmployeeNoteCreateUpdateDialogComponent,
    EmployeeDocumentationComponent,
    EmployeeActivityStatsComponent,
    EmployeeAccountComponent,
    BasicInfoComponent,
    EmployeeCitiesComponent,
    EmployeeDepartmentsComponent,
    EmployeePositionsComponent,
    EmployeeActivityTableComponent,
    DayPipe,
    EmployeeFilterPipe
  ],
  imports: [
    CommonModule,
    EmployeeRoutingModule,
    SharedModule
  ],
  exports: [
    EmployeeFilterPipe
  ],
  providers: [EmployeeService, EmployeeFilterPipe]
})
export class EmployeeModule { }
