import { AfterContentChecked, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { TriggerDialogComponent } from './trigger-dialog/trigger-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AssignTaskDialogComponent } from './assign-task-dialog/assign-task-dialog.component';
import { TriggerTripleClickDialogComponent } from './trigger-triple-click-dialog/trigger-triple-click-dialog.component';
import { Subscription, catchError, throwError } from 'rxjs';
import { TasksService } from '../../services/tasks.service';
import { ITask } from '../../models/task-model';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import moment = require('moment');
import { ChangeDetectorRef } from '@angular/core';
import { TriggerDoubleClickDialogComponent } from './trigger-double-click-dialog/trigger-double-click-dialog.component';
import { TaskNoteDialogComponent } from '@app/modules/shared/components/task-note-dialog/task-note-dialog.component';
import { RelatedToDialogComponent } from './related-to-dialog/related-to-dialog.component';
import { DateRange } from '@angular/material/datepicker';
import * as XLSX from 'xlsx';
import { TitleCasePipe } from '@angular/common';
import { TasksPipe } from '../../pipes/tasks.pipe';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';

@Component({
  selector: 'app-tasks-page',
  templateUrl: './tasks-page.component.html',
  styleUrls: ['./tasks-page.component.scss']
})
export class TasksPageComponent implements OnInit, AfterContentChecked, OnDestroy {
  //Department menu
  departmentArray: any[] = [];
  isOpenDepartmentMenu: boolean = false;

  //Created by menu
  isOpenCreatedByMenu: boolean = false;

  //Related to menu
  relatedToArray: any[] = [];
  isOpenRelatedToMenu: boolean = false;
  relatedToSearch: string = '';

  //Search
  creatorSearch: string = '';
  assignedSearch: string = '';
  driverSearch: string = '';
  truckSearch: string = '';
  trailerSearch: string = '';

  //Filters
  filterArray: any[] = [
    {name: 'Pending', icon: 'assignment', key: 'pending', bgColor: 'bg-orange', activeFilter: 'active-orange-filter', inactiveFilter: 'inactive-orange-filter'},
    {name: 'Overdue', icon: 'update', key: 'overdue', bgColor: 'bg-red', activeFilter: 'active-red-filter', inactiveFilter: 'inactive-red-filter'},
    {name: 'Done', icon: 'done', key: 'done', bgColor: 'bg-green', activeFilter: 'active-green-filter', inactiveFilter: 'inactive-green-filter'}
  ]

  activeFilterIndex: number = 0;

  statusArray: string[] = ['Pending', 'Overdue', 'Done'];

  //Metadata
  filterMetaData: any = {
    countObj: {
      pending: 0,
      overdue: 0,
      done: 0
    }
  };

  //Data source
  dataSource: ITask[] = [];

  //Filters obj
  filterObj: any = {
    departmentValue: [],
    createdByValue: [],
    relatedToValue: [],
    assignedToValue: [],
    driverValue: [],
    truckValue: [],
    trailerValue: [],
    startDate: '',
    endDate: ''
  };

  //Filters
  department: string[] = [];
  createdBy: string[] = [];
  relatedTo: string[] = [];
  assignedTo: string[] = [];
  driver: string[] = [];
  truck: string[] = [];
  trailer: string[] = [];
  startDate: string = '';
  endDate: string = '';

  //Dropdown menu
  isOpenAssignedMenu: boolean = false;
  isOpenDriverMenu: boolean = false;
  isOpenTruckMenu: boolean = false;
  isOpenTrailerMenu: boolean = false;

  //Calendar
  @Input() selectedRangeValue: DateRange<Date> | any;
  @Output() selectedRangeValueChange = new EventEmitter<DateRange<Date>>();
  isOpenCalendar: boolean = false;

  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));

  //Excel config
  xlsxConfig: any = [
    {columnName: 'No.', selected: true},
    {columnName: 'Department', selected: true},
    {columnName: 'Created by', selected: true},
    {columnName: 'Reason', selected: true},
    {columnName: 'Assigned to', selected: true},
    {columnName: 'Driver', selected: true},
    {columnName: 'Truck', selected: true},
    {columnName: 'Trailer', selected: true},
    {columnName: 'Task created', selected: true},
    {columnName: 'Due date', selected: true},
    {columnName: 'Status', selected: true}
  ];

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();
  subscription3: Subscription = new Subscription();
  subscription4: Subscription = new Subscription();

  constructor(private dialog: MatDialog, 
              private transformService: TransformService,
              private tasksService: TasksService,
              private sharedService: SharedService,
              private spinner: NgxSpinnerService,
              private cdref: ChangeDetectorRef,
              private titleCase: TitleCasePipe,
              private taskPipe: TasksPipe,
              private rulesService: RulesService) { }

  ngOnInit(): void {
    this.getTableData();  
    this.getAllDepartments();
    this.getAllRelatedTo();
    this.refreshRelatedTo();
  };

  run() {
    let filterData: any = JSON.parse(JSON.stringify(this.filterObj));
    this.department = filterData.departmentValue;
    this.createdBy = filterData.createdByValue;
    this.relatedTo = filterData.relatedToValue;
    this.assignedTo = filterData.assignedToValue;
    this.driver = filterData.driverValue;
    this.truck = filterData.truckValue;
    this.trailer = filterData.trailerValue;
    this.startDate = filterData.startDate;
    this.endDate = filterData.endDate;
  };

  reset() {
    this.filterObj.departmentValue = [];
    this.filterObj.createdByValue = [];
    this.filterObj.relatedToValue = [];
    this.filterObj.assignedToValue = [];
    this.filterObj.driverValue = [];
    this.filterObj.truckValue = [];
    this.filterObj.trailerValue = [];
    this.filterObj.startDate = '';
    this.filterObj.endDate = '';

    this.department = [];
    this.createdBy = [];
    this.relatedTo = [];
    this.assignedTo = [];
    this.driver = [];
    this.truck = [];
    this.trailer = [];
    this.startDate = '';
    this.endDate = '';
  };
 
  getTableData() {
    this.spinner.show('tasks-table');
    this.subscription1 = this.tasksService.getAllTasks()
    .pipe(catchError((err: any) => {
      this.spinner.hide('tasks-table');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: ITask[]) => {
      this.dataSource = response;
      this.spinner.hide('tasks-table');
      this.loaded = true;
      console.log(response)
    });
  };

  getAllDepartments() {
    this.subscription2 = this.sharedService.getAllEmployeeDepartments().subscribe((response: any) => {
      this.departmentArray = response;
    });
  };

  getAllRelatedTo() {
    this.subscription3 = this.tasksService.getRelatedToData().subscribe((response: any) => {
      this.relatedToArray = response;
    })
  };

  refreshRelatedTo() {
    this.subscription4 = this.tasksService.refreshData.subscribe((response: any) => {
      this.relatedToArray = response;
    });
  };

  openRelatedToDialog() {
    let dialogRef: any = this.dialog.open(RelatedToDialogComponent, {
      disableClose: true,
      autoFocus: false,
      panelClass: 'related-to-dialog-main-container'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.relatedToArray = result;
      }
    })
  }

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dataSource));
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'department':
          return compare(a.department, b.department, isAsc);
        case 'created_by':
          return compare(a.created_by, b.created_by, isAsc);
        case 'task':
          return compare(a.task, b.task, isAsc);
        case 'assigned_to':
          return compare(a.assigned_to, b.assigned_to, isAsc);
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'truck_no':
          return compare(a.truck_no, b.truck_no, isAsc);
        case 'trailer_no':
          return compare(a.trailer_no, b.trailer_no, isAsc);
        case 'created_date':
          return compare(new Date(a.created_date), new Date(b.created_date), isAsc);
        case 'due_date':
          return compare(new Date(a.due_date), new Date(b.due_date), isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'num_of_notes':
          return compare(a.num_of_notes, b.num_of_notes, isAsc);
        default:
          return 0;
      }
    });
  }

  openTriggerDialog(element: ITask) {
    if(element.triggered === null) {
      this.openTriggerOneClickDialog(element);
    }
    else if(this.getCurrentDate >= moment(element.triggered).add(1, 'days').format('YYYY-MM-DDT14:00:00')) {
      this.openTriggerTripleClickDialog(element)
    }
    else {
      this.openTriggerDoubleClickDialog(element.triggered);
    }
  }

  openTriggerOneClickDialog(element: ITask) {
    let dialogRef = this.dialog.open(TriggerDialogComponent, {
      autoFocus: false,
      panelClass: 'trigger-dialog-container',
      data: element
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result)  {
        let timestamp: string = this.getCurrentDate; 
        this.tasksService.trigger(element.id, timestamp).subscribe((response: any) => {
          if(response) {
            element.triggered = timestamp;
            this.sendAlert(2, [element])
          }
        });
      }
    })
  };

  openTriggerDoubleClickDialog(date: string) {
    this.dialog.open(TriggerDoubleClickDialogComponent, {
      autoFocus: false,
      panelClass: 'trigger-double-click-dialog-container',
      data: date
    });
  };

  openTriggerTripleClickDialog(element: ITask) {
    let dialogRef: any = this.dialog.open(TriggerTripleClickDialogComponent, {
      autoFocus: false,
      panelClass: 'trigger-triple-click-dialog-container'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result)  {
        let timestamp: string = this.getCurrentDate; 
        this.tasksService.trigger(element.id, timestamp).subscribe((response: any) => {
          if(response) {
            element.triggered = timestamp;
            let notificationType: number = response.alertManager ? 3 : 2;
            this.sendAlert(notificationType, [element])
          }
        });
      }
    })
  }

  assignTaskDialog(obj: any) {
    let dialogRef = this.dialog.open(AssignTaskDialogComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: 'assign-task-dialog-container',
      data: obj
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      let index: number = this.getIndex(obj.data.id);
      if(result?.length > 0)  {
        if(obj.editMode && (obj.data.dispetcher_id !== result[0].dispetcher_id)) {
          this.sendAlert(1, result);
          this.dataSource[index] = result[0];
        }
        else if(obj.editMode) {
          this.dataSource[index] = result[0];
        }
        else {
          this.sendAlert(1, result);
          this.dataSource = this.dataSource.concat(result);
        }
        this.dataSource = [...this.dataSource];
      }

      if(result === 'DELETE') {
        this.dataSource.splice(index, 1);
        this.dataSource = [...this.dataSource];
      }
    })
  }

  openTaskNoteDialog(element: ITask) {
    let dialogRef: any = this.dialog.open(TaskNoteDialogComponent, {
      disableClose: true,
      autoFocus: false,
      panelClass: 'task-note-dialog-container',
      data: {element: element, noteRequired: false}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      element.num_of_notes = result;
    })
  };

  checkDueDate(dueDate: string) {
    let date1 = new Date(moment().format('YYYY-MM-DDT00:00:00'));
    let date2 = new Date(dueDate);
    let Difference_In_Time = date2.getTime() - date1.getTime();
    let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
    if(Difference_In_Days < 1) {
      return true;
    }
    return false;
  };

  changeTaskStatus(obj: any, status: number) {
    const fullName: string = `${this.loggedUser.first_name} ${this.loggedUser.last_name}`;
    const nickname: string = `${this.loggedUser.nickname}`;
    const creator: string = nickname.length === 0 ? fullName : nickname?.trim().split(' ')[0];
    let element: any = {...obj};
    element.edited_by = creator;
    element.edited_date = moment().format('YYYY-MM-DDTHH:mm:ss');
    element.status = status;
    console.log(element);
    this.tasksService.createUpdateTask([element]).subscribe((array: any) => {
      if(array.length > 0) {
        let response: any = array[0];
        obj.edited_by = response.edited_by;
        obj.edited_date = response.edited_date;
        obj.status = response.status;
      }
    });
  };

  dateRangeClicked(m: any) {
    if (!this.selectedRangeValue?.start || this.selectedRangeValue?.end) {
      this.selectedRangeValue = new DateRange<Date>(m, null);
    } 
    else {
      const start = this.selectedRangeValue.start;
      const end = m;
      if (end < start) {
        this.selectedRangeValue = new DateRange<Date>(end, start);
      } 
      else {
        this.selectedRangeValue = new DateRange<Date>(start, end);
      }
    }
    this.selectedRangeValueChange.emit(this.selectedRangeValue);
    if(this.selectedRangeValue.start && this.selectedRangeValue.end) {
      this.filterObj.startDate = moment(this.selectedRangeValue.start._d).format('YYYY-MM-DD');
      this.filterObj.endDate = moment(this.selectedRangeValue.end._d).format('YYYY-MM-DD');
      this.isOpenCalendar = false;
    }
  }

  //Select unselect all
  selectUnselectAll(filterKey: string, array: any[], key: string) {
    if(this.filterObj[filterKey].length > 0) {
      this.filterObj[filterKey] = [];
    }
    else {
      for(let i = 0; i < array.length; i++) {
        if(!this.filterObj[filterKey].includes(array[i][key])) {
          this.filterObj[filterKey].push(array[i][key]);
        }
      };
    }
  };

  //Check uncheck value
  checkUncheckValue(key: string, value: string) {
    let index = this.filterObj[key].indexOf(value);
    if (index === -1) {
      this.filterObj[key].push(value);
    } 
    else {
      this.filterObj[key].splice(index, 1);
    }
  };

  sendAlert(alertType: number, notificationArray: any[]) {
    this.tasksService.sendTaskAlert(alertType, notificationArray, this.getCurrentDate).subscribe((response: any) => {
      console.log(response);
    });
  };

  getIndex(id: number) {
    for(let i = 0; i < this.dataSource.length; i++) {
      if(this.dataSource[i].id === id) {
        return i;
      };
    };
  };

  get getCurrentDate(): string {
    let date = new Date();
    let europeDateTime: any = date.toLocaleString("de-DE", {hour12: false, timeZone: "Europe/Berlin"}).split(',');
    let format: string = europeDateTime[0].split('.')
    let day: string = +format[0] < 10 ? `0${format[0]}` : format[0];
    let month: string = +format[1] < 10 ? `0${format[1]}` : format[1];
    let year: string = format[2];
    return `${year}-${month}-${day}T${europeDateTime[1].trim()}`;
  }

  getTriggerColor(element: ITask) {
    if(element.triggered) {
      return this.getCurrentDate >= moment(element.triggered).add(1, 'days').format('YYYY-MM-DDT14:00:00');
    }
    return true;
  };

  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[42].allowed) {
      const tableData: any[] = this.taskPipe.transform(this.dataSource, this.activeFilterIndex, 
      [this.department, this.createdBy, this.relatedTo, this.assignedTo, this.driver, this.truck, this.trailer, this.startDate, 
      this.endDate], this.filterMetaData);
      const excelTable: any[] = [];
      for(let i = 0; i < tableData.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, i + 1, columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, tableData[i].department, columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, this.titleCase.transform(tableData[i].created_by), columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, tableData[i].task, columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, this.titleCase.transform(tableData[i].assigned_to), columnsConfig[4].selected);
        this.transformService.selectedColumn(obj, columnsConfig[5].columnName, this.titleCase.transform(tableData[i].driver), columnsConfig[5].selected);
        this.transformService.selectedColumn(obj, columnsConfig[6].columnName, tableData[i].truck_no, columnsConfig[6].selected);
        this.transformService.selectedColumn(obj, columnsConfig[7].columnName, tableData[i].trailer_no, columnsConfig[7].selected);
        this.transformService.selectedColumn(obj, columnsConfig[8].columnName, this.transformService.transformDateFormat(tableData[i].created_date, 'MMM DD, YYYY.'), columnsConfig[8].selected);
        this.transformService.selectedColumn(obj, columnsConfig[9].columnName, this.transformService.transformDateFormat(tableData[i].due_date, 'MMM DD, YYYY.'), columnsConfig[9].selected);
        this.transformService.selectedColumn(obj, columnsConfig[10].columnName, this.statusArray[tableData[i].status], columnsConfig[10].selected);
        excelTable.push(obj);
      };
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'tasks.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  transformDateFormat(date: any, format: string) {
    if(date) {
      return moment(date).format(format);
    }
    return '';
  };

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
    this.subscription4?.unsubscribe();
  };

  identify(index, item){
    return item.id; 
  };

}

function compare(a: number | any, b: number | any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}