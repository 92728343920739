import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PaymentsService } from '@app/modules/accounting/services/payments.service';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { RelatedToDialogComponent } from '@app/modules/tasks/components/tasks-page/related-to-dialog/related-to-dialog.component';
import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-payment-type-dialog',
  templateUrl: './payment-type-dialog.component.html',
  styleUrls: ['./payment-type-dialog.component.scss']
})
export class PaymentTypeDialogComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[37].data;
  //Form group
  formGroup: FormGroup;

  dataSource: any = [];
  searchValue: string = '';

  //Category menu
  isOpenCategoryMenu: boolean = false;
  categoryArray: any[] = [
    {category: 'Repair'}, {category: 'Fuel'}, {category: 'Miscellaneous'}, {category: 'Pre-employment'}
  ];

  //Filter array
  filterArray: string[] = ['Repair', 'Fuel', 'Miscellaneous', 'Pre-employment'];
  activeFilter: string = '';

  //Edit index
  editedRowIndex: number | null = null;

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private dialog: MatDialog,
              private transformService: TransformService,
              private paymentsService: PaymentsService,
              private formBuilder: FormBuilder,
              private rulesService: RulesService,
              public dialogRef: MatDialogRef<RelatedToDialogComponent>,
              private spinner: NgxSpinnerService) {
                this.formGroup = this.formBuilder.nonNullable.group({
                  'id': [0],
                  'category': ['', [Validators.required, Validators.minLength(2)]],
                  'task': ['', [Validators.required, Validators.minLength(2)]],
                  'is_active': [true],
                  'created_by': [''],
                  'created_date': [null],
                  'edited_by': [''],
                  'edited_date': [null]
                })
              }

  ngOnInit(): void {
    this.getAllReasons();
  }

  getAllReasons() {
    this.spinner.show('payment-type-table');
    this.subscription = this.paymentsService.getPaymentReasonsData()
    .pipe(catchError((err: any) => {
      this.spinner.hide('payment-type-table');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.dataSource = response;
      this.spinner.hide('payment-type-table');
      this.loaded = true;
      console.log(response)
    })
  };

  addNewReason() {
    if(this.permissions[1].sectionArray[0].allowed) {
      this.formGroup.reset();
      this.formGroup.patchValue({'category': this.activeFilter !== 'All Categories' ? this.activeFilter : ''});
      this.dataSource.unshift(this.formGroup.value);
      this.dataSource = [...this.dataSource]
      this.editedRowIndex = 0;
    }
    else {
      this.showForbiddenMessage();
    }
  };

  saveUpdateReason(element: any) {
    const loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    const fullName: string = `${loggedUser.first_name} ${loggedUser.last_name}`;
    const nickname: string = `${loggedUser.nickname}`;
    const creator: string = nickname.length === 0 ? fullName : nickname?.trim().split(' ')[0];
    if(element.id) {
      this.formGroup.patchValue({
        'edited_by': creator,
        'edited_date': moment().format('YYYY-MM-DDTHH:mm:ss')
      });
    }
    else {
      this.formGroup.patchValue({
        'created_by': creator,
        'created_date': moment().format('YYYY-MM-DDTHH:mm:ss')
      });
    }
    this.paymentsService.createUpdatePaymentReason(this.formGroup.value).subscribe((response: any) => {
      console.log(response);
      if(response?.id) {
        element.id = response.id
        element.category = response.category;
        element.task = response.task;
        element.is_active = response.is_active;
        element.created_by = response.created_by;
        element.created_date = response.created_date;
        element.edited_by = response.edited_by;
        element.edited_date = response.edited_date;
        this.formGroup.reset();
        this.editedRowIndex = null;
      }
    });
  };

  editReason(element: any, index: number) {
    if(this.permissions[1].sectionArray[1].allowed) {
      this.formGroup.setValue(element);
      this.editedRowIndex = index;
    }
    else {
      this.showForbiddenMessage();
    }
  };

  cancelReason(element: any, index: number) {
    if(element.id === 0) {
      this.dataSource.splice(index, 1);
      this.dataSource = [...this.dataSource];
    };
    this.editedRowIndex = null;
    this.formGroup.reset();
  }

  deleteReason(obj: any, index: number) {
    if(this.permissions[1].sectionArray[2].allowed) {
      let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
        autoFocus: false,
        panelClass: 'delete-dialog-container',
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        console.log(result)
        if(result) {
          let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
          let date: string = moment().format('YYYY-MM-DDTHH:mm:ss');
          const fullName: string = `${loggedUser.first_name} ${loggedUser.last_name}`;
          const nickname: string = `${loggedUser.nickname}`;
          const creator: string = nickname.length === 0 ? fullName : nickname?.trim().split(' ')[0];
          this.paymentsService.deletePaymentReason(obj.id, loggedUser.id, creator, date).subscribe((response: any) => {
            if(response) {
              this.dataSource.splice(index, 1);
              this.dataSource = [...this.dataSource];
            };
          });
        }
      })
    }
    else {
      this.showForbiddenMessage();
    }
  };

  //Show forbidden message
  showForbiddenMessage() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();  
  };

}
