import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NAService } from '@app/modules/notifications-alerts-settings/services/n-a.service';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import moment = require('moment');
import { Subscription, catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-create-update-n-a',
  templateUrl: './create-update-n-a.component.html',
  styleUrls: ['./create-update-n-a.component.scss']
})
export class CreateUpdateNAComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[60].data;
  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
  fullName: string = `${this.loggedUser.first_name} ${this.loggedUser.last_name}`;
  nickname: string = `${this.loggedUser.nickname}`;
  name: string = `${this.nickname.length === 0 ? this.fullName : this.nickname?.trim().split(' ')[0]}`;

  //Form group
  formGroup: any = this._formBuilder.nonNullable.group({
    id: [0],
    name: ['', [Validators.required, Validators.minLength(2)]],
    description: [''],
    tag: ['', [Validators.required, Validators.minLength(2)]],
    emails_to: ['[]', [Validators.required, Validators.minLength(3)]], 
    emails_cc: ['[]'],
    emails_bcc: ['[]'],
    created_date: [null],
    created_by: [this.name],
    update_date: [null],
    update_by: ['']
  });

  //Emails list
  emailArray: any[] = [];

  //To emails
  toEmailsArray: any[] = [];

  //CC emails
  ccEmailsArray: any[] = [];

  //BCC emails
  bccEmailsArray: any[] = [];

  //Subscription
  subscription: Subscription = new Subscription();

  //Multiple clicks
  multipleClicks: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any, 
              private _formBuilder: FormBuilder,
              private rulesService: RulesService,
              private notificationsAlertsSettingsService: NAService,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<CreateUpdateNAComponent>) { }

  ngOnInit(): void {
    if(this.obj.editMode) {
      this.formGroup.patchValue(this.obj.data);
      this.formGroup.patchValue({'update_by': this.name});
      this.toEmailsArray = this.formArray('emails_to');
      this.ccEmailsArray = this.formArray('emails_cc');
      this.bccEmailsArray = this.formArray('emails_bcc');
    }
    this.getAllEmails();
    console.log(this.obj)
  }

  getAllEmails() {
    this.subscription = this.notificationsAlertsSettingsService.getAllEmails().subscribe((responseObj: any) => {
      console.log(responseObj)
      let filteredArray: any[] = [];
      let allDepartments: any = {
        accounting: [],
        afterHours: [],
        dispatch: [],
        finance: [],
        hos: [],
        maintenance: [],
        marketing: [],
        recruiting: [],
        safety: []
      };

      for(let i = 0; i < responseObj.req1.length; i++) {
        filteredArray.unshift({id: 0, emailArray: [{id: 0, email: responseObj.req1[i].username, name: responseObj.req1[i].division_name}], name: responseObj.req1[i].division_name, class: 'orange-bg'});
      }

      for(let i = 0; i < responseObj.req2.length; i++) {

        if(!responseObj.req2[i].is_active) {

          for(let j = 0; j < responseObj.req2[i].companies.length; j++) {

            if(responseObj.req2[i].companies[j].is_primary) {

              if(responseObj.req2[i].department.trim() === 'Accounting') {
                allDepartments.accounting.push({id: responseObj.req2[i].settings_id, email: responseObj.req2[i].companies[j].email, name: `${responseObj.req2[i].nickname}`});
              }
              if(responseObj.req2[i].department.trim() === 'After Hours') {
                allDepartments.afterHours.push({id: responseObj.req2[i].settings_id, email: responseObj.req2[i].companies[j].email, name: `${responseObj.req2[i].nickname}`});
              }
              if(responseObj.req2[i].department.trim() === 'Dispatch') {
                allDepartments.dispatch.push({id: responseObj.req2[i].settings_id, email: responseObj.req2[i].companies[j].email, name: `${responseObj.req2[i].nickname}`});
              }
              if(responseObj.req2[i].department.trim() === 'Finance') {
                allDepartments.finance.push({id: responseObj.req2[i].settings_id, email: responseObj.req2[i].companies[j].email, name: `${responseObj.req2[i].nickname}`});
              }
              if(responseObj.req2[i].department.trim() === 'Hours of Service') {
                allDepartments.hos.push({id: responseObj.req2[i].settings_id, email: responseObj.req2[i].companies[j].email, name: `${responseObj.req2[i].nickname}`});
              }
              if(responseObj.req2[i].department.trim() === 'Maintenance') {
                allDepartments.maintenance.push({id: responseObj.req2[i].settings_id, email: responseObj.req2[i].companies[j].email, name: `${responseObj.req2[i].nickname}`});
              }
              if(responseObj.req2[i].department.trim() === 'Marketing') {
                allDepartments.marketing.push({id: responseObj.req2[i].settings_id, email: responseObj.req2[i].companies[j].email, name: `${responseObj.req2[i].nickname}`});
              }
              if(responseObj.req2[i].department.trim() === 'Recruiting') {
                allDepartments.recruiting.push({id: responseObj.req2[i].settings_id, email: responseObj.req2[i].companies[j].email, name: `${responseObj.req2[i].nickname}`});
              }
              if(responseObj.req2[i].department.trim() === 'Safety') {
                allDepartments.safety.push({id: responseObj.req2[i].settings_id, email: responseObj.req2[i].companies[j].email, name: `${responseObj.req2[i].nickname}`});
              }

              filteredArray.push({id: responseObj.req2[i].settings_id, emailArray: [{id: responseObj.req2[i].settings_id, email: responseObj.req2[i].companies[j].email, name: `${responseObj.req2[i].nickname}`}], name: `${responseObj.req2[i].nickname}`, class: 'red-bg'});
              break;
            }

          }

        }

      }
      
      filteredArray.unshift({id: 0, emailArray: allDepartments.safety, name: 'Safety', class: 'blue-bg'});
      filteredArray.unshift({id: 0, emailArray: allDepartments.recruiting, name: 'Recruiting', class: 'blue-bg'});
      filteredArray.unshift({id: 0, emailArray: allDepartments.marketing, name: 'Marketing', class: 'blue-bg'});
      filteredArray.unshift({id: 0, emailArray: allDepartments.maintenance, name: 'Maintenance', class: 'blue-bg'});
      filteredArray.unshift({id: 0, emailArray: allDepartments.hos, name: 'Hours of Service', class: 'blue-bg'});
      filteredArray.unshift({id: 0, emailArray: allDepartments.finance, name: 'Finance', class: 'blue-bg'});
      filteredArray.unshift({id: 0, emailArray: allDepartments.dispatch, name: 'Dispatch', class: 'blue-bg'});
      filteredArray.unshift({id: 0, emailArray: allDepartments.afterHours, name: 'After Hours', class: 'blue-bg'});
      filteredArray.unshift({id: 0, emailArray: allDepartments.accounting, name: 'Accounting', class: 'blue-bg'});
      this.emailArray = filteredArray;
    });
  };

  //Add email
  addEmail(emailsArray: any[], key: string, array: any[]) {
    for(let i = 0; i < emailsArray.length; i++) {
      array.push(emailsArray[i])
    };
    const emails: string = JSON.stringify(array);
    this.formGroup.patchValue({[key]: emails});
  };

  //Remove email
  removeEmail(key: string, index: number, array: any[]) {
    array.splice(index, 1);
    const emails: string = JSON.stringify(array);
    this.formGroup.patchValue({[key]: emails});
  };

  saveData() {
    this.formGroup.markAllAsTouched();
    if(this.formGroup.valid && this.multipleClicks) {
      this.multipleClicks = false;
      const formData: any = this.formGroup.value;
      if(formData.id) {
        formData.update_date = moment().format('YYYY-MM-DDTHH:mm:ss');
      }
      else {
        formData.created_date = moment().format('YYYY-MM-DDTHH:mm:ss');
      }
      this.notificationsAlertsSettingsService.changeNotificationSettings(formData)
      .pipe(catchError((err: any) => {
          this.showErrorMessage();
          this.multipleClicks = true;
          return throwError(() => err);
      }))
      .subscribe((response: any) => {
        console.log(response);
        if(response?.id) {
          this.dialogRef.close(response);
        }
        else {
          this.showErrorMessage();
        }
      }); 
    }
  };

  deleteNotificationAlert() {
    if(this.permissions[0].sectionArray[2].allowed) {
      let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
          autoFocus: false,
          panelClass: 'delete-dialog-container',
        });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          const formData: any = this.formGroup.value;
          this.notificationsAlertsSettingsService.deleteNotificationSettings(formData.id)
          .pipe(catchError((err: any) => {
            this.showErrorMessage();
            return throwError(() => err);
          }))
          .subscribe((response: boolean) => {
            if(response) {
              this.dialogRef.close('DELETE FROM TABLE');
            }
            else {
              this.showErrorMessage();
            }
          });
        }
      })
    }
    else {
      this.showForbiddenMesage();
    }
  }

  //Show error message
  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  //Show forbidden message
  showForbiddenMesage() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  }

  //Check validation
  checkValidation(key: string): boolean {
    return !this.formGroup.controls[key].valid && this.formGroup.controls[key]?.touched; 
  };

  //Get form value
  formData(formKey: string) {
    return this.formGroup.controls[formKey].value;
  };

  formArray(formKey: string): any[] {
    return JSON.parse(this.formGroup.controls[formKey].value);
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();  
  };

}
