import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';
import { NAService } from '../../services/n-a.service';
import { CreateUpdateNAComponent } from './create-update-n-a/create-update-n-a.component';
import { MailsSettingsComponent } from './mails-settings/mails-settings.component';

@Component({
  selector: 'app-n-a-page',
  templateUrl: './n-a-page.component.html',
  styleUrls: ['./n-a-page.component.scss']
})
export class NAPageComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[60].data;

  //Data source
  tableData: any[] = [];

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private transformService: TransformService,
              private spinner: NgxSpinnerService,
              private rulesService: RulesService,
              private dialog: MatDialog,
              private notificationsAlertsSettings: NAService) { }

  ngOnInit(): void {
    this.getTableData();
  };

  getTableData() {
    this.tableData = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('notifications-alerts');
    this.subscription = this.notificationsAlertsSettings.getAllNotifications()
    .pipe(catchError((err: any) => {
      this.spinner.hide('notifications-alerts');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response)
      this.tableData = response;
      this.spinner.hide('notifications-alerts');
      this.loaded = true;
    });
  };

  openNotificationsAlertsDialog() {
    if(this.permissions[0].sectionArray[0].allowed) {
      let dialogRef: any = this.dialog.open(CreateUpdateNAComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'component-dialog-container',
        data: {editMode: false, data: {}}
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.tableData.push(result);
          this.tableData = [...this.tableData];
        }
      })
    }
    else {
      this.showForbiddenMesage();
    }
  };

  openMailSettingsDivisionDialog() {
    this.dialog.open(MailsSettingsComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: 'component-dialog-container'
    })
  };

  //Show forbidden message
  showForbiddenMesage() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}
