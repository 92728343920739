import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'desc-field',
  templateUrl: './desc-field.component.html',
  styleUrls: ['./desc-field.component.scss']
})
export class DescFieldComponent {
  @Input() description: string = '';

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'start', originY: 'bottom'},
      {overlayX: 'start', overlayY: 'top'},
      0, 5
    ),
    new ConnectionPositionPair(
      { originX: 'start', originY: 'top' },
      { overlayX: 'start', overlayY: 'bottom' },
      0, -5
    )
  ];

  isOpenDescriptionDropdown: boolean = false;
}
