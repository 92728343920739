import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { AddEditAccidentsDialogComponent } from '../add-edit-accidents-dialog/add-edit-accidents-dialog.component';
import { IAccident } from '@app/modules/accidents/models/accident.model';
import { ClaimsDialogComponent } from '@app/modules/claims/components/claims-page/claims-dialog/claims-dialog.component';

@Component({
  selector: 'accidents-table',
  templateUrl: './accidents-table.component.html',
  styleUrls: ['./accidents-table.component.scss']
})
export class AccidentsTableComponent {
  permissions: any = this.rulesService.UserData[43].data;
  permissionsClaim: any = this.rulesService.UserData[57].data;
  @Input() dataSource: IAccident[] = [];
  @Output() triggerEvent = new EventEmitter<boolean>();

  //Filters
  filters: any = {
    drivers: [],
    loads: [],
    trucks: [],
    equipments: [],
    dateOccured: null,
    faultForIncident: [],
    claimNo: [],
    cities: [],
    states: [],
    zips: [],
    caseNo: [],
    citationNo: [],
    drugTestDate: null,
    alcoholTestDate: null,
    dotRecordable: 'Both',
    hazmat: 'Both',
    closed: 'Both',
  };

  constructor(private dialog: MatDialog, public transformService: TransformService, private rulesService: RulesService) { }

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dataSource));
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'load_no':
          return compare(a.load_no, b.load_no, isAsc);
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'equipment_no':
          return compare(a.equipment_no, b.equipment_no, isAsc);
        case 'date_occured':
          return compare(new Date(a.date_occured), new Date(b.date_occured), isAsc);
        case 'fault_for_incident':
          return compare(a.fault_for_incident, b.fault_for_incident, isAsc);
        case 'claim_number':
          return compare(a.claim_number, b.claim_number, isAsc);
        case 'location_city':
          return compare(a.location_city, b.location_city, isAsc);
        case 'location_state':
          return compare(a.location_state, b.location_state, isAsc);
        case 'location_zip':
          return compare(a.location_zip, b.location_zip, isAsc);
        case 'case_number':
          return compare(a.case_number, b.case_number, isAsc);
        case 'citation_number':
          return compare(a.citation_number, b.citation_number, isAsc);
        case 'drug_test_date':
          return compare(new Date(a.drug_test_date), new Date(b.drug_test_date), isAsc);
        case 'alcohol_test_date':
          return compare(new Date(a.alcohol_test_date), new Date(b.alcohol_test_date), isAsc);
        case 'is_dot_recordable':
          return compare(a.is_dot_recordable, b.is_dot_recordable, isAsc);
        case 'is_hazmat':
          return compare(a.is_hazmat, b.is_hazmat, isAsc);
        case 'is_closed':
          return compare(a.is_closed, b.is_closed, isAsc);
        default:
          return 0;
      }
    });
  }

  addEditAccident(isEdit: boolean, id: number | null) {
    if(this.permissions[0].sectionArray[1].allowed) {
      let dialogRef = this.dialog.open(AddEditAccidentsDialogComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'component-dialog-container',
        data: {editMode: isEdit, id: id}
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.triggerEvent.emit(true);
        }
      });
    }
    else {
      this.msgForbbidenAccess();
    }
  }

  openClaimsDialog(element: IAccident) {
    if(this.permissionsClaim[0].sectionArray[0].allowed) {
      this.dialog.open(ClaimsDialogComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'component-dialog-container',
        data: {claimId: 0, accident_id: element.id, initForm: element}
      });
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  selectFilter(propertyName: string, value: any) {
    this.filters[propertyName] = value;
    this.filters = {...this.filters};
  };

  clearFilters() {
    this.filters = {
      drivers: [],
      loads: [],
      trucks: [],
      equipments: [],
      dateOccured: null,
      faultForIncident: [],
      claimNo: [],
      cities: [],
      states: [],
      zips: [],
      caseNo: [],
      citationNo: [],
      drugTestDate: null,
      alcoholTestDate: null,
      dotRecordable: 'Both',
      hazmat: 'Both',
      closed: 'Both',
    };
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

}

function compare(a: number | any, b: number | any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
