import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/services/auth/auth.guard';
import { InspectionsPageComponent } from './components/inspections-page/inspections-page.component';

const routes: Routes = [
	{
		path: '',
		component: InspectionsPageComponent,
		canActivate: [AuthGuard],
		data: { roles: 23 }
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class InspectionsRoutingModule { }
