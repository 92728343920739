import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NAPageComponent } from './components/n-a-page/n-a-page.component';
import { SharedModule } from '../shared/shared.module';
import { NAService } from './services/n-a.service';
import { NoticationsAlertsRouting } from './notifications-alerts.routing';
import { NATableComponent } from './components/n-a-page/n-a-table/n-a-table.component';
import { DescFieldComponent } from './components/n-a-page/n-a-table/desc-field/desc-field.component';
import { ListOfEmailsComponent } from './components/n-a-page/n-a-table/list-of-emails/list-of-emails.component';
import { CreateUpdateNAComponent } from './components/n-a-page/create-update-n-a/create-update-n-a.component';
import { AutocompleteEmailsComponent } from './components/n-a-page/create-update-n-a/autocomplete-emails/autocomplete-emails.component';
import { MailsSettingsComponent } from './components/n-a-page/mails-settings/mails-settings.component';
import { MailsSettingsFormComponent } from './components/n-a-page/mails-settings/mails-settings-form/mails-settings-form.component';

@NgModule({
  declarations: [
    NAPageComponent,
    NATableComponent,
    DescFieldComponent,
    ListOfEmailsComponent,
    CreateUpdateNAComponent,
    AutocompleteEmailsComponent,
    MailsSettingsComponent,
    MailsSettingsFormComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NoticationsAlertsRouting
  ],
  providers: [NAService]
})
export class NotificationsAlertsSettingsModule { }
