import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'repairLog'
})
export class RepairLogPipe implements PipeTransform {

  transform(array: any[], filterObj: any): any[] {
    if (!array) {
      return [];
    }

    let transformedArray: any[] = [];
    for(let i = 0; i < array.length; i++) {
      let condition1: boolean = this.calendarFilter(filterObj.DateOfLogEntry, array[i].DateOfLogEntry);
      let condition2: boolean = filterObj.LoggedBy.length === 0 ? true : filterObj.LoggedBy.includes(array[i].LoggedBy);
      let condition3: boolean = filterObj.TruckNo.length === 0 ? true : filterObj.TruckNo.includes(array[i].TruckNo);
      let condition4: boolean = filterObj.TrailerNo.length === 0 ? true : filterObj.TrailerNo.includes(array[i].TrailerNo);
      let condition5: boolean = filterObj.LogID.length === 0 ? true : filterObj.LogID.includes(array[i].LogID);
      let condition6: boolean = filterObj.DivisionName.length === 0 ? true : filterObj.DivisionName.includes(array[i].DivisionName);
      let condition7: boolean = filterObj.DriverName.length === 0 ? true : filterObj.DriverName.includes(array[i].DriverName);
      let condition8: boolean = filterObj.Dispatcher.length === 0 ? true : filterObj.Dispatcher.includes(array[i].Dispatcher);
      let condition9: boolean = filterObj.ReasonForRepair.length === 0 ? true : filterObj.ReasonForRepair.includes(array[i].ReasonForRepair);
      let condition10: boolean = this.checkboxFilter(filterObj.DescriptionOfRepair, array[i].DescriptionOfRepair);
      let condition11: boolean = filterObj.LocationOfRepair.length === 0 ? true : filterObj.LocationOfRepair.includes(array[i].LocationOfRepair);
      let condition12: boolean = filterObj.Status.length === 0 ? true : filterObj.Status.includes(array[i].Status);
      let condition13: boolean = this.checkboxFilter(filterObj.notes, array[i].notes);

      if(condition1 && condition2 && condition3 &&  condition4 && condition5 && condition6 && condition7 && condition8 
      && condition9 && condition10 && condition11 &&  condition12 && condition13) {
        transformedArray.push(array[i]);
      }
    };
    return transformedArray;
  }

  calendarFilter(selectedDateRange: any, dateToCompare: string) {
    let condition: boolean = true;
    if(selectedDateRange) {
      const start = Date.parse(selectedDateRange.start._d);
      const end = Date.parse(selectedDateRange.end._d);
      const d = Date.parse(dateToCompare);
      condition = d.valueOf() >= start.valueOf() && d.valueOf() <= end.valueOf();
    }
    return condition;
  };

  checkboxFilter(filter: string, value: string) {
    let condition: boolean = true;
    if(filter === 'Both') {
      condition  = true;
    }
    else if(filter === 'With Desc' || filter === 'With Note') {
      condition = value.length > 0;
    }
    else {
      condition = value.length === 0;
    }
    return condition;
  };

}
