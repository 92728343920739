import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'driversResult'
})
export class DriversResultPipe implements PipeTransform {

  transform(array: any[], bonusAchievedArray: any, filterArray: any[], quickStatsObj: any): any[] {
    if (!array) {
      return [];
    }
    const targetDataSolo: any[] = bonusAchievedArray.targetDataSolo;
    const targetDataTeam: any[] = bonusAchievedArray.targetDataTeam;
    const typesObj: any = {'SOLO COMPANY': 'Company', 'TEAM COMPANY': 'Company', 'SOLO RENT' : 'Rent to run', 'TEAM RENT': 'Rent to run', 'OWNER OPERATOR': 'Owner'};
    const driverTypes: any = {'SOLO COMPANY': 'Solo', 'TEAM COMPANY': 'Team', 'SOLO RENT': 'Solo', 'TEAM RENT': 'Team', 'OWNER OPERATOR': 'Solo'};

    let countObj: any = {
      avgGross: 0,
      avgRpm: 0,
      avgMileage: 0
    };
    let sumGross: number = 0;
    let sumMileage: number = 0;

    let transformedArray: any = array.filter((obj: any) => {
      let condition1: boolean = filterArray[0].length === 0 ? true : filterArray[0].includes(obj.dispatcher?.split('/ ')[1]?.split(' ')[0]);
      let condition2: boolean = filterArray[1].length === 0 ? true : filterArray[1].includes(driverTypes[obj.status]);
      let condition3: boolean = filterArray[2].length === 0 ? true : filterArray[2].includes(typesObj[obj.status]);
      let condition4: boolean = filterArray[3].length === 0 ? true : filterArray[3].includes((obj.recruiter || obj.recruiter1))
      let condition5: boolean = !filterArray[4];
      let condition6: boolean = filterArray[5].length === 0 ? true : (filterArray[5].includes('Active drivers') && obj.active === true) || (filterArray[5].includes('Inactive drivers') && obj.active === false);
      let condition7: boolean = filterArray[6].length === 0 ? true : `${obj.first_name} ${obj.last_name}`?.toLocaleLowerCase().includes(filterArray[6].toLocaleLowerCase());

      //Condition 5
      if(filterArray[4] && driverTypes[obj.status] === 'Solo') {
        if(targetDataSolo.length === 2) {
          condition5 = obj.gross_perc >= 100 && obj.rpm_perc >= 100;
        }
        if(targetDataSolo.length === 1) {
          const conditionKeysObj: any = {'Gross': 'gross_perc', 'Rate per mile': 'rpm_perc'};
          condition5 = obj[conditionKeysObj[targetDataSolo[0].condition]] >= 100;
        }
      }
      
      if(filterArray[4] && driverTypes[obj.status] === 'Team') {
        if(targetDataTeam.length === 2) {
          condition5 = obj.gross_perc >= 100 && obj.rpm_perc >= 100;
        }
        if(targetDataTeam.length === 1) {
          const conditionKeysObj: any = {'Gross': 'gross_perc', 'Rate per mile': 'rpm_perc'};
          condition5 = obj[conditionKeysObj[targetDataTeam[0].condition]] >= 100;
        }
      }

      if(condition1 && condition2 && condition3 && condition4 && condition5 && condition6 && condition7) {
        //Counts statistics
        sumGross += obj.gross;
        sumMileage += obj.mileage;
        return obj;
      };
   
    });

    countObj.avgGross = sumGross / transformedArray.length;
    countObj.avgRpm = sumGross / sumMileage;
    countObj.avgMileage = sumMileage / transformedArray.length;
    quickStatsObj.countObj = countObj;
    return transformedArray;
  }

}
