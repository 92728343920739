import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClaimsPageComponent } from './components/claims-page/claims-page.component';
import { ClaimsTableComponent } from './components/claims-page/claims-table/claims-table.component';
import { ClaimsDialogComponent } from './components/claims-page/claims-dialog/claims-dialog.component';
import { ClaimTablePipe } from './pipes/claim-table.pipe';
import { ClaimTypePipe } from './pipes/claim-type.pipe';
import { ClaimsService } from './services/claims.service';
import { SharedModule } from '../shared/shared.module';
import { ClaimsRoutingModule } from './claims.routing';
import { ClaimsFilesTabComponent } from './components/claims-page/claims-dialog/claims-files-tab/claims-files-tab.component';
import { ContactsTableComponent } from './components/claims-page/claims-dialog/contacts-table/contacts-table.component';
import { FormBasicDetailsComponent } from './components/claims-page/claims-dialog/form-basic-details/form-basic-details.component';
import { FormCargoComponent } from './components/claims-page/claims-dialog/form-cargo/form-cargo.component';
import { FormClaimDetailsComponent } from './components/claims-page/claims-dialog/form-claim-details/form-claim-details.component';
import { FormClosedComponent } from './components/claims-page/claims-dialog/form-closed/form-closed.component';
import { FormCommentsComponent } from './components/claims-page/claims-dialog/form-comments/form-comments.component';
import { FormLiabilityPersonalInjuryComponent } from './components/claims-page/claims-dialog/form-liability-personal-injury/form-liability-personal-injury.component';
import { FormLiabilityPhysicalDamageComponent } from './components/claims-page/claims-dialog/form-liability-physical-damage/form-liability-physical-damage.component';
import { FormLiabilityPropertyDamageComponent } from './components/claims-page/claims-dialog/form-liability-property-damage/form-liability-property-damage.component';
import { FormOccAccComponent } from './components/claims-page/claims-dialog/form-occ-acc/form-occ-acc.component';
import { FormPhysicalDamageComponent } from './components/claims-page/claims-dialog/form-physical-damage/form-physical-damage.component';
import { UploadFilesComponent } from './components/claims-page/claims-dialog/upload-files/upload-files.component';

@NgModule({
  declarations: [
    ClaimsPageComponent,
    ClaimsTableComponent,
    ClaimsDialogComponent,
    ClaimTablePipe,
    ClaimTypePipe,
    ClaimsFilesTabComponent,
    ContactsTableComponent,
    FormBasicDetailsComponent,
    FormCargoComponent,
    FormClaimDetailsComponent,
    FormClosedComponent,
    FormCommentsComponent,
    FormLiabilityPersonalInjuryComponent,
    FormLiabilityPhysicalDamageComponent,
    FormLiabilityPropertyDamageComponent,
    FormOccAccComponent,
    FormPhysicalDamageComponent,
    UploadFilesComponent
  ],
  imports: [
    CommonModule,
    ClaimsRoutingModule,
    SharedModule
  ],
  providers: [ClaimsService, ClaimTablePipe]
})
export class ClaimsModule { }
