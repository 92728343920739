import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dispatchTable'
})
export class DispatchTablePipe implements PipeTransform {

  transform(array: any, filters: any, metaObj: any): any[] {

    //Statistics
    let sumDrivers: number = 0;
    let sumCompany: number = 0;
    let sumLease: number = 0;
    let sumOwner: number = 0;
    let sumGross: number = 0;
    let sumRate_per_mile: number = 0;
    let sumMileage: number = 0;
    let sumCompany_benefit_miles_percent: number = 0;
    let sumCompany_benefit_miles: number = 0;
    let sumMargin_percent: number = 0;
    let sumMargin: number = 0;
    let sumLength_per_load: number = 0;
    let sumGross_per_load: number = 0;
    let sumAvg_weight: number = 0;
    let sumDriver_retention: number = 0;
    let sumDriver_gross: number = 0;
    let sumWeight: number = 0;
    let sumLoads: number = 0;
    let sumLease_amount: number = 0;
    let sumCompanyGross: number = 0;
    let sumCompanyMileage: number = 0;
    let sumLeaseGross: number = 0;
    let sumLeaseMileage: number = 0;
    let sumOwnerGross: number = 0;
    let sumOwnerMileage: number = 0;
    let numOfDrivers: number = 0;
    console.log(filters)
    const transformedArray: any[] = [];

    for(let i = 0; i < array.length; i++) {
      let element: any = array[i];
      element.company_rpm = element.company_gross / element.company_mileage;
      element.lease_rpm = element.rent_gross / element.rent_mileage;
      element.owner_rpm = element.owner_gross / element.owner_mileage;
      const condition1: boolean = filters.dispatchStatus ? element.is_active === true : true;
      const condition2: boolean = filters.dispSelectedIds.length === 0 ? true : filters.dispSelectedIds.includes(element.disp_id);

      if(condition1 && condition2) {
        transformedArray.push(element);
        sumDrivers += element.drivers;
        sumCompany += element.company;
        sumLease += element.lease;
        sumOwner += element.owner;
        sumCompanyGross += element.company_gross;
        sumCompanyMileage += element.company_mileage;
        sumLeaseGross += element.rent_gross;
        sumLeaseMileage += element.rent_mileage;
        sumOwnerGross += element.owner_gross;
        sumOwnerMileage += element.owner_mileage;
        sumGross += element.gross;
        sumRate_per_mile += element.rate_per_mile;
        sumMileage += element.mileage;
        sumCompany_benefit_miles_percent += element.benefit_mileage_percent;
        sumCompany_benefit_miles += element.benefit_mileage;
        sumMargin_percent += element.margin_percent;
        sumMargin += element.margin;
        sumLength_per_load += element.lengt_per_load;
        sumGross_per_load += element.gross_per_load;
        sumLoads += element.loads;
        sumAvg_weight += element.avg_weight;
        sumDriver_retention += element.driver_retention;
        sumLease_amount += element.lease_amount;
        sumDriver_gross += element.driver_gross;
        sumWeight += element.weight;
      }
    };

    numOfDrivers = transformedArray.length;

    metaObj.countObj.drivers = sumDrivers;
    metaObj.countObj.company = sumCompany;
    metaObj.countObj.lease = sumLease;
    metaObj.countObj.owner = sumOwner;
    metaObj.countObj.company_rate = sumCompanyGross / sumCompanyMileage;
    metaObj.countObj.lease_rate = sumLeaseGross / sumLeaseMileage;
    metaObj.countObj.owner_rate = sumOwnerGross / sumOwnerMileage;
    metaObj.countObj.gross = sumGross;
    metaObj.countObj.rate_per_mile = sumGross / sumMileage ? sumGross / sumMileage : 0;
    metaObj.countObj.mileage = sumMileage;
    metaObj.countObj.company_benefit_miles_percent = (sumCompany_benefit_miles / sumMileage) * 100;
    metaObj.countObj.company_benefit_miles = sumCompany_benefit_miles;
    metaObj.countObj.margin_percent = ((sumGross - sumDriver_gross) / sumLease_amount) * 100;
    metaObj.countObj.margin = sumGross - sumDriver_gross;
    metaObj.countObj.length_per_load = sumMileage / sumLoads;
    metaObj.countObj.gross_per_load = sumGross / sumLoads;
    metaObj.countObj.avg_weight = sumWeight / sumLoads;
    metaObj.countObj.driver_retention = sumDriver_retention / numOfDrivers;
    metaObj.countObj.loads = sumLoads;
    return transformedArray.sort((a: any, b: any) => {
      return a.supervisor - b.supervisor;
    });
  }

}
