import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { catchError, Subscription, throwError } from 'rxjs';
import * as XLSX from 'xlsx';
import { AddEditDeductionsDialogComponent } from './add-edit-deductions-dialog/add-edit-deductions-dialog.component';
import { DeductionsTableComponent } from './deductions-table/deductions-table.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { DeductionsService } from '../../services/deductions.service';
import { IDeduction } from '../../models/deduction.model';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-dashboard-deductions-page',
  templateUrl: './dashboard-deductions-page.component.html',
  styleUrls: ['./dashboard-deductions-page.component.scss']
})
export class DashboardDeductionsPageComponent implements OnInit, OnDestroy {
  @ViewChild(DeductionsTableComponent) childComponent: any;
  permissions: any = this.rulesService.UserData[58].data;

  //Excel config
  xlsxConfig: any = [
    {columnName: 'Created by', selected: true},
    {columnName: 'Driver Name', selected: true},
    {columnName: 'Division', selected: true},
    {columnName: 'Unit (TRK or TRL)', selected: true},
    {columnName: 'Description', selected: true},
    {columnName: 'Total Deduction $', selected: true},
    {columnName: 'Number of Installments', selected: true},
    {columnName: 'Files', selected: true}
  ];

  //Date 
  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  };
  
  //Datasource
  deductionsArray: IDeduction[] = [];

  loaded: boolean = true;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Type
  deductionsTypes: any[] = [
    {name: 'All Deductions', type: null}, 
    {name: 'Scheduled', type: true}, 
    {name: 'Not Scheduled', type: false}
  ];
  selectedType: boolean | null = null;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private transformService: TransformService, 
              private deductionsService: DeductionsService,
              private titleCase: TitleCasePipe,
              private dialog: MatDialog,
              private spinner: NgxSpinnerService,
              private rulesService: RulesService) { } 

  ngOnInit(): void {
    this.getDeductionsTableData();
  };

  getDeductionsTableData() {
    this.deductionsArray = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('deductions-table');
    this.subscription = this.deductionsService.getDeductions(this.dateObj.startDate, this.dateObj.endDate, this.selectedType)
    .pipe(catchError((err: any) => {
      this.spinner.hide('deductions-table');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: IDeduction[]) => {
      this.deductionsArray = response;
      this.spinner.hide('deductions-table');
      this.loaded = true;
    });
  };

  clearFilters() {
    this.childComponent.clearFilters();
  };

  openDeductionsDialog() {
    if(this.permissions[0].sectionArray[0].allowed) {
      const dialogRef = this.dialog.open(AddEditDeductionsDialogComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'component-dialog-container',
        data: {editMode: false, data: {}}
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.deductionsArray.push(result);
          this.deductionsArray = [...this.deductionsArray];
        }
      });
    }
    else {
      this.showForbiddenMesage();
    }
  };

  //Export to excel
  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[49].allowed) {
      const excelTable: any[] = [];
      for(let i = 0; i < this.deductionsArray.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, this.titleCase.transform(this.deductionsArray[i].issued_by), columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.titleCase.transform(this.deductionsArray[i].driver_name), columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, this.deductionsArray[i].division, columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, this.deductionsArray[i].unit_no, columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, this.deductionsArray[i].description, columnsConfig[4].selected);
        this.transformService.selectedColumn(obj, columnsConfig[5].columnName, `$${this.transformService.addCommasDots(this.deductionsArray[i].total_deductions, 'round')}`, columnsConfig[5].selected);
        this.transformService.selectedColumn(obj, columnsConfig[6].columnName, this.deductionsArray[i].no_of_installments, columnsConfig[6].selected);
        this.transformService.selectedColumn(obj, columnsConfig[7].columnName, this.deductionsArray[i].files.length, columnsConfig[7].selected);
        excelTable.push(obj);
      };
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'deductions.xlsx');
    }
    else {
      this.showForbiddenMesage();
    }
  };

  //Show forbidden message
  showForbiddenMesage() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();  
  };

}
