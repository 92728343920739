import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaintenanceRoutingModule } from './maintenance.routing';
import { SharedModule } from '../shared/shared.module';
import { BreakDownByGroupTrucksComponent } from './components/maintenance-page/break-down-by-group-trucks/break-down-by-group-trucks.component';
import { BreakDownByMileageTrucksComponent } from './components/maintenance-page/break-down-by-mileage-trucks/break-down-by-mileage-trucks.component';
import { BreakdownByGroupLineChartComponent } from './components/maintenance-page/breakdown-by-group-line-chart/breakdown-by-group-line-chart.component';
import { BreakdownByGroupLineChart2Component } from './components/maintenance-page/breakdown-by-group-line-chart2/breakdown-by-group-line-chart2.component';
import { BreakdownByGroupTrailersDialogComponent } from './components/maintenance-page/breakdown-by-group-trailers-dialog/breakdown-by-group-trailers-dialog.component';
import { BreakdownByGroupTrailersComponent } from './components/maintenance-page/breakdown-by-group-trailers/breakdown-by-group-trailers.component';
import { BreakdownByGroupTrucksDialogComponent } from './components/maintenance-page/breakdown-by-group-trucks-dialog/breakdown-by-group-trucks-dialog.component';
import { BreakdownByMileageTrucksDialogComponent } from './components/maintenance-page/breakdown-by-mileage-trucks-dialog/breakdown-by-mileage-trucks-dialog.component';
import { BreakdownByYearTrailersDialogComponent } from './components/maintenance-page/breakdown-by-year-trailers-dialog/breakdown-by-year-trailers-dialog.component';
import { BreakdownByYearTrailersComponent } from './components/maintenance-page/breakdown-by-year-trailers/breakdown-by-year-trailers.component';
import { DashboardMaintenancePageComponent } from './components/maintenance-page/dashboard-maintenance-page.component';
import { MaintenanceCardsLineChartComponent } from './components/maintenance-page/maintenance-cards-line-chart/maintenance-cards-line-chart.component';
import { MaintenanceDragDropCardsComponent } from './components/maintenance-page/maintenance-drag-drop-cards/maintenance-drag-drop-cards.component';
import { TrailersLineChartComponent } from './components/maintenance-page/trailers-line-chart/trailers-line-chart.component';
import { TrailersTableComponent } from './components/maintenance-page/trailers-table/trailers-table.component';
import { TruckLineChartComponent } from './components/maintenance-page/truck-line-chart/truck-line-chart.component';
import { TruckTableComponent } from './components/maintenance-page/truck-table/truck-table.component';
import { MaintenanceService } from './services/maintenance.service';
import { MaintenanceRepairLogPageComponent } from './components/maintenance-repair-log-page/maintenance-repair-log-page.component';
import { CreateUpdateRepairComponent } from './components/maintenance-repair-log-page/create-update-repair/create-update-repair.component';
import { MaintenanceRepairLogTableComponent } from './components/maintenance-repair-log-page/maintenance-repair-log-table/maintenance-repair-log-table.component';
import { MaintenanceRepairLogService } from './services/maintenance-repair-log.service';
import { RepairLogPipe } from './pipes/repair-log.pipe';

@NgModule({
  declarations: [
    DashboardMaintenancePageComponent,
    BreakDownByGroupTrucksComponent,
    BreakDownByMileageTrucksComponent,
    BreakdownByGroupLineChartComponent,
    BreakdownByGroupLineChart2Component,
    BreakdownByGroupTrailersComponent,
    BreakdownByGroupTrailersDialogComponent,
    BreakdownByGroupTrucksDialogComponent,
    BreakdownByMileageTrucksDialogComponent,
    BreakdownByYearTrailersComponent,
    BreakdownByYearTrailersDialogComponent,
    MaintenanceCardsLineChartComponent,
    MaintenanceDragDropCardsComponent,
    TrailersLineChartComponent,
    TrailersTableComponent,
    TruckLineChartComponent,
    TruckTableComponent,
    MaintenanceRepairLogPageComponent,
    CreateUpdateRepairComponent,
    MaintenanceRepairLogTableComponent,
    RepairLogPipe
  ],
  imports: [
    CommonModule,
    MaintenanceRoutingModule,
    SharedModule
  ],
  providers: [MaintenanceService, MaintenanceRepairLogService]
})
export class MaintenanceModule { }
