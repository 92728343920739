import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reportAmortization'
})
export class ReportAmortizationPipe implements PipeTransform {

  transform(array: any[], filterObj: any, filterMetaData: any): any[] {
    if (!array) {
      return [];
    }

    let countObj: any = {
      sumOfAmortization: 0,
      sumOfLoanAmount: 0,
      sumOfMonthlyPay: 0,
      sumOfPayOffAmount: 0,
      sumOfInterestRate: 0,

      avgNumOfAmortization: 0,
      avgNumOfLoanAmount: 0,
      avgNumOfMonthlyPay: 0,
      avgNumOfPayOffAmount: 0,
      avgNumOfInterestRate: 0,
    };

    let transformedArray: any[] = [];
    for(let i = 0; i < array.length; i++) {
      let condition1: boolean = filterObj.bankName.length === 0 ? true : filterObj.bankName.includes(array[i].BankName);
      let condition2: boolean = filterObj.truckTrailer.length === 0 ? true : filterObj.truckTrailer.includes(array[i].TruckTrailer);
      let condition3: boolean = filterObj.typeofEquipment.length === 0 ? true : filterObj.typeofEquipment.includes(array[i].TypeofEquipment);
      let condition4: boolean = filterObj.companyLoan.length === 0 ? true : filterObj.companyLoan.includes(array[i].CompanyLoan);
      let condition5: boolean = this.numericFilter(filterObj.loanAmount.sign, filterObj.loanAmount.value, array[i].LoanAmount);
      let condition6: boolean = this.numericFilter(filterObj.monthlyPay.sign, filterObj.monthlyPay.value, array[i].MonthlyPay);
      let condition7: boolean =  this.calendarFilter(filterObj.purchasedDate, array[i].PurchasedDate);
      let condition8: boolean =  this.calendarFilter(filterObj.endDate, array[i].EndDate);
      let condition9: boolean = this.numericFilter(filterObj.payOffAmountLeft.sign, filterObj.payOffAmountLeft.value, array[i].PayOffAmountLeft);
      let condition10: boolean = this.numericFilter(filterObj.interestRate.sign, filterObj.interestRate.value, array[i].InterestRate);
      let condition11: boolean = filterObj.year.length === 0 ? true : filterObj.year.includes(array[i].Year);
      let condition12: boolean = filterObj.vinNo.length === 0 ? true : filterObj.vinNo.includes(array[i].VINNO);

      if(condition1 && condition2 && condition3 &&  condition4 && condition5 && condition6 && condition7 && condition8 && 
      condition9 && condition10 && condition11 && condition12) {
        countObj.sumOfLoanAmount += array[i].LoanAmount;
        countObj.sumOfMonthlyPay += array[i].MonthlyPay;
        countObj.sumOfPayOffAmount += array[i].PayOffAmountLeft;
        countObj.sumOfInterestRate += array[i].InterestRate;

        transformedArray.push(array[i]);
      }
    };
    const numOfData: number = transformedArray.length;
    countObj.sumOfAmortization = numOfData;
    countObj.avgNumOfAmortization = countObj.sumOfAmortization / numOfData;
    countObj.avgNumOfLoanAmount = countObj.sumOfLoanAmount / numOfData;
    countObj.avgNumOfMonthlyPay = countObj.sumOfMonthlyPay / numOfData;
    countObj.avgNumOfPayOffAmount = countObj.sumOfPayOffAmount / numOfData;
    countObj.avgNumOfInterestRate = countObj.sumOfInterestRate / numOfData;
    filterMetaData.countObj = countObj;
    return transformedArray;
  }

  calendarFilter(selectedDateRange: any, dateToCompare: string) {
    let condition: boolean = true;
    if(selectedDateRange) {
      const start = Date.parse(selectedDateRange.start._d);
      const end = Date.parse(selectedDateRange.end._d);
      const d = Date.parse(dateToCompare);
      condition = d.valueOf() >= start.valueOf() && d.valueOf() <= end.valueOf();
    }
    return condition;
  };

  numericFilter(sign: string, value: number | null, elementValue: number) {
    let key: boolean = true;
    if(value) {
      if(sign === 'More than') {
        key = Number(elementValue) > Number(value);
      }
      if(sign === 'Less than') {
        key = Number(elementValue) < Number(value);
      }
      if(sign === 'Equal') {
        key = Number(elementValue) == Number(value);
      }
    }
    return key;
  };

}
