import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appTwoDecimals]'
})
export class TwoDecimalsDirective {

 constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const input = this.el.nativeElement;
    const value = input.value;
    const cursorPosition = input.selectionStart;
    const resetCursor = () => {
      input.setSelectionRange(cursorPosition, cursorPosition);
    };

    let processedValue = value;
    processedValue = processedValue.split(' ').join('');
    if (/^[0]*\.00$/.test(processedValue)) {
      input.value = '';
      return;
    }

    let pointIndex = processedValue.indexOf('..');
    if (pointIndex >= 0) {
      processedValue = processedValue.replace('..', '.');
      resetCursor();
      input.value = processedValue;
      return;
    }

    if ([...processedValue].filter(c => c === '.').length > 1) {
      const firstPointIndex = processedValue.indexOf('.');
      processedValue = [...processedValue].filter((c, k) => k <= firstPointIndex || c !== '.').join('');
      resetCursor();
      input.value = processedValue;
      return;
    }

    if (!processedValue.includes('.')) {
      processedValue = (+processedValue).toFixed(2);
    }

    input.value = processedValue;
    resetCursor();
  }

}
