import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SharedService } from '../shared/services/shared.service';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss']
})
export class WelcomePageComponent implements OnInit, OnDestroy {
  @Output() showWelcomeMenu = new EventEmitter<boolean>();

  cards: any[] = [];

  buttonActive: boolean = false;

  public welcome_page: boolean = true;

  subscription: Subscription | any;
  Permission: any;

  constructor(private router: Router, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.showWelcomeMenu.emit(this.welcome_page);
    this.subscription = this.sharedService.getCompanyData().subscribe((response: any) => {
      this.cards = response.divisions;
      this.cards.push({id: -1, name: 'All Companies'})
      console.log(response);
    });

  }

  chooseCard(obj: any) {
    if(obj.id === -1) {
      let complete: boolean = JSON.parse(JSON.stringify(obj.selected = !obj.selected));
      this.cards.forEach(t => {(t.selected = complete)});
    } else {
      obj.selected = !obj.selected
    }
    if(this.cards.some(el => el.selected === true)) {
      this.buttonActive = true;
    } else {
      this.buttonActive = false;
    }
  };

  goToDashboard() {
    let selectedIds: number[] = [];

      for(let key in this.cards) {
        if(this.cards[key].id !== -1 && this.cards[key].selected) {
          selectedIds.push(this.cards[key].id);
        }
      }
    localStorage.setItem('selectedCompanies', JSON.stringify(selectedIds));
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    //New route protection
    let userData: any = JSON.parse(JSON.parse(localStorage.getItem('currentUser'))?.value);
    if(userData.route === 'admin/dispatchers/dashboard-dispatch-profile-page/') {
      this.router.navigate([userData.route + currentUser.pt_id])
    } 
    else {
      this.router.navigate([userData.route]);
    }
  };
  
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}

