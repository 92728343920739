import { Component, Input } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'contacts-table',
  templateUrl: './contacts-table.component.html',
  styleUrls: ['./contacts-table.component.scss']
})
export class ContactsTableComponent {
  @Input() formGroup: FormGroup;

  //Edit index
  editedRowIndex: number | null = null;
  editedRowElement: any;

  constructor(private dialog: MatDialog,
              private _formBuilder: FormBuilder) { }

  addContact() {
    const formArray: any = this.formArray.controls;
    const itemGroup = this._formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(2)]],
      phone: ['', [Validators.required, Validators.minLength(2)]],
      ext: [''],
      cell: [''],
      email: ['', [Validators.required, Validators.email]],
      role: ['']
    });
    formArray.unshift(itemGroup);
    this.formGroup.controls['cd_contacts'].updateValueAndValidity();
    this.editedRowIndex = 0;
  }

  editContact(element: any, index: number) {
    this.editedRowElement = element.value;
    this.editedRowIndex = index;
  };

  cancelContact(element: any, index: number) {
    if(element.value.name === '' || element.value.phone === '' || element.value.email === '') {
      this.formArray.removeAt(index);
    }
    else {
      this.formArray.controls[index].patchValue(this.editedRowElement);
    }
    this.editedRowIndex = null;
    this.editedRowElement = undefined;
  }

  saveContact() {
    this.editedRowIndex = null;
  };

  deleteContact(obj: any, index: number) {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result)
      if(result) {

      }
    })
  };

  //Get form array
  get formArray(): FormArray {
    return this.formGroup.controls['cd_contacts'] as FormArray;
  };

}
