import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, catchError, throwError } from 'rxjs';
import { DispatcherProfileService } from '../services/dispatcher-profile.service';
import { ActivatedRoute } from '@angular/router';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RulesService } from '@app/modules/shared/services/rules.service';

@Component({
  selector: 'app-dispatch-profile',
  templateUrl: './dispatch-profile.component.html',
  styleUrls: ['./dispatch-profile.component.scss']
})
export class DispatchProfileComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[15].data;
  linechartData: any = undefined;

  infoData: any;
  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private route: ActivatedRoute,
              private transformService: TransformService,
              private dispatchProfileService: DispatcherProfileService,
              private rulesService: RulesService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    let id: number = +this.route.snapshot.paramMap.get('id');
    this.spinner.show('dispatch-info');
    this.subscription = this.dispatchProfileService.getInfoData(id)
    .pipe(catchError((err: any) => {
      this.spinner.hide('dispatch-info');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      this.infoData = response;
      this.spinner.hide('dispatch-info');
      this.loaded = true;
    });
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();    
  };

}
