import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import moment = require('moment');
import { Subscription, catchError, throwError } from 'rxjs';
import { ReasonsDialogComponent } from '../reasons-dialog/reasons-dialog.component';
import { WarningReserveSpotComponent } from '../warning-reserve-spot/warning-reserve-spot.component';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { FleetHealthService } from '@app/modules/fleet-health/services/fleet-health.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { AskForApprovalDialogComponent } from '../ask-for-approval-dialog/ask-for-approval-dialog.component';
import { ConnectionPositionPair } from '@angular/cdk/overlay';

@Component({
  selector: 'app-reserve-spot-dialog',
  templateUrl: './reserve-spot-dialog.component.html',
  styleUrls: ['./reserve-spot-dialog.component.scss']
})
export class ReserveSpotDialogComponent implements OnInit, OnDestroy {
  //Autocomplete data
  autoCompleteData: any[] = [];

  //Trucks
  isOpenTruckAutocomplete: boolean = false;
  truckSearch: string = '';

  //Drivers
  driver: string = '';

  //Trailers
  trailer: string = '';

  //Calendar value
  date: string = '';

  //Time
  isOpenTimeMenu: boolean = false;
  time: string = '';
  hour: any = '';
  minutes: any = '';

  //Reasons
  reasonsArray: any[] = [
    {reasonValue: 'Category', isOpenReasonMenu: false, value: ''}
  ];
  
  reasonsObj: any;
  isOpenReasonMenu: boolean = false;
  reasonValue: string = 'Category';

  reasonsArraySelectedValues: any[] = [];

  reasonsDataWithoutGroup: any[] = [];

  categoryAdded: boolean = true;

  //Note
  note: string = '';

  //Files
  fileNames: any[] = [];
  files: any[] = [];
  isOpenFileMenuIndex: number | null = null;

  //Selected obj
  obj: any;

  //Emails
  emailArray: any[] = [];
  isOpenEmailSelectionMenu: boolean = false;
  isOpenEmailMenuIndex: number | null = null;
  selectedEmails: string[] = [];
  searchEmail: string = '';

  //Validation
  unitNoAdded: boolean = true;

  //Data saved
  loading: boolean = false;
  error: boolean = false;

  //Multiple clicks
  multipleClicks: boolean = true;

  //Message delete in progress
  msgDelete: boolean = false;
  msgDeleteError: boolean = false;

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      0, 5
    )
  ];

  //Subscription
  subscription1: Subscription | any;
  subscription2: Subscription | any;
  subscription3: Subscription | any;
  subscription4: Subscription | any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private sharedService: SharedService,
              private fleetService: FleetHealthService) { }

  ngOnInit(): void { 
    this.date = this.data.spotDate;
    if(this.data.editMode) {
      this.initForm();
    }
    this.getTrucks();
    this.getAllCategories();
    console.log(this.data);
  }

  initForm() {
    let initObj: any = JSON.parse(JSON.stringify(this.data.obj));
    this.obj = initObj;
    this.truckSearch = initObj.unit_no;
    this.driver = initObj.driver;
    this.trailer = initObj.trailer;
    this.date = initObj.date;
    this.time = moment(new Date(initObj.date)).format('HH:mm');
    this.hour = moment(new Date(initObj.date)).format('HH');
    this.minutes = moment(new Date(initObj.date)).format('mm');
    this.reasonsArraySelectedValues = initObj.reasons;
    let reasonsArray: any[] = [];
    for(let key in initObj.reasons) {
      reasonsArray.push({reasonValue: initObj.reasons[key].subcategory, isOpenReasonMenu: false, value: initObj.reasons[key].subcategory})
    }
    this.reasonsArray = reasonsArray.length > 0 ? reasonsArray : this.reasonsArray;
    this.note = initObj.note;
    this.fileNames = JSON.parse(JSON.stringify(this.data.obj.files));
    this.files = initObj.files;
    this.selectedEmails = initObj.emails && !initObj.emails.includes('') ? initObj.emails : [];              
  };

  getTrucks() {
    this.subscription1 = this.fleetService.getTrucks().subscribe((response: any) => {
      this.autoCompleteData = response;
    });
  }

  getAllCategories() {
    this.subscription2 = this.fleetService.getAllCategories().subscribe((response: any) => {
      this.reasonsDataWithoutGroup = response;
      this.reasonsObj = this.groupCategories(response)
    })
  };

  groupCategories(array: any[]) {
    const groups: any = array.reduce((acc: any, o: any) => {

      o['category'] = o['category'];
    
      if (!acc[o['category']]) {
        acc[o['category']] = [];
      }
          
      acc[o['category']].push(o);
  
      return acc;
  
    }, {});

    return groups;
  }

  //Select option
  selectOption(obj: any) {
    this.truckSearch = obj.unit_no;
    this.driver = obj.driver;
    this.trailer = obj.trailer;
    this.obj = obj;
    this.selectedEmails = [];
    this.emailArray = [];
    this.searchEmail = '';
    this.getEmailsByDivision(obj.division_id);
    this.getAllDepartments(obj.division_id);
    this.isOpenTruckAutocomplete = false;
  }

  //Get all emails
  getEmailsByDivision(id: number) {
    this.subscription3 = this.sharedService.getEmailsByDivison(id).subscribe((response: any) => {
      if(response.length > 0) {
        this.emailArray = this.emailArray.concat(response);
      }
    })
  }  

  //Get all departments
  getAllDepartments(division_id: number) {
    this.subscription4 = this.sharedService.getAllEmployeeDepartments().subscribe((response: any) => {
      for(let i = 0; i < response.length; i++) {
        if(response[i].division_id === division_id && (response[i].email.startsWith('safety'))) {
          this.selectedEmails.push(response[i].email);
          this.emailArray.push({employee_id: undefined, pt_id: 0, name: response[i].name, email: response[i].email})
        }
      };
    })
  };

  //Select reasons
  selectReason(array: any[], index: number, obj: any, index2: number) {
    obj.isOpenReasonMenu = !obj.isOpenReasonMenu; 
    obj.reasonValue = array[index].subcategory; 
    obj.value = array[index].subcategory;
    this.reasonsArraySelectedValues[index2] = array[index];
  }

  //Add select
  addSelect() {
    this.reasonsArray.push({reasonValue: 'Category', isOpenReasonMenu: false, value: ''});
  };

  //Remove select
  removeSelect(index: number) {
    this.reasonsArray.splice(index, 1);
    this.reasonsArraySelectedValues.splice(index, 1);
  }

  //Date
  dateClicked(date: string) {
    this.fleetService.getScheduleCalendarData(date, moment(date).format('YYYY-MM-DDT23:59:59'))
    .subscribe((response: any) => {
      if(response[0].slots !== response[0].available && response[0].slots !== -1) {
        this.date = date;
      }
      else {
        let dialogRef: any = this.dialog.open(WarningReserveSpotComponent, {
          autoFocus: false,
          panelClass: 'warning-reserve-msg-dialog-container'
        });
        dialogRef.afterClosed().subscribe((result: any) => {
          if(result) {
            let dialogRef = this.dialog.open(AskForApprovalDialogComponent, {
              autoFocus: false,
              panelClass: 'ask-for-approval-dialog-container',
              data: {fillForm: false, obj: '', spotDate: this.date},
              disableClose: true
            });
            dialogRef.afterClosed().subscribe((result: any) => {
              if(result) {
                this.fleetService.refreshDataSubject.next(true);
              }
            });
          };
        });
      }
    });
  }

  //Open reasons dialog
  openReasonsDialog() {
    let dialogRef: any = this.dialog.open(ReasonsDialogComponent, {
      autoFocus: false,
      panelClass: 'reasons-dialog-main-container',
      data: this.reasonsDataWithoutGroup
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      this.getAllCategories();
    });
  }

  //Add files
  onFileSelected(event: any) {  
    let length: any = Object.keys(event.target.files).length;
    for(let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i);
    }
  }

  addMultipleFiles(event: any, index: number) {
    let fileName = event.target.files[index].name;
    let reader = new FileReader();
    reader.onload = (e: any) => {
    let uint8 = new Uint8Array(e.target.result);
    let result = [];
    for (var i = 0; i < uint8.length; i++) {
      result.push(uint8[i]);
    }
    let obj: any = {
      filename: fileName,
      data: result
    };
    this.fileNames.push({filename: fileName, data: result});
    this.files.push(obj);
  }

  reader.readAsArrayBuffer(event.target.files[index])
  };

  //Files methods
  openFileInNewTab(obj: any) {
    this.isOpenFileMenuIndex = null;
    this.sharedService.downloadPreviewFile(obj.filename, this.arrayBufferToBase64(obj.data));
  };

  downloadFile(obj: any) {
    this.isOpenFileMenuIndex = null;
    let source: string = `data:application/octet-stream;base64,${this.arrayBufferToBase64(obj.data)}`;
    const downloadLink = document.createElement('a');
    const fileName = obj.filename;
    downloadLink.href = source;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  deleteFile(index: number) {
    this.isOpenFileMenuIndex = null;
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.fileNames.splice(index, 1);
        this.files.splice(index, 1);
      }
    })
  };

  //Discard emails
  discardEmails(i: number) {
    this.selectedEmails.splice(i, 1);
  };
  
  //Time methods
  leadingZero(hour: boolean) {
    if(hour) {
      this.hour = String(this.hour).padStart(2, '0');
    }
    else {
      this.minutes = String(this.minutes).padStart(2, '0');
    }
  }

  applyTime() {
    if(isNaN(this.hour) || this.hour === '') {
      this.hour = '00';
    }
    
    if(isNaN(this.minutes) || this.minutes === '') {
      this.minutes = '00';
    }
    this.time = `${this.hour}:${this.minutes}`;
    this.isOpenTimeMenu = false;
  };

  getBtnName() {
    if(this.loading) {
      return 'Loading...' 
    }
    else if(this.data.editMode) {
      return 'Update spot';
    }
    else {
      return 'Reserve spot';
    }
  };

  reserveSpot() {
    this.unitNoAdded = this.truckSearch ? true : false;
    this.categoryAdded = this.reasonsArraySelectedValues.length === 0 ? false : true;
    if(this.truckSearch && this.multipleClicks && this.categoryAdded) {
      this.loading = true;
      let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
      let time: string = this.time ? this.time : '00:00';
      let date: any = moment(this.date).format(`YYYY-MM-DDT${time}:00`);
  
      let obj: any =  {id: 0, unit_no: this.obj.unit_no, model: this.obj.model, odometers: null, 
      loaded: null, truck_id: this.obj.truck_id, driver: this.obj.driver, driver_phone_number: this.obj.driver_phone_number, 
      driver_id: this.obj.driver_id, dispatcher: this.obj.dispatcher, dispatcher_id: this.obj.dispatcher_id, trailer: this.obj.trailer, 
      trailer_id: this.obj.trailer_id, reasons: this.reasonsArraySelectedValues, date: date, status: 'On time', note: this.note,
      creator_id: loggedUser.id, created_by: loggedUser.first_name + ' ' + loggedUser.last_name, longitude: null, latitude: null,  
      distance_from_yard: null, eta: null, fuel_percent: null, def: null, created_date: moment().format('YYYY-MM-DDTHH:mm:ss'), 
      data_history: null, status_history: [], files: this.files, division_id: this.obj.division_id, emails: this.selectedEmails, 
      edited_by: '', edited_date: ''};

      if(this.data.editMode) {

        for(let key in this.files) {
          if(typeof this.files[key].data === 'string') {
            let byteArray: any = atob(this.files[key].data).split('').map(function (c) { return c.charCodeAt(0); })
            this.files[key].data = byteArray;
          }
        }

        obj.id = this.data.obj.id;
        obj.data_history = this.data.obj.data_history;
        obj.edited_by = loggedUser.first_name + ' ' + loggedUser.last_name;
        obj.edited_date = moment().format('YYYY-MM-DDTHH:mm:ss');
      }
      this.fleetService.reserveSpot(obj)
       .pipe(catchError((err: any) => {
          this.loading = false;
          this.error = true;
        return throwError(() => err);
      }))
       .subscribe((response: any) => {
        console.log(response);
        if(response) {
          this.multipleClicks = false;
          this.dialogRef.close(true);
        }
        else {
          this.error = true;
        }
        this.loading = false;
       })
    }

  }

  selectEmail(element: any) {
    /*
    let isExist = this.selectedEmails.indexOf(element.email);
    if(isExist === -1) {
      this.selectedEmails.push(element.email);
    }
    */
  };

  //Delete spot
  deleteSpot() {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.msgDelete = true;
        let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
        let fullName: string = loggedUser.first_name + ' ' + loggedUser.last_name;
        this.fleetService.deleteReservedSpot(this.obj.id, fullName,  moment().format('YYYY-MM-DDTHH:mm:ss'))
        .pipe(catchError((err: any) => {
          this.error = false;
          this.msgDelete = false;
          this.msgDeleteError = true;
          return throwError(() => err);
        }))
        .subscribe((response: any) => {
          console.log(response);
          if(response) {
            this.msgDelete = false;
            this.dialogRef.close(true);
          }
        })
      }
      else {
        this.msgDelete = false;
        this.msgDeleteError = false;
      }
    })
  };

  arrayBufferToBase64(data: any) {
    const base64Pattern = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
    if(base64Pattern.test(data)) return data;
    const bytes = new Uint8Array(data);
    let binary: string = '';
    for(let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  };

  ngOnDestroy() {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
    this.subscription4?.unsubscribe();
  };

}
