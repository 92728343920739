import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'claimTable'
})
export class ClaimTablePipe implements PipeTransform {

  transform(array: any[], filterObj: any): any[] {
    if (!array) {
      return [];
    }

    let transformedArray: any[] = [];
    for(let i = 0; i < array.length; i++) {
      let condition1: boolean = filterObj.claim.length === 0 ? true : filterObj.claim.includes(array[i].claim);
      let condition2: boolean = filterObj.adjuster.length === 0 ? true : filterObj.adjuster.includes(array[i].adjuster);
      let condition3: boolean = this.calendarFilter(filterObj.claim_opened, array[i].claim_opened);
      let condition4: boolean = this.calendarFilter(filterObj.claim_closed, array[i].claim_closed);
      let condition5: boolean = filterObj.status.length === 0 ? true : filterObj.status.includes(array[i].status);
      let condition6: boolean = this.calendarFilter(filterObj.date_of_loss, array[i].date_of_loss);
      let condition7: boolean = filterObj.truck_no.length === 0 ? true : filterObj.truck_no.includes(array[i].truck_no);
      let condition8: boolean = filterObj.driver_no.length === 0 ? true : filterObj.driver_no.includes(array[i].driver_no);
      let condition9: boolean = filterObj.type_of_claim.length === 0 ? true : filterObj.type_of_claim.includes(this.getTypeOfClaim(array[i].type_of_claim));
      let condition10: boolean = filterObj.claimant.length === 0 ? true : filterObj.claimant.includes(array[i].claimant);
      let condition11: boolean = this.calendarFilter(filterObj.last_update, array[i].last_update);

      if(condition1 && condition2 && condition3 &&  condition4 && condition5 && condition6 && condition7 && condition8 && condition9 
      && condition10 &&  condition11) {
        transformedArray.push(array[i]);
      }
    };
    return transformedArray;
  }

  getTypeOfClaim(id: number) {
    const obj: any = {'1': 'Liability – Physical Damage', '2': 'Cargo', '3': 'Physical Damage', '4': 'Liability – Personal Injury',
    '5': 'Liability – Property Damage', '6': 'OCC/ACC'};
    if(id) {
      return obj[id];
    }
    return '';
  };

  calendarFilter(selectedDateRange: any, dateToCompare: string) {
    let condition: boolean = true;
    if(selectedDateRange) {
      const start = Date.parse(selectedDateRange.start._d);
      const end = Date.parse(selectedDateRange.end._d);
      const d = Date.parse(dateToCompare);
      condition = d.valueOf() >= start.valueOf() && d.valueOf() <= end.valueOf();
    }
    return condition;
  };

  checkboxFilter(selectedValue: string, checkedValue: string, value: boolean) {
    let condition: boolean = true;
    if(selectedValue === 'Both') {
      condition  = true;
    }
    else if(selectedValue === checkedValue) {
      condition = value === true;
    }
    else {
      condition = value === false;
    }
    return condition;
  };

}
