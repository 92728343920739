import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountingModule } from './accounting/accounting.module';
import { BusinessPartnersModule } from './business-partners/business-partners.module';
import { RouterModule } from '@angular/router';
import { CoreModule } from './core/core.module';
import { MainComponentComponent } from './main-component/main-component.component';
import { MaterialModule } from './material/material.module';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { MainRoutingModule } from './main.routing';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { OverviewModule } from './overview/overview.module';
import { SharedModule } from './shared/shared.module';
import { DispatcherProfilePageModule } from './dispatcher-profile-page/dispatcher-profile-page.module';
import { DispatchersLiveBoardModule } from './dispatchers-live-board/dispatchers-live-board.module';
import { DispatchersPerformancesModule } from './dispatchers-performances/dispatchers-performances.module';
import { DocumentsModule } from './documents/documents.module';
import { DriversFilterModule } from './drivers-filter/drivers-filter.module';
import { DriversPerformancePageModule } from './drivers-performance-page/drivers-performance-page.module';
import { DriversProfilePageModule } from './drivers-profile-page/drivers-profile-page.module';
import { DriversSearchPageModule } from './drivers-search-page/drivers-search-page.module';
import { DriversUnderperformingModule } from './drivers-underperforming/drivers-underperforming.module';
import { EmployeeModule } from './employee/employee.module';
import { EstimationsModule } from './estimations/estimations.module';
import { FleetHealthModule } from './fleet-health/fleet-health.module';
import { FullAppModule } from './full-app/full-app.module';
import { HometimeModule } from './hometime/hometime.module';
import { InspectionsModule } from './inspections/inspections.module';
import { LoadAnalysisModule } from './load-analysis/load-analysis.module';
import { MaintenanceModule } from './maintenance/maintenance.module';
import { MapsModule } from './maps/maps.module';
import { NotCoveredTrucksModule } from './not-covered-trucks/not-covered-trucks.module';
import { PrebookedLoadsModule } from './prebooked-loads/prebooked-loads.module';
import { PrebookedUpcomingModule } from './prebooked-upcoming/prebooked-upcoming.module';
import { RecruitingModule } from './recruiting/recruiting.module';
import { SafetyModule } from './safety/safety.module';
import { SettingsModule } from './settings/settings.module';
import { StatementsModule } from './statements/statements.module';
import { TicketsModule } from './tickets/tickets.module';
import { TotalLoadsModule } from './total-loads/total-loads.module';
import { TrucksModule } from './trucks/trucks.module';
import { WarningsModule } from './warnings/warnings.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { BrokersToAvoidModule } from './brokers-to-avoid/brokers-to-avoid.module';
import { ChatModule } from './chat/chat.module';
import { TasksModule } from './tasks/tasks.module';
import { ReportsModule } from './reports/reports.module';
import { LoadBoardModule } from './load-board/load-board.module';

import { TrucksMapModule } from './trucks-map/trucks-map.module';
import { DispatchRewardSystemModule } from './dispatch-reward-system/dispatch-reward-system.module';
import { AccidentsModule } from './accidents/accidents.module';

@NgModule({
  declarations: [
    MainComponentComponent,
    WelcomePageComponent,
    PageNotFoundComponent
  ],
  imports: [
    CommonModule,
    MainRoutingModule,
    AccountingModule,
    BusinessPartnersModule,
    CoreModule,
    DispatcherProfilePageModule,
    DispatchersLiveBoardModule,
    DispatchersPerformancesModule,
    DocumentsModule,
    DriversFilterModule,
    DriversPerformancePageModule,
    DriversProfilePageModule,
    DriversSearchPageModule,
    DriversUnderperformingModule,
    EmployeeModule,
    EstimationsModule,
    FleetHealthModule,
    FullAppModule,
    HometimeModule,
    InspectionsModule,
    LoadAnalysisModule,
    MaintenanceModule,
    MapsModule,
    MaterialModule,
    NotCoveredTrucksModule,
    OverviewModule,
    PrebookedLoadsModule,
    PrebookedUpcomingModule,
    RecruitingModule,
    SafetyModule,
    SettingsModule,
    SharedModule,
    StatementsModule,
    TicketsModule,
    TotalLoadsModule,
    TrucksModule,
    WarningsModule,
    BrokersToAvoidModule,
    ChatModule,
    TasksModule,
    ReportsModule,
    LoadBoardModule,
    TrucksMapModule,
    DispatchRewardSystemModule,
    AccidentsModule,
    RouterModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ]
})
export class MainModule { }
