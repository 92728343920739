import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DriverApplicationsPageComponent } from './components/driver-applications-page/driver-applications-page.component';
import { DriverApplicationsRoutingModule } from './driver-applications.routing';
import { SharedModule } from '../shared/shared.module';
import { DriverApplicationsTableComponent } from './components/driver-applications-page/driver-applications-table/driver-applications-table.component';
import { DropdownBtnComponent } from './components/driver-applications-page/driver-applications-table/dropdown-btn/dropdown-btn.component';
import { RangeCalendarComponent } from './components/driver-applications-page/driver-applications-table/range-calendar/range-calendar.component';
import { DriverApplicationsPipe } from './pipes/driver-applications.pipe';

@NgModule({
  declarations: [
    DriverApplicationsPageComponent,
    DriverApplicationsTableComponent,
    DropdownBtnComponent,
    RangeCalendarComponent,
    DriverApplicationsPipe
  ],
  imports: [
    CommonModule,
    DriverApplicationsRoutingModule,
    SharedModule
  ]
})
export class DriverApplicationsModule { }
