import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateUpdatePaymentDialogComponent } from './create-update-payment-dialog/create-update-payment-dialog.component';
import { catchError, Subscription, throwError } from 'rxjs';
import { PaymentsService } from '../../services/payments.service';
import moment = require('moment');
import { TransformService } from '@app/modules/shared/services/transform.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import * as XLSX from 'xlsx';
import { PaymentTypeDialogComponent } from './payment-type-dialog/payment-type-dialog.component';
import { PaymentsTableComponent } from './payments-table/payments-table.component';

@Component({
  selector: 'app-dashboard-payments-page',
  templateUrl: './dashboard-payments-page.component.html',
  styleUrls: ['./dashboard-payments-page.component.scss']
})
export class DashboardPaymentsPageComponent implements OnDestroy {
  @ViewChild(PaymentsTableComponent) childComponent: any;
  permissions: any = this.rulesService.UserData[37].data;

  //Date 
  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  };

  //Type of payments
  typeOfPayments: any[] = [
    {
      name: 'Repair Payments', 
      columns: [
        {columnName: 'Created by', columnKey: 'IssuedBy', filterNum: 0, width: 'w175', letterTranform: true, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Driver Name', columnKey: 'DriverName', filterNum: 0, width: 'w192', letterTranform: true, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Division', columnKey: 'division', filterNum: 0, width: 'w140', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Unit (TRK or TRL)', columnKey: 'unit_no', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Date of Payment', columnKey: 'DateOfPayment', filterNum: 3, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: true, isCheckbox: false, isFile: false},
        {columnName: 'Payment Method', columnKey: 'PaymentMethod', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Money Code', columnKey: 'MoneyCode', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Payment Amount', columnKey: 'PaymantAmount', filterNum: 1, width: 'w109', letterTranform: false, prefix: '$', formatNumbers: true, numOfDecimals: 2, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Payment Issued to', columnKey: 'PaymentIssuedTo', filterNum: 0, width: 'w125', letterTranform: true, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Type of Repair', columnKey: 'TypeOfRepair', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Reason for Payment', columnKey: 'ReasonForPeyment', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Attachment', columnKey: 'fileName', filterNum: 2, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: false, isDate: false, isCheckbox: false, isFile: true},
        {columnName: 'Reviewed', columnKey: 'reviewed', filterNum: 2, width: 'w100', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: false, isDate: false, isCheckbox: true, isFile: false},
        {columnName: 'Charge the Driver', columnKey: 'chargeTheDriver', filterNum: 2, width: 'w100', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: false, isDate: false, isCheckbox: true, isFile: false}
      ],
      type: 0 
    }, 
    {
      name: 'Fuel Payments',
      columns: [
        {columnName: 'Created by', columnKey: 'IssuedBy', filterNum: 0, width: 'w175', letterTranform: true, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Driver Name', columnKey: 'DriverName', filterNum: 0, width: 'w192', letterTranform: true, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Division', columnKey: 'division', filterNum: 0, width: 'w140', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Unit (TRK or TRL)', columnKey: 'unit_no', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Date of Payment', columnKey: 'DateOfPayment', filterNum: 3, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: true, isCheckbox: false, isFile: false},
        {columnName: 'Issued To', columnKey: 'PaymentIssuedTo', filterNum: 0, width: 'w125', letterTranform: true, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'State', columnKey: 'Stete', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Payment Method', columnKey: 'PaymentMethod', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Money Code', columnKey: 'MoneyCode', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Payment Amount', columnKey: 'PaymantAmount', filterNum: 1, width: 'w109', letterTranform: false, prefix: '$', formatNumbers: true, numOfDecimals: 2, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Amount Issued', columnKey: 'IssuedAmount', filterNum: 1, width: 'w109', letterTranform: false, prefix: '$', formatNumbers: true, numOfDecimals: 2, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Amount Used', columnKey: 'AmountUsed', filterNum: 1, width: 'w109', letterTranform: false, prefix: '$', formatNumbers: true, numOfDecimals: 2, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Gallons', columnKey: 'Quantity', filterNum: 1, width: 'w109', letterTranform: false, prefix: '', formatNumbers: true, numOfDecimals: 3, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Reason for Payment', columnKey: 'ReasonForPeyment', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Attachment', columnKey: 'fileName', filterNum: 2, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: false, isDate: false, isCheckbox: false, isFile: true},
        {columnName: 'Reviewed', columnKey: 'reviewed', filterNum: 2, width: 'w100', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: false, isDate: false, isCheckbox: true, isFile: false},
        {columnName: 'Charge the Driver', columnKey: 'chargeTheDriver', filterNum: 2, width: 'w100', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: false, isDate: false, isCheckbox: true, isFile: false}
      ],
      type: 1
    },
    {
      name: 'Miscellaneous Payments',
      columns: [
        {columnName: 'Created by', columnKey: 'IssuedBy', filterNum: 0, width: 'w175', letterTranform: true, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Driver Name', columnKey: 'DriverName', filterNum: 0, width: 'w192', letterTranform: true, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Division', columnKey: 'division', filterNum: 0, width: 'w140', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Unit (TRK or TRL)', columnKey: 'unit_no', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Date of Payment', columnKey: 'DateOfPayment', filterNum: 3, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: true, isCheckbox: false, isFile: false},
        {columnName: 'Payment Method', columnKey: 'PaymentMethod', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Money Code', columnKey: 'MoneyCode', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Payment Amount', columnKey: 'PaymantAmount', filterNum: 1, width: 'w109', letterTranform: false, prefix: '$', formatNumbers: true, numOfDecimals: 2, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Payment Issued to', columnKey: 'PaymentIssuedTo', filterNum: 0, width: 'w125', letterTranform: true, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Reason for Payment', columnKey: 'ReasonForPeyment', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Attachment', columnKey: 'fileName', filterNum: 2, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: false, isDate: false, isCheckbox: false, isFile: true},
        {columnName: 'Reviewed', columnKey: 'reviewed', filterNum: 2, width: 'w100', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: false, isDate: false, isCheckbox: true, isFile: false},
        {columnName: 'Charge the Driver', columnKey: 'chargeTheDriver', filterNum: 2, width: 'w100', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: false, isDate: false, isCheckbox: true, isFile: false}
      ],
      type: 2
    },
    {
      name: 'Pre-employment',
      columns: [
        {columnName: 'Created by', columnKey: 'IssuedBy', filterNum: 0, width: 'w175', letterTranform: true, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Driver Name', columnKey: 'DriverName', filterNum: 0, width: 'w192', letterTranform: true, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Division', columnKey: 'division', filterNum: 0, width: 'w140', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Date of Payment', columnKey: 'DateOfPayment', filterNum: 3, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: true, isCheckbox: false, isFile: false},
        {columnName: 'Payment Method', columnKey: 'PaymentMethod', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Money Code', columnKey: 'MoneyCode', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Payment Amount', columnKey: 'PaymantAmount', filterNum: 1, width: 'w109', letterTranform: false, prefix: '$', formatNumbers: true, numOfDecimals: 2, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Payment Issued to', columnKey: 'PaymentIssuedTo', filterNum: 0, width: 'w125', letterTranform: true, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Reason for Payment', columnKey: 'ReasonForPeyment', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Attachment', columnKey: 'fileName', filterNum: 2, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: false, isDate: false, isCheckbox: false, isFile: true},
        {columnName: 'Reviewed', columnKey: 'reviewed', filterNum: 2, width: 'w100', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: false, isDate: false, isCheckbox: true, isFile: false},
        {columnName: 'Charge the Driver', columnKey: 'chargeTheDriver', filterNum: 2, width: 'w100', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: false, isDate: false, isCheckbox: true, isFile: false}
      ],
      type: 3
    }
  ];

  selectedTypeColumns: any[] = [];
  selectedTypeDataSource: any[] = [];
  selectedType: number | undefined = undefined;

  //Excel config
  xlsxConfig: any = [
    {columnName: 'Created by', selected: true},
    {columnName: 'Driver Name', selected: true},
    {columnName: 'Division', selected: true},
    {columnName: 'Unit (TRK or TRL)', selected: true},
    {columnName: 'Date of Payment', selected: true},
    {columnName: 'Payment Method', selected: true},
    {columnName: 'Money Code', selected: true},
    {columnName: 'Amount Issued', selected: true},
    {columnName: 'Payment Amount', selected: true},
    {columnName: 'Payment Issued to', selected: true},
    {columnName: 'Type of Repair', selected: true},
    {columnName: 'Reason for Payment', selected: true},
    {columnName: 'Attachment', selected: true},
    {columnName: 'Reviewed', selected: true},
    {columnName: 'Charge the Driver', selected: true}
  ];
 
  //Subscription
  subscription: Subscription = new Subscription();

  loaded: boolean = true;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  constructor(private transformService: TransformService,
              private dialog: MatDialog, 
              private paymentsService: PaymentsService,
              private spinner: NgxSpinnerService,
              private rulesService: RulesService) { };


  getPaymentsByType() {
    console.log(this.permissions)
    this.selectedTypeColumns = [];
    this.selectedTypeDataSource = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('payments-table');
    this.subscription = this.paymentsService.getPaymentsByTypeAndDate(this.selectedType, this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('payments-table');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.selectedTypeColumns = this.typeOfPayments[this.selectedType].columns;
      this.selectedTypeDataSource = response;
      if(response.length > 0) {
        this.getXlxsConfigBasedOnType(`type${response[0].type}`);
      }
      this.spinner.hide('payments-table');
      this.loaded = true;
      console.log(response)
    });
  };

  run() {
    if(this.selectedType !== undefined) {
      this.getPaymentsByType();
    }
  };

  createUpdatePaymentDialog() {
    if(this.permissions[0].sectionArray[0].allowed) {
      let dialogRef: any = this.dialog.open(CreateUpdatePaymentDialogComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'component-dialog-container',
        data: {editMode: false, data: {}}
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result && this.selectedType !== undefined) {
          if(result.type === this.selectedType) {
            this.selectedTypeDataSource.push(result);
            this.selectedTypeDataSource = [...this.selectedTypeDataSource];
          }
        }
      })
    }
    else {
      this.showForbiddenMesage();
    }
  };

  //Export to excel
  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[4].allowed) {
      const typesName: any = {0: 'repair-payments.xlsx', 1: 'fuel-payments.xlsx', 2: 'miscellaneous-payments.xlsx', 3: 'Pre-employment'};
      let fileName = typesName[this.selectedType];
      const excelTable: any[] = [];
      for(let i = 0; i < this.selectedTypeDataSource.length; i++) {
        let obj: any = {};
        if(this.selectedType === 0) {
          this.transformService.selectedColumn(obj, columnsConfig[0].columnName, this.selectedTypeDataSource[i].IssuedBy, columnsConfig[0].selected);
          this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.selectedTypeDataSource[i].DriverName, columnsConfig[1].selected);
          this.transformService.selectedColumn(obj, columnsConfig[2].columnName, this.selectedTypeDataSource[i].division, columnsConfig[2].selected);
          this.transformService.selectedColumn(obj, columnsConfig[3].columnName, this.selectedTypeDataSource[i].unit_no, columnsConfig[3].selected);
          this.transformService.selectedColumn(obj, columnsConfig[4].columnName, this.transformService.transformDateFormat(this.selectedTypeDataSource[i].DateOfPayment, 'YYYY-MM-DD'), columnsConfig[4].selected);
          this.transformService.selectedColumn(obj, columnsConfig[5].columnName, this.selectedTypeDataSource[i].PaymentMethod, columnsConfig[5].selected);
          this.transformService.selectedColumn(obj, columnsConfig[6].columnName, this.selectedTypeDataSource[i].MoneyCode, columnsConfig[6].selected);
          this.transformService.selectedColumn(obj, columnsConfig[7].columnName, this.selectedTypeDataSource[i].IssuedAmount, columnsConfig[7].selected);
          this.transformService.selectedColumn(obj, columnsConfig[8].columnName, this.selectedTypeDataSource[i].PaymantAmount, columnsConfig[8].selected);
          this.transformService.selectedColumn(obj, columnsConfig[9].columnName, this.selectedTypeDataSource[i].PaymentIssuedTo, columnsConfig[9].selected);
          this.transformService.selectedColumn(obj, columnsConfig[10].columnName, this.selectedTypeDataSource[i].TypeOfRepair, columnsConfig[10].selected);
          this.transformService.selectedColumn(obj, columnsConfig[11].columnName, this.selectedTypeDataSource[i].ReasonForPeyment, columnsConfig[11].selected);
          this.transformService.selectedColumn(obj, columnsConfig[12].columnName, this.selectedTypeDataSource[i].fileName, columnsConfig[12].selected);
          this.transformService.selectedColumn(obj, columnsConfig[13].columnName, this.selectedTypeDataSource[i].reviewed, columnsConfig[13].selected);
          this.transformService.selectedColumn(obj, columnsConfig[14].columnName, this.selectedTypeDataSource[i].chargeTheDriver, columnsConfig[14].selected);
        }
        if(this.selectedType === 1) {
          this.transformService.selectedColumn(obj, columnsConfig[0].columnName, this.selectedTypeDataSource[i].IssuedBy, columnsConfig[0].selected);
          this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.selectedTypeDataSource[i].DriverName, columnsConfig[1].selected);
          this.transformService.selectedColumn(obj, columnsConfig[2].columnName, this.selectedTypeDataSource[i].division, columnsConfig[2].selected);
          this.transformService.selectedColumn(obj, columnsConfig[3].columnName, this.selectedTypeDataSource[i].unit_no, columnsConfig[3].selected);
          this.transformService.selectedColumn(obj, columnsConfig[4].columnName, this.transformService.transformDateFormat(this.selectedTypeDataSource[i].DateOfPayment, 'YYYY-MM-DD'), columnsConfig[4].selected);
          this.transformService.selectedColumn(obj, columnsConfig[5].columnName, this.selectedTypeDataSource[i].PaymentIssuedTo, columnsConfig[5].selected);
          this.transformService.selectedColumn(obj, columnsConfig[6].columnName, this.selectedTypeDataSource[i].Stete, columnsConfig[6].selected);
          this.transformService.selectedColumn(obj, columnsConfig[7].columnName, this.selectedTypeDataSource[i].PaymentMethod, columnsConfig[7].selected);
          this.transformService.selectedColumn(obj, columnsConfig[8].columnName, this.selectedTypeDataSource[i].MoneyCode, columnsConfig[8].selected);
          this.transformService.selectedColumn(obj, columnsConfig[9].columnName, this.selectedTypeDataSource[i].IssuedAmount, columnsConfig[9].selected);
          this.transformService.selectedColumn(obj, columnsConfig[10].columnName, this.selectedTypeDataSource[i].AmountUsed, columnsConfig[10].selected);
          this.transformService.selectedColumn(obj, columnsConfig[11].columnName, this.selectedTypeDataSource[i].Quantity, columnsConfig[11].selected);
          this.transformService.selectedColumn(obj, columnsConfig[12].columnName, this.selectedTypeDataSource[i].ReasonForPeyment, columnsConfig[12].selected);
          this.transformService.selectedColumn(obj, columnsConfig[13].columnName, this.selectedTypeDataSource[i].fileName, columnsConfig[13].selected);
          this.transformService.selectedColumn(obj, columnsConfig[14].columnName, this.selectedTypeDataSource[i].reviewed, columnsConfig[14].selected);
          this.transformService.selectedColumn(obj, columnsConfig[15].columnName, this.selectedTypeDataSource[i].chargeTheDriver, columnsConfig[15].selected);
        }
        if(this.selectedType === 2) {
          this.transformService.selectedColumn(obj, columnsConfig[0].columnName, this.selectedTypeDataSource[i].IssuedBy, columnsConfig[0].selected);
          this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.selectedTypeDataSource[i].DriverName, columnsConfig[1].selected);
          this.transformService.selectedColumn(obj, columnsConfig[2].columnName, this.selectedTypeDataSource[i].division, columnsConfig[2].selected);
          this.transformService.selectedColumn(obj, columnsConfig[3].columnName, this.selectedTypeDataSource[i].unit_no, columnsConfig[3].selected);
          this.transformService.selectedColumn(obj, columnsConfig[4].columnName, this.transformService.transformDateFormat(this.selectedTypeDataSource[i].DateOfPayment, 'YYYY-MM-DD'), columnsConfig[4].selected);
          this.transformService.selectedColumn(obj, columnsConfig[5].columnName, this.selectedTypeDataSource[i].PaymentMethod, columnsConfig[5].selected);
          this.transformService.selectedColumn(obj, columnsConfig[6].columnName, this.selectedTypeDataSource[i].MoneyCode, columnsConfig[6].selected);
          this.transformService.selectedColumn(obj, columnsConfig[7].columnName, this.selectedTypeDataSource[i].IssuedAmount, columnsConfig[7].selected);
          this.transformService.selectedColumn(obj, columnsConfig[8].columnName, this.selectedTypeDataSource[i].AmountUsed, columnsConfig[8].selected);
          this.transformService.selectedColumn(obj, columnsConfig[9].columnName, this.selectedTypeDataSource[i].PaymentIssuedTo, columnsConfig[9].selected);
          this.transformService.selectedColumn(obj, columnsConfig[10].columnName, this.selectedTypeDataSource[i].ReasonForPeyment, columnsConfig[10].selected);
          this.transformService.selectedColumn(obj, columnsConfig[11].columnName, this.selectedTypeDataSource[i].fileName, columnsConfig[11].selected);
          this.transformService.selectedColumn(obj, columnsConfig[12].columnName, this.selectedTypeDataSource[i].reviewed, columnsConfig[12].selected);
          this.transformService.selectedColumn(obj, columnsConfig[13].columnName, this.selectedTypeDataSource[i].chargeTheDriver, columnsConfig[13].selected);
        }
        if(this.selectedType === 3) {
          this.transformService.selectedColumn(obj, columnsConfig[0].columnName, this.selectedTypeDataSource[i].IssuedBy, columnsConfig[0].selected);
          this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.selectedTypeDataSource[i].DriverName, columnsConfig[1].selected);
          this.transformService.selectedColumn(obj, columnsConfig[2].columnName, this.selectedTypeDataSource[i].division, columnsConfig[2].selected);
          this.transformService.selectedColumn(obj, columnsConfig[3].columnName, this.transformService.transformDateFormat(this.selectedTypeDataSource[i].DateOfPayment, 'YYYY-MM-DD'), columnsConfig[3].selected);
          this.transformService.selectedColumn(obj, columnsConfig[4].columnName, this.selectedTypeDataSource[i].PaymentMethod, columnsConfig[4].selected);
          this.transformService.selectedColumn(obj, columnsConfig[5].columnName, this.selectedTypeDataSource[i].MoneyCode, columnsConfig[5].selected);
          this.transformService.selectedColumn(obj, columnsConfig[6].columnName, this.selectedTypeDataSource[i].IssuedAmount, columnsConfig[6].selected);
          this.transformService.selectedColumn(obj, columnsConfig[7].columnName, this.selectedTypeDataSource[i].AmountUsed, columnsConfig[7].selected);
          this.transformService.selectedColumn(obj, columnsConfig[8].columnName, this.selectedTypeDataSource[i].PaymentIssuedTo, columnsConfig[8].selected);
          this.transformService.selectedColumn(obj, columnsConfig[9].columnName, this.selectedTypeDataSource[i].ReasonForPeyment, columnsConfig[9].selected);
          this.transformService.selectedColumn(obj, columnsConfig[10].columnName, this.selectedTypeDataSource[i].fileName, columnsConfig[10].selected);
          this.transformService.selectedColumn(obj, columnsConfig[11].columnName, this.selectedTypeDataSource[i].reviewed, columnsConfig[11].selected);
          this.transformService.selectedColumn(obj, columnsConfig[12].columnName, this.selectedTypeDataSource[i].chargeTheDriver, columnsConfig[12].selected);
        }
        excelTable.push(obj);
      };
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, fileName);
    }
    else {
      this.showForbiddenMesage();
    }
  };
  
  getXlxsConfigBasedOnType(type: string) {
    let type0Array: any[] = [
      {columnName: 'Created by', selected: true},
      {columnName: 'Driver Name', selected: true},
      {columnName: 'Division', selected: true},
      {columnName: 'Unit (TRK or TRL)', selected: true},
      {columnName: 'Date of Payment', selected: true},
      {columnName: 'Payment Method', selected: true},
      {columnName: 'Money Code', selected: true},
      {columnName: 'Amount Issued', selected: true},
      {columnName: 'Payment Amount', selected: true},
      {columnName: 'Payment Issued to', selected: true},
      {columnName: 'Type of Repair', selected: true},
      {columnName: 'Reason for Payment', selected: true},
      {columnName: 'Attachment', selected: true},
      {columnName: 'Reviewed', selected: true},
      {columnName: 'Charge the Driver', selected: true}
    ];
    let type1Array: any[] = [
      {columnName: 'Created by', selected: true},
      {columnName: 'Driver Name', selected: true},
      {columnName: 'Division', selected: true},
      {columnName: 'Unit (TRK or TRL)', selected: true},
      {columnName: 'Date of Payment', selected: true},
      {columnName: 'Issued To', selected: true},
      {columnName: 'State', selected: true},
      {columnName: 'Payment Method', selected: true},
      {columnName: 'Money Code', selected: true},
      {columnName: 'Amount Issued', selected: true},
      {columnName: 'Amount Used', selected: true},
      {columnName: 'Gallons', selected: true},
      {columnName: 'Reason for Payment', selected: true},
      {columnName: 'Attachment', selected: true},
      {columnName: 'Reviewed', selected: true},
      {columnName: 'Charge the Driver', selected: true}
    ];
    let type2Array: any[] = [
      {columnName: 'Created by', selected: true},
      {columnName: 'Driver Name', selected: true},
      {columnName: 'Division', selected: true},
      {columnName: 'Unit (TRK or TRL)', selected: true},
      {columnName: 'Date of Payment', selected: true},
      {columnName: 'Payment Method', selected: true},
      {columnName: 'Money Code', selected: true},
      {columnName: 'Amount Issued', selected: true},
      {columnName: 'Amount Used', selected: true},
      {columnName: 'Payment Issued to', selected: true},
      {columnName: 'Reason for Payment', selected: true},
      {columnName: 'Attachment', selected: true},
      {columnName: 'Reviewed', selected: true},
      {columnName: 'Charge the Driver', selected: true}
    ];
    let type3Array: any[] = [
      {columnName: 'Created by', selected: true},
      {columnName: 'Driver Name', selected: true},
      {columnName: 'Division', selected: true},
      {columnName: 'Date of Payment', selected: true},
      {columnName: 'Payment Method', selected: true},
      {columnName: 'Money Code', selected: true},
      {columnName: 'Amount Issued', selected: true},
      {columnName: 'Amount Used', selected: true},
      {columnName: 'Payment Issued to', selected: true},
      {columnName: 'Reason for Payment', selected: true},
      {columnName: 'Attachment', selected: true},
      {columnName: 'Reviewed', selected: true},
      {columnName: 'Charge the Driver', selected: true}
    ];
    const obj = {type0: type0Array, type1: type1Array, type2: type2Array, type3: type3Array};
    this.xlsxConfig = obj[type];
  }

  clearFilters() {
    this.childComponent.clearFilters();
  };

  openPaymentTypeDialog() {
    this.dialog.open(PaymentTypeDialogComponent, {
      disableClose: true,
      autoFocus: false,
      panelClass: 'component-dialog-container'
    });
  };

  //Show forbidden message
  showForbiddenMesage() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}