import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'form-comments',
  templateUrl: './form-comments.component.html',
  styleUrls: ['./form-comments.component.scss']
})
export class FormCommentsComponent {
  @Input() formGroup: FormGroup;
}
