import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddEditBrokersToAvoidDialogComponent } from './add-edit-brokers-to-avoid-dialog/add-edit-brokers-to-avoid-dialog.component';
import { Subscription, catchError, throwError } from 'rxjs';
import { BrokersToAvoidService } from '../../services/brokers-to-avoid.service';
import { IBrokersToAvoid } from '../../models/brokers-to-avoid.model';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';

@Component({
  selector: 'app-brokers-to-avoid',
  templateUrl: './brokers-to-avoid.component.html',
  styleUrls: ['./brokers-to-avoid.component.scss']
})
export class BrokersToAvoidComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[30].data;
  data: IBrokersToAvoid[] = [];
  history: boolean = false;

  //Divisions array
  divisionsArray: any[] = [];
  selectedDivision: number = -1;

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();

  constructor(private rulesService: RulesService,
              private brokersToAvoidService: BrokersToAvoidService, 
              private sharedService: SharedService,
              private dialog: MatDialog,
              public transformService: TransformService, 
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getTableData();
    this.getAllDivisions();
  }

  getTableData() {
    this.data = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('brokers-to-avoid');
    this.subscription1 = this.brokersToAvoidService.getBrokersToAvoidTableData(this.history)
    .pipe(catchError((err: any) => {
      this.spinner.hide('brokers-to-avoid');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: IBrokersToAvoid[]) => {
      this.data = response;
      this.spinner.hide('brokers-to-avoid');
      this.loaded = true;
      console.log(response)
    });
  };

  getAllDivisions() {
    this.subscription2 = this.sharedService.getCompanyData().subscribe((response: any) => {
      let dataArray: any[] = [];
      dataArray = response.divisions;
      dataArray.push({id: -1, name: 'All Companies'});
      this.divisionsArray = dataArray;
      console.log(this.divisionsArray)
    });
  };

  //Add/Edit Brokers
  openAddEditBrokersToAvoidDialog() {
    if(this.permissions[0].allowedAll) {
      let dialogRef = this.dialog.open(AddEditBrokersToAvoidDialogComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'brokers-to-avoid-dialog-container',
        data: {obj: {}, editMode: false, readOnly: false}
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.data.push(result);
          this.data = [...this.data];
        }
      });
    }
    else {
      this.dialog.open(MsgForbbidenAccessComponent, {
        autoFocus: false,
        panelClass: 'forbidden-msg-dialog-container'
      })
    }
  };

  //Show history
  showHistory() {
    this.history = !this.history;
    this.getTableData();
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  };

}
