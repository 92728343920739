import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NAService } from '@app/modules/notifications-alerts-settings/services/n-a.service';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import moment = require('moment');
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-mails-settings-form',
  templateUrl: './mails-settings-form.component.html',
  styleUrls: ['./mails-settings-form.component.scss']
})
export class MailsSettingsFormComponent implements OnInit {
  //Form group
  formGroup: FormGroup = this.formBuilder.nonNullable.group({
    'id': [0],
    'division_name': ['', [Validators.required, Validators.minLength(2)]],
    'division_id': [null, Validators.required],
    'username': ['', [Validators.required, Validators.minLength(2)]],
    'password': ['', [Validators.required, Validators.minLength(2)]],
    'mail_server': ['', [Validators.required, Validators.minLength(2)]],
    'smtp_port': [null, Validators.required],
    'from_user': ['', [Validators.required, Validators.minLength(2)]],
    'created_by': [''],
    'created_date': [null],
    'edited_by': [''],
    'edited_date': [null]
  })

  //Multiple clicks
  multipleClicks: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any,
              private formBuilder: FormBuilder,
              private dialog: MatDialog,
              private notificationsAlertsSettingsService: NAService,
              public dialogRef: MatDialogRef<MailsSettingsFormComponent>) { }

  ngOnInit(): void {
    if(this.obj.editMode) {
      this.formGroup.patchValue(this.obj.data);
    }
  }

  saveUpdateData() {
    this.formGroup.markAllAsTouched();
    if(this.formGroup.valid && this.multipleClicks) {
      this.multipleClicks = false;
      const loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
      const fullName: string = `${loggedUser.first_name} ${loggedUser.last_name}`;
      const nickname: string = `${loggedUser.nickname}`;
      const creator: string = nickname.length === 0 ? fullName : nickname?.trim().split(' ')[0];
      if(this.formGroup.value.id) {
        this.formGroup.patchValue({
          'edited_by': creator,
          'edited_date': moment().format('YYYY-MM-DDTHH:mm:ss')
        });
      }
      else {
        this.formGroup.patchValue({
          'created_by': creator,
          'created_date': moment().format('YYYY-MM-DDTHH:mm:ss')
        });
      }
      const formData = this.formGroup.value;
      this.notificationsAlertsSettingsService.changeDivisionSettings(formData)
      .pipe(catchError((err: any) => {
        this.multipleClicks = true;
        this.showErrorMessage();
        return throwError(() => err);
      }))
      .subscribe((response: any) => {
        console.log(response);
        if(response?.id) {
          this.dialogRef.close(response);
        }
      });
    }
  };

  //Check validation
  checkValidation(key: string): boolean {
    return !this.formGroup.controls[key].valid && this.formGroup.controls[key]?.touched; 
  };

  //Show error message
  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

}
