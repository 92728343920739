import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccidentsRoutingModule } from './accidents.routing';
import { SharedModule } from '../shared/shared.module';
import { AccidentsPageComponent } from './components/accidents-page/accidents-page.component';
import { AddEditAccidentsDialogComponent } from './components/accidents-page/add-edit-accidents-dialog/add-edit-accidents-dialog.component';
import { AddContactDialogComponent } from './components/accidents-page/add-edit-accidents-dialog/add-contact-dialog/add-contact-dialog.component';
import { AccidentsTableComponent } from './components/accidents-page/accidents-table/accidents-table.component';
import { AccidentsService } from './services/accidents.service';
import { AccidentTableFilterPipe } from './pipes/accident-table-filter.pipe';

@NgModule({
  declarations: [
    AccidentsPageComponent,
    AddEditAccidentsDialogComponent,
    AddContactDialogComponent,
    AccidentsTableComponent,
    AccidentTableFilterPipe
  ],
  imports: [
    CommonModule,
    AccidentsRoutingModule,
    SharedModule
  ],
  providers: [AccidentsService, AccidentTableFilterPipe]
})
export class AccidentsModule { }
