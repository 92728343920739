import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { IAccidentDetails } from '../models/accident-details.model';

@Injectable()

export class AccidentsService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  refreshDataSubject: Subject<any> = new Subject<any>();

  constructor(private http: HttpClient) { }

  getAccidentsByDate(startDate: string, endDate: string) {
    return this.http.get(`${_G.extendApiUrl}accident?start=${startDate}&end=${endDate}`, this.requestOptions);
  };

  getAccidentDetails(id: number) {
    return this.http.get(`${_G.extendApiUrl}accident/byid?id=${id}`, this.requestOptions);
  };

  getAllLocationInfo() {
    return this.http.get(`${_G.extendApiUrl}accident/zips`, this.requestOptions);;
  };

  getAllAccidentLoads() {
    return this.http.get(`${_G.extendApiUrl}accident/loads`, this.requestOptions)
  };

  createUpdateAccident(obj: IAccidentDetails) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}accident`, json, this.requestOptions);
  };

  addUpdateContact(obj: any) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}accident/contacts`, json, this.requestOptions);
  };

  getAllFilesByAccident(accidentId: number) {
    return this.http.get(`${_G.apiUrl}Download/accidentfiles?id=${accidentId}`);
  };

  getAccidentFileById(fileId: number, filename: string) {
    return this.http.get(`${_G.apiUrl}Download/accidentfile?id=${fileId}&file_name=${filename}`);
  };

  getAllAccidentsFile(accidentId: number) {
    return this.http.get(`${_G.extendApiUrl}Download/accidentfiles?id=${accidentId}`, this.requestOptions);
  };

  uploadAccidentFile(accidentId: number, formData: any) {
    return this.http.post(`${_G.apiUrl}upload/accident/${accidentId}`, formData);
  };

  getBrokersAndCommodities() {
    return this.http.get(`${_G.extendApiUrl}dispatcher/brokersvalue`, this.requestOptions);
  }; 
  
  getCoNameList() {
    return this.http.get(`${_G.extendApiUrl}accident/locations`, this.requestOptions);
  };

}
