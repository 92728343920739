import { Component, OnDestroy, OnInit } from '@angular/core';
import { MailsSettingsFormComponent } from './mails-settings-form/mails-settings-form.component';
import { NAService } from '@app/modules/notifications-alerts-settings/services/n-a.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-mails-settings',
  templateUrl: './mails-settings.component.html',
  styleUrls: ['./mails-settings.component.scss']
})
export class MailsSettingsComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[60].data;
  dataSource: any[] = [];
  searchValue: string = '';

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription = new Subscription();
  
  constructor(private dialog: MatDialog,
              private transformService: TransformService,
              private rulesService: RulesService,
              private notificationsAlertsSettingsService: NAService,
              public dialogRef: MatDialogRef<MailsSettingsComponent>,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getAllDivisionsMails();
  };

  getAllDivisionsMails() {
    this.spinner.show('mails-divisions-settings-table');
    this.subscription = this.notificationsAlertsSettingsService.getAllDivisionSettings()
    .pipe(catchError((err: any) => {
      this.spinner.hide('mails-divisions-settings-table');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.dataSource = response;
      this.spinner.hide('mails-divisions-settings-table');
      this.loaded = true;
      console.log(response);
    })
  }

  addEditMailDivision(editMode: boolean, data: any) {
    if((this.permissions[1].sectionArray[0].allowed && !editMode) || (this.permissions[1].sectionArray[1].allowed && editMode)) {
      let dialogRef: any = this.dialog.open(MailsSettingsFormComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'component-dialog-container',
        data: {editMode: editMode, data: data}
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          if(editMode) {
            let index: number = this.getIndex(data.id);
            this.dataSource[index] = result;
          }
          else {
            this.dataSource.push(result);
          }
          this.dataSource = [...this.dataSource];
        }
      })
    }
    else {
      this.showForbiddenMesage();
    }
  }

  deleteDivisionMail(obj: any, index: number) {
    if(this.permissions[1].sectionArray[2].allowed) {
      let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
        autoFocus: false,
        panelClass: 'delete-dialog-container',
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        console.log(result)
        if(result) {
          this.notificationsAlertsSettingsService.deleteDivisionSettings(obj.id)
          .pipe(catchError((err: any) => {
            this.showErrorMessage();
            return throwError(() => err);
          }))
          .subscribe((response: any) => {
            if(response) {
              this.dataSource.splice(index, 1);
              this.dataSource = [...this.dataSource];
            };
          });
        }
      })
    }
    else {
      this.showForbiddenMesage();
    }
  };

  getIndex(id: number) {
    for(let i = 0; i < this.dataSource.length; i++) {
      if(this.dataSource[i].id === id) {
        return i;
      };
    };
  };

  //Show forbidden message
  showForbiddenMesage() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  }

  //Show error message
  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}
