import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardMaintenancePageComponent } from './components/maintenance-page/dashboard-maintenance-page.component';
import { AuthGuard } from '../core/services/auth/auth.guard';
import { MaintenanceRepairLogPageComponent } from './components/maintenance-repair-log-page/maintenance-repair-log-page.component';

const routes: Routes = [
	{
		path: 'maintenance',
		canActivate: [AuthGuard],
        children: [
           {
             path: 'maintenance-page',
             component: DashboardMaintenancePageComponent
           },
    ],
    data: { roles: 11 }
	},
  {
		path: 'maintenance',
		canActivate: [AuthGuard],
        children: [
           {
             path: 'maintenance-repair-log',
             component: MaintenanceRepairLogPageComponent
           },
    ],
    data: { roles: 60 }
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class MaintenanceRoutingModule { }