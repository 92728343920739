import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { ITask } from '../models/task-model';
import { Subject } from 'rxjs';

@Injectable()

export class TasksService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  refreshData: Subject<any> = new Subject();

  constructor(private http: HttpClient) { }

  getAllTasks() {
	  return this.http.get(`${_G.extendApiUrl}dispatcher/tasks`, this.requestOptions);
  };

  createUpdateTask(array: ITask[]) {
	  let json: string = JSON.stringify(array);
	  return this.http.post(`${_G.extendApiUrl}dispatcher/tasks`, json, this.requestOptions);
  };

  deleteTask(taskId: number) {
	  return this.http.delete(`${_G.extendApiUrl}dispatcher/tasks/${taskId}`, this.requestOptions);
  };

  trigger(id: number, date: string) {
	  return this.http.get(`${_G.extendApiUrl}dispatcher/trigger?id=${id}&date=${date}`, this.requestOptions);
  };

  getTrucks() {
	  return this.http.get(`${_G.extendApiUrl}trucks/pttrucks`, this.requestOptions);
  };

  getTrailers() {
    return this.http.get(`${_G.extendApiUrl}trailers/pttailers`, this.requestOptions);
  };

  getDrivers() {
    return this.http.get(`${_G.extendApiUrl}drivers/ptdrivers`, this.requestOptions);
  };

  getActiveLoads() {
    return this.http.get(`${_G.extendApiUrl}loads/loadsnotcompleted`, this.requestOptions);
  };

  createUpdateRelatedTo(obj: any) {
	  let json: string = JSON.stringify(obj);
	  return this.http.post(`${_G.extendApiUrl}dispatcher/categorytasks`, json, this.requestOptions);
  };

  getRelatedToData() {
    return this.http.get(`${_G.extendApiUrl}dispatcher/categorytasks`, this.requestOptions);
  };

  deleteRelatedTo(ids: number, id: number, user: string, date: string) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/deletecategorytasks?ids=${ids}&id=${id}&user=${user}&date=${date}`, this.requestOptions);
  };

  sendTaskAlert(alertType: number, array: any[], date: string) {
    let json: string = JSON.stringify(array);
    return this.http.post(`${_G.extendApiUrl}dispatcher/taskalert?alert_type=${alertType}&date=${date}`, json, this.requestOptions);
  };

  getFilesByTaskId(taskId: number) {
    console.log(`${_G.extendApiUrl}dispatcher/tasksfiles?task_id=${taskId}`)
    return this.http.get(`${_G.extendApiUrl}dispatcher/tasksfiles?task_id=${taskId}`, this.requestOptions);
  };

}
