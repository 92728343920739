import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentTable'
})
export class PaymentTablePipe implements PipeTransform {

  transform(array: any[], filterObj: any): any[] {
    if (!array) {
      return [];
    }

    let transformedArray: any[] = [];
    for(let i = 0; i < array.length; i++) {
      let condition1: boolean = filterObj.IssuedBy.length === 0 ? true : filterObj.IssuedBy.includes(array[i].IssuedBy);
      let condition2: boolean = filterObj.DriverName.length === 0 ? true : filterObj.DriverName.includes(array[i].DriverName);
      let condition3: boolean = filterObj.division.length === 0 ? true : filterObj.division.includes(array[i].division);
      let condition4: boolean = filterObj.unit_no.length === 0 ? true : filterObj.unit_no.includes(array[i].unit_no);
      let condition5: boolean = this.calendarFilter(filterObj.DateOfPayment, array[i].DateOfPayment);
      let condition6: boolean = filterObj.PaymentMethod.length === 0 ? true : filterObj.PaymentMethod.includes(array[i].PaymentMethod);
      let condition7: boolean = filterObj.MoneyCode.length === 0 ? true : filterObj.MoneyCode.includes(array[i].MoneyCode);
      let condition8: boolean = this.numericFilter(filterObj.IssuedAmount.sign, filterObj.IssuedAmount.value, array[i].IssuedAmount);
      let condition9: boolean = this.numericFilter(filterObj.PaymantAmount.sign, filterObj.PaymantAmount.value, array[i].PaymantAmount);
      let condition10: boolean = filterObj.PaymentIssuedTo.length === 0 ? true : filterObj.PaymentIssuedTo.includes(array[i].PaymentIssuedTo);
      let condition11: boolean = filterObj.TypeOfRepair.length === 0 ? true : filterObj.TypeOfRepair.includes(array[i].TypeOfRepair);
      let condition12: boolean = this.numericFilter(filterObj.AmountUsed.sign, filterObj.AmountUsed.value, array[i].AmountUsed);
      let condition13: boolean = this.numericFilter(filterObj.Quantity.sign, filterObj.Quantity.value, array[i].Quantity);
      let condition14: boolean = filterObj.Stete.length === 0 ? true : filterObj.Stete.includes(array[i].Stete);
      let condition15: boolean = filterObj.ReasonForPeyment.length === 0 ? true : filterObj.ReasonForPeyment.includes(array[i].ReasonForPeyment);
      let condition16: boolean = this.checkboxFilter(filterObj.reviewed, 'Checked', array[i].reviewed);
      let condition17: boolean = this.checkboxFileFilter(filterObj.fileName, 'With file', array[i].fileName);
      let condition18: boolean = this.checkboxFilter(filterObj.chargeTheDriver, 'Checked', array[i].chargeTheDriver);

      if(condition1 && condition2 && condition3 &&  condition4 && condition5 && condition6 && condition7
      && condition8 && condition9 && condition10 &&  condition11 && condition12 && condition13 && condition14 && condition15 
      && condition16 && condition17 && condition18) {
        transformedArray.push(array[i]);
      }
    };
    return transformedArray;
  }

  calendarFilter(selectedDateRange: any, dateToCompare: string) {
    let condition: boolean = true;
    if(selectedDateRange) {
      const start = Date.parse(selectedDateRange.start._d);
      const end = Date.parse(selectedDateRange.end._d);
      const d = Date.parse(dateToCompare);
      condition = d.valueOf() >= start.valueOf() && d.valueOf() <= end.valueOf();
    }
    return condition;
  };

  numericFilter(sign: string, value: number | null, elementValue: number) {
    let key: boolean = true;
    if(value) {
      if(sign === 'More than') {
        key = Number(elementValue) > Number(value);
      }
      if(sign === 'Less than') {
        key = Number(elementValue) < Number(value);
      }
      if(sign === 'Equal') {
        key = Number(elementValue) == Number(value);
      }
    }
    return key;
  };

  checkboxFilter(selectedValue: string, checkedValue: string, value: boolean) {
    let condition: boolean = true;
    if(selectedValue === 'Both') {
      condition  = true;
    }
    else if(selectedValue === checkedValue) {
      condition = value === true;
    }
    else {
      condition = value === false;
    }
    return condition;
  };

  checkboxFileFilter(selectedValue: string, checkedValue: string, value: string) {
    let condition: boolean = true;
    if(selectedValue === 'Both') {
      condition  = true;
    }
    else if(selectedValue === checkedValue) {
      condition = value.length > 0;
    }
    else {
      condition = value.length === 0;
    }
    return condition;
  }

}
