import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Subject, forkJoin } from 'rxjs';

@Injectable()
export class EmployeeService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy',
    }),
    responseType: 'blob' as 'json',
  };

  refreshDataSubject: any = new Subject<any>();

  constructor(private http: HttpClient) { }

  getAllEmployees() {
    return this.http.get(`${_G.extendApiUrl}employee`, this.requestOptions);
  }

  createEmployee(obj: any) {
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}employee`, json, this.requestOptions);
  };

  deleteEmployee(id: number) {
    return this.http.delete(`${_G.extendApiUrl}employee/${id}`, this.requestOptions);
  };

  getEmployeeDocument(id: number, filename: string) {
    return this.http.get(`${_G.extendApiUrl}employee/employeedocument?id=${id}&name=${filename}`, this.requestOptions);
  };  

  deleteEmployeeDocument(id: number, filename: string, fileId: number) {
    return this.http.get(`${_G.extendApiUrl}employee/deleteemployeedocument?id=${id}&name=${filename}&doc_id=${fileId}`, this.requestOptions)
  }

  getEmployeeTableData(startDate: string, endDate: string) {
    let response1: any = this.http.get(`${_G.extendApiUrl}employee`, this.requestOptions);
    let response2: any = this.http.get(`${_G.extendApiUrl}employee/employeestatus?start=${startDate}&end=${endDate}`, this.requestOptions);
    return forkJoin([response1, response2]);
  };

  getActivityStats(employeeId: number, startDate: string, endDate: string) {
    return this.http.get(`${_G.extendApiUrl}employee/activitystatus?employee_id=${employeeId}&start=${startDate}&end=${endDate}`, this.requestOptions);
  };

  getActivityStatsForAll(startDate: string, endDate: string) {
    return this.http.get(`${_G.extendApiUrl}employee/employeestatus?start=${startDate}&end=${endDate}`, this.requestOptions);
  };

  //Employee departments
  getUsersFromPt() {
    return this.http.get(`${_G.extendApiUrl}users/ptusers`, this.requestOptions);
  };

  getAllEmployeeDepartments() {
    return this.http.get(`${_G.extendApiUrl}employee/dedmpartments`, this.requestOptions);
  };

  getEmployeeDepartmentsAndCompanies() {
    let response1: any = this.http.get(`${_G.extendApiUrl}employee/dedmpartments`, this.requestOptions);
    let response2: any = this.http.get(`${_G.extendApiUrl}Maintenance/getCompanyData`, this.requestOptions);
    return forkJoin([response1, response2]);
  };

  getEmployeeDepartmentsAndOffices() {
    let response1: any = this.http.get(`${_G.extendApiUrl}employee/dedmpartments`, this.requestOptions);
    let response2: any = this.http.get(`${_G.extendApiUrl}employee/city`, this.requestOptions);
    return forkJoin({response1: response1, response2: response2});
  }

  createUpdateEmployeeDepartment(obj: any) {
    let json: any = JSON.stringify(obj);
    console.log(json)
    return this.http.post(`${_G.extendApiUrl}employee/dedmpartments`, json, this.requestOptions);
  };

  deleteEmloyeeDepartment(id: number) {
    return this.http.get(`${_G.extendApiUrl}employee/deletededmpartments?id=${id}`, this.requestOptions);
  };

  //Employee cities
  getAllEmployeeCities() {
    return this.http.get(`${_G.extendApiUrl}employee/city`, this.requestOptions);
  };

  addUpdateEmployeeCity(obj: any) {
    let json: any= JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}employee/city`, json, this.requestOptions);
  };

  deleteEmployeeCity(id: number) {
    return this.http.get(`${_G.extendApiUrl}employee/deletecity?id=${id}`, this.requestOptions);
  };

  //Employee positions
  getAllEmployeePositions() {
    return this.http.get(`${_G.extendApiUrl}employee/positions`, this.requestOptions);
  };

  addUpdateEmployeePosition(obj: any) {
    let json: any= JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}employee/positions`, json, this.requestOptions);
  };

  deleteEmployeePosition(id: number) {
    return this.http.get(`${_G.extendApiUrl}employee/deletepositions?id=${id}`, this.requestOptions);
  };

  //Send request
  sendRequest(obj: any) {
    const json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}employee/absence`, json, this.requestOptions);
  };

  //Requests
  getAllRequest(startDate: string, endDate: string) {
    console.log(`${_G.extendApiUrl}employee/absence?start=${startDate}&end=${endDate}`)
    return this.http.get(`${_G.extendApiUrl}employee/absence?start=${startDate}&end=${endDate}`, this.requestOptions);
  };

}
