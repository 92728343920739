import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';

@Injectable()

export class NAService {
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
  });
  requestOptions = { headers: this.headers };

  constructor(private http: HttpClient) { }

  getAllNotifications() {
    return this.http.get(`${_G.extendApiUrl}notification/settings`, this.requestOptions);
  };

  changeNotificationSettings(obj: any) {
    const json: string = JSON.stringify(obj);
    console.log(json)
    return this.http.post(`${_G.extendApiUrl}notification/settings`, json, this.requestOptions);
  };

  deleteNotificationSettings(id: number) {
    return this.http.delete(`${_G.extendApiUrl}notification/settings?id=${id}`, this.requestOptions);
  };

  getAllEmails() {
    const req1: any = this.http.get(`${_G.extendApiUrl}notification/mails`, this.requestOptions);
    const req2: any = this.http.get(`${_G.extendApiUrl}employee`, this.requestOptions);
    return forkJoin({req1: req1, req2: req2});
  };

  getAllDivisionSettings() {
    return this.http.get(`${_G.extendApiUrl}notification/mails`, this.requestOptions);
  };

  changeDivisionSettings(obj: any) {
    const json: string = JSON.stringify(obj);
    console.log(json);
    return this.http.post(`${_G.extendApiUrl}notification/mails`, json, this.requestOptions);
  };

  deleteDivisionSettings(id: number) {
    return this.http.delete(`${_G.extendApiUrl}notification/mails?id=${id}`, this.requestOptions);
  };
}
