import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TransformService } from '@app/modules/shared/services/transform.service';

import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexStroke, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { Subscription } from 'rxjs';
import { DispatchersPerformancesService } from '../../services/dispatchers-performances.service';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  yaxis: ApexYAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  stroke: ApexStroke | any;
  markers: any;
  colors: any;
};

@Component({
  selector: 'app-dispatch-p-table-line-chart',
  templateUrl: './dispatch-p-table-line-chart.component.html',
  styleUrls: ['./dispatch-p-table-line-chart.component.scss']
})
export class DispatchPTableLineChartComponent implements OnInit, OnDestroy {
  @Input() date: any;
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;
  subscription: Subscription | any;

  selectPeriod: FormControl | any;

  addedNames: string[] = [];

  graphData: any[] = [];

  //Period
  isOpenPeriodMenu: boolean = false;
  menuValue: string = 'By day';
  selectPeriodArray: any[] = [
    {name: 'By year', value: 'year'},
    {name: 'By quarter', value: 'quarter'},
    {name: 'By month', value: 'month'},
    {name: 'By week', value: 'week'},
    {name: 'By day', value: 'day'},
  ];

  avgTruckNumber: number | any = 0;
  isTruckSelected: boolean = false;

  constructor(private dispatchersPerformancesService: DispatchersPerformancesService, private transformService: TransformService) {
    this.selectPeriod = new FormControl(['day']);
  }

  ngOnInit(): void {
    this.dataChanged();
  }

  dataChanged() {
    this.subscription = this.dispatchersPerformancesService.dispatchTableLineChartSubject.subscribe((response: any) => {
      if (response.reset) {
        this.graphData = [];
        this.addedNames = [];
      } else {

        let dispName: string = response.dispatcherName + ' (' + response.title + ')';
        if (this.addedNames.includes(dispName) && this.addedNames.length > 0) {
          this.graphData = this.graphData.filter(object => {
            return object.name[0] !== dispName
          });
          this.addedNames = this.addedNames.filter(name => {
            return name !== dispName
          });

        } else {
          this.addedNames.push(dispName);
          
          let returnedobj: any = this.transformService.countDataByPeriodDispath(response.data, this.selectPeriod.value[0], response?.key,
            response?.title);
          this.graphData.push(returnedobj);

          if(response.key === 'drivers') {
            this.countAvgNumOfTrucks(returnedobj);
          }

        }

        this.isTruckSelected = this.addedNames.some((element: any) => {
          return element.endsWith('(TRUCKS)') === true;
        });

        if(this.graphData.length > 0) {
          this.initGraph(this.graphData);
        }
      }
    })
  }

  //Count avg value for truck
  countAvgNumOfTrucks(obj: any) {
    let dataArray: any[] = obj.total;
    let sumValue: number = 0;
    for(let i = 0; i < dataArray.length; i++) {
      sumValue += dataArray[i];
    }
    let avgValue: number = sumValue / dataArray.length;
    if(avgValue) {
      this.avgTruckNumber = avgValue.toFixed(1);
    }
    else {
      this.avgTruckNumber = avgValue;
    }
  };

  initGraph(data: any) {
    let allDatesArray: any[] = this.transformService.getDatesInRange(new Date(this.date.startDate), new Date(this.date.endDate), this.selectPeriod.value[0]);
    let arrayData: any[] = JSON.parse(JSON.stringify(data));
    let seriesData: any[] = [];
    for (let key in arrayData) {

      let obj: any = {
        name: arrayData[key].name,
        data: arrayData[key].total
      }
      
      for(let i = 0; i < allDatesArray.length; i++) {
          if(arrayData[key].categories[i] !== allDatesArray[i]) {
            arrayData[key].categories.splice(i, 0, allDatesArray[i])
            arrayData[key].total.splice(i, 0, 0);
          }
      }
      seriesData.push(obj)
    }
    this.chartOptions = {
      series: seriesData,
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
          }
        },
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        },
        fontFamily: 'Poppins'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight",
        width: 1,
      },
      grid: {
        row: {
          colors: ["transparent", "transparent"],
          opacity: 0.5
        }
      },
      xaxis: {
        categories: arrayData[0].categories
      },
      yaxis: this.addYAxis(seriesData),
      markers: {
        size: 5
      },
      colors: ['#0030FF', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'],
      tooltip: {
        enabled: true,
        inverseOrder: false,
        style: {
          fontSize: '12px'
        },
      }
    };
  }

  addYAxis(array: any) {
    let yAxisArray: any[] = [];
    let colors: string[] = ['#0030FF', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'];
    for(let i = 0; i < array.length; i++) {
      let obj: any;
      if(i === 0) {
        obj = {
        seriesName: array[i].name[0], axisTicks: {show: true}, axisBorder: {show: false, color: colors[i]}, min: 0, forceNiceScale: true,
        labels: {style: {colors: colors[i]}, formatter: (num: number) => { return num.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
        }
      } 
      else {
        obj = {
          seriesName: array[i].name[0], opposite: true, axisTicks: {show: true}, axisBorder: {show: false, color: colors[i]}, min: 0, forceNiceScale: true,
          labels: {style: {colors: colors[i]}, formatter: (num: number) => { return num.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
        }
      }

      yAxisArray.push(obj);
    }

    return yAxisArray;
  }

  onlyOneSelect(value: string, obj: any) {
    this.menuValue = obj.name;
    this.selectPeriod = new FormControl([value]);
    let lineChartData: any = JSON.parse(JSON.stringify(this.graphData));
    this.graphData = [];
    for (let key in lineChartData) {
      let objToPush: any = this.transformService.countDataByPeriodDispath(lineChartData[key].data, this.selectPeriod.value[0],
      lineChartData[key].key, lineChartData[key].title);
      if(lineChartData[key].key === 'drivers') {
        this.countAvgNumOfTrucks(objToPush);
      }
      this.graphData.push(objToPush);
    }
    this.initGraph(this.graphData);
    this.isOpenPeriodMenu = false;
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}
