import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { Subject} from 'rxjs';

@Injectable()
export class InspectionsService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  refresh$: Subject<boolean> = new Subject<boolean>();
  data$: Subject<any> = new Subject<any>();
  
  constructor(private http: HttpClient, private transformService: TransformService) { }

  getInspectionData(startDate: string, endDate: string) {
    return this.http.get(`${_G.extendApiUrl}drivers/DotInspections?start=${startDate}&end=${endDate}${this.transformService.filterParams}`, this.requestOptions);
  }

  deleteDotInspection(id: number) {
    return this.http.get(`${_G.extendApiUrl}drivers/deleteDotInspections?id=${id}`, this.requestOptions);
  }

  getTrucks() {
    return this.http.get(`${_G.extendApiUrl}mitf/mitfttruckdata?${this.transformService.filterParams}`, this.requestOptions);
  }

  getViolations() {
    return this.http.get(`${_G.extendApiUrl}drivers/vioaltion`, this.requestOptions);
  };

  deleteViolation(id: number) {
    const url = `${_G.extendApiUrl}drivers/deleteviolation?id=${id}`;
    return this.http.get(url, this.requestOptions);
  };

  deleteRelated(id: number) {
    const url = `${_G.extendApiUrl}drivers/deleterelated?id=${id}`;
    return this.http.get(url, this.requestOptions);
  };

  saveInspection(editItem: any) {
    let json: string = JSON.stringify(editItem);
    console.log(json)
    return this.http.post(`${_G.extendApiUrl}drivers/editDotInspection`, json, this.requestOptions);
  }

  //Create violation
  createViolation(obj: any) {
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}drivers/vioaltion`, json, this.requestOptions);
  };

  //Delete violation
  deleteViolationFromList(section: string) {
    return this.http.get(`${_G.extendApiUrl}drivers/deletevioaltions?section=${section}`, this.requestOptions);
  };

}
