import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'form-closed',
  templateUrl: './form-closed.component.html',
  styleUrls: ['./form-closed.component.scss']
})
export class FormClosedComponent {
  @Input() formGroup: FormGroup;
}
