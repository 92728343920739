import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, Subscription, throwError } from 'rxjs';
import { BreakdownByMileageTrucksDialogComponent } from '../breakdown-by-mileage-trucks-dialog/breakdown-by-mileage-trucks-dialog.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { MaintenanceService } from '@app/modules/maintenance/services/maintenance.service';

@Component({
  selector: 'app-break-down-by-mileage-trucks',
  templateUrl: './break-down-by-mileage-trucks.component.html',
  styleUrls: ['./break-down-by-mileage-trucks.component.scss']
})
export class BreakDownByMileageTrucksComponent implements OnInit, OnChanges, OnDestroy {
  @Input() date: any;

  breakDownByMileageData: any;

  subscription1: Subscription | any;
  subscription2: Subscription | any;

  //Interval
  interval: any;

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  constructor(private maintenanceService: MaintenanceService, 
              public transformService: TransformService,
              private dialog: MatDialog,
              private spinner: NgxSpinnerService,
              private rulesService: RulesService) {}

  ngOnInit() {
    this.spinner.show('breakdown-by-mileage');
    this.dateChanged();
    if(this.rulesService.liveUpdate) {
      this.interval = setInterval(() => {
        this.getData();
      }, this.rulesService.miliseconds);
    }
  }

  ngOnChanges(): void {
    if(this.date) { 
      this.getData();
    }
  }

  dateChanged() {
    this.subscription1 = this.maintenanceService.dateChanged.subscribe((response: any) => {
      this.date.startDate = response.startDate;
      this.date.endDate = response.endDate;
      this.breakDownByMileageData = undefined;
      this.error = false;
      this.loaded = false;
      this.spinner.show('breakdown-by-mileage');
      this.loaded = false;
      this.getData();
    })
  }

  getData() {
    this.subscription2 = this.maintenanceService.getBreakDownByMileage(this.date.startDate, this.date.endDate, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('breakdown-by-mileage');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.breakDownByMileageData = response;
      this.spinner.hide('breakdown-by-mileage');
      this.loaded = true;
      console.log(response);
    })
  }

  //Open details dialog
  openDetails(index: number, name: string) {
    this.dialog.open(BreakdownByMileageTrucksDialogComponent, {
      width: '1000px',
      height: '693px',
      autoFocus: false,
      panelClass: 'truck-mileage-dialog',
      data: {index: index, card: name, obj: this.breakDownByMileageData}
    });
  };

  ngOnDestroy(): void { 
    clearInterval(this.interval);
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  }
}
