import { Component, Input } from '@angular/core';

@Component({
  selector: 'footer-page',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  yearDate : Date = new Date();

  get dashboardTheme(): boolean {
    if(localStorage.getItem('dashboard-theme')) {
      return JSON.parse(localStorage.getItem('dashboard-theme'));
    }
    return false;
  }
}
