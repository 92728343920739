import { Component } from '@angular/core';

@Component({
  selector: 'app-driver-applications-page',
  templateUrl: './driver-applications-page.component.html',
  styleUrls: ['./driver-applications-page.component.scss']
})
export class DriverApplicationsPageComponent {
  //Tabs array
  tabsArray: any[] = [
    {id: 1, name: 'Overview'}, {id: 2, name: 'Hiring process'}, {id: 3, name: 'Orientation scheduled'}
  ]
  activeTab: string = 'Hiring process';
}
