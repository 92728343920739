import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SafetyService } from '@app/modules/safety/services/safety.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-delete-file-confirmation-dialog',
  templateUrl: './delete-file-confirmation-dialog.component.html',
  styleUrls: ['./delete-file-confirmation-dialog.component.scss']
})
export class DeleteFileConfirmationDialogComponent implements OnInit {
  //Category
  categoryName: string = '';

  //Divisions
  selectedDivisions: string[] = [];
  isOpenDivision: boolean = false;
  divisionsArray: any[] = [];

  //Truck type
  selectedTypes: string[] = ['Company', 'Rent to run', 'Owner'];
  isOpenTruckType: boolean = false;
  trucksTypeArray: any[] = [
    {type: 'Company', selected: true, filter: ['SOLO COMPANY', 'TEAM COMPANY']},
    {type: 'Rent to run', selected: true, filter: ['SOLO RENT', 'TEAM RENT']},
    {type: 'Owner', selected: true, filter: ['OWNER', 'OWNER OPERATOR']},
  ];
  allTypesObj: any = {selected: true};
  allTrucksWithAndWithoutType: any[] = [];

  //Truck unit no
  isOpenTruckMenu: boolean = false;
  truckUnitValues: string[] = [];
  allTrucks: any = {selected: false};
  trucksArray: any[] = [];

  searchText: string = '';

  applyToOneTruck: boolean = true;

  subscription: Subscription | any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<DeleteFileConfirmationDialogComponent>,
  private safetyService: SafetyService) {  }

  ngOnInit(): void {
    this.subscription = this.safetyService.getTrucksForSearch().subscribe((response: any) => {
      this.trucksArray = response;
      this.allTrucksWithAndWithoutType = response;
      this.groupByProperty(response);
      console.log(response);
    });
    this.data.categories.forEach(element => {
      if(element.ID === this.data.categoryId) {
        this.categoryName = element.categoryName;
      }
    });
    console.log(this.data);
  }

  //Select truck type
  selectAllTypes() {
    this.allTypesObj.selected = !this.allTypesObj.selected;

    for(let key in this.trucksTypeArray) {
      this.trucksTypeArray[key].selected = this.allTypesObj.selected;
    }

    this.trucksArray = [...this.allTrucksWithAndWithoutType];
    console.log(this.trucksArray)
  };

   selectTruckType(obj: any) {
    obj.selected = !obj.selected;
    let index: number = this.selectedTypes.indexOf(obj.type);

    if(index === -1) {
      this.selectedTypes.push(obj.type);
    }
    else {
      this.selectedTypes.splice(index, 1);
    }
    this.filterData();
  };

  getTypeValues() {
    let value: string = '';

    if(this.selectedTypes.length === 3) {
      value = 'All types';
    }
    else {
      this.selectedTypes.forEach((type: string, index: number) => {
        if(index === 1) {
          value += ', ' + type;
          return
        }
        value += type;
      });
    }

    value = value === '' ? 'Type' : value;

    return value;
  }
0
  //Select truck unit
  selectAll() {
    this.truckUnitValues = [];
    let complete: boolean = JSON.parse(JSON.stringify(this.allTrucks.selected = !this.allTrucks.selected));
    this.trucksArray.forEach(t => {
      if(complete) {
        this.truckUnitValues.push(t.unit_no);
      }
      (t.selected = complete)
    });
  };

  selectUnitNo(obj: any) {
    obj.selected = !obj.selected;

    let index: number = this.truckUnitValues.indexOf(obj.unit_no);

    if(index === -1) {
      this.truckUnitValues.push(obj.unit_no);
    }
    else {
      this.truckUnitValues.splice(index, 1);
    }
    console.log(this.truckUnitValues.length)
  }

  getTruckValues() {
    let value: string = '';
    this.truckUnitValues.forEach((type: string, index: number) => {
      if(index > 0 && index < 3) {
        value += ', ' + type;
        return
      }

      if(index < 3) { 
        value += type;
      }
    });
    value = value === '' ? '#Truck' : value;
    return value ;
  };

  //Select division
  selectDivision(obj: any) {
    obj.selected = !obj.selected;
    let index: number = this.selectedDivisions.indexOf(obj.division);

    if(index === -1) {
      this.selectedDivisions.push(obj.division);
    }
    else {
      this.selectedDivisions.splice(index, 1);
    }

    this.filterData();
  };

  getDivisionValues() {
    let value: string = '';

    if(this.selectedDivisions.length === 3) {
      value = 'All divisions';
    }
    else {
      this.selectedDivisions.forEach((type: string, index: number) => {
        if(index === 1) {
          value += ', ' + type;
          return
        }
        value += type;
      });
    }

    value = value === '' ? 'Division' : value;
    return value;
  }

   //Group by property
   groupByProperty(array: any[]) {
    const groups: any = array.reduce((acc: any, obj: any) => {
      
    if (!acc[obj.companyName]) {
      acc[obj.companyName] = [];
    }
        
    acc[obj.companyName].push(obj);
      
    return acc;
      
    }, {});

    for(let key in groups) {
      this.divisionsArray.push({division: key, selected: true, data: groups[key]});
      this.selectedDivisions.push(key);
    }
  }

  //Filter data
  filterData() {
    let data: any[] = [];

    let filteredData: any[] = [];

    for(let i = 0; i < this.divisionsArray.length; i++) {
      if(this.divisionsArray[i].selected) {
        data = data.concat(this.divisionsArray[i].data);
      }
    };

    for(let key in data) {
      if(this.trucksTypeArray[0].selected && this.trucksTypeArray[0].filter.includes(data[key].driver_status)) {
        filteredData.push(data[key]);
      };

      if(this.trucksTypeArray[1].selected && this.trucksTypeArray[1].filter.includes(data[key].driver_status)) {
        filteredData.push(data[key]);
      };

      if(this.trucksTypeArray[2].selected && this.trucksTypeArray[2].filter.includes(data[key].driver_status)) {
        filteredData.push(data[key]);
      };
    }

    this.trucksArray = filteredData;
    console.log(this.trucksArray)
  };

  toggleSlider() {
    this.applyToOneTruck = !this.applyToOneTruck;
    const isSelected: boolean = this.applyToOneTruck ? false : true;
    for(let i = 0; i < this.trucksArray.length; i++) {
      this.trucksArray[i].selected = isSelected;
    };  
  };

  confirm() {
    let filteredArray: any[] = [];
    if(this.applyToOneTruck) {
      filteredArray.push(this.data.truckClicked)
    }
    else {
      this.trucksArray.forEach((element: any) => {
        if(element.selected) {
          filteredArray.push(element.unit_no);
        }
      });
    }
    console.log(this.trucksArray)
    this.dialogRef.close({filteredArray: filteredArray, fileName: this.data.obj.fileName});
  };

}
