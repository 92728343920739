import { Component, Input } from '@angular/core';
import { CreateUpdateNAComponent } from '../create-update-n-a/create-update-n-a.component';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { RulesService } from '@app/modules/shared/services/rules.service';

@Component({
  selector: 'n-a-table',
  templateUrl: './n-a-table.component.html',
  styleUrls: ['./n-a-table.component.scss']
})
export class NATableComponent {
  permissions: any = this.rulesService.UserData[60].data;
  @Input() dataSource: any[] = [];

  constructor(private dialog: MatDialog, private rulesService: RulesService) { }

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dataSource));
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return compare(a.name, b.name, isAsc);
        case 'tag':
          return compare(a.tag, b.tag, isAsc);
        case 'emails_to':
          return compare(JSON.parse(a.emails_to)?.length, JSON.parse(b.emails_to)?.length, isAsc);
        case 'emails_cc':
          return compare(JSON.parse(a.emails_cc)?.length, JSON.parse(b.emails_cc)?.length, isAsc);
        case 'emails_bcc':
          return compare(JSON.parse(a.emails_bcc)?.length, JSON.parse(b.emails_bcc)?.length, isAsc);
        case 'created_by':
          return compare(a.created_by, b.created_by, isAsc);
        case 'created_date':
          return compare(new Date(a.created_date), new Date(b.created_date), isAsc);
        case 'description':
          return compare(a.description, b.description, isAsc);
        default:
          return 0;
      }
    });
  }

  openNotificationsAlertsDialog(obj: any, index: number) {
    if(this.permissions[0].sectionArray[1].allowed) {
      let dialogRef: any = this.dialog.open(CreateUpdateNAComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'component-dialog-container',
        data: {editMode: true, data: obj}
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          if(result === 'DELETE FROM TABLE') {
            this.dataSource.splice(index, 1);
            this.dataSource = [...this.dataSource];
            return;
          };
          this.dataSource[index] = result;
          this.dataSource = [...this.dataSource];
        }
      })
    }
    else {
      this.showForbiddenMesage();
    }
  }

  //Show forbidden message
  showForbiddenMesage() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  }

  getEmailsArray(array: string): any[] {
    return JSON.parse(array);
  };

}

function compare(a: any, b: any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}