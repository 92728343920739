import { Component, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'form-cargo',
  templateUrl: './form-cargo.component.html',
  styleUrls: ['./form-cargo.component.scss']
})
export class FormCargoComponent {
  @Input() formGroup: FormGroup;

  constructor() { }

  uploadFiles(obj: any, key: string) {
    if(obj.name === 'UPLOAD') {
      const filesArray: any[] = this.formArray(key)?.controls;
      filesArray.push(obj.itemGroup);
      this.formGroup.controls[key].updateValueAndValidity();
    }
    else {
      this.formArray(key).removeAt(obj.index);
    }
  };

  //Get form value
  formData(formKey: string) {
    return this.formGroup.controls[formKey].value;
  };

  //Get form array
  formArray(formKey: string): FormArray {
    return this.formGroup.controls[formKey] as FormArray;
  };
  
}
