import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accidentTableFilter'
})
export class AccidentTableFilterPipe implements PipeTransform {

  transform(array: any[], filterObj: any): any[] {
    if (!array) {
      return [];
    }

    let transformedArray: any[] = [];
    for(let i = 0; i < array.length; i++) {
      let condition1: boolean = filterObj.drivers.length === 0 ? true : filterObj.drivers.includes(array[i].driver);
      let condition2: boolean = filterObj.loads.length === 0 ? true : filterObj.loads.includes(array[i].load_no);
      let condition3: boolean = filterObj.trucks.length === 0 ? true : filterObj.trucks.includes(array[i].unit_no);
      let condition4: boolean = filterObj.equipments.length === 0 ? true : filterObj.equipments.includes(array[i].equipment_no);
      let condition5: boolean = this.calendarFilter(filterObj.dateOccured, array[i].date_occured);
      let condition6: boolean = filterObj.faultForIncident.length === 0 ? true : filterObj.faultForIncident.includes(array[i].fault_for_incident);
      let condition7: boolean = filterObj.claimNo.length === 0 ? true : filterObj.claimNo.includes(array[i].claim_number);
      let condition8: boolean = filterObj.cities.length === 0 ? true : filterObj.cities.includes(array[i].location_city);
      let condition9: boolean = filterObj.states.length === 0 ? true : filterObj.states.includes(array[i].location_state);
      let condition10: boolean = filterObj.zips.length === 0 ? true : filterObj.zips.includes(array[i].location_zip);
      let condition11: boolean = filterObj.caseNo.length === 0 ? true : filterObj.caseNo.includes(array[i].case_number);
      let condition12: boolean = filterObj.citationNo.length === 0 ? true : filterObj.citationNo.includes(array[i].citation_number);
      let condition13: boolean = this.calendarFilter(filterObj.drugTestDate, array[i].drug_test_date);
      let condition14: boolean = this.calendarFilter(filterObj.alcoholTestDate, array[i].alcohol_test_date);
      let condition15: boolean = this.checkboxFilter(filterObj.dotRecordable, 'Checked', array[i].is_dot_recordable);
      let condition16: boolean = this.checkboxFilter(filterObj.hazmat, 'Checked', array[i].is_hazmat);
      let condition17: boolean = this.checkboxFilter(filterObj.closed, 'Checked', array[i].is_closed);

      if(condition1 && condition2 && condition3 &&  condition4 && condition5 && condition6 && condition7 && condition8 && 
      condition9 && condition10 &&  condition11 && condition12 && condition13 && condition14 && condition15 && condition16 && 
      condition17) {
        transformedArray.push(array[i]);
      }
    };
    return transformedArray;
  }

  calendarFilter(selectedDateRange: any, dateToCompare: string) {
    let condition: boolean = true;
    if(selectedDateRange) {
      const start = Date.parse(selectedDateRange.start._d);
      const end = Date.parse(selectedDateRange.end._d);
      const d = Date.parse(dateToCompare);
      condition = d.valueOf() >= start.valueOf() && d.valueOf() <= end.valueOf();
    }
    return condition;
  };

  checkboxFilter(selectedValue: string, checkedValue: string, value: boolean) {
    let condition: boolean = true;
    if(selectedValue === 'Both') {
      condition  = true;
    }
    else if(selectedValue === checkedValue) {
      condition = value === true;
    }
    else {
      condition = value === false;
    }
    return condition;
  };

}
