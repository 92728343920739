import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { catchError, throwError } from 'rxjs';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { WarningMsgDialogComponent } from '../warning-msg-dialog/warning-msg-dialog.component';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-loads-documentation-dialog',
  templateUrl: './loads-documentation-dialog.component.html',
  styleUrls: ['./loads-documentation-dialog.component.scss']
})
export class LoadsDocumentationDialogComponent implements OnInit {
  //Select menu
  isOpenMenu: boolean = false;
  fileType: string = 'File type - Other';
  menuArray: any[] = [
    {name: 'File type - Bol'},
    {name: 'File type - Confirmation'},
    {name: 'File type - Other'}
  ];

  fileNamesArray: any[] = [];

  //Upload
  newFilesArray: any[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              private sharedService: SharedService) { }

  ngOnInit(): void {
    this.data.fileNames.forEach(element => {
      this.fileNamesArray.push({fileName: element})
    });
  }

  //Preview file
  previewFile(obj: any) {
    obj.pdfLoading = true;
    this.sharedService.downloadLoadFile(this.data.loadNo, obj.fileName).subscribe((base64: string) => {
      this.sharedService.downloadPreviewFile(obj.fileName, base64);
      obj.pdfLoading = false;
    })
  }

  //Upload file
  onFileSelected(event: any) {
    let filename: string = '';
    let listOfFiles: any = event.target.files;  
    if(this.fileType === 'File type - Bol') {
      filename = 'bol.pdf';
    }
    if(this.fileType === 'File type - Confirmation') {
      filename = 'confirmation.pdf';
    }
    const formData: any = new FormData();  
    for(let i =  0; i <  listOfFiles.length; i++)  {  
      formData.append("file[]", listOfFiles[i], this.fileType === 'File type - Other' ? listOfFiles[i].name : filename);
    } 
    this.sharedService.uploadFilesToLoad(formData, this.data.loadId, filename === 'confirmation.pdf' ? true : false)
    .pipe(catchError((err: any) => {
      this.dialog.open(WarningMsgDialogComponent, {
        autoFocus: false,
        panelClass: 'warning-msg-dialog-container'
      });
      return throwError(() => err);
    }))
    .subscribe((response: any[]) => {
      if(response[0]) {
        for(let j = 0; j < listOfFiles.length; j++) {
          this.fileNamesArray.push({fileName: this.fileType === 'File type - Other' ? listOfFiles[j].name : filename})
        };
        this.dialog.open(SuccessDialogComponent, {
          autoFocus: false,
          panelClass: 'success-dialog-container'
        });  
      }
      else {
        this.dialog.open(WarningMsgDialogComponent, {
          autoFocus: false,
          panelClass: 'warning-msg-dialog-container'
        });
      }
    })
  }

}

