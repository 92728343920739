import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';
import { AddEditAccidentsDialogComponent } from '../add-edit-accidents-dialog/add-edit-accidents-dialog.component';
import * as XLSX from 'xlsx';
import { AccidentsService } from '@app/modules/accidents/services/accidents.service';
import { AccidentTableFilterPipe } from '@app/modules/accidents/pipes/accident-table-filter.pipe';
import { IAccident } from '@app/modules/accidents/models/accident.model';
import { ClaimsDialogComponent } from '../claims-dialog/claims-dialog.component';

@Component({
  selector: 'accidents-table',
  templateUrl: './accidents-table.component.html',
  styleUrls: ['./accidents-table.component.scss']
})
export class AccidentsTableComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[43].data;

  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  };

  //Excel config
  xlsxConfig: any[] = [
    {columnName: 'No.', selected: true},
    {columnName: 'Driver', selected: true},
    {columnName: 'Load #', selected: true},
    {columnName: 'Truck #', selected: true},
    {columnName: 'Equipment #', selected: true},
    {columnName: 'Date of Loss', selected: true},
    {columnName: 'Whose fault was the incident', selected: true},
    {columnName: 'Claim', selected: true},
    {columnName: 'City', selected: true},
    {columnName: 'State', selected: true},
    {columnName: 'Zip code', selected: true},
    {columnName: 'Police Case #', selected: true},
    {columnName: 'Drug Test Date', selected: true},
    {columnName: 'Alcohol Test Date', selected: true},
    {columnName: 'DOT Recordable', selected: true},
    {columnName: 'Hazmat', selected: true},
    {columnName: 'Closed', selected: true}
  ];
  
  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription = new Subscription();
  
  dataSource: IAccident[] | any = [];

  //Filters
  driverSearch: string = '';
  loadNumberSearch: string = '';
  truckSearch: string = '';
  equipmentSearch: string = '';
  claimSearch: string = '';
  citySearch: string = '';
  stateSearch: string = '';
  zipSearch: string = '';
  caseSearch: string = '';
  citationSearch: string = '';

  //Checked
  radioBtns: any[] = [
    {name: 'Checked'},
    {name: 'Unchecked'},
    {name: 'Both'},
  ];

  radioFaultBtns: any[] = [
    {name: 'Our driver'},
    {name: '3rd Party'},
    {name: 'Both'},
  ];

  //Driver fault
  isOpenFaultMenu: boolean = false;
  radioFaultValue: string = 'Both';

  //Dot
  isOpenDotMenu: boolean = false;
  radioDotValue: string = 'Both';

  //Hazmat
  isOpenHazmatMenu: boolean = false;
  radioHazmatValue: string = 'Both';

  //Closed
  isOpenClosedMenu: boolean = false;
  radioClosedValue: string = 'Both';

  //Date occured
  dateOccuredRangeValue: DateRange<Date> | undefined;
  @Output() dateOccuredRangeValueChange = new EventEmitter<DateRange<Date>>();
  isOpenDateOccuredCalendar: boolean = false;
  dateOccuredObj: any;

  //Drug test date
  dateDrugRangeValue: DateRange<Date> | undefined;
  @Output() dateDrugRangeValueChange = new EventEmitter<DateRange<Date>>();
  isOpenDateDrugCalendar: boolean = false;
  dateDrugObj: any; 

  //Alcohol test date
  dateAlcoholRangeValue: DateRange<Date> | undefined;
  @Output() dateAlcoholRangeValueChange = new EventEmitter<DateRange<Date>>();
  isOpenDateAlcoholCalendar: boolean = false;
  dateAlcoholObj: any; 

  constructor(private dialog: MatDialog,
    private accidentsService: AccidentsService,
    public transformService: TransformService, 
    private spinner: NgxSpinnerService,
    private rulesService: RulesService,
    private titleCase: TitleCasePipe,
    private accidentsTablePipe: AccidentTableFilterPipe
  ) { }

  ngOnInit(): void {
    this.getTableData();
  }

  getTableData() {
    this.dataSource = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('accidents');
    this.subscription = this.accidentsService.getAccidentsByDate(this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('accidents');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: IAccident[]) => {
      this.dataSource = response;
      this.spinner.hide('accidents');
      this.loaded = true;
      console.log(response);
    });
  };

  getDate(result: any) {
    this.dateObj.startDate = result.startDate;
    this.dateObj.endDate = result.endDate;
    this.getTableData();
  };

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dataSource));
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'load_no':
          return compare(a.load_no, b.load_no, isAsc);
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'equipment_no':
          return compare(a.equipment_no, b.equipment_no, isAsc);
        case 'date_occured':
          return compare(new Date(a.date_occured), new Date(b.date_occured), isAsc);
        case 'fault_for_incident':
          return compare(a.fault_for_incident, b.fault_for_incident, isAsc);
        case 'claim_number':
          return compare(a.claim_number, b.claim_number, isAsc);
        case 'location_city':
          return compare(a.location_city, b.location_city, isAsc);
        case 'location_state':
          return compare(a.location_state, b.location_state, isAsc);
        case 'location_zip':
          return compare(a.location_zip, b.location_zip, isAsc);
        case 'case_number':
          return compare(a.case_number, b.case_number, isAsc);
        case 'citation_number':
          return compare(a.citation_number, b.citation_number, isAsc);
        case 'drug_test_date':
          return compare(new Date(a.drug_test_date), new Date(b.drug_test_date), isAsc);
        case 'alcohol_test_date':
          return compare(new Date(a.alcohol_test_date), new Date(b.alcohol_test_date), isAsc);
        case 'is_dot_recordable':
          return compare(a.is_dot_recordable, b.is_dot_recordable, isAsc);
        case 'is_hazmat':
          return compare(a.is_hazmat, b.is_hazmat, isAsc);
        case 'is_closed':
          return compare(a.is_closed, b.is_closed, isAsc);
        default:
          return 0;
      }
    });
  }

  selectedChange(m: any, type: number) {
    if(type === 1) {
      if (!this.dateOccuredRangeValue?.start || this.dateOccuredRangeValue?.end) {
        this.dateOccuredRangeValue = new DateRange<Date>(m, null);
      } 
      else {
        const start = this.dateOccuredRangeValue.start;
        const end = m;
        if (end < start) {
            this.dateOccuredRangeValue = new DateRange<Date>(end, start);
        } else {
            this.dateOccuredRangeValue = new DateRange<Date>(start, end);
        }
        this.dateOccuredObj = this.dateOccuredRangeValue;
      } 
      this.dateOccuredRangeValueChange.emit(this.dateOccuredRangeValue);
    }
    if(type === 2) {
      if (!this.dateDrugRangeValue?.start || this.dateDrugRangeValue?.end) {
        this.dateDrugRangeValue = new DateRange<Date>(m, null);
      } 
      else {
        const start = this.dateDrugRangeValue.start;
        const end = m;
        if (end < start) {
            this.dateDrugRangeValue = new DateRange<Date>(end, start);
        } else {
            this.dateDrugRangeValue = new DateRange<Date>(start, end);
        }
        this.dateDrugObj = this.dateDrugRangeValue;
      } 
      this.dateDrugRangeValueChange.emit(this.dateDrugRangeValue);
    }
    if(type === 3) {
      if (!this.dateAlcoholRangeValue?.start || this.dateAlcoholRangeValue?.end) {
        this.dateAlcoholRangeValue = new DateRange<Date>(m, null);
      } 
      else {
        const start = this.dateAlcoholRangeValue.start;
        const end = m;
        if (end < start) {
            this.dateAlcoholRangeValue = new DateRange<Date>(end, start);
        } else {
            this.dateAlcoholRangeValue = new DateRange<Date>(start, end);
        }
        this.dateAlcoholObj = this.dateAlcoholRangeValue;
      } 
      this.dateAlcoholRangeValueChange.emit(this.dateAlcoholRangeValue);
    }
  }

  addEditAccident(isEdit: boolean, id: number | null) {
    if(this.permissions[0].sectionArray[isEdit ? 1 : 0].allowed) {
      let dialogRef = this.dialog.open(AddEditAccidentsDialogComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'component-dialog-container',
        data: {editMode: isEdit, id: id}
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.getTableData();
        }
      });
    }
    else {
      this.msgForbbidenAccess();
    }
  }

  openClaimsDialog() {
    let dialogRef = this.dialog.open(ClaimsDialogComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: 'component-dialog-container',
      data: {data: [], editMode: false}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
   
      }
    });
  };

  clearFilters() {
    this.driverSearch = '';
    this.loadNumberSearch = '';
    this.truckSearch = '';
    this.equipmentSearch = '';
    this.claimSearch = '';
    this.citySearch = '';
    this.stateSearch = '';
    this.zipSearch = '';
    this.caseSearch = '';
    this.citationSearch = '';
    this.radioFaultValue = 'Both'
    this.radioDotValue = 'Both';
    this.radioHazmatValue = 'Both';
    this.radioClosedValue = 'Both';
    this.dateOccuredRangeValue = undefined;
    this.dateOccuredObj = undefined;
    this.dateDrugRangeValue = undefined;
    this.dateDrugObj = undefined;
    this.dateAlcoholRangeValue = undefined;
    this.dateAlcoholObj = undefined;
  };

  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[20].allowed) {
      const tableData: any[] = this.accidentsTablePipe.transform(this.dataSource,
        [this.driverSearch, this.loadNumberSearch, this.truckSearch, this.equipmentSearch, this.claimSearch, this.citySearch, this.stateSearch, 
          this.zipSearch, this.caseSearch, this.citationSearch, this.radioDotValue, this.radioHazmatValue, this.radioClosedValue, 
          this.radioFaultValue], 
        ['driver', 'load_no', 'unit_no', 'equipment_no', 'claim_number', 'location_city', 'location_state', 'location_zip', 'case_number', 'citation_number', 'is_dot_recordable', 'is_hazmat', 'is_closed', 'fault_for_incident'],
        {dateOccuredObj: this.dateOccuredObj, dateDrugObj: this.dateDrugObj, dateAlcoholObj: this.dateAlcoholObj})
      const excelTable: any[] = [];
      for(let i = 0; i < tableData.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, i + 1, columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.titleCase.transform(tableData[i].driver), columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, tableData[i].load_no, columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, tableData[i].unit_no, columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, tableData[i].equipment_no, columnsConfig[4].selected);
        this.transformService.selectedColumn(obj, columnsConfig[5].columnName, this.transformDateFormat(tableData[i].date_occured, 'MMM DD, YYYY H:mm'), columnsConfig[5].selected);
        this.transformService.selectedColumn(obj, columnsConfig[6].columnName, tableData[i].fault_for_incident, columnsConfig[6].selected);
        this.transformService.selectedColumn(obj, columnsConfig[7].columnName, tableData[i].claim_number, columnsConfig[7].selected);
        this.transformService.selectedColumn(obj, columnsConfig[8].columnName, tableData[i].location_city, columnsConfig[8].selected);
        this.transformService.selectedColumn(obj, columnsConfig[9].columnName, tableData[i].location_state, columnsConfig[9].selected);
        this.transformService.selectedColumn(obj, columnsConfig[10].columnName, tableData[i].location_zip, columnsConfig[10].selected);
        this.transformService.selectedColumn(obj, columnsConfig[11].columnName, tableData[i].case_number, columnsConfig[11].selected);
        this.transformService.selectedColumn(obj, columnsConfig[12].columnName, this.transformDateFormat(tableData[i].drug_test_date, 'MMM DD, YYYY.'), columnsConfig[12].selected);
        this.transformService.selectedColumn(obj, columnsConfig[13].columnName, this.transformDateFormat(tableData[i].alcohol_test_date, 'MMM DD, YYYY.'), columnsConfig[13].selected);
        this.transformService.selectedColumn(obj, columnsConfig[14].columnName, tableData[i].is_dot_recordable, columnsConfig[14].selected);
        this.transformService.selectedColumn(obj, columnsConfig[15].columnName, tableData[i].is_hazmat, columnsConfig[15].selected);
        this.transformService.selectedColumn(obj, columnsConfig[16].columnName, tableData[i].is_closed, columnsConfig[16].selected);
        excelTable.push(obj);
      };
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'accidents.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  transformDateFormat(date: any, format: string) {
    if(date) {
      return moment(date).format(format);
    }
    return '';
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();   
  };

}

function compare(a: number | any, b: number | any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
