import { Pipe, PipeTransform } from '@angular/core';
import moment = require('moment');

@Pipe({
  name: 'filterStatements'
})
export class FilterStatementsPipe implements PipeTransform {

  transform(array: any[], avgFleet: any, toWeek: number, metaData: any): any {

    let tranformedArray: any = array.slice(0, toWeek);
    const paySign: string[] = ['SOLO COMPANY', 'TEAM COMPANY'];

    //Statistics
    let countObj: any = {
      grossAvg: 0,
      driverGrossAvg:0,
      mileageAvg: 0,
      paidMilesAvg: 0,
      rateAvg: 0,
      weightAvg: 0,
      loadsAvg: 0,
      utilizationAvg: 0,
      hometimeAvg: 0,
      dealAvg: 0,
      paycheckAvg: 0
    }

    let weekNum: number = 0;
    let sumGross: number = 0;
    let sumDriverGross: number = 0;
    let sumMileage: number = 0;
    let sumPaidMiles: number = 0;
    let sumLoads: number = 0;
    let sumHometime: number = 0;
    let sumDeal: number = 0;
    let sumPaycheck: number = 0;

    for(let i = 0; i < tranformedArray.length; i++) {
      tranformedArray[i].showPaycheck = true;
      const paymentDate = moment(tranformedArray[i].mondey);
      const currentDate = moment();
      const daysDifference = currentDate.diff(paymentDate, 'days');
      if(daysDifference <= 15 && daysDifference > 8) {
        const date = new Date();
        const optionsDateTime: any = {timeZone: 'America/Chicago', year: 'numeric', month: '2-digit', day: '2-digit' };
        const currentDateInChicago = new Intl.DateTimeFormat('en-US', optionsDateTime).format(date);
        const otherDateInChicago = paymentDate.format('MM/DD/YYYY');
        const optionsTime: any = {timeZone: 'America/Chicago', hour: 'numeric', hour12: false};
        const chicagoHour = new Intl.DateTimeFormat('en-US', optionsTime).format(date);
        if(!(currentDateInChicago === otherDateInChicago && parseInt(chicagoHour, 10) >= 8)) {
          tranformedArray[i].showPaycheck = false;
        } 
      }

      if(tranformedArray[i].gross > 0 || tranformedArray[i].mileage > 0) {
        sumGross += tranformedArray[i].gross;
        sumDriverGross += tranformedArray[i].driver_gross;
        sumMileage += tranformedArray[i].mileage;
        sumPaidMiles += tranformedArray[i].paid_mileage;
        sumLoads += tranformedArray[i].num_of_loads;
        sumHometime += tranformedArray[i].home_time;
        sumDeal += tranformedArray[i].deal;
        sumPaycheck += tranformedArray[i].statement;
        weekNum++;
      }
    }
  
    if(weekNum === 0) {
      weekNum = 1;
    }
  
    countObj.grossAvg = `$${addCommasDots(sumGross / weekNum, 'round')}`;
    countObj.driverGrossAvg = `$${addCommasDots(sumDriverGross / weekNum, 'round')}`;
    countObj.mileageAvg = `${addCommasDots(sumMileage / weekNum, 'round')}`;
    countObj.paidMilesAvg = `${addCommasDots(sumPaidMiles / weekNum, 'round')}`;
    countObj.rateAvg = `$${addCommasDots(Number.isNaN(sumGross / sumMileage) ? 0 : sumGross / sumMileage)}`;
    countObj.loadsAvg = `${addCommasDots(sumLoads / weekNum, 'round')}`;
    countObj.hometimeAvg = `${addCommasDots(sumHometime / weekNum, 'round')}`;

    if(paySign.includes(avgFleet.driver_status)) {
      countObj.dealAvg = `$${addCommasDots(sumDeal / weekNum)}`;
    } 
    else {
      countObj.dealAvg = `${addCommasDots((sumDeal * 100) / weekNum, 'round')}%`;
    }
    countObj.paycheckAvg = `$${addCommasDots(sumPaycheck / weekNum, 'round')}`;

    function addCommasDots(num: any, roundNumber?: any)  {
      typeof num !== 'number' ? num = 0 : num;
      roundNumber === 'round' ? num = Math.round(num) : num;
      let result = (num - Math.floor(num)) !== 0;
      let number: any;
      if(result) {
        number = num.toFixed(2);
      } else {
        number = num;
      }
      return number.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    };

    metaData.countObj = countObj;
    return tranformedArray;
  }

}
