import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { Sort } from '@angular/material/sort';
import { Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as XLSX from 'xlsx';

import { NgxSpinnerService } from 'ngx-spinner';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { OverviewService } from '../../services/overview.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { TitleCasePipe } from '@angular/common';
import { SortPipe } from '@app/modules/shared/pipes/sort.pipe';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { MatDialog } from '@angular/material/dialog';
import { FormatNumPipe } from '@app/modules/shared/pipes/format-num.pipe';

@Component({
  selector: 'app-dispatch-table',
  templateUrl: './dispatch-table.component.html',
  styleUrls: ['./dispatch-table.component.scss']
})
export class DispatchTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() date: any;
  subscription1: Subscription | any;
  subscription2: Subscription | any;
  subscription3: Subscription | any;
  subscription4: Subscription | any;
  subscription5: Subscription | any;

  //Excel config
  xlsxConfig: any[] = [
    {columnName: 'No.', selected: true},
    {columnName: 'Dispatcher', selected: true},
    {columnName: 'Truck', selected: true},
    {columnName: 'Company', selected: true},
    {columnName: 'Lease', selected: true},
    {columnName: 'Owner', selected: true},
    {columnName: 'Company Rate', selected: true},
    {columnName: 'Lease Rate', selected: true},
    {columnName: 'Owner Rate', selected: true},
    {columnName: 'Gross', selected: true},
    {columnName: 'Rate', selected: true},
    {columnName: 'Mileage', selected: true},
    {columnName: 'Company Benefit Miles %', selected: true},
    {columnName: 'Company Benefit Miles', selected: true},
    {columnName: 'Margin %', selected: true},
    {columnName: 'Margin', selected: true},
    {columnName: 'Length Per Load', selected: true},
    {columnName: 'Gross Per Load', selected: true},
    {columnName: 'Avg Weight', selected: true},
    {columnName: 'Driver Retention', selected: true}
  ];

  compare: boolean = true;

  clickedColumn: string | undefined;
  selectedIndexArray: number[] = [];

  dataSource: any[] = [];

  oneRowClickedColumns: boolean[] = [
    false, false, false, false, false, 
    false, false, false, false, false, 
    false, false, false, false, false, 
    false, false, false
  ];

  listByDays: any;

  graphData: any[] = [];

  title: any;

  selectedRow: any;

  metaObj: any = {
    countObj: {
      drivers: 0,
      company: 0,
      lease: 0,
      owner: 0,
      company_rate: 0,
      lease_rate: 0,
      owner_rate: 0,
      gross: 0,
      rate_per_mile: 0,
      mileage: 0,
      company_benefit_miles_percent: 0,
      company_benefit_miles: 0,
      margin_percent: 0,
      margin: 0,
      length_per_load: 0,
      gross_per_load: 0,
      avg_weight: 0,
      driver_retention: 0
    }
  };

  topByGrossArray: number[] = [];
  topByRpmArray: number[] = [];

  //Filters
  dispatchersArray: any[] = [];
  activeDispatchers: boolean = true;
  selectedDispatchersIds: number[] = [];
  searchText: string = '';

  //Interval
  interval: any;

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  constructor(private overviewService: OverviewService,
              public transformService: TransformService,
              private formatNum: FormatNumPipe,
              private sharedService: SharedService,
              private spinner: NgxSpinnerService,
              private rulesService: RulesService,
              private titleCase: TitleCasePipe,
              private sortPipe: SortPipe,
              private dialog: MatDialog) { }

  ngOnInit() {
    this.spinner.show('sp5');
    this.dateChanged();
    this.getAllDispatchers();
    if(this.rulesService.liveUpdate) {
      this.interval = setInterval(() => {
        this.getTableData();
      }, this.rulesService.miliseconds);
    }
  }

  ngOnChanges(): void {
    if (this.date) {
      this.getTableData();
    }
  }

  getAllDispatchers() {
    this.subscription5 = this.sharedService.getAllDispatchers().subscribe((response: any) => {
      this.dispatchersArray = response;
      console.log(response);
    })
  };

  dateChanged() {
    this.subscription1 = this.overviewService.dateChanged.subscribe((response: any) => {
      this.date.startDate = response.startDate;
      this.date.endDate = response.endDate;
      this.dataSource = [];
      this.error = false;
      this.loaded = false;
      this.spinner.show('sp5');
      this.getTableData();
    })
  }

  getTableData() {
    this.subscription2 = this.overviewService.getDispatchTable(this.date.startDate, this.date.endDate, this.transformService.filterParams, this.date.dispatcherOffice)
    .pipe(catchError((err: any) => {
      this.spinner.hide('sp5');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.reset();
      this.topByGrossArray = [];
      this.topByRpmArray = [];
      if(response.total.length > 4) {
        //Top 5 by gross statistics
        let tempGrossArray: any = JSON.parse(JSON.stringify(response.total));
        tempGrossArray.sort((a: any, b: any) => {
          return b.gross - a.gross;
        });
        //Top 5 by rpm statistics
        let tempRpmArray: any = JSON.parse(JSON.stringify(response.total));
        tempRpmArray.sort((a: any, b: any) => {
          return b.rate_per_mile - a.rate_per_mile;
        });

        for(let i = 0; i < response.total.length; i++) {
          if(tempGrossArray[i].gross > 0 && !tempGrossArray[i].supervisor && this.topByGrossArray.length < 5) {
            this.topByGrossArray.unshift(tempGrossArray[i].gross);
          };
          if(tempRpmArray[i].rate_per_mile > 0 && !tempRpmArray[i].supervisor && this.topByRpmArray.length < 5) {
            this.topByRpmArray.unshift(tempRpmArray[i].rate_per_mile);
          }
          if(this.topByGrossArray.length === 5 && this.topByRpmArray.length === 5) {
            break;
          }
        };
      }
      this.dataSource = response.total;
      this.listByDays = response.list;
      this.spinner.hide('sp5');
      this.loaded = true;
      console.log(response)
    })
  }

  select(obj: any, key: string, index: number, title: string, oneRowColumn?: any) {
    let array: any[] = [];
    for (let i = 0; i < this.listByDays.length; i++) {
      let name = this.listByDays[i].name;
      this.listByDays[i].company_rpm = this.listByDays[i].company_gross / this.listByDays[i].company_mileage;
      this.listByDays[i].lease_rpm = this.listByDays[i].rent_gross / this.listByDays[i].rent_mileage;
      this.listByDays[i].owner_rpm = this.listByDays[i].owner_gross / this.listByDays[i].owner_mileage;
      if (obj.name === name) {
        array.push(this.listByDays[i]);
      };
    }
    if (!this.compare) {
      this.selectedRow !== index && this.selectedRow !== undefined ? this.reset() : this.selectedRow = index;
      this.clickedColumn = key;
      this.oneRowClickedColumns[oneRowColumn] = !this.oneRowClickedColumns[oneRowColumn];
      this.selectedRow = index;
      let objForGraph = { key: this.clickedColumn, data: array, title: title, dispatcherName: obj.name, reset: false }
      this.overviewService.dispatchLineChartSubject.next(objForGraph);
    } 
    else {
      if (this.selectedIndexArray.length === 0) {
        this.clickedColumn = key;
      }
      if (this.clickedColumn === key) {

        let res = this.selectedIndexArray.findIndex((num: number) => num === index);

        if (res === -1) {
          this.selectedIndexArray.push(index);
        } else {
          this.selectedIndexArray.splice(res, 1);
        }
        let objForGraph = { key: this.clickedColumn, data: array, title: title, dispatcherName: obj.name, reset: false }
        this.overviewService.dispatchLineChartSubject.next(objForGraph);
      }
    }
  };

  setClass(index: number, clickedColumn: string, oneRowIndex?: any) {
    let className: string = '';

    if (!this.compare && this.oneRowClickedColumns[oneRowIndex] && this.selectedRow === index) {
      return className = 'active';
    }

    if (this.selectedIndexArray.includes(index) && clickedColumn === this.clickedColumn) {
      className = 'active';
    }

    return className;
  };

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dataSource));
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      if(!a.supervisor && !b.supervisor) {
        switch (sort.active) {
          case 'position':
            return compare(a.position, b.position, isAsc);
          case 'name':
            return compare(a.name, b.name, isAsc);
          case 'drivers':
            return compare(a.drivers, b.drivers, isAsc);
          case 'company':
            return compare(a.company, b.company, isAsc);
          case 'lease':
            return compare(a.lease, b.lease, isAsc);
          case 'owner':
            return compare(a.owner, b.owner, isAsc);
          case 'company_rpm':
            return compare(a.company_rpm, b.company_rpm, isAsc);
          case 'lease_rpm':
            return compare(a.lease_rpm, b.lease_rpm, isAsc);
          case 'owner_rpm':
            return compare(a.owner_rpm, b.owner_rpm, isAsc);
          case 'gross':
            return compare(a.gross, b.gross, isAsc);
          case 'rate_per_mile':
            return compare(a.rate_per_mile, b.rate_per_mile, isAsc);
          case 'mileage':
            return compare(a.mileage, b.mileage, isAsc);
          case 'benefit_mileage_percent':
            return compare(a.benefit_mileage_percent, b.benefit_mileage_percent, isAsc);
          case 'benefit_mileage':
            return compare(a.benefit_mileage, b.benefit_mileage, isAsc);
          case 'margin_percent':
            return compare(a.margin_percent, b.margin_percent, isAsc);
          case 'margin':
            return compare(a.margin, b.margin, isAsc);
          case 'lengt_per_load':
            return compare(a.lengt_per_load, b.lengt_per_load, isAsc);
          case 'gross_per_load':
            return compare(a.gross_per_load, b.gross_per_load, isAsc);
          case 'avg_weight':
            return compare(a.avg_weight, b.avg_weight, isAsc);
          case 'driver_retention':
            return compare(a.driver_retention, b.driver_retention, isAsc);
          default:
            return 0;
        }
      }
    });
  };

  //Search
  toggleSelection(element: any) {
    let index: number = this.selectedDispatchersIds.indexOf(element.id);
    if(index === -1) {
      this.selectedDispatchersIds.push(element.id);
    }
    else {
      this.selectedDispatchersIds.splice(index, 1);
    }
    this.selectedDispatchersIds = [...this.selectedDispatchersIds];
  };

  onChange(isTrue: boolean) {
    this.compare = isTrue;
    this.reset();
  };

  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[0].allowed) {
      const tableData: any[] = this.sortPipe.transform(this.dataSource);
      const excelTable: any[] = [];
      for(let i = 0; i < tableData.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, i + 1, columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.titleCase.transform(tableData[i].name), columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, this.formatNum.transform(tableData[i].drivers, 1), columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, this.formatNum.transform(tableData[i].company, 1), columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, this.formatNum.transform(tableData[i].lease, 2), columnsConfig[4].selected);
        this.transformService.selectedColumn(obj, columnsConfig[5].columnName, this.formatNum.transform(tableData[i].owner, 2), columnsConfig[5].selected);
        this.transformService.selectedColumn(obj, columnsConfig[6].columnName, `$${this.formatNum.transform(tableData[i].company_rpm, 2)}`, columnsConfig[6].selected);
        this.transformService.selectedColumn(obj, columnsConfig[7].columnName, `$${this.formatNum.transform(tableData[i].lease_rpm, 2)}`, columnsConfig[7].selected);
        this.transformService.selectedColumn(obj, columnsConfig[8].columnName, `$${this.formatNum.transform(tableData[i].owner_rpm, 2)}`, columnsConfig[8].selected);
        this.transformService.selectedColumn(obj, columnsConfig[9].columnName, `$${this.formatNum.transform(tableData[i].gross, 0)}`, columnsConfig[9].selected);
        this.transformService.selectedColumn(obj, columnsConfig[10].columnName, `$${this.formatNum.transform(tableData[i].rate_per_mile, 2)}`, columnsConfig[10].selected);
        this.transformService.selectedColumn(obj, columnsConfig[11].columnName, `${this.formatNum.transform(tableData[i].mileage, 0)}`, columnsConfig[11].selected);
        this.transformService.selectedColumn(obj, columnsConfig[12].columnName, `${this.formatNum.transform(tableData[i].benefit_mileage_percent, 0)}`, columnsConfig[12].selected);
        this.transformService.selectedColumn(obj, columnsConfig[13].columnName, `${this.formatNum.transform(tableData[i].benefit_mileage, 0)}`, columnsConfig[13].selected);
        this.transformService.selectedColumn(obj, columnsConfig[14].columnName, `${this.formatNum.transform(tableData[i].margin_percent, 2)}%`, columnsConfig[14].selected);
        this.transformService.selectedColumn(obj, columnsConfig[15].columnName, `$${this.formatNum.transform(tableData[i].margin, 2)}`, columnsConfig[15].selected);
        this.transformService.selectedColumn(obj, columnsConfig[16].columnName, `${this.formatNum.transform(tableData[i].lengt_per_load, 0)}`, columnsConfig[16].selected);
        this.transformService.selectedColumn(obj, columnsConfig[17].columnName, `$${this.formatNum.transform(tableData[i].gross_per_load, 0)}`, columnsConfig[17].selected);
        this.transformService.selectedColumn(obj, columnsConfig[18].columnName, `${this.formatNum.transform(tableData[i].avg_weight, 0)} lbs`, columnsConfig[18].selected);
        this.transformService.selectedColumn(obj, columnsConfig[19].columnName, `${this.formatNum.transform(tableData[i].driver_retention, 0)} days`, columnsConfig[19].selected);
        excelTable.push(obj);
      };
      let footerObj: any = {};
      this.transformService.selectedColumn(footerObj, columnsConfig[0].columnName, '', columnsConfig[0].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[1].columnName, '', columnsConfig[1].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[2].columnName, this.metaObj.countObj.drivers, columnsConfig[2].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[3].columnName, this.metaObj.countObj.company, columnsConfig[3].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[4].columnName, this.metaObj.countObj.lease, columnsConfig[4].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[5].columnName,this.metaObj.countObj.owner, columnsConfig[5].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[6].columnName, this.metaObj.countObj.company_rate, columnsConfig[6].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[7].columnName, this.metaObj.countObj.lease_rate, columnsConfig[7].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[8].columnName,this.metaObj.countObj.owner_rate, columnsConfig[8].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[9].columnName, `$${this.metaObj.countObj.gross}`, columnsConfig[9].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[10].columnName, `$${this.metaObj.countObj.rate_per_mile}`, columnsConfig[10].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[11].columnName, this.metaObj.countObj.mileage, columnsConfig[11].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[12].columnName, `${this.metaObj.countObj.company_benefit_miles_percent}%`, columnsConfig[12].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[13].columnName, this.metaObj.countObj.company_benefit_miles, columnsConfig[13].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[14].columnName, `${this.metaObj.countObj.margin_percent}%`, columnsConfig[14].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[15].columnName, `$${this.metaObj.countObj.margin}`, columnsConfig[15].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[16].columnName, this.metaObj.countObj.length_per_load, columnsConfig[16].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[17].columnName, `$${this.metaObj.countObj.gross_per_load}`, columnsConfig[17].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[18].columnName, `${this.metaObj.countObj.avg_weight} lbs`, columnsConfig[18].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[19].columnName, `${this.metaObj.countObj.driver_retention} days`, columnsConfig[19].selected);
      excelTable.push(footerObj);
      console.log(excelTable);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'dispatchers.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  reset() {
    let objForGraph = { key: undefined, data: undefined, title: undefined, dispatcherName: undefined, reset: true };
    this.selectedIndexArray = [];
    this.oneRowClickedColumns = [
      false, false, false, false, false, 
      false, false, false, false, false, 
      false, false, false, false, false, 
      false, false, false
    ];
    this.graphData = [];
    this.clickedColumn = undefined;
    this.overviewService.dispatchLineChartSubject.next(objForGraph);
  }

  toggleDispatchers(isTrue: boolean) {
    this.activeDispatchers = isTrue;
    this.resetFilters();
  };

  resetFilters() {
    this.selectedDispatchersIds = [];
    this.searchText = '';
  };

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
    this.subscription4?.unsubscribe();
    this.subscription5?.unsubscribe();
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
