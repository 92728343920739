import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'claimTable'
})
export class ClaimTablePipe implements PipeTransform {

  transform(array: any[], filterObj: any, filterMetaData: any): any[] {
    if (!array) {
      return [];
    }
    const countObj: any = {
      'Total': 0,
      'Active': 0,
      'Closed': 0,
      'On Ice': 0,
      'Pending Check': 0,

      '1': 0,//Liability – Physical Damage
      '2': 0, //Cargo
      '3': 0, //Physical Damage
      '4': 0, //Liability – Personal Injury
      '5': 0, //Liability – Property Damage
    }
    console.log(filterObj)
    let transformedArray: any[] = [];
    for(let i = 0; i < array.length; i++) {
      let condition1: boolean = filterObj.claim.length === 0 ? true : filterObj.claim.includes(array[i].claim);
      let condition2: boolean = filterObj.adjuster.length === 0 ? true : filterObj.adjuster.includes(array[i].adjuster);
      let condition3: boolean = this.calendarFilter(filterObj.claim_opened, array[i].claim_opened);
      let condition4: boolean = this.calendarFilter(filterObj.claim_closed, array[i].claim_closed);
      let condition5: boolean = filterObj.status.length === 0 ? true : filterObj.status.includes(array[i].status);
      let condition6: boolean = this.calendarFilter(filterObj.date_of_loss, array[i].date_of_loss);
      let condition7: boolean = filterObj.truck_no.length === 0 ? true : filterObj.truck_no.includes(array[i].truck_no);
      let condition8: boolean = filterObj.driver_no.length === 0 ? true : filterObj.driver_no.includes(array[i].driver_no);
      let condition9: boolean = filterObj.type_of_claim.length === 0 ? true : filterObj.type_of_claim.includes(this.getTypeOfClaim(array[i].type_of_claim));
      let condition10: boolean = filterObj.claimant.length === 0 ? true : filterObj.claimant.includes(array[i].claimant);
      let condition11: boolean = this.calendarFilter(filterObj.last_update, array[i].last_update);
      let condition12: boolean = filterObj.claimantStatus === 'Total' ? true : filterObj.claimantStatus === array[i].status;
      let condition13: boolean = filterObj.stTypeOfClaiment === 0 ? true : filterObj.stTypeOfClaiment === array[i].type_of_claim;
      console.log(condition12)
      console.log(condition13)
      //Counting statistics
      const status: string = array[i].status;
      const typeOfClaim: string = array[i].type_of_claim;
      if(countObj.hasOwnProperty(status)) {
        countObj[status]++;
      }
      if(countObj.hasOwnProperty(typeOfClaim)) {
        countObj[typeOfClaim]++;
      }
      countObj['Total']++;

      if(condition1 && condition2 && condition3 &&  condition4 && condition5 && condition6 && condition7 && condition8 && condition9 
      && condition10 &&  condition11 && condition12 && condition13) {
        transformedArray.push(array[i]);
      }
    };

    filterMetaData.countObj.total = countObj['Total'];
    filterMetaData.countObj.active = countObj['Active'];
    filterMetaData.countObj.closed = countObj['Closed'];
    filterMetaData.countObj.on_ice = countObj['On Ice'];
    filterMetaData.countObj.pending_check = countObj['Pending Check'];

    filterMetaData.countObj.liability_phys_damage = countObj['1'];
    filterMetaData.countObj.cargo = countObj['2'];
    filterMetaData.countObj.phys_damage = countObj['3'];
    filterMetaData.countObj.liability_per_injury = countObj['4'];
    filterMetaData.countObj.liability_property_damage = countObj['5'];
    console.log(transformedArray)
    return transformedArray;
  }

  getTypeOfClaim(id: number) {
    const obj: any = {'1': 'Liability – Physical Damage', '2': 'Cargo', '3': 'Physical Damage', '4': 'Liability – Personal Injury',
    '5': 'Liability – Property Damage', '6': 'OCC/ACC'};
    if(id) {
      return obj[id];
    }
    return '';
  };

  calendarFilter(selectedDateRange: any, dateToCompare: string) {
    let condition: boolean = true;
    if(selectedDateRange) {
      const start = Date.parse(selectedDateRange.start._d);
      const end = Date.parse(selectedDateRange.end._d);
      const d = Date.parse(dateToCompare);
      condition = d.valueOf() >= start.valueOf() && d.valueOf() <= end.valueOf();
    }
    return condition;
  };

  checkboxFilter(selectedValue: string, checkedValue: string, value: boolean) {
    let condition: boolean = true;
    if(selectedValue === 'Both') {
      condition  = true;
    }
    else if(selectedValue === checkedValue) {
      condition = value === true;
    }
    else {
      condition = value === false;
    }
    return condition;
  };

}
