import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { CreateUpdateAmortizationComponent } from '../create-update-amortization/create-update-amortization.component';
import { RulesService } from '@app/modules/shared/services/rules.service';

@Component({
  selector: 'report-amortization-table',
  templateUrl: './report-amortization-table.component.html',
  styleUrls: ['./report-amortization-table.component.scss']
})
export class ReportAmortizationTableComponent {
  permissions: any = this.rulesService.UserData[59].data;
  @Input() dataSource: any[] = [];
  
  filterMetaData: any = {
    countObj: {
      sumOfAmortization: 0,
      sumOfLoanAmount: 0,
      sumOfMonthlyPay: 0,
      sumOfPayOffAmount: 0,
      sumOfInterestRate: 0,

      avgNumOfAmortization: 0,
      avgNumOfLoanAmount: 0,
      avgNumOfMonthlyPay: 0,
      avgNumOfPayOffAmount: 0,
      avgNumOfInterestRate: 0,
    }
  }

  //Filters
  filters: any = {
    bankName: [],
    truckTrailer: [],
    typeofEquipment: [],
    companyLoan: [],
    loanAmount: {sign: 'More than', value: null},
    monthlyPay: {sign: 'More than', value: null},
    purchasedDate: null,
    endDate: null,
    payOffAmountLeft: {sign: 'More than', value: null},
    interestRate: {sign: 'More than', value: null},
    year: [],
    vinNo: []
  };

  constructor(private dialog: MatDialog, private rulesService: RulesService) { }

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dataSource));
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'BankName':
          return compare(a.BankName, b.BankName, isAsc);
        case 'TruckTrailer':
          return compare(a.TruckTrailer, b.TruckTrailer, isAsc);
        case 'TypeofEquipment':
          return compare(a.TypeofEquipment, b.TypeofEquipment, isAsc);
        case 'CompanyLoan':
          return compare(a.CompanyLoan, b.CompanyLoan, isAsc);
        case 'LoanAmount':
          return compare(a.LoanAmount, b.LoanAmount, isAsc);
        case 'MonthlyPay':
          return compare(a.MonthlyPay, b.MonthlyPay, isAsc);
        case 'PurchasedDate':
          return compare(new Date(a.PurchasedDate), new Date(b.PurchasedDate), isAsc);
        case 'EndDate':
          return compare(new Date(a.EndDate), new Date(b.EndDate), isAsc);
        case 'PayOffAmountLeft':
          return compare(a.PayOffAmountLeft, b.PayOffAmountLeft, isAsc);
        case 'InterestRate':
          return compare(a.InterestRate, b.InterestRate, isAsc);
        case 'Year':
          return compare(a.Year, b.Year, isAsc);
        case 'VINNO':
          return compare(a.VINNO, b.VINNO, isAsc);
        default:
          return 0;
      }
    });
  }

  openAmortizationDialog(element: any) {
    if(this.permissions[0].sectionArray[1].allowed) {
      let dialogRef = this.dialog.open(CreateUpdateAmortizationComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'component-dialog-container',
        data: {editMode: true, data: element}
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          if(result === 'DELETE AMORTIZATION') {
            let index: number = this.getIndex(element.TruckTrailer);
            this.dataSource.splice(index, 1);
            this.dataSource = [...this.dataSource];
          }
          else {
            let index: number = this.getIndex(element.TruckTrailer);
            this.dataSource[index] = result;
            this.dataSource = [...this.dataSource];
          }
        }
      });
    }
    else {
      this.showForbiddenMessage();
    }
  };

  selectFilter(propertyName: string, value: any) {
    this.filters[propertyName] = value;
    this.filters = {...this.filters};
  };

  clearFilters() {
    this.filters = {
      bankName: [],
      truckTrailer: [],
      typeofEquipment: [],
      companyLoan: [],
      loanAmount: {sign: 'More than', value: null},
      monthlyPay: {sign: 'More than', value: null},
      purchasedDate: null,
      endDate: null,
      payOffAmountLeft: {sign: 'More than', value: null},
      interestRate: {sign: 'More than', value: null},
      year: [],
      vinNo: []
    };
  };

  getIndex(TruckTrailer: string) {
    for(let i = 0; i < this.dataSource.length; i++) {
      if(this.dataSource[i].TruckTrailer === TruckTrailer) {
        return i;
      };
    };
  };

  //Show forbidden message
  showForbiddenMessage() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

}

function compare(a: any, b: any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}