import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(array: any[], searchText: string | number | null, key: string): any[] {
    if (!array) {
      return [];
    }
    if (!searchText) {
      return array;
    }
    const stringValue: string = `${searchText}`.toLocaleLowerCase();
    return array.filter((obj: any) => {
      if(key === '') {
        return obj.toLocaleLowerCase().includes(stringValue);
      } 
      else {
        return `${obj[key]}`?.toLocaleLowerCase().includes(stringValue);
      }
    });
  }

}
