import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SuccessDialogComponent } from '@app/modules/shared/components/success-dialog/success-dialog.component';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';
import { AddContactDialogComponent } from './add-contact-dialog/add-contact-dialog.component';
import * as JSZip from 'jszip';  
import * as FileSaver from 'file-saver';  
import { IAccidentDetails } from '@app/modules/accidents/models/accident-details.model';
import { AccidentsService } from '@app/modules/accidents/services/accidents.service';

@Component({
  selector: 'app-add-edit-accidents-dialog',
  templateUrl: './add-edit-accidents-dialog.component.html',
  styleUrls: ['./add-edit-accidents-dialog.component.scss']
})
export class AddEditAccidentsDialogComponent implements OnInit, OnDestroy {
  tabsArray: any[] = [
    {name: 'Accident'}, 
    {name: 'Files'}
  ];
  activeTab: string = 'Accident';
  
  formGroup: NgForm;
  formInit: IAccidentDetails = {
    'id': 0,
    'load_no': null,
    'unit_no': '',
    'driver': '',
    'commodity': '',
    'date_occured': null,
    'recovery_truck': '',
    'case_number': '',
    'citation_number': '',
    'claim_number': '',
    'is_hazmat': false,
    'is_citation_issued': false,
    'is_copy_of_report_obtained': false,
    'is_preventable': false,
    'is_tow': false,
    'is_ambulance': false,
    'is_fatality': false,
    'is_dot_recordable': false,
    'number_of_deaths': null,
    'number_of_non_fatal_injuries': null,
    'reported_insurance_date': null,
    'drug_test_date': null,
    'alcohol_test_date': null,
    'is_closed': false,
    'comments': '',
    'location_name': '',
    'location_street1': '',
    'location_street2': '',
    'location_city': '',
    'location_state': '',
    'location_zip': '',
    'has_comment': false,
    'equipment_no': null,
    'load_id': null,
    'driver_id': null,
    'truck_id': null,
    'date_created': null,
    'date_modified': null,
    'user_created_id': null,
    'user_modified_id': null,
    'equipment_id': null,
    'fault_for_incident': '',
    'contacts': []
  }

  //Load menu
  loadArray: any[] = [];

  //Truck menu
  truckArray: any[] = [];

  //Driver menu
  driverArray: any[] = [];

  //Equipment menu
  equipmentArray: any[] = [];

  //Fault for accident
  faultForAccidentArray: any[] = [
    {name: 'Our driver'},
    {name: '3rd Party'}
  ];

  //Company menu
  locationsOfAccidentArray: any[] = [];

  //Location
  locationArray: any[] = [];

  //Commodity
  commodityArray: any[] = [];

  //Files
  filelist: string[] = [];
  pdfLoading: boolean = false;

  //Contacts table
  displayedColumns: string[] = ['name', 'phone_number', 'extension', 'cell_phone_number', 'email',
  'role', 'comments', 'action'];
  addedData: boolean = false;

  //Style config
  styleConfig: any = {
    'width': '210px',
    'height': '45px',
    'background-color': '#f4f8ff',
    'color': '#1F1F1F',
    'box-shadow': 'none',
    'border-radius': '25px',
    'border-top': 'thin solid #0000001f',
    'arrow-color': '#0856cd',
    'opened-dropdown-shadow': '0px 1px 6.65px 0.35px rgba(13, 39, 80, 0.16)'
  };

  calendarStyleConfig: any = {
    'width': '210px',
    'height': '45px',
    'background-color': '#f4f8ff',
    'color': '#1F1F1F',
    'box-shadow': 'none',
    'border-radius': '25px',
    'border-top': 'thin solid #0000001f',
    'opened-dropdown-shadow': '0px 1px 6.65px 0.35px rgba(13, 39, 80, 0.16)'
  };

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();
  subscription3: Subscription = new Subscription();
  subscription4: Subscription = new Subscription();
  subscription5: Subscription = new Subscription();
  subscription6: Subscription = new Subscription();
  subscription7: Subscription = new Subscription();
  subscription8: Subscription = new Subscription();
  subscription9: Subscription = new Subscription();

  loaded: boolean = true;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  loading: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any, private dialog: MatDialog,
  private accidentsService: AccidentsService, private transformService: TransformService,
  private sharedService: SharedService, private spinner: NgxSpinnerService,
  public dialogRef: MatDialogRef<AddEditAccidentsDialogComponent>) { }

  ngOnInit(): void {
    if(this.obj.editMode) {
      this.getAccidentDetails();
      this.getAllFilesByAccident();
    }
    this.getLoads();
    this.getTrucksAutocomplete();
    this.getAllDrivers();
    this.getAllTrailers();
    this.getBrokersAndCommodities();
    this.getLocations();
    this.getCoNameList();
  };

  getAccidentDetails() {
    this.loaded = false;
    this.spinner.show('accidents-dialog');
    this.subscription1 = this.accidentsService.getAccidentDetails(this.obj.id)
    .pipe(catchError((err: any) => {
      this.spinner.hide('accidents-dialog');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: IAccidentDetails | any) => {
      console.log(response);
      let obj: IAccidentDetails = response;
      obj.date_occured = moment(response.date_occured).format('MMM DD, YYYY H:mm');
      obj.reported_insurance_date = response.reported_insurance_date ? moment(response.reported_insurance_date).format('MMM DD, YYYY H:mm') : null;
      obj.drug_test_date = response.drug_test_date ? moment(response.drug_test_date).format('MMM DD, YYYY H:mm') : null;
      obj.alcohol_test_date = response.alcohol_test_date ? moment(response.alcohol_test_date).format('MMM DD, YYYY H:mm') : null;
      this.formInit = obj;
      this.spinner.hide('accidents-dialog');
      this.loaded = true;
    });
  };

  getAllFilesByAccident() {
    this.subscription2 = this.sharedService.getAllFilesByAccident(this.obj.id).subscribe((response: any[]) => {
      this.filelist = response;
      console.log(response);
    });
  };

  getLoads() {
    this.subscription3 = this.accidentsService.getAllAccidentLoads().subscribe((response: any[]) => {
      console.log(response);
      this.loadArray = response;
    });
  }

  getTrucksAutocomplete() {
    this.subscription4 = this.sharedService.getActiveTrucksAutocomplete().subscribe((response: any[]) => {
      console.log(response);
      this.truckArray = response;
    });
  }

  getAllDrivers() {
    this.subscription5 = this.sharedService.getAllDrivers().subscribe((response: any[]) => {
      console.log(response);
      this.driverArray = response;
    });
  };

  getAllTrailers() {
    this.subscription6 = this.sharedService.getAllTrailers().subscribe((response: any[]) => {
      this.equipmentArray = response;
      console.log(response);
    });
  };

  getBrokersAndCommodities() {
    this.subscription7 = this.accidentsService.getBrokersAndCommodities().subscribe((response: any) => {
      console.log(response);
      this.commodityArray = response.compmodities;
    });
  };

  getLocations() {
    this.subscription8 = this.accidentsService.getAllLocationInfo().subscribe((response: any[]) => {
      console.log(response);
      this.locationArray = response;
    });
  };

  getCoNameList() {
    this.subscription9 = this.accidentsService.getCoNameList().subscribe((response: any) => {
      console.log(response);
      this.locationsOfAccidentArray = response;
    });
  };
  
  //Add update contact
  addUpdateContact(editMode: boolean, obj: any, index: number) {
    let dialogRef = this.dialog.open(AddContactDialogComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: 'component-dialog-container',
      data: {accidentId: this.formInit.id, obj: obj, editMode: editMode}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        if(editMode) {
          this.formInit.contacts[index] = result;
          this.formInit.contacts = [...this.formInit.contacts];
        }
        else {
          this.formInit.contacts.push(result);
          this.formInit.contacts = [...this.formInit.contacts];
        }
        this.addedData = true;
        console.log(result);
      }
    });
  };

  //Set data
  autocompleteFields(obj: any) {
    this.formInit.load_no = obj.load_no;
    this.formInit.load_id = obj.load_id;
    this.formInit.unit_no = obj.truck_no;
    this.formInit.commodity = obj.commodity;
    this.formInit.truck_id = obj.truck_id;
    this.formInit.driver = obj.driver;
    this.formInit.driver_id = obj.driver_id;
    this.formInit.equipment_no = obj.trailer_no;
    this.formInit.equipment_id = obj.trailer_id;
  };

  autocompleteTruck(obj: any) {
    this.formInit.unit_no = obj.truck_unit_no; 
    this.formInit.driver = obj.full_name;
    this.formInit.equipment_no = obj.trailer_unit_no;

    this.formInit.truck_id = obj.truck_id; 
    this.formInit.driver_id = obj.driver_id;
    this.formInit.equipment_id = obj.trailer_id;
  };

  autocompleteLocations(obj: any) {
    this.formInit.location_zip = obj.zip_code; 
    this.formInit.location_city = obj.city;
    this.formInit.location_state = obj.state_long;
  };

  saveData() {
    let contacts: any[] = [];
    if(this.formInit.id === 0 && this.formInit.contacts?.length > 0) {
      contacts = JSON.parse(JSON.stringify(this.formInit.contacts));
      this.formInit.contacts = [];
    }

    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    if(this.obj.editMode) {
      this.formInit.date_modified = moment().format('YYYY-MM-DDTHH:mm:ss');
    }
    else {
      this.formInit.date_created = moment().format('YYYY-MM-DDTHH:mm:ss');
    }
    this.formInit.user_created_id = loggedUser.pt_id;
    this.formInit.user_modified_id = loggedUser.pt_id;
    this.formInit.reported_insurance_date = this.formInit.reported_insurance_date === false ? null : this.formInit.reported_insurance_date;
    this.formInit.drug_test_date = this.formInit.drug_test_date === false ? null : this.formInit.drug_test_date;
    this.formInit.alcohol_test_date = this.formInit.alcohol_test_date === false ? null : this.formInit.alcohol_test_date;
    this.formInit.number_of_deaths = this.formInit.number_of_deaths === null ? 0 : this.formInit.number_of_deaths;
    this.formInit.number_of_non_fatal_injuries = this.formInit.number_of_non_fatal_injuries === null ? 0 : this.formInit.number_of_non_fatal_injuries;
    
    let formInitForServer: any = JSON.parse(JSON.stringify(this.formInit));
    formInitForServer.comments = formInitForServer.comments.replace(/\n/g, "</NOVI RED>");
 
    this.loading = true;
    this.accidentsService.createUpdateAccident(formInitForServer)
    .pipe(catchError((err: any) => {
      this.showErrorMsg();
      this.loading = false;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      if(response.id) {
        this.formInit = response;
        this.formInit.contacts = [];
        if(contacts.length > 0) {
          for(let i = 0; i < contacts.length; i++) {
            contacts[i].accident_id = response.id;
            this.accidentsService.addUpdateContact(contacts[i])
            .subscribe((contactObj: any) => {
              this.formInit.contacts.push(contactObj);
            });
          };
        }
        this.dialogRef.close(true);
      }
      else {
        this.showErrorMsg();
      }
      this.loading = false;
    });
  };

  showSuccessMsg() {
    this.dialog.open(SuccessDialogComponent, {
      autoFocus: false,
      panelClass: 'success-dialog-container'
    });  
  };

  showErrorMsg() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  downloadDriverFile(filename: string) {
    this.pdfLoading = true;
    this.sharedService.getAccidentFileById(this.obj.id, filename)
    .pipe(catchError((err: any) => {
      this.pdfLoading = false;
      this.dialog.open(WarningMsgDialogComponent, {
        autoFocus: false,
        panelClass: 'warning-msg-dialog-container'
      });
      return throwError(() => err);
    }))
    .subscribe((base64: any) => {
      this.pdfLoading = false;
      let source: string = `data:application/octet-stream;base64,${base64}`;
      const downloadLink = document.createElement('a');
      const fileName = filename;
      downloadLink.href = source;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  };

  downloadAllFiles() {
    this.pdfLoading = true;
    this.sharedService.getAllAccidentsFile(this.obj.id)
    .pipe(catchError((err: any) => {
      this.pdfLoading = false;
      this.dialog.open(WarningMsgDialogComponent, {
        autoFocus: false,
        panelClass: 'warning-msg-dialog-container'
      });
      return throwError(() => err);
    }))
    .subscribe((filesArray: any) => {
      const zip = new JSZip();
      filesArray.forEach((file: any) => {
        zip.file(file.filename, file.data, { base64: true });
      });
      // Generate the zip file asynchronously
      zip.generateAsync({ type: 'blob' }).then((content: any) => {
        // Save the zip file using FileSaver.js
        FileSaver.saveAs(content, 'accident-files.zip');
        this.pdfLoading = false;
      });
    });
  }

  previewDriverFile(filename: string) {
    this.pdfLoading = true;
    this.sharedService.getAccidentFileById(this.obj.id, filename)
    .pipe(catchError((err: any) => {
      this.pdfLoading = false;
      this.dialog.open(WarningMsgDialogComponent, {
        autoFocus: false,
        panelClass: 'warning-msg-dialog-container'
      });
      return throwError(() => err);
    }))
    .subscribe((base64: any) => {
      this.pdfLoading = false;
      this.sharedService.downloadPreviewFile(filename, base64);
    });
  };

  onFileSelected(event: any) {
    let listOfFiles: any = event.target.files;  
    const formData: any = new FormData();  
    for  (let i =  0; i <  listOfFiles.length; i++)  {  
      formData.append("file[]", listOfFiles[i], listOfFiles[i].name);
    } 
    this.accidentsService.uploadAccidentFile(this.obj.id, formData)
    .pipe(catchError((err: any) => {
      this.dialog.open(WarningMsgDialogComponent, {
        autoFocus: false,
        panelClass: 'warning-msg-dialog-container'
      });
      return throwError(() => err);
    }))
    .subscribe((response: any[]) => {
      if(response) {
        for(let j = 0; j < listOfFiles.length; j++) {
          this.filelist.push(listOfFiles[j].name);
        };
        this.dialog.open(SuccessDialogComponent, {
          autoFocus: false,
          panelClass: 'success-dialog-container'
        });  
      }
      else {
        this.dialog.open(WarningMsgDialogComponent, {
          autoFocus: false,
          panelClass: 'warning-msg-dialog-container'
        });
      }
    })
  }

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
    this.subscription4?.unsubscribe();
    this.subscription5?.unsubscribe();
    this.subscription6?.unsubscribe();
    this.subscription7?.unsubscribe();
    this.subscription8?.unsubscribe();
    this.subscription9?.unsubscribe();
  };

}
