import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class MaintenanceRepairLogService {
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
  });
  requestOptions = { headers: this.headers };

  constructor (private http: HttpClient) { }

  getMaintenanceRepairLogData(startDate: string, endDate: string, dayInTheWeek: number) {
    const param: string = dayInTheWeek === -1 ? '' : `&dayInTheWeek=${dayInTheWeek}`;
    return this.http.get(`${_G.extendApiUrl}Maintenance/repairlog?startDate=${startDate}&endDate=${endDate}${param}`, this.requestOptions);
  };

  getLogID(repairType: string) {
    return this.http.get(`${_G.extendApiUrl}Maintenance/repairlogid?reasonForRepair=${repairType}`, this.requestOptions);
  };

  createUpdateMaintenanceRepairLog(obj: any) {
    const json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}Maintenance/repairlog`, json, this.requestOptions);
  };

  changeLogStatus(obj: any) {
    const json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}Maintenance/repairlogstatus`, json, this.requestOptions);
  };

  changeNote(obj: any) {
    const json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}Maintenance/repairlognotes`, json, this.requestOptions);
  };

  deleteMaintenanceRepairLog(id: number) {
    return this.http.delete(`${_G.extendApiUrl}Maintenance/repairlog?id=${id}`, this.requestOptions);
  };

  getMaintenanceRepairFileById(id: number) {
    return this.http.get(`${_G.extendApiUrl}Maintenance/repairLogFile/${id}`, this.requestOptions);
  };

}
