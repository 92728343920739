import { AfterContentChecked, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SetTargetDialogComponent } from './set-target-dialog/set-target-dialog.component';
import moment = require('moment');
import { catchError, Subscription, throwError } from 'rxjs';
import { DispatchRewardSystemService } from '@app/modules/dispatch-reward-system/services/dispatch-reward-system.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { DispNamePipe } from '@app/modules/shared/pipes/disp-name.pipe';
import { NgxSpinnerService } from 'ngx-spinner';
import { Sort } from '@angular/material/sort';
import { ApexAxisChartSeries, ApexChart, ApexXAxis, ApexYAxis, ApexDataLabels, ApexGrid, ApexStroke, ChartComponent } from 'ng-apexcharts';
import { StatusDialogComponent } from '@app/modules/shared/components/status-dialog/status-dialog.component';
import { Router } from '@angular/router';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import * as XLSX from 'xlsx';
import { TitleCasePipe } from '@angular/common';
import { SearchPipe } from '@app/modules/shared/pipes/search.pipe';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { QuickStatsLinechartComponent } from './quick-stats-linechart/quick-stats-linechart.component';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  yaxis: ApexYAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  stroke: ApexStroke | any;
  markers: any;
  colors: any;
  tooltip: any;
};

@Component({
  selector: 'dispatch-reward-system-cards',
  templateUrl: './dispatch-reward-system-cards.component.html',
  styleUrls: ['./dispatch-reward-system-cards.component.scss']
})
export class DispatchRewardSystemCardsComponent implements OnInit, AfterContentChecked, OnDestroy {
  @ViewChild('driversChartContainer') chartContainer!: ElementRef;
  @ViewChild(QuickStatsLinechartComponent) childComponent: any;
  permissions: any = this.rulesService.UserData[32].data;

  //Statistics array
  stCardsArray: any[] = [
    {date: moment().startOf('isoWeek').format('YYYY-MM-DD'), dataSource: [], targetDataSolo: [], targetDataTeam: [], targetDataSoloAndTeam: [],
      bonusAchievedSoloAndTeam: 0, bonusAchievedSolo: 0, bonusAchievedTeam: 0, targetSuccessRateSolo: 0, targetSuccessRateTeam: 0, sumGrossTarget: 0, sumRpmTarget: 0},
    {date: moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD'), dataSource: [], targetDataSolo: [], targetDataTeam: [], targetDataSoloAndTeam: [], 
      bonusAchievedSoloAndTeam: 0, bonusAchievedSolo: 0, bonusAchievedTeam: 0, targetSuccessRateSolo: 0, targetSuccessRateTeam: 0, sumGrossTarget: 0, sumRpmTarget: 0},
    {date: moment().subtract(2, 'week').startOf('isoWeek').format('YYYY-MM-DD'), dataSource: [], targetDataSolo: [], targetDataTeam: [], targetDataSoloAndTeam: [],
      bonusAchievedSoloAndTeam: 0, bonusAchievedSolo: 0, bonusAchievedTeam: 0, targetSuccessRateSolo: 0, targetSuccessRateTeam: 0, sumGrossTarget: 0, sumRpmTarget: 0},
    {date: moment().subtract(3, 'week').startOf('isoWeek').format('YYYY-MM-DD'), dataSource: [], targetDataSolo: [], targetDataTeam: [], targetDataSoloAndTeam: [],
      bonusAchievedSoloAndTeam: 0, bonusAchievedSolo: 0, bonusAchievedTeam: 0, targetSuccessRateSolo: 0, targetSuccessRateTeam: 0, sumGrossTarget: 0, sumRpmTarget: 0},
    {date: moment().subtract(4, 'week').startOf('isoWeek').format('YYYY-MM-DD'), dataSource: [], targetDataSolo: [], targetDataTeam: [], targetDataSoloAndTeam: [],
      bonusAchievedSoloAndTeam: 0, bonusAchievedSolo: 0, bonusAchievedTeam: 0, targetSuccessRateSolo: 0, targetSuccessRateTeam: 0, sumGrossTarget: 0, sumRpmTarget: 0}
  ];
  activeCard: number = 0;

  //Quick stats
  quickStatsObj: any = {
    countObj: {
      avgGross: 0,
      avgRpm: 0,
      avgMileage: 0,
      totalGross: 0
    }
  };

  quickStatsSelectUnselectObj: any = {
    grossPerTruckSelected: false,
    rpmPerTruckSelected: false,
    mileagePerTruckSelected: false,
    totalGrossSelected: false
  };
  quickStatsSelect: string = '';
 
  //Drivers trucks data
  filtersViewArray: any[] = [
    {name: 'Drivers'},
    {name: 'Trucks'}
  ];
  activeViewFilter: number = 0;

  //Graph data
  public chartOptions: Partial<ChartOptions> | any;

  //Drivers-trucks datasource
  dataSource: any[] = [];
  trucksDataSource: any[] = [];
  loadsDataSource: any[] = [];
  graphData: any[] = [];
  loadsStObj: any = {
    avgGross: 0,
    avgRpm: 0,
    avgMileage: 0,
    showGrossInLinechart: true,
    showRpmInLinechart: false,
    showMileageInLinechart: false
  }
  statusesDataSource: any[] = [];
  isOpened: boolean = false;
  expandRowIndex: number | undefined = undefined;

  daysOfWeek: any[] = [];
  today: string = moment().format('YYYY-MM-DDTHH:mm:ss');

  //Date obj
  loadsDateObj: any = {
    'Last 5 weeks': {startDate: moment().subtract(5, 'week').startOf('isoWeek').format('YYYY-MM-DD'), endDate: moment().endOf('isoWeek').format('YYYY-MM-DD'), numOfWeeks: 5},
    'Last 4 weeks': {startDate: moment().subtract(4, 'week').startOf('isoWeek').format('YYYY-MM-DD'), endDate: moment().endOf('isoWeek').format('YYYY-MM-DD'), numOfWeeks: 4},
    'Last 3 weeks': {startDate: moment().subtract(3, 'week').startOf('isoWeek').format('YYYY-MM-DD'), endDate: moment().endOf('isoWeek').format('YYYY-MM-DD'), numOfWeeks: 3},
    'Last 2 weeks': {startDate: moment().subtract(2, 'week').startOf('isoWeek').format('YYYY-MM-DD'), endDate: moment().endOf('isoWeek').format('YYYY-MM-DD'), numOfWeeks: 2},
    'Last week': {startDate: moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD'), endDate: moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD'), numOfWeeks: 1},
    'This week': {startDate: moment().startOf('isoWeek').format('YYYY-MM-DD'), endDate: moment().endOf('isoWeek').format('YYYY-MM-DD'), numOfWeeks: 1}
  };
  periodNameKey: string = 'Last 5 weeks';
  currentWeekBlink: number = -1;

  //Filters drivers
  driverSearch: string = '';
  selectedDispatchers: string[] = [];
  selectedPositions: string[] = [];
  selectedTypes: string[] = [];
  selectedRecruiters: string[] = [];

  //Bonus achieved
  bonusAchieved: boolean = false;

  selectedDrivers: string[] = [];

  //Tabs container
  tabsArray: any[] = [
    {name: 'Solo drivers', activeKeys: {
      dataArray: 'targetDataSolo',
      bonusAchieved: 'bonusAchievedSolo',
      targetSuccessRate: 'targetSuccessRateSolo',
      succesRate: 'succesRate'
    }}, 
    {name: 'Team drivers', activeKeys: {
      dataArray: 'targetDataTeam',
      bonusAchieved: 'bonusAchievedTeam',
      targetSuccessRate: 'targetSuccessRateTeam',
      succesRate: 'succesRate',
    }}
  ];
  activeTab: number = 0;
  topByTotalPoints: number = 0;

  //Sort by
  sortByName: string = 'Gross - Highest to lowest';

  //Current week
  dateObj: any = {
    startDate: moment().startOf('isoWeek').format('YYYY-MM-DD'), 
    endDate: moment().endOf('isoWeek').format('YYYY-MM-DD')
  };

  //Dispatchers
  dispatchArray: any[] = [];

  //Dispatch data
  selectedWeeksArray: string[] = [];
  selectedWeekDateObj: any = {
    startDate: moment().startOf('isoWeek').format('YYYY-MM-DD'), 
    endDate: moment().endOf('isoWeek').format('YYYY-MM-DD')
  };
  isOneOfSelectedWeekCurrent: boolean = true;
  weeksArray: any[] = [];

  dispatchDataSource: any[] = [];
  dispatchRewardsDataSource: any[] = [];
  dispatchRewardsObj: any = {};
  dispatchSearch: string = '';

  //Statuses array
  statusArray: any[] = [
    {name: 'Approved'}, {name: 'Pending'}, {name: 'Rejected'}
  ];
  statusIcon: any = {
    'Approved': {class: 'green-icon', icon: 'check_circle_outline'}, 
    'Pending': {class: 'gray-icon', icon: 'pending_actions'}, 
    'Rejected': {class: 'red-icon', icon: 'cancel'}
  };
  isOpenRewardStatusIndex: number | null = null;

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'center', originY: 'bottom'},
      {overlayX: 'center', overlayY: 'top'},
      0, 5
    )
  ];

  //Transfer points
  isOpenTransferPointsMenu: number | null = null;

  //Recrutiers data
  recruitersArray: any[] = [];

  driverTypesObj: any = {
    'SOLO COMPANY': 'Company',
    'TEAM COMPANY': 'Company',
    'SOLO RENT': 'Rent to run',
    'TEAM RENT': 'Rent to run',
    'OWNER OPERATOR': 'Owner'
  };

  //Excel config
  xlsxConfig: any = [
    {columnName: 'No.', selected: true},
    {columnName: 'Dispatcher', selected: true},
    {columnName: 'Target achieved', selected: true},
    {columnName: 'Earnings', selected: true},
    {columnName: 'Gross pts', selected: true},
    {columnName: 'RPM pts', selected: true},
    {columnName: 'Total pts', selected: true}
  ];

  //Interval
  interval: any;

  loaded1: boolean = false;
  error1: boolean = false;
  loaded2: boolean = false;
  error2: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();
  subscription3: Subscription = new Subscription();
  subscription4: Subscription = new Subscription();

  constructor(private dialog: MatDialog, 
              private dispatchRewardSystemService: DispatchRewardSystemService,
              private sharedService: SharedService,
              private dispName: DispNamePipe,
              private router: Router,
              private cdref: ChangeDetectorRef,
              private transformService: TransformService,
              private rulesService: RulesService,
              private spinner: NgxSpinnerService,
              private titleCase: TitleCasePipe,
              private searchPipe: SearchPipe) { }

  ngOnInit(): void {
    this.getWeeksArray();
    this.getDriversRewardsData();
    this.getDispatchersRewardsData();
    this.getAllRecruiters();
    this.interval = setInterval(() => {
      let checkObj: any = this.someWeekCurrentWeek;
      if(checkObj.condition) {
        this.getDriversRewardsDataLive(checkObj);
      }
    }, 10000);
  };

  //Drivers data live update
  getDriversRewardsDataLive(obj: any) {
    this.subscription1 = this.dispatchRewardSystemService.getRewardsDataByDate(obj.condition, this.dateObj.startDate, this.dateObj.endDate)
    .subscribe((response: any) => {
      if(this.stCardsArray[this.activeCard].date === this.dateObj.startDate) {
        this.dataSource = response;
        if(this.sortByName !== 'Gross - Highest to lowest') {
          this.sortDriversData(this.sortByName);
        }
      }
      this.countsData(this.stCardsArray[obj.index].targetDataSoloAndTeam, response, obj.index);
      if(this.childComponent && this.someWeekCurrentWeek.weekCurrentBoolean) {
        this.childComponent.getGraphData(false);
      }
    });
  };

  //Drivers data
  getDriversRewardsData() {
    this.resetStData(0);
    this.resetStData(1);
    this.resetStData(2);
    this.resetStData(3);
    this.resetStData(4);
    this.dataSource = [];
    this.error1 = false;
    this.loaded1 = false;
    this.spinner.show('drivers-rewards-table');
    this.subscription2 = this.dispatchRewardSystemService.getTargetsByDate(this.someWeekCurrentWeek.weekCurrentBoolean, this.stCardsArray)
    .pipe(catchError((err: any) => {
      this.spinner.hide('drivers-rewards-table');
      this.loaded1 = true;
      this.error1 = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.dataSource = response[`dataSourceArray`+(this.activeCard + 1)];
      if(this.sortByName !== 'Gross - Highest to lowest') {
        this.sortDriversData(this.sortByName);
      }
      this.countsData(response.week1Array, response.dataSourceArray1, 0);
      this.countsData(response.week2Array, response.dataSourceArray2, 1);
      this.countsData(response.week3Array, response.dataSourceArray3, 2);
      this.countsData(response.week4Array, response.dataSourceArray4, 3);
      this.countsData(response.week5Array, response.dataSourceArray5, 4);
      this.spinner.hide('drivers-rewards-table');
      this.loaded1 = true;
      console.log(response);
    });
    this.getDaysOfWeek();
  };

  changeTab(tabIndex: number) {
    this.activeViewFilter = tabIndex;
    if(tabIndex) {
      this.calculateDataTrucksTab();
    }
  }

  //Trucks
  calculateDataTrucksTab() {
    this.trucksDataSource = this.dataSource.reduce((acc: any, obj: any) => {
      const unit_no = acc.find(k => k.unit_no === obj.unit_no);
      if (unit_no) {
        unit_no.gross += obj.gross;
      } 
      else {
        acc.push({ ...obj});
      }
      return acc;
    }, []);
  };

  //Dispatchers data
  getDispatchersRewardsData() {
    this.error2 = false;
    this.loaded2 = false;
    this.spinner.show('dispatchers-rewards-table');
    this.subscription3 = this.dispatchRewardSystemService.getDispatchRewardsByDate(this.isOneOfSelectedWeekCurrent, this.selectedWeekDateObj.startDate, this.selectedWeekDateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('dispatchers-rewards-table');
      this.loaded2 = true;
      this.error2 = true;
      this.resetDispatchRewardsData();
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      this.resetDispatchRewardsData();
      this.generateDispatchersRewardsTable(response.dispatchersArray, response.targetArray, response.driversArray);
      this.spinner.hide('dispatchers-rewards-table');
      this.loaded2 = true;
    });
  }

  selectUnselectWeek(selectedWeeks: string[]) {
    this.selectedWeeksArray = selectedWeeks.sort();
    const currentWeekStartDate: string = moment().startOf('isoWeek').format('YYYY-MM-DD');
    this.isOneOfSelectedWeekCurrent = selectedWeeks.includes(currentWeekStartDate);
    let selectedWeeksArrayLength: number = selectedWeeks.length;
    if(selectedWeeksArrayLength === 0) {
      this.selectedWeekDateObj.startDate = '2024-08-26';
      this.selectedWeekDateObj.endDate = moment().endOf('isoWeek').format('YYYY-MM-DD');
      this.getDispatchersRewardsData();
      return;
    };
    if(selectedWeeksArrayLength === 1) {
      this.selectedWeekDateObj.startDate = this.selectedWeeksArray[0];
      this.selectedWeekDateObj.endDate = moment(this.selectedWeeksArray[0]).endOf('isoWeek').format('YYYY-MM-DD');
      this.getDispatchersRewardsData();
      return;
    };
    this.selectedWeekDateObj.startDate = this.selectedWeeksArray[0];
    this.selectedWeekDateObj.endDate = moment(this.selectedWeeksArray[this.selectedWeeksArray.length - 1]).endOf('isoWeek').format('YYYY-MM-DD');
    this.getDispatchersRewardsData();
  };

  changeDriverRewordsStatus(element: any, status: string) {
    if(this.permissions[1].sectionArray[1].allowed) {
      this.isOpenRewardStatusIndex = null;
      const date: string = this.dateWithoutTime(element.target_from);
      let obj: any = {currentWeek: date === moment().startOf('isoWeek').format('YYYY-MM-DD'), date: date, driverId: element.driver_id, status: status}
      this.dispatchRewardSystemService.setDriverRewordsStatus(obj).subscribe((response: any) => {
        if(response) {
          element.reword_status = status;
          this.getDispatchersRewardsData();
        };
      });
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  transferDispatchPoints(element: any, dispObj: any) {
    if(this.permissions[1].sectionArray[0].allowed) {
      this.isOpenTransferPointsMenu = null;
      const obj: any = {
        target_from: this.dateWithoutTime(element.target_from), 
        target_to: this.dateWithoutTime(element.target_to), 
        disp_id: element.disp_id, 
        disp_id2: dispObj.id,
        disp2_name: dispObj.name
      };
      this.dispatchRewardSystemService.transferDispatchRewardsPoints(obj)
      .subscribe((response: any) => {
        if(response) {
          element.disp2_id = dispObj.id;
          element.disp2_name = dispObj.name
          this.getDispatchersRewardsData();
        };
      });
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  //Dispatchers list
  generateDispatchersRewardsTable(dispatchersArray: any[], targetArray: any[], driversArray: any[]) {
    let index: number = 0;
    for(let i = 0; i < dispatchersArray.length; i++) {
      const nickname: string | undefined = this.dispName.transform(dispatchersArray[i].name);
      dispatchersArray[i].nickname = nickname ? nickname : dispatchersArray[i].name;
      if(dispatchersArray[i].name === 'OTR Unassigned' || dispatchersArray[i].name === 'CHICAGO Unassigned') continue;
      this.dispatchArray.push(dispatchersArray[i]);
      this.dispatchRewardsObj[dispatchersArray[i].id] = index;
      this.dispatchRewardsDataSource.push({
        dispId: dispatchersArray[i].id, 
        dispatcher: dispatchersArray[i].nickname, 
        is_active: dispatchersArray[i].is_active,
        target_achived: 0,
        earnings: 0,
        gross_pts: 0,
        rpm_pts: 0,
        total_points: 0
      });
      index++;
    };
    this.calculateDispatchRewardsData(targetArray, driversArray);
  }

  calculateDispatchRewardsData(arrayOfArraysTarget: any[], driversArray: any[]) {
    let calculationDataObj: any = {};

    //Target calculation
    for(let i = 0; i < arrayOfArraysTarget.length; i++) {
      let targetStartDate: string = this.dateWithoutTime(arrayOfArraysTarget[i][0].target_from);

      if(!this.selectedWeeksArray.includes(targetStartDate)) {
        continue;
      };

      if(arrayOfArraysTarget[i][0].type === 0) {
        calculationDataObj[`reword-solo-${targetStartDate}`] = arrayOfArraysTarget[i][0].reword;
      };
      if(arrayOfArraysTarget[i][0].type === 1) {
        calculationDataObj[`reword-team-${targetStartDate}`] = arrayOfArraysTarget[i][0].reword;
      };

      for(let j = 0; j < arrayOfArraysTarget[i].length; j++) {
        if(arrayOfArraysTarget[i][j].type === 0) {
          if(arrayOfArraysTarget[i][j].condition === 'Gross') {
            calculationDataObj[`target-gross-solo-${targetStartDate}`] = arrayOfArraysTarget[i][j].target;
          };
          if(arrayOfArraysTarget[i][j].condition === 'Rate per mile') {
            calculationDataObj[`target-rpm-solo-${targetStartDate}`] = arrayOfArraysTarget[i][j].target;
          };
        }
        if(arrayOfArraysTarget[i][j].type === 1) {
          if(arrayOfArraysTarget[i][j].condition === 'Gross') {
            calculationDataObj[`target-gross-team-${targetStartDate}`] = arrayOfArraysTarget[i][j].target;
          };
          if(arrayOfArraysTarget[i][j].condition === 'Rate per mile') {
            calculationDataObj[`target-rpm-team-${targetStartDate}`] = arrayOfArraysTarget[i][j].target;
          };
        }
      };

    };
    //Drivers data calculation
    for(let i = 0; i < driversArray.length; i++) {
      let targetStartDate: string = this.dateWithoutTime(driversArray[i].target_from);
      if(!this.selectedWeeksArray.includes(targetStartDate)) {
        continue;
      };
      let index: number = this.dispatchRewardsObj[driversArray[i].disp2_id ? driversArray[i].disp2_id : driversArray[i].disp_id];
      if(driversArray[i].gross >= calculationDataObj[`target-gross-solo-${targetStartDate}`] 
      && driversArray[i].rpm >= calculationDataObj[`target-rpm-solo-${targetStartDate}`]
      && (driversArray[i].status.includes('SOLO') || driversArray[i].status === 'OWNER OPERATOR')) {
        if(index && (driversArray[i].reword_status === 'Approved' || driversArray[i].reword_status === '')) {
          this.dispatchRewardsDataSource[index].target_achived++;
          this.dispatchRewardsDataSource[index].earnings += calculationDataObj[`reword-solo-${targetStartDate}`];
        }
      };

      if(driversArray[i].gross >= calculationDataObj[`target-gross-team-${targetStartDate}`] 
      && driversArray[i].rpm >= calculationDataObj[`target-rpm-team-${targetStartDate}`]
      && driversArray[i].status.includes('TEAM')) {
        if(index && (driversArray[i].reword_status === 'Approved' || driversArray[i].reword_status === '')) {
          this.dispatchRewardsDataSource[index].target_achived++;
          this.dispatchRewardsDataSource[index].earnings += calculationDataObj[`reword-team-${targetStartDate}`];
        }
      };
    
      if(index && (driversArray[i].reword_status === 'Approved' || driversArray[i].reword_status === '')) {
        this.dispatchRewardsDataSource[index].gross_pts += driversArray[i].gross_points;
        this.dispatchRewardsDataSource[index].rpm_pts += driversArray[i].rpm_points;
        this.dispatchRewardsDataSource[index].total_points = this.dispatchRewardsDataSource[index].gross_pts + this.dispatchRewardsDataSource[index].rpm_pts;
      }
    };
    this.dispatchDataSource = this.dispatchRewardsDataSource.sort((a: any, b: any) => b.total_points - a.total_points);
    if(this.dispatchDataSource.length > 2) {
      const top3DataSource: any[] = this.dispatchDataSource.slice(0, 3);
      this.topByTotalPoints = top3DataSource[2].total_points;
    }
  };

  //Recruiters list
  getAllRecruiters() {
    this.subscription4 = this.sharedService.getAllEmployees().subscribe((response: any) => {
      for(let i = 0; i < response.length; i++) {
        if(response[i].position === 'Recruiter') {
          this.recruitersArray.push(response[i]);
        };
      };
    })
  };

  getWeeksArray() {
    const startDate: Date = new Date('2024-08-26T00:00:00');
    const endDate: Date = new Date(moment().endOf('isoWeek').format('YYYY-MM-DDTHH:mm:ss'));
    const date: Date = new Date(startDate.getTime());
    const dates = [];
    while (date <= endDate) {
      let d1 = `${moment(date).startOf('isoWeek').format('MMM DD')}-${moment(date).endOf('isoWeek').format('DD')}`;
      if(!dates.includes(d1)) {
        dates.push(d1);
        this.weeksArray.unshift({week: moment(date).format('YYYY-MM-DD')});
      }

      date.setDate(date.getDate() + 1);
    }
    this.selectedWeeksArray.push(this.weeksArray[0].week);
  };

  //Set target dialog
  openTargetDialog() {
    if(this.permissions[0].allowedAll) {
      let dialogRef: any = this.dialog.open(SetTargetDialogComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'component-dialog-container',
        data: {selectedObj: this.stCardsArray[this.activeCard], allDates: this.stCardsArray}
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.getDriversRewardsData();
        }
      })
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  syncList() {
    this.dispatchRewardSystemService.syncDriverList(this.stCardsArray[this.activeCard].date, moment(this.stCardsArray[this.activeCard].date).endOf('isoWeek').format('YYYY-MM-DD'))
    .pipe(catchError((err: any) => {
      this.showErrorMessage();
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      if(response) {
        this.getDriversRewardsData();
      }
      else {
        this.showErrorMessage();
      }
    });
  };

  //Sort data
  sortDriversData(value: string) {
    this.sortByName = value;
    if(value === 'Bonus Achieved') {
      this.bonusAchieved = true;
    }
    else {
      this.bonusAchieved = false;
      let key: string = '';
      let isAsc: boolean = value.includes('Lowest to highest');
      if(value.startsWith('Gross')) {
        key = 'gross';
      }
      else if(value.startsWith('Points')) {
        key = 'points';
      }
      else {
        key = 'rpm';
      }
      const data = JSON.parse(JSON.stringify(this.dataSource));
      this.dataSource = data.sort((a: any, b: any) => {
        switch (key) {
          case 'gross':
            return compare(a.gross, b.gross, isAsc);
          case 'rpm':
            return compare(a.rpm, b.rpm, isAsc);
          case 'points':
            return compare((a.gross_points + a.rpm_points), (b.gross_points + b.rpm_points), isAsc);
          default:
            return 0;
        }
      });
    }
  };

  sortDispatchData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dispatchDataSource));
    if (!sort.active || sort.direction === '') {
      this.dispatchDataSource = data;
      return;
    }
    this.dispatchDataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'dispatcher':
          return compare(this.dispName.transform(a.dispatcher), this.dispName.transform(b.dispatcher), isAsc);
        case 'target_achived':
          return compare(a.target_achived, b.target_achived, isAsc);
        case 'earnings':
          return compare(a.earnings, b.earnings, isAsc);
        case 'gross_pts':
          return compare(a.gross_pts, b.gross_pts, isAsc);
        case 'rpm_pts':
          return compare(a.rpm_pts, b.rpm_pts, isAsc);
        case 'total_points':
          return compare(a.total_points, b.total_points, isAsc);
        default:
          return 0;
      }
    });
  }

  countsData(arrayOfArraysTarget: any[], driversArray: any[], index: number) {
    let calculationDataObj: any = {
      'targetGross-0': 0,
      'calculation_points_after-0': 0,
      'targetGrossSuccesRateSolo': 0,
      'targetRpm-0': 0,
      'calculation_points_after-1': 0,
      'targetRpmSuccesRateSolo': 0,
      'numOfSoloDrivers': 0,

      'targetGross-1': 0,
      'targetGrossSuccesRateTeam': 0,
      'targetRpm-1': 0,
      'targetRpmSuccesRateTeam': 0,
      'numOfTeamDrivers': 0,

      'bonusAchievedSolo': 0,
      'bonusAchievedTeam': 0,
      'bonusAchievedSoloAndTeam': 0,

      'grossIndex-0': null,
      'rpmIndex-0': null,
      'grossIndex-1': null,
      'rpmIndex-1': null,

      'sumGross': 0,
      'sumMileage': 0
    };
   
    this.stCardsArray[index].targetDataSoloAndTeam = arrayOfArraysTarget;

    //Target calculation
    for(let i = 0; i < arrayOfArraysTarget.length; i++) {

      if(arrayOfArraysTarget[i][0].type === 0) {
        this.stCardsArray[index].targetDataSolo = arrayOfArraysTarget[i];
      };
      if(arrayOfArraysTarget[i][0].type === 1) {
        this.stCardsArray[index].targetDataTeam = arrayOfArraysTarget[i];
      };

      for(let j = 0; j < arrayOfArraysTarget[i].length; j++) {
        if(arrayOfArraysTarget[i][j].condition === 'Gross') {
          calculationDataObj[`targetGross-${arrayOfArraysTarget[i][j].type}`] = arrayOfArraysTarget[i][j].target;
          calculationDataObj[`grossIndex-${arrayOfArraysTarget[i][j].type}`] = j;
        };
        if(arrayOfArraysTarget[i][j].condition === 'Rate per mile') {
          calculationDataObj[`targetRpm-${arrayOfArraysTarget[i][j].type}`] = arrayOfArraysTarget[i][j].target;
          calculationDataObj[`rpmIndex-${arrayOfArraysTarget[i][j].type}`] = j;
          calculationDataObj[`calculation_points_after-${arrayOfArraysTarget[i][j].type}`] = arrayOfArraysTarget[i][j].calculation_points_after;
        };
        arrayOfArraysTarget[i][j].succesRate = 0;
      };

    };

    //Drivers data calculation
    for(let i = 0; i < driversArray.length; i++) {
      if(driversArray[i].status.includes('SOLO') || driversArray[i].status === 'OWNER OPERATOR') {
        if(driversArray[i].gross >= calculationDataObj['targetGross-0']) {
          calculationDataObj.targetGrossSuccesRateSolo++;
        };
        if(driversArray[i].rpm >= calculationDataObj['targetRpm-0'] && driversArray[i].mileage > calculationDataObj['calculation_points_after-0']) {
          calculationDataObj.targetRpmSuccesRateSolo++;
        };
        calculationDataObj.numOfSoloDrivers++;
      }

      if(driversArray[i].status.includes('TEAM')) {
        if(driversArray[i].gross >= calculationDataObj['targetGross-1']) {
          calculationDataObj.targetGrossSuccesRateTeam++;
        };
        if(driversArray[i].rpm >= calculationDataObj['targetRpm-1'] && driversArray[i].mileage > calculationDataObj['calculation_points_after-1']) {
          calculationDataObj.targetRpmSuccesRateTeam++;
        };
        calculationDataObj.numOfTeamDrivers++;
      }

      if(driversArray[i].gross >= calculationDataObj['targetGross-0'] 
        && driversArray[i].rpm >= calculationDataObj['targetRpm-0'] 
        && (driversArray[i].status.includes('SOLO') || driversArray[i].status === 'OWNER OPERATOR') 
        && this.stCardsArray[index].targetDataSolo.length > 0) {
          calculationDataObj.bonusAchievedSolo++;
          calculationDataObj.bonusAchievedSoloAndTeam++;
      };
      if(driversArray[i].gross >= calculationDataObj['targetGross-1'] 
        && driversArray[i].rpm >= calculationDataObj['targetRpm-1'] 
        && driversArray[i].status.includes('TEAM') && this.stCardsArray[index].targetDataTeam.length > 0) {
          calculationDataObj.bonusAchievedTeam++;
          calculationDataObj.bonusAchievedSoloAndTeam++;
      };

      calculationDataObj.sumGross += driversArray[i].gross;
      calculationDataObj.sumMileage += driversArray[i].mileage;
    };

    if(calculationDataObj['grossIndex-0'] !== null && this.stCardsArray[index].targetDataSolo.length > 0) {
      this.stCardsArray[index].targetDataSolo[calculationDataObj['grossIndex-0']].succesRate = 
      (calculationDataObj['targetGrossSuccesRateSolo'] / calculationDataObj['numOfSoloDrivers']) * 100;
    };

    if(calculationDataObj['grossIndex-1'] !== null && this.stCardsArray[index].targetDataTeam.length > 0) {
      this.stCardsArray[index].targetDataTeam[calculationDataObj['grossIndex-1']].succesRate = 
      (calculationDataObj['targetGrossSuccesRateTeam'] / calculationDataObj['numOfTeamDrivers']) * 100;
    };

    if(calculationDataObj['rpmIndex-0'] !== null && this.stCardsArray[index].targetDataSolo.length > 0) {
      this.stCardsArray[index].targetDataSolo[calculationDataObj['rpmIndex-0']].succesRate = 
      (calculationDataObj['targetRpmSuccesRateSolo'] / calculationDataObj['numOfSoloDrivers']) * 100;
    };

    if(calculationDataObj['rpmIndex-1'] !== null && this.stCardsArray[index].targetDataTeam.length > 0) {
      this.stCardsArray[index].targetDataTeam[calculationDataObj['rpmIndex-1']].succesRate = 
      (calculationDataObj['targetRpmSuccesRateTeam'] / calculationDataObj['numOfTeamDrivers']) * 100;
    };

    this.stCardsArray[index].sumGrossTarget = calculationDataObj.sumGross;
    this.stCardsArray[index].sumRpmTarget = calculationDataObj.sumGross / calculationDataObj.sumMileage;

    this.stCardsArray[index].targetSuccessRateSolo = (calculationDataObj.bonusAchievedSolo / calculationDataObj.numOfSoloDrivers) * 100;
    this.stCardsArray[index].targetSuccessRateTeam = (calculationDataObj.bonusAchievedTeam / calculationDataObj.numOfTeamDrivers) * 100;
    
    this.stCardsArray[index].bonusAchievedSolo = calculationDataObj.bonusAchievedSolo;
    this.stCardsArray[index].bonusAchievedTeam = calculationDataObj.bonusAchievedTeam;
    this.stCardsArray[index].bonusAchievedSoloAndTeam = calculationDataObj.bonusAchievedSoloAndTeam;

    this.stCardsArray[index].dataSource = driversArray;
  };

  //Change period
  changePeriod(addOrSubstract: string) {
    this.resetRowData();
    this.resetQuickStatsData();
    this.stCardsArray[0].date = moment(this.stCardsArray[0].date)[addOrSubstract](1, 'week').startOf('isoWeek').format('YYYY-MM-DD');
    this.stCardsArray[1].date = moment(this.stCardsArray[1].date)[addOrSubstract](1, 'week').startOf('isoWeek').format('YYYY-MM-DD');
    this.stCardsArray[2].date = moment(this.stCardsArray[2].date)[addOrSubstract](1, 'week').startOf('isoWeek').format('YYYY-MM-DD');
    this.stCardsArray[3].date = moment(this.stCardsArray[3].date)[addOrSubstract](1, 'week').startOf('isoWeek').format('YYYY-MM-DD');
    this.stCardsArray[4].date = moment(this.stCardsArray[4].date)[addOrSubstract](1, 'week').startOf('isoWeek').format('YYYY-MM-DD');
    this.getDriversRewardsData();
  };

  //Select week
  selectWeek(dataSource: any[], i: number) {
    this.activeCard = i; 
    this.activeViewFilter = 0;
    this.expandRowIndex = undefined;
    this.resetRowData(); 
    this.resetQuickStatsData();
    this.dataSource = dataSource;
    this.sortDriversData(this.sortByName); 
    this.getDaysOfWeek();
  };

  //Get percent
  getPercent(value: number) {
    if(value > 100) {
      return 100;
    }
    else {
      return value;
    }
  };

  //Filters method
  selectFilters(arrayName: string, selectedValues: string[]) {
    this[arrayName] = selectedValues;
    this[arrayName] = [...this[arrayName]];
  };

  //Expand row
  expandRow(element: any, i: number) {
    this.resetRowData();
    if(this.expandRowIndex === i) {
      this.expandRowIndex = undefined;
    }
    else {
      this.expandRowIndex = i;
      this.getLoadsData(element.driver_id, true, {startDate: this.stCardsArray[this.activeCard].date, endDate: moment(this.stCardsArray[this.activeCard].date).endOf('isoWeek').format('YYYY-MM-DD')});
      this.getLoadsData(element.driver_id, false, this.loadsDateObj[this.periodNameKey]);
      this.getStatusesByDriver(element.driver_id);
    }
  };

  //Loads data
  getLoadsData(driverId: number, tableData: boolean, dateObj: any) {
    this.dispatchRewardSystemService.getDriverLoadsData(driverId, dateObj.startDate, dateObj.endDate)
    .subscribe((response: any) => {
      if(tableData) {
        this.loadsDataSource = response;
      }
      else {
        this.graphData = response;
        this.getLoadsLinechartData(dateObj);
      }
    });
  }

  //Loads linechart
  getLoadsLinechartData(dateObj: any) {
    let startDate: string = `${dateObj.startDate}T00:00:00`;
    let endDate: string = `${dateObj.endDate}T00:00:00`; 

    if(this.graphData.length > 0) {
      const hireDate: string | null = this.graphData[0].hire_date;
      const terminationDate: string | null = this.graphData[0].termination_date;
      
      startDate = hireDate <= startDate ? startDate : hireDate;
      endDate = terminationDate <= endDate ? terminationDate : endDate;

      let weekOrDay: string = this.periodNameKey === 'This week' || this.periodNameKey === 'Last week' ? 'day' : 'week';

      let allDatesArray: any[] = this.transformService.getDatesInRange(new Date(startDate), new Date(endDate), weekOrDay);
      this.currentWeekBlink = -1;
      let group = this.graphData.reduce((r: any, o: any) => {
        let periodName: any = '';
   
        if(this.periodNameKey === 'This week' || this.periodNameKey === 'Last week') {
          periodName = `${moment(o.pu_date).startOf('day').format('ddd MM.DD')}`;
        }
        else {
          periodName = `${moment(o.pu_date).startOf('isoWeek').format('MMM DD')}-${moment(o.pu_date).endOf('isoWeek').format('DD')}`;
        }

        if(r[periodName]) {
          r[periodName].gross += o.gross;
          r[periodName].mileage += o.mileage;
        } else {
          r[periodName] = {categories: periodName,  gross: o.gross ? o.gross: 0, mileage: o.mileage ? o.mileage: 0};
        };

      return r;
      }, {});
 
      //Statistics data
      let sumGross: number = 0;
      let sumMileage: number = 0;
      let numOfWeeks: number = weekOrDay === 'week' ? allDatesArray.length - 1 : 1;

      let result = Object.keys(group).map((key) => group[key]);
      let objOfArrays: any = {categories: [], totalGross: [], totalRpm: [], totalMileage: []};
      let index: number = 0;
      let currentWeek = `${moment().startOf('isoWeek').format('MMM DD')}-${moment().endOf('isoWeek').format('DD')}`;
      for(let i = 0; i < allDatesArray.length; i++) {
        if(allDatesArray[i] === currentWeek) {
          this.currentWeekBlink = i;
        }
        if(allDatesArray[i] === result[index]?.categories) {
          objOfArrays.categories.push(result[index].categories);
          objOfArrays.totalGross.push(result[index].gross);
          objOfArrays.totalRpm.push((result[index].gross / result[index].mileage).toFixed(2));
          objOfArrays.totalMileage.push(result[index].mileage);
          if(i !== (allDatesArray.length - 1)) {
            sumGross += result[index].gross;
            sumMileage += result[index].mileage;
          }
          index += (result.length - 1) === index ? 0 : 1;
        }
        else {
          objOfArrays.categories.push(allDatesArray[i]);
          objOfArrays.totalGross.push(0);
          objOfArrays.totalRpm.push(0);
          objOfArrays.totalMileage.push(0);
        }
      }
      this.loadsStObj.avgGross = sumGross / numOfWeeks;
      this.loadsStObj.avgRpm = sumGross / sumMileage;
      this.loadsStObj.avgMileage = sumMileage / numOfWeeks;
  
      let dataSeries: any[] = [];
      if(this.loadsStObj.showGrossInLinechart) {
        dataSeries.push({name: 'Gross', data: objOfArrays.totalGross});
      }
      if(this.loadsStObj.showRpmInLinechart) {
        dataSeries.push({name: 'Rate', data: objOfArrays.totalRpm});
      }
      if(this.loadsStObj.showMileageInLinechart) {
        dataSeries.push({name: 'Mileage', data: objOfArrays.totalMileage});
      }

      this.initGraph(objOfArrays.categories, dataSeries);
    }
    else {
      this.chartOptions = undefined;
    }
  };

  //Init graph
  initGraph(categories: any[], seriesData: any) {
    this.chartOptions = {
      series: seriesData,
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
          }
        },
        height: 250,
        type: "line",
        zoom: {
          enabled: false
        },
        fontFamily: 'Poppins'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight",
        width: 1,
      },
      grid: {
        row: {
          colors: ["transparent", "transparent"],
          opacity: 0.5
        }
      },
      xaxis: {
        categories: categories
      },
      yaxis: this.addYAxis(seriesData),
      markers: {
        size: 5
      },
      colors: ['#0030FF', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'],
      tooltip: {
        enabled: true,
        inverseOrder: false,
        style: {
          fontSize: '12px'
        },
      }
    };
    if(seriesData.length > 0 && this.currentWeekBlink !== -1) {
      this.blinkMarker(this.currentWeekBlink);
    }
  }

  //Y axis graph
  addYAxis(array: any) {
    let yAxisArray: any[] = [];
    let colors: string[] = ['#0030FF', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'];
    for(let i = 0; i < array.length; i++) {
      let obj: any;
      if(i === 0) {
        obj = {
        seriesName: array[i].name[0], axisTicks: {show: true}, axisBorder: {show: false, color: colors[i]},
        labels: {style: {colors: colors[i]}, formatter: (num: number) => { 
          let value: number = num;
          if(array[i].name !== 'Rate') {
            value = Math.round(value);
          }
          return value.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
        }
      } 
      else {
        obj = {
          seriesName: array[i].name[0], opposite: true, axisTicks: {show: true}, axisBorder: {show: false, color: colors[i]},
          labels: {style: {colors: colors[i]}, formatter: (num: number) => {
            let value: number = num;
            if(array[i].name !== 'Rate') {
              value = Math.round(value);
            }
            return value.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }}
        }
      }

      yAxisArray.push(obj);
    }

    return yAxisArray;
  }

  blinkMarker(index: number) {
    setTimeout(() => {
      const element = this.chartContainer.nativeElement;
      const marker = element.querySelectorAll('.apexcharts-marker')?.[index];
      marker?.classList.add('blinking')
    }, 1000);
  };

  //Add status
  addStatus(day: any, element: any) {
    if(element.hire_date <= moment(day.status_date).format('YYYY-MM-DDTHH:mm:ss') && !day.terminated) {
      let driverId: number = element.driver_id;
      let driverName: string = `${element.first_name} ${element.last_name}`;
      if(element.driver_id1) {
        driverId = element.driver_id1;
        driverName = `${element.first_name1} ${element.last_name1}`;
      }
      let data: any = {
        driverInfo: {info: {id: driverId, name: driverName, truck_no: element.unit_no, trailer_no: element.trailer_no}},
        date: new Date(day.status_date), 
        allStatusArray: this.statusesDataSource,
        startDate: this.stCardsArray[this.activeCard].date,
        endDate: element.status.length > 0 && new Date(element.status[element.status.length-1].status_date).getTime() > new Date(day.status_date).getTime() ? 
        element.status[element.status.length-1].status_date.split('T')[0] : moment(day.status_date).format('YYYY-MM-DD')
      };
      let dialogRef: any = this.dialog.open(StatusDialogComponent, {
        autoFocus: false,
        panelClass: 'status-dialog-container',
        data: data
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result)  {
          this.getStatusesByDriver(driverId)
        }
      })
    }
  };

  //Get statuses by driver
  getStatusesByDriver(driverId: number) {
    this.sharedService.getDriverStatusesByDriver(driverId, this.stCardsArray[this.activeCard].date, moment(this.stCardsArray[this.activeCard].date).endOf('isoWeek').format('YYYY-MM-DD'))
    .subscribe((response: any) => {
      let statusIndex: number = 0;
      const dataSource: any[] = [];
      for(let i = 0; i < 7; i++) {
        if(response[statusIndex]?.status_date === moment(this.stCardsArray[this.activeCard].date).add(i, 'd').format('YYYY-MM-DDT00:00:00')) {
          dataSource.push(response[statusIndex]);
          statusIndex++;
        }
        else {
          dataSource.push({className: null, color: '', craetor: '', creator_department: '', creator_id: null, datecreated: null, dispatcher: null,
            dispatcher_id: 0, driver_className: null, driver_id: null, driver_name: '', edit_date: null, editor: '', files: [],
            icon_id: null, icon_name: 'no-status.png', id: null, no_className: null, notes: [], status: 'No status', status_date: moment(this.stCardsArray[this.activeCard].date).add(i, 'd').format('YYYY-MM-DDT00:00:00'), 
            status_group_name: 'No status', terminated: false, total_className: null, trailer: null, unit_no: null});
        };
      }
      this.statusesDataSource = dataSource;
    });
  }

  changeQuickStatsSelection(key: string, statsKey: string) {
    this.quickStatsSelectUnselectObj[key] = !this.quickStatsSelectUnselectObj[key];
    if(this.quickStatsSelectUnselectObj[key]) {
      this.quickStatsSelect = `${statsKey}Selected`;
    }
    else {
      this.quickStatsSelect = `${statsKey}Unselected`;
    }
  };

  //Open in new tab
  openInNewTab(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  };

  dateWithoutTime(date: string) {
    return date.split('T')[0];
  };

  //Show error message
  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  //Reset filters
  resetFilters() {
    this.driverSearch = '';
    this.selectedDispatchers = [];
    this.selectedPositions = [];
    this.selectedTypes = [];
    this.selectedRecruiters = [];
    this.selectedDrivers = [];
  };

  //Reset row data
  resetRowData() {
    this.chartOptions = undefined;
    this.loadsDataSource = [];
    this.graphData = [];
    this.statusesDataSource = [];
    this.loadsStObj.avgGross = 0;
    this.loadsStObj.avgRpm = 0;
    this.loadsStObj.avgMileage = 0;
    this.periodNameKey = 'Last 5 weeks';
  };

  resetQuickStatsData() {
    this.quickStatsObj.countObj.avgGross = 0;
    this.quickStatsObj.countObj.avgRpm = 0;
    this.quickStatsObj.countObj.avgMileage = 0;
    this.quickStatsObj.countObj.totalGross = 0;
  };

  //Reset dispatch rewards data
  resetDispatchRewardsData() {
    this.dispatchArray = [];
    this.dispatchDataSource = [];
    this.dispatchRewardsDataSource = [];
    this.dispatchRewardsObj = {};
  };

  //Reset st data
  resetStData(index: number) {
    this.stCardsArray[index].dataSource = [];
    this.stCardsArray[index].targetDataSolo = [];
    this.stCardsArray[index].targetDataTeam = [];
    this.stCardsArray[index].bonusAchievedSoloAndTeam = 0;
    this.stCardsArray[index].bonusAchievedSolo = 0;
    this.stCardsArray[index].bonusAchievedTeam = 0;
    this.stCardsArray[index].targetSuccessRateSolo = 0;
    this.stCardsArray[index].targetSuccessRateTeam = 0;
    this.stCardsArray[index].avgGross = 0;
    this.stCardsArray[index].avgRpm = 0;
    this.stCardsArray[index].avgMileage = 0;
    this.stCardsArray[index].avgGrossTarget = 0;
    this.stCardsArray[index].avgRpmTarget = 0;
  }

  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[44].allowed) {
      const tableData: any[] = this.searchPipe.transform(this.dispatchDataSource, this.dispatchSearch, 'dispatcher');
      const excelTable: any[] = [];
      for(let i = 0; i < tableData.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, i + 1, columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.titleCase.transform(this.dispName.transform(tableData[i].dispatcher)), columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, tableData[i].target_achived, columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, tableData[i].earnings, columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, tableData[i].gross_pts, columnsConfig[4].selected);
        this.transformService.selectedColumn(obj, columnsConfig[5].columnName, tableData[i].rpm_pts, columnsConfig[5].selected);
        this.transformService.selectedColumn(obj, columnsConfig[6].columnName, tableData[i].total_points, columnsConfig[6].selected);
        excelTable.push(obj);
      };
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'target.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  //Get days of week
  getDaysOfWeek() {
    this.daysOfWeek = [];
    for(let i = 1; i <= 7; i++) {
      this.daysOfWeek.push({day: moment(this.stCardsArray[this.activeCard].date).day(i).format('ddd'), date: moment(this.stCardsArray[this.activeCard].date).day(i).format('YYYY-MM-DD')})
    }
  };

  //Get bonus
  showHideImage(element: any) {
    const driverTypes: any = {'SOLO COMPANY': 'targetDataSolo', 'TEAM COMPANY': 'targetDataTeam', 'SOLO RENT': 'targetDataSolo', 'TEAM RENT': 'targetDataTeam', 'OWNER OPERATOR': 'targetDataSolo'};
    let targetArray: any[] = this.stCardsArray[this.activeCard][driverTypes[element.status]];
    if(targetArray.length === 2) {
      return element.gross_perc >= 100 && element.rpm_perc >= 100;
    }
    if(targetArray.length === 1) {
      return targetArray[0].condition === 'Gross' ? element.gross_perc >= 100 : element.rpm_perc >= 100;
    }
    return false;
  };

  trackByDriver(index, item){
    return item.driver_id; 
  };

  trackByDisp(index, item){
    return item.id; 
  };

  get showSyncButton(): boolean {
    return (moment().isoWeek() <= moment(this.stCardsArray[this.activeCard].date).isoWeek());
  };

  get someWeekCurrentWeek(): any {
    let index: number | undefined = undefined;
    let condition: boolean = false;
    let weekCurrentBoolean: boolean[] = [false, false, false, false, false];
    if(this.stCardsArray[0].date === this.dateObj.startDate) {
      index = 0;
      condition = true;
      weekCurrentBoolean[0] = true;
    }
    if(this.stCardsArray[1].date === this.dateObj.startDate) {
      index = 1;
      condition = true;
      weekCurrentBoolean[1] = true;
    }
    if(this.stCardsArray[2].date === this.dateObj.startDate) {
      index = 2;
      condition = true;
      weekCurrentBoolean[2] = true;
    }
    if(this.stCardsArray[3].date === this.dateObj.startDate) {
      index = 3;
      condition = true;
      weekCurrentBoolean[3] = true;
    }
    if(this.stCardsArray[4].date === this.dateObj.startDate) {
      index = 4;
      condition = true;
      weekCurrentBoolean[4] = true;
    }
    return {index: index, condition: condition, weekCurrentBoolean: weekCurrentBoolean};
  };
  
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  };
 
  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();  
    this.subscription3?.unsubscribe(); 
    this.subscription4?.unsubscribe(); 
  };

}

function compare(a: number | any, b: number | any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}