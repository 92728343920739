import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ignoreEmptyString'
})
export class IgnoreEmptyStringPipe implements PipeTransform {

  transform(array: any[], key: string): any[] {
    const filteredArray: any[] = [];
    for(let i = 0; i < array.length; i++) {
      if(array[i][key]) {
        filteredArray.push(array[i]);
      }
    };
    return filteredArray;
  }

}
