import { Component, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'form-liability-physical-damage',
  templateUrl: './form-liability-physical-damage.component.html',
  styleUrls: ['./form-liability-physical-damage.component.scss']
})
export class FormLiabilityPhysicalDamageComponent {
  @Input() formGroup: FormGroup;
  @Input() locationsArray: any[] = [];

  constructor() { }
  
  uploadFiles(obj: any, key: string) {
    if(obj.name === 'UPLOAD') {
      const filesArray: any[] = this.formArray(key)?.controls;
      filesArray.push(obj.itemGroup);
      this.formGroup.controls[key].updateValueAndValidity();
    }
    else {
      this.formArray(key).removeAt(obj.index);
    }
  };

  autocompleteLocation(element: any) {
    this.formGroup.patchValue({
      'cr_l_physc_d_our_vehicle_city': element.city, 
      'cr_l_physc_d_our_vehicle_state': element.state, 
      'cr_l_physc_d_our_vehicle_zipcode': element.zip_code
    });
  };

  //Get form value
  formData(formKey: string) {
    return this.formGroup.controls[formKey].value;
  };
  
  //Check validation
  checkValidation(key: string): boolean {
    return !this.formGroup.controls[key]?.valid && this.formGroup.controls[key]?.touched; 
  };

  //Get form array
  formArray(formKey: string): FormArray {
    return this.formGroup.controls[formKey] as FormArray;
  };

}
