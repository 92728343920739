import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ClaimsService } from '@app/modules/claims/services/claims.service';

@Component({
  selector: 'form-claim-details',
  templateUrl: './form-claim-details.component.html',
  styleUrls: ['./form-claim-details.component.scss']
})
export class FormClaimDetailsComponent {
  @Input() formGroup: FormGroup;
  @Input() data: any = {locationsArray: [], claimantArray: []};

  constructor(private claimsService: ClaimsService) { }

  copyContent() {
    const data: any = this.formGroup.value;
    this.formGroup.patchValue({
      'cr_pd_name_of_facility': data.los_name_of_facility,
      'cr_pd_address': data.los_address,
      'cr_pd_city': data.los_city,
      'cr_pd_state': data.los_state,
      'cr_pd_zipcode': data.los_zipcode
    });
  };

  selectClaimType(value: number) {
    this.formGroup.patchValue({'type_of_claim': value});
  };
  
  chooseClaimant(value: string) {
    this.formGroup.patchValue({'claimant': value});
  };

  findAccidentIfExist(event: any) {
    this.claimsService.getIncidentByClaimNumber(this.formData('claim')).subscribe((response: any) => {
      console.log(response)
      if(response.id) {
        this.formGroup.patchValue({
          'load_no': response.load_no,
          'truck_no': response.unit_no,
          'trailer_no': response.equipment_no,
          'driver_no': response.driver,
          'accident_id': response.id,
          'date_of_loss': response.date_occured,
          'los_city': response.location_city,
          'los_state': response.location_state,
          'los_zipcode': response.location_zip,
          'fault_for_the_loss': response.fault_for_incident
        });
      }

      if ((event.key === 'Backspace' || event.key === 'Delete') && this.formData('accident_id')) {
        this.resetAutocomplete();
      }
      
    });
  };

  resetAutocomplete() {
    this.formGroup.patchValue({
      'load_no': null,
      'truck_no': null,
      'trailer_no': null,
      'driver_no': null,
      'accident_id': 0,
      'date_of_loss': null,
      'los_city': null,
      'los_state': null,
      'los_zipcode': null,
      'fault_for_the_loss': null
    });
  }

  setValidators(key: string, validators: any[]) {
    this.formGroup.controls[key].setValidators(validators);
    this.formGroup.controls[key].updateValueAndValidity();
  };

  clearValidators(key: string) {
    this.formGroup.controls[key].clearValidators();
    this.formGroup.controls[key].updateValueAndValidity();
  };

  //Check validation
  checkValidation(key: string): boolean {
    return !this.formGroup.controls[key]?.valid && this.formGroup.controls[key]?.touched; 
  };

  //Get form value
  formData(formKey: string) {
    return this.formGroup.controls[formKey].value;
  };

  getTypeOfCalm(id: number) {
    const obj: any = {'1': 'Liability – Physical Damage', '2': 'Cargo', '3': 'Physical Damage', '4': 'Liability – Personal Injury',
    '5': 'Liability – Property Damage', '6': 'OCC/ACC'};
    if(id) {
      return obj[id];
    }
    return '';
  };

}
