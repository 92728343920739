import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'form-occ-acc',
  templateUrl: './form-occ-acc.component.html',
  styleUrls: ['./form-occ-acc.component.scss']
})
export class FormOccAccComponent {
  @Input() formGroup: FormGroup;

  //Get form value
  formData(formKey: string) {
    return this.formGroup.controls[formKey].value;
  };

}
