import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'claims-files-tab',
  templateUrl: './claims-files-tab.component.html',
  styleUrls: ['./claims-files-tab.component.scss']
})
export class ClaimsFilesTabComponent {
  @Input() formGroup: FormGroup;
  files: any[] = [{filename: 'Test'}, {filename: 'Test2'}, {filename: 'Test3'}];
}
