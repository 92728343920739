import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deductionTable'
})
export class DeductionTablePipe implements PipeTransform {

  transform(array: any[], filterObj: any): any[] {
    if (!array) {
      return [];
    }

    let transformedArray: any[] = [];
    for(let i = 0; i < array.length; i++) {
      let condition1: boolean = filterObj.issued_by.length === 0 ? true : filterObj.issued_by.includes(array[i].issued_by);
      let condition2: boolean = filterObj.driver_name.length === 0 ? true : filterObj.driver_name.includes(array[i].driver_name);
      let condition3: boolean = filterObj.division.length === 0 ? true : filterObj.division.includes(array[i].division);
      let condition4: boolean = filterObj.unit_no.length === 0 ? true : filterObj.unit_no.includes(array[i].unit_no);
      let condition5: boolean = this.calendarFilter(filterObj.created_date, array[i].created_date);
      let condition6: boolean = this.checkboxFilter(filterObj.description, 'With Desc', array[i].description?.length > 0);
      let condition7: boolean = this.numericFilter(filterObj.total_deductions.sign, filterObj.total_deductions.value, array[i].total_deductions);
      let condition8: boolean = this.numericFilter(filterObj.no_of_installments.sign, filterObj.no_of_installments.value, array[i].no_of_installments);
      let condition9: boolean = this.checkboxFilter(filterObj.files, 'With files', array[i].files?.length > 0);
      let condition10: boolean = filterObj.scheduled_by.length === 0 ? true : filterObj.scheduled_by.includes(array[i].name);
      if(condition1 && condition2 && condition3 &&  condition4 && condition5 && condition6 && condition7 && condition8 && condition9 && condition10) {
        transformedArray.push(array[i]);
      }
    };
    return transformedArray;
  }

  checkboxFilter(selectedValue: string, checkedValue: string, value: boolean) {
    let condition: boolean = true;
    if(selectedValue === 'Both') {
      condition  = true;
    }
    else if(selectedValue === checkedValue) {
      condition = value === true;
    }
    else {
      condition = value === false;
    }
    return condition;
  };

  numericFilter(sign: string, value: number | null, elementValue: number) {
    let key: boolean = true;
    if(value) {
      if(sign === 'More than') {
        key = Number(elementValue) > Number(value);
      }
      if(sign === 'Less than') {
        key = Number(elementValue) < Number(value);
      }
      if(sign === 'Equal') {
        key = Number(elementValue) == Number(value);
      }
    }
    return key;
  };

  calendarFilter(selectedDateRange: any, dateToCompare: string) {
    let condition: boolean = true;
    if(selectedDateRange) {
      const start = Date.parse(selectedDateRange.start._d);
      const end = Date.parse(selectedDateRange.end._d);
      const d = Date.parse(dateToCompare);
      condition = d.valueOf() >= start.valueOf() && d.valueOf() <= end.valueOf();
    }
    return condition;
  };

}
