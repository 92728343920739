import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Subscription } from 'rxjs';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-email-dialog',
  templateUrl: './email-dialog.component.html',
  styleUrls: ['./email-dialog.component.scss']
})
export class EmailDialogComponent implements OnInit, OnDestroy {
  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
  emailArray: any[] = [];
  searchText: string = '';

  selectedEmails: any[] = [];

  subscription: Subscription | any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<any>,
              private sharedService: SharedService) { }

  ngOnInit(): void {
    if(this.data.selectedEmails.length) {
      this.selectedEmails = this.data.selectedEmails;
    }
    else {
      this.selectedEmails = this.data.deafultAddressArray;
      this.selectedEmails.unshift({id: this.loggedUser.id, email: this.loggedUser.username, name: `${this.loggedUser.first_name} ${this.loggedUser.last_name}`});
    };
    this.getAllEmails();
  }

  getAllEmails() {
    this.subscription = this.sharedService.getAllEmails().subscribe((response: any) => {
      console.log(response);
      let filteredArray: any[] = [];
      let allDepartments: any = {
        accounting: [],
        afterHours: [],
        dispatch: [],
        finance: [],
        hos: [],
        maintenance: [],
        marketing: [],
        recruiting: [],
        safety: []
      };

      for(let i = 0; i < response.length; i++) {

        if(!response[i].is_active) {

          for(let j = 0; j < response[i].companies.length; j++) {

            if(response[i].companies[j].is_primary) {

              if(response[i].department.trim() === 'Accounting') {
                allDepartments.accounting.push({id: response[i].settings_id, email: response[i].companies[j].email, name: `${response[i].name} / ${response[i].nickname} ${response[i].companies[j].ext}`});
              }
              if(response[i].department.trim() === 'After Hours') {
                allDepartments.afterHours.push({id: response[i].settings_id, email: response[i].companies[j].email, name: `${response[i].name} / ${response[i].nickname} ${response[i].companies[j].ext}`});
              }
              if(response[i].department.trim() === 'Dispatch') {
                allDepartments.dispatch.push({id: response[i].settings_id, email: response[i].companies[j].email, name: `${response[i].name} / ${response[i].nickname} ${response[i].companies[j].ext}`});
              }
              if(response[i].department.trim() === 'Finance') {
                allDepartments.finance.push({id: response[i].settings_id, email: response[i].companies[j].email, name: `${response[i].name} / ${response[i].nickname} ${response[i].companies[j].ext}`});
              }
              if(response[i].department.trim() === 'Hours of Service') {
                allDepartments.hos.push({id: response[i].settings_id, email: response[i].companies[j].email, name: `${response[i].name} / ${response[i].nickname} ${response[i].companies[j].ext}`});
              }
              if(response[i].department.trim() === 'Maintenance') {
                allDepartments.maintenance.push({id: response[i].settings_id, email: response[i].companies[j].email, name: `${response[i].name} / ${response[i].nickname} ${response[i].companies[j].ext}`});
              }
              if(response[i].department.trim() === 'Marketing') {
                allDepartments.marketing.push({id: response[i].settings_id, email: response[i].companies[j].email, name: `${response[i].name} / ${response[i].nickname} ${response[i].companies[j].ext}`});
              }
              if(response[i].department.trim() === 'Recruiting') {
                allDepartments.recruiting.push({id: response[i].settings_id, email: response[i].companies[j].email, name: `${response[i].name} / ${response[i].nickname} ${response[i].companies[j].ext}`});
              }
              if(response[i].department.trim() === 'Safety') {
                allDepartments.safety.push({id: response[i].settings_id, email: response[i].companies[j].email, name: `${response[i].name} / ${response[i].nickname} ${response[i].companies[j].ext}`});
              }

              filteredArray.push({id: response[i].settings_id, emailArray: [{id: response[i].settings_id, email: response[i].companies[j].email, name: `${response[i].name} / ${response[i].nickname} ${response[i].companies[j].ext}`}], name: `${response[i].name} / ${response[i].nickname} ${response[i].companies[j].ext}`, group: false});
              break;
            }

          }

        }

      }
      
      filteredArray.unshift({id: 0, emailArray: allDepartments.safety, name: 'Safety', group: true});
      filteredArray.unshift({id: 0, emailArray: allDepartments.recruiting, name: 'Recruiting', group: true});
      filteredArray.unshift({id: 0, emailArray: allDepartments.marketing, name: 'Marketing', group: true});
      filteredArray.unshift({id: 0, emailArray: allDepartments.maintenance, name: 'Maintenance', group: true});
      filteredArray.unshift({id: 0, emailArray: allDepartments.hos, name: 'Hours of Service', group: true});
      filteredArray.unshift({id: 0, emailArray: allDepartments.finance, name: 'Finance', group: true});
      filteredArray.unshift({id: 0, emailArray: allDepartments.dispatch, name: 'Dispatch', group: true});
      filteredArray.unshift({id: 0, emailArray: allDepartments.afterHours, name: 'After Hours', group: true});
      filteredArray.unshift({id: 0, emailArray: allDepartments.accounting, name: 'Accounting', group: true});
      this.emailArray = filteredArray;
    })
  }

  addEmail(obj: any) {
    for(let i = 0; i < obj.emailArray.length; i++) {
      let isExist = this.selectedEmails.find((user: any) => user.email === obj.emailArray[i].email);
      if(!isExist) {
        this.selectedEmails.push({id: obj.emailArray[i].id, email: obj.emailArray[i].email, name: obj.emailArray[i].name});
      }
    };
    this.searchText = '';
  };

  //Discard emails
  discardEmails(i: number) {
    this.selectedEmails.splice(i, 1);
  };

  tagUsers() {
    if(this.selectedEmails.length > 1) {
      this.dialogRef.close(this.selectedEmails);
    }
    else {
      this.dialogRef.close();
    }
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}
