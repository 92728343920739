import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DriverApplicationsService {
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
  });
  requestOptions = { headers: this.headers };

  constructor(private http: HttpClient) { }

  getAllDriverApplications(startDate: string, endDate: string) {
    return this.http.get(`${_G.extendApiUrl}fullapp/app?start=${startDate}&end=${endDate}`, this.requestOptions);
  };

  changeDriverApplicationsData(obj: any) {
    const json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}fullapp/app`, json, this.requestOptions);
  };

}
