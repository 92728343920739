import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import moment = require('moment');
import { IAccident } from '../../models/accident.model';
import { TitleCasePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, Subscription, throwError } from 'rxjs';
import { AccidentTableFilterPipe } from '../../pipes/accident-table-filter.pipe';
import { AccidentsService } from '../../services/accidents.service';
import * as XLSX from 'xlsx';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { AddEditAccidentsDialogComponent } from './add-edit-accidents-dialog/add-edit-accidents-dialog.component';
import { AccidentsTableComponent } from './accidents-table/accidents-table.component';

@Component({
  selector: 'app-accidents-page',
  templateUrl: './accidents-page.component.html',
  styleUrls: ['./accidents-page.component.scss']
})
export class AccidentsPageComponent implements OnInit, OnDestroy {
  @ViewChild(AccidentsTableComponent) childComponent: any;
  permissions: any = this.rulesService.UserData[43].data;

  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  };

  accidentsArray: IAccident[] | any = [];

  //Excel config
  xlsxConfig: any[] = [
    {columnName: 'No.', selected: true},
    {columnName: 'Driver', selected: true},
    {columnName: 'Load #', selected: true},
    {columnName: 'Truck #', selected: true},
    {columnName: 'Equipment #', selected: true},
    {columnName: 'Date of Loss', selected: true},
    {columnName: 'Whose fault was the incident', selected: true},
    {columnName: 'Claim', selected: true},
    {columnName: 'City', selected: true},
    {columnName: 'State', selected: true},
    {columnName: 'Zip code', selected: true},
    {columnName: 'Police Case #', selected: true},
    {columnName: 'Drug Test Date', selected: true},
    {columnName: 'Alcohol Test Date', selected: true},
    {columnName: 'DOT Recordable', selected: true},
    {columnName: 'Hazmat', selected: true},
    {columnName: 'Closed', selected: true}
  ];
  
  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private dialog: MatDialog,
    private accidentsService: AccidentsService,
    public transformService: TransformService, 
    private spinner: NgxSpinnerService,
    private rulesService: RulesService,
    private titleCase: TitleCasePipe,
    private accidentsTablePipe: AccidentTableFilterPipe) { }

  ngOnInit(): void {
    this.getAccidentsTableData();
  }

  getAccidentsTableData() {
    this.accidentsArray = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('accidents');
    this.subscription = this.accidentsService.getAccidentsByDate(this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('accidents');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: IAccident[]) => {
      this.accidentsArray = response;
      this.spinner.hide('accidents');
      this.loaded = true;
      console.log(response);
    });
  };

  addEditAccident(isEdit: boolean, id: number | null) {
    if(this.permissions[0].sectionArray[0].allowed) {
      let dialogRef = this.dialog.open(AddEditAccidentsDialogComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'component-dialog-container',
        data: {editMode: isEdit, id: id}
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.getAccidentsTableData();
        }
      });
    }
    else {
      this.msgForbbidenAccess();
    }
  }

  clearFilters() {
    this.childComponent.clearFilters();
  };

  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[20].allowed) {
      const tableData: any[] = this.accidentsTablePipe.transform(this.accidentsArray, this.childComponent.filters);
      const excelTable: any[] = [];
      for(let i = 0; i < tableData.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, i + 1, columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.titleCase.transform(tableData[i].driver), columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, tableData[i].load_no, columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, tableData[i].unit_no, columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, tableData[i].equipment_no, columnsConfig[4].selected);
        this.transformService.selectedColumn(obj, columnsConfig[5].columnName, this.transformDateFormat(tableData[i].date_occured, 'MMM DD, YYYY H:mm'), columnsConfig[5].selected);
        this.transformService.selectedColumn(obj, columnsConfig[6].columnName, tableData[i].fault_for_incident, columnsConfig[6].selected);
        this.transformService.selectedColumn(obj, columnsConfig[7].columnName, tableData[i].claim_number, columnsConfig[7].selected);
        this.transformService.selectedColumn(obj, columnsConfig[8].columnName, tableData[i].location_city, columnsConfig[8].selected);
        this.transformService.selectedColumn(obj, columnsConfig[9].columnName, tableData[i].location_state, columnsConfig[9].selected);
        this.transformService.selectedColumn(obj, columnsConfig[10].columnName, tableData[i].location_zip, columnsConfig[10].selected);
        this.transformService.selectedColumn(obj, columnsConfig[11].columnName, tableData[i].case_number, columnsConfig[11].selected);
        this.transformService.selectedColumn(obj, columnsConfig[12].columnName, this.transformDateFormat(tableData[i].drug_test_date, 'MMM DD, YYYY.'), columnsConfig[12].selected);
        this.transformService.selectedColumn(obj, columnsConfig[13].columnName, this.transformDateFormat(tableData[i].alcohol_test_date, 'MMM DD, YYYY.'), columnsConfig[13].selected);
        this.transformService.selectedColumn(obj, columnsConfig[14].columnName, tableData[i].is_dot_recordable, columnsConfig[14].selected);
        this.transformService.selectedColumn(obj, columnsConfig[15].columnName, tableData[i].is_hazmat, columnsConfig[15].selected);
        this.transformService.selectedColumn(obj, columnsConfig[16].columnName, tableData[i].is_closed, columnsConfig[16].selected);
        excelTable.push(obj);
      };
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'accidents.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };
  
  getTypeOfClaim(id: number) {
    const obj: any = {'1': 'Liability – Physical Damage', '2': 'Cargo', '3': 'Physical Damage', '4': 'Liability – Personal Injury',
    '5': 'Liability – Property Damage', '6': 'OCC/ACC'};
    if(id) {
      return obj[id];
    }
    return '';
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  transformDateFormat(date: any, format: string) {
    if(date) {
      return moment(date).format(format);
    }
    return '';
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
  
}
