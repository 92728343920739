import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CompletedTaskDialogComponent } from '../completed-task-dialog/completed-task-dialog.component';

@Component({
  selector: 'task-status-dropdown',
  templateUrl: './task-status-dropdown.component.html',
  styleUrls: ['./task-status-dropdown.component.scss']
})
export class TaskStatusDropdownComponent {
  _status: number = 0;
  @Input() set status(value: number) {
    this._status = value;
  }
  @Output() emitEvent = new EventEmitter<any>();

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      0, 1
    )
  ];

  statusArray: any[] = [
    {name: 'Pending', icon: 'assignment', color: 'orange', statusIndex: 0},
    {name: 'Done', icon: 'done', color: 'green fw', statusIndex: 2}
  ];

  statusObj: any = {
    '0': 'Pending',
    '1': 'Overdue',
    '2': 'Done',
    'icon-0': 'assignment',
    'icon-1': 'update',
    'icon-2': 'done'
  };
  isOpenMenu: boolean = false;

  constructor(private dialog: MatDialog) { }

  changeStatus(obj: any) {
    this.isOpenMenu = !this.isOpenMenu;
    if(obj.name === 'Done') {
      this.openCompleteTaskDialog(obj.statusIndex);
    }
    else {
      this.emitEvent.emit(obj.statusIndex);
      this._status = obj.statusIndex;
    }
  };

  getClass(status: string) : string {
    if(status === 'Done') {
      return 'border-green';
    }
    else if(status === 'Overdue') {
      return 'border-red';
    } 
    else {
      return 'border-orange';
    };
  };

  openCompleteTaskDialog(statusIndex: number) {
    let dialogRef = this.dialog.open(CompletedTaskDialogComponent, {
      autoFocus: false,
      panelClass: 'completed-task-dialog-container'
    });
    dialogRef.afterClosed().subscribe((response: any[]) => {
      if(response) {
        this.emitEvent.emit(statusIndex);
        this._status = statusIndex;
      }
    });
  }

}
