import { Component, Input } from '@angular/core';
import { ApexAxisChartSeries, ApexChart, ApexXAxis, ApexResponsive, ApexLegend, ApexDataLabels, ApexPlotOptions } from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  responsive: ApexResponsive | any;
  labels: string | any;
  colors: string | any;
  legend: ApexLegend | any;
  tooltip: any;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
};


@Component({
  selector: 'inspections-piecharts',
  templateUrl: './inspections-piecharts.component.html',
  styleUrls: ['./inspections-piecharts.component.scss']
})
export class InspectionsPiechartsComponent {
  stObj: any = {
    numOfPassedInspections: 0, numOfFailedInspections: 0, 
    passedInspectionsLevelI: 0, passedInspectionsLevelII: 0, passedInspectionsLevelIII: 0,
    failedInspectionsLevelI: 0, failedInspectionsLevelII: 0, failedInspectionsLevelIII: 0,
    allInspectionsLevelI: 0, allInspectionsLevelII: 0, allInspectionsLevelIII: 0,
  };
  graphData: any[] = [];

  @Input() set updateGraphs(obj: any) {
    this.stObj = obj.calculatedData;
    this.graphData = obj.graphData;
    this.changePieData(1, obj.inspectionLevel);
    this.changePieData(2, obj.inspectionType);
    this.changePieData(3, obj.inspectionType);
  };

  colorPalette = ['#0f4bac', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107', '#FF9800', '#FF5722'];

  //Outcome
  outcomeValue: string = 'All Levels';
  outcomeObj: any = {
    'All Levels': {name: 'All Levels', key: 'numOfPassedInspections', key2: 'numOfFailedInspections'}, 
    'Level I': {name: 'Level I', key: 'passedInspectionsLevelI', key2: 'failedInspectionsLevelI'}, 
    'Level II': {name: 'Level II', key: 'passedInspectionsLevelII', key2: 'failedInspectionsLevelII'}, 
    'Level III':{name: 'Level III', key: 'passedInspectionsLevelIII', key2: 'failedInspectionsLevelIII'}
  };

  //Level
  levelValue: string = 'All Inspections';
  levelsObj: any = {
    'All Inspections': {name: 'All Inspections', key: 'allInspectionsLevelI', key2: 'allInspectionsLevelII', key3: 'allInspectionsLevelIII'}, 
    'Passed': {name: 'Passed', key: 'passedInspectionsLevelI', key2: 'passedInspectionsLevelII', key3: 'passedInspectionsLevelIII'}, 
    'Failed': {name: 'Failed', key: 'failedInspectionsLevelI', key2: 'failedInspectionsLevelII', key3: 'failedInspectionsLevelIII'}
  };

  //State
  stateValue: string = 'All Inspections';

  public chartOptions1: Partial<ChartOptions> | any;
  public chartOptions2: Partial<ChartOptions> | any;
  public chartOptions3: Partial<ChartOptions> | any;


  changePieData(type: number, value: string) {
    if(type === 1) {
      this.outcomeValue = value;
      this.chartOptions1 = this.pieChart(['PASSED', 'FAILED'], [this.stObj[this.outcomeObj[this.outcomeValue].key], this.stObj[this.outcomeObj[this.outcomeValue].key2]]);
    }
    if(type === 2) {
      this.levelValue = value;
      this.chartOptions2 = this.pieChart(['LEVEL I', 'LEVEL II', 'LEVEL III'], [this.stObj[this.levelsObj[this.levelValue].key], this.stObj[this.levelsObj[this.levelValue].key2], this.stObj[this.levelsObj[this.levelValue].key3]]);
    }
    if(type === 3) {
      this.stateValue = value;
      this.calculateStatesData();
    }
  };

  calculateStatesData() {
    let labels: string[] = [];
    let series: number[] = [];
    const frequency = {};
    for(let i = 0; i < this.graphData.length; i++) {
      let state: string = this.graphData[i].state;
      if(this.stateValue  === 'All Inspections') {
        frequency[state] = (frequency[state] || 0) + 1;
      }

      if(this.stateValue  === 'Passed' && this.graphData[i].inspection_result === 'PASSED') {
        frequency[state] = (frequency[state] || 0) + 1;
      }

      if(this.stateValue  === 'Failed' && this.graphData[i].inspection_result === 'FAILED') {
        frequency[state] = (frequency[state] || 0) + 1;
      }
    }

    for (const property in frequency) {
      labels.push(property);
      series.push(frequency[property]);
    }
    this.chartOptions3 = this.pieChart(labels, series);
  }

  pieChart(labels: string[], series: number[]) {
    return {
      colors: this.colorPalette,
      series: series,
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -33
          }, 
        }
      },
      chart: {
        width: 450,
        type: "pie",
        fontFamily: 'Poppins'
      },
      labels: labels,
      legend: {
        position: 'bottom',
        formatter: function(seriesName, opts) {
          return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex]]
      }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
    
  };


}
