import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DriverApplicationsPageComponent } from './components/driver-applications-page/driver-applications-page.component';
import { AuthGuard } from '../core/services/auth/auth.guard';

const routes: Routes = [
    {
        path: '',
        component: DriverApplicationsPageComponent,
        canActivate: [AuthGuard],
        data: { roles: 0 }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DriverApplicationsRoutingModule { }