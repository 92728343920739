import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/services/auth/auth.guard';
import { ReportMileagePerTruckComponent } from './components/report-page/report-mileage-per-truck/report-mileage-per-truck.component';
import { ReportTruckActivationComponent } from './components/report-page/report-truck-activation/report-truck-activation.component';
import { ReportTruckActivityComponent } from './components/report-page/report-truck-activity/report-truck-activity.component';
import { ReportTruckProfitabilityComponent } from './components/report-page/report-truck-profitability/report-truck-profitability.component';
import { ReportAmortizationComponent } from './components/report-page/report-amortization/report-amortization.component';

const routes: Routes = [
    {
		path: 'mileage-per-truck',
		canActivate: [AuthGuard],
		component: ReportMileagePerTruckComponent,
		data: { roles: 48 }
	},
	{
		path: 'truck-activation',
		canActivate: [AuthGuard],
		component: ReportTruckActivationComponent,
		data: { roles: 49 }
	},
	{
		path: 'truck-activity',
		canActivate: [AuthGuard],
		component: ReportTruckActivityComponent,
		data: { roles: 50 }
	},
	{
		path: 'truck-profitability',
		canActivate: [AuthGuard],
		component: ReportTruckProfitabilityComponent,
		data: { roles: 51 }
	},
	{
		path: 'truck-amortization',
		canActivate: [AuthGuard],
		component: ReportAmortizationComponent,
		data: { roles: 59 }
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ReportsRoutingModule { }