import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'autocomplete-emails',
  templateUrl: './autocomplete-emails.component.html',
  styleUrls: ['./autocomplete-emails.component.scss']
})
export class AutocompleteEmailsComponent {  
  @Input() emailsListArray: any[] = [];
  @Output() triggerEvent = new EventEmitter<any>();

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'start', originY: 'bottom'},
      {overlayX: 'start', overlayY: 'top'},
      0, 19
    ),
    new ConnectionPositionPair(
      { originX: 'start', originY: 'top' },
      { overlayX: 'start', overlayY: 'bottom' },
      0, -19
    )
  ];

  isOpenAutocompleteDropdown: boolean = false;

  //Add email
  addEmail(event: KeyboardEvent, el: any) {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (event.key === 'Enter' && regex.test(el.value)) {
      this.triggerEvent.emit([{id: 0, name: '/', email: el.value}]);
      el.value = '';
      this.isOpenAutocompleteDropdown = false;
    }
  };

  selectEmail(emailsArray: any[]) {
    this.triggerEvent.emit(emailsArray);
    this.isOpenAutocompleteDropdown = !this.isOpenAutocompleteDropdown;
  };

}
