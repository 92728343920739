import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CreateUpdatePaymentDialogComponent } from '@app/modules/accounting/components/dashboard-payments-page/create-update-payment-dialog/create-update-payment-dialog.component';
import { MaintenanceRepairLogService } from '@app/modules/maintenance/services/maintenance-repair-log.service';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { DispNamePipe } from '@app/modules/shared/pipes/disp-name.pipe';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import moment = require('moment');
import { catchError, Subscription, throwError } from 'rxjs';

@Component({
  selector: 'app-create-update-repair',
  templateUrl: './create-update-repair.component.html',
  styleUrls: ['./create-update-repair.component.scss']
})
export class CreateUpdateRepairComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[60].data;

  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
  fullName: string = `${this.loggedUser.first_name} ${this.loggedUser.last_name}`;
  nickname: string = `${this.loggedUser.nickname}`;
  nameToDisplay: string = `${this.nickname.length === 0 ? this.fullName : this.nickname?.trim().split(' ')[0]}`;

  //Repair group
  repairFormGroup: any = this._formBuilder.nonNullable.group({
    Id: [0],
    DateOfLogEntry: [moment().format('YYYY-MM-DD')],
    LoggedBy: [this.nameToDisplay, [Validators.required, Validators.minLength(2)]],
    LoggedById: [this.loggedUser.id],
    EquipmentType: ['Truck'],
    TruckNo: [''],
    TruckId: [0],
    TrailerNo: [''],
    TrailerId: [0],
    DriverName: [''],
    DriverId: [0],
    Dispatcher: '',
    DispatcherId: [0],
    Division: [null],
    ReasonForRepair: [''],
    LogID: [null],
    TypeOfRepair: [''],
    LocationOfRepair: [''],
    DescriptionOfRepair: [''],
    Status: [''],
    CostOfRepair: [0],
    DateOfLoss: [null],
    DateCreated: [null],
    EditedBy: [''],
    EditedDate: [null],
    DivisionName: [''],
    InvoiceFileId: [''],
    InvoiceFileName: [''],
    InvoiceFile: [null],
    LocationOfRepairAddress: [''],
    LocationOfRepairZip: [''],
    LocationOfRepairCity: [''],
    LocationOfRepairState: [''],
    notes: ['']
  });
  
  //Equipment type array
  equipmentTypeArray: any[] = [
    {name: 'Truck'},
    {name: 'Trailer'}
  ];

  //Trucks
  trucksDataArray: any[] = [];
  trucksById: any = {
    '0': {unit_no: '', driver1_id: 0, dispatcher_id: 0}
  };

  //Trailers
  trailersDataArray: any[] = [];
  trailersById: any = {
    '0': {unit_no: ''}
  };

  //Drivers
  driversDataArray: any[] = [];
  driversById: any = {
    '0': {full_name_upper_case: ''}
  };

  //Dispatchers
  dispatchersArray: any[] = [];
  dispatchersById: any = {
    '0': {name: ''}
  };

  //Divisions
  divisionsDataArray: any[] = [];
  divisionById: any = {};

  //File
  isOpenFileMenu: boolean = false;
  fileLoaded: boolean = true;
  fileData: string = ''; 

  //Multiple clicks
  multipleClicks: boolean = true;
  
  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();
  subscription3: Subscription = new Subscription();
  subscription4: Subscription = new Subscription();
  subscription5: Subscription = new Subscription();
  subscription6: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<CreateUpdateRepairComponent>,
              private _formBuilder: FormBuilder,
              private rulesService: RulesService,
              private sharedService: SharedService,
              private titleCasePipe: TitleCasePipe,
              private dispName: DispNamePipe,
              private maintenanceRepairLogService: MaintenanceRepairLogService) { }

  ngOnInit(): void {
    if(this.obj.editMode) {
     this.repairFormGroup.patchValue(this.obj.data);
     if(this.obj.data.InvoiceFileName !== '') {
      this.fileLoaded = false;
      this.getMaintenanceRepairFile();
    }
    }
    this.getAllTrucks();
    this.getAllTrailers();
    this.getAllDrivers();
    this.getAllDispatchers();
    this.getAllDivisions();
  }    

  //Get file
  getMaintenanceRepairFile() {
    this.subscription1 = this.maintenanceRepairLogService.getMaintenanceRepairFileById(this.obj.data.Id)
    .pipe(catchError((err: any) => {
      this.fileLoaded = false;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response)
      this.fileData = response.InvoiceFile;
      if(response) {
        this.repairFormGroup.patchValue({'InvoiceFileId': response.InvoiceFileId,'InvoiceFileName': response.InvoiceFileName, 'InvoiceFile': response.InvoiceFile});
      }
      this.fileLoaded = true;
    });
  };  
  
  getAllTrucks() {
    this.subscription2 = this.sharedService.getAllTrucksData().subscribe((trucksArray: any[]) => {
      this.trucksDataArray = trucksArray;
      for(let i = 0; i < trucksArray.length; i++) {
        this.trucksById[trucksArray[i].id] = trucksArray[i];
      };
    });
  };
 
  getAllTrailers() {
    this.subscription3 = this.sharedService.getTrailersData().subscribe((response: any) => {
      this.trailersDataArray = response;
      for(let i = 0; i < response.length; i++) {
        this.trailersById[response[i].id] = response[i];
      }
    });
  };

  getAllDrivers() {
    this.subscription4 = this.sharedService.getDriversData().subscribe((response: any) => {
      for(let i = 0; i < response.length; i++) {
        response[i].full_name = this.titleCasePipe.transform(`${response[i].first_name} ${response[i].last_name}`);
        response[i].full_name_upper_case = `${response[i].first_name} ${response[i].last_name}`;
        this.driversById[response[i].id] = response[i];
      }
      this.driversDataArray = response;
    });
  };

  getAllDispatchers() {
    this.subscription5 = this.sharedService.getAllDispatchers().subscribe((response: any) => {
      this.dispatchersArray = response;
      for(let i = 0; i < response.length; i++) {
        const obj: any = response[i];
        obj.nickname = this.titleCasePipe.transform(this.dispName.transform(response[i].name));
        this.dispatchersById[response[i].id] = obj;
      };
    })
  };

  getAllDivisions() {
    this.subscription6 = this.sharedService.getCompanyData().subscribe((response: any) => {
      this.divisionsDataArray = response.divisions;
      for(let i = 0; i < this.divisionsDataArray.length; i++) {
        this.divisionById[this.divisionsDataArray[i].id] = this.divisionsDataArray[i];
      };
    });
  }

  //Autocomplete form
  autocompleteForm(event: any, isTruck: boolean) {
    const isObject = typeof event === 'object';

    let patchData: any = {};
  
    if (isObject) {
      const truckId = isTruck ? event?.id : event?.truck_id;
      const trailerId = isTruck ? event?.equipment_id : event?.id;
      const truckUnitNo = isTruck ? event?.unit_no : this.trucksById[truckId]?.unit_no;
      const trailerUnitNo = isTruck ? this.trailersById[trailerId]?.unit_no : event?.unit_no;
      const driverId = isTruck ? event?.driver1_id : this.trucksById[truckId]?.driver1_id;
      const dispatcherId = isTruck ? event?.dispatcher_id : this.trucksById[truckId]?.dispatcher_id;
      const divisionId = Number(event?.division_id) || null;
  
      Object.assign(patchData, {
        TruckNo: truckUnitNo,
        TruckId: truckId,
        TrailerNo: trailerUnitNo,
        TrailerId: trailerId,
        DriverName: this.driversById[driverId]?.full_name_upper_case || '',
        DriverId: driverId || 0,
        Dispatcher: this.dispatchersById[dispatcherId]?.nickname || '',
        DispatcherId: dispatcherId || 0,
        Division: divisionId,
        DivisionName: divisionId ? this.divisionById[divisionId]?.name || '' : ''
      });
    } 
    else {
      Object.assign(patchData, isTruck ? { TruckNo: event, TruckId: 0 } : { TrailerNo: event, TrailerId: 0 });
    }
    Object.keys(patchData).forEach(
      (key) => (patchData[key] === '' || patchData[key] === 0) && delete patchData[key]
    );
    this.repairFormGroup.patchValue(patchData);
  };

  //Files methods
  onFileSelected(event: any) {  
    let length: any = Object.keys(event.target.files).length;
    for(let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i);
    }
  }

  addMultipleFiles(event: any, index: number) {
    let fileName: string = event.target.files[index].name.replace(/[^a-zA-Z0-9.]/g,'_');
    let reader = new FileReader();
    reader.onload = (e: any) => {
      let base64: string = e.target.result.split(',')[1];
      this.repairFormGroup.patchValue({'InvoiceFileName': fileName, 'InvoiceFile': base64});
      this.fileData = base64;
    };
    reader.readAsDataURL(event.target.files[index]);
  };

  setReasonForRepair(repairType: string) {
    this.repairFormGroup.patchValue({'ReasonForRepair': repairType});
    this.maintenanceRepairLogService.getLogID(repairType)
    .subscribe((logID: number) => {
      this.repairFormGroup.patchValue({'LogID': logID});
    });
  }

  openFileInNewTab() {
    this.isOpenFileMenu = !this.isOpenFileMenu;
    this.sharedService.downloadPreviewFile(this.formData('InvoiceFileName'), this.fileData);
  };

  downloadFile() {
    this.isOpenFileMenu = !this.isOpenFileMenu;
    this.sharedService.downloadFile(this.fileData, this.formData('InvoiceFileName'));
  };

  deleteFile() {
    this.isOpenFileMenu = !this.isOpenFileMenu;
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.repairFormGroup.patchValue({'InvoiceFileId': 0, 'InvoiceFileName': '', 'InvoiceFile': null});
        this.fileData = '';
      }
    })
  };

  openPayment() {
    const formData: any = this.repairFormGroup.value;
    const element: any = {id: 0, type: 0, IssuedBy: formData.LoggedBy, creator_id: formData.LoggedById, DriverName: formData.DriverName,
    unit_no: formData.TruckNo, DateOfPayment: formData.DateOfLogEntry, PaymentMethod: '', PaymantAmount: null, PaymentIssuedTo: null,
    TypeOfRepair: formData.TypeOfRepair, InvoiceReceived: false, InvoiceUploadedToPT: false, MoneyCode: '', IssuedAmount: 0, AmountUsed: 0,
    Stete: '', Quantity: '', FuelReceiptReceived: false, CardUsed: '', RELAYCode: '', ReasonForPeyment: null, ReasonForMoneyCode: '',
    note: formData.notes, fileId: 0, fileName: formData.InvoiceFileName, fileData: formData.InvoiceFile, division: formData.DivisionName,
    reviewed: false, chargeTheDriver: false, driverCharged: false, amountCharged: 0, requestTrigger: null, driverId: formData.DriverId,
    driverMailSend: null, issuedID: formData.LoggedById,voided: false};
    this.dialogRef.close(false);
    this.dialog.open(CreateUpdatePaymentDialogComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: 'component-dialog-container',
      data: {editMode: true, data: element}
    });
  };

  saveData() {  
    this.repairFormGroup.markAllAsTouched();
    const formData: any = this.repairFormGroup.value;
    if(this.repairFormGroup.valid && this.multipleClicks) {
      this.multipleClicks = false;
      if(formData.Id === 0) {
        formData.DateCreated = moment().format('YYYY-MM-DDTHH:mm:ss');
      }
      else {
        formData.EditedBy = this.nameToDisplay;
        formData.EditedDate = moment().format('YYYY-MM-DDTHH:mm:ss');
      }
      this.maintenanceRepairLogService.createUpdateMaintenanceRepairLog(formData)
      .pipe(catchError((err: any) => {
        this.showErrorMessage();
        this.multipleClicks = true;
        return throwError(() => err);
      }))
      .subscribe((response: any) => {
        console.log(response);
        if(response?.Id) {
          this.dialogRef.close(response);
        }
        else {
          this.showErrorMessage();
        }
      }); 
    } 
    console.log(this.repairFormGroup.value);
  }

  deleteRepairLog() {
    if(this.permissions[0].sectionArray[2].allowed) {
      const formData: any = this.repairFormGroup.value;
      let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
        autoFocus: false,
        panelClass: 'delete-dialog-container',
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.maintenanceRepairLogService.deleteMaintenanceRepairLog(formData.Id)
          .pipe(catchError((err: any) => {
            this.showErrorMessage();
            return throwError(() => err);
          }))
          .subscribe((response: boolean) => {
            if(response) {
              this.dialogRef.close('DELETE REPAIR LOG');
            }
            else {
              this.showErrorMessage();
            }
          });
        }
      })
    }
    else {
      this.showForbiddenMessage();
    }
  }

  //Get form value
  formData(formKey: string) {
    return this.repairFormGroup.controls[formKey].value;
  };

  //Check validation
  checkValidation(key: string): boolean {
    return !this.repairFormGroup.controls[key].valid && this.repairFormGroup.controls[key]?.touched; 
  };

  //Show error message
  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  //Show forbidden message
  showForbiddenMessage() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();  
    this.subscription2?.unsubscribe();  
    this.subscription3?.unsubscribe();  
    this.subscription4?.unsubscribe();  
    this.subscription5?.unsubscribe();  
    this.subscription6?.unsubscribe();  
  };

}
