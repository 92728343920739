import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { Subscription, catchError, throwError } from 'rxjs';
import { DispatcherProfileService } from '../../services/dispatcher-profile.service';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from '@app/modules/shared/services/shared.service';
import moment = require('moment');
import { ITask } from '@app/modules/tasks/models/task-model';
import { TaskNoteDialogComponent } from '@app/modules/shared/components/task-note-dialog/task-note-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'tasks-card',
  templateUrl: './tasks-card.component.html',
  styleUrls: ['./tasks-card.component.scss']
})
export class TasksCardComponent implements OnInit, AfterContentChecked, OnDestroy { 
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      0, 5
    )
  ];
  isOpenTaskMenu: boolean = false;
  
  //Filters
  filterMetaData: any = {
    countObj: {
      pending: 0,
      overdue: 0,
      done: 0
    }
  };

  //Filters
  filterArray: any[] = [
    {name: 'Pending', icon: 'assignment', key: 'pending', bgColor: 'bg-orange', activeFilter: 'active-orange-filter', inactiveFilter: 'inactive-orange-filter'},
    {name: 'Overdue', icon: 'update', key: 'overdue', bgColor: 'bg-red', activeFilter: 'active-red-filter', inactiveFilter: 'inactive-red-filter'},
    {name: 'Done', icon: 'done', key: 'done', bgColor: 'bg-green', activeFilter: 'active-green-filter', inactiveFilter: 'inactive-green-filter'}
  ]
  activeFilterIndex: number = 0;

  tasksArray: any[] = [];

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Interval
  interval: any;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private transformService: TransformService,
              private route: ActivatedRoute, 
              private dialog: MatDialog,
              private dispatchProfileService: DispatcherProfileService,
              private sharedService: SharedService,
              private spinner: NgxSpinnerService,
              private cdref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.spinner.show('dispatch-tasks');
    this.getTaskData();
    this.interval = setInterval(() => {
      this.getTaskData();
    }, 60000);
  };

  getTaskData() {
    let id: number = +this.route.snapshot.paramMap.get('id');
    this.subscription = this.dispatchProfileService.getTasksByDispatcher(id)
    .pipe(catchError((err: any) => {
      this.spinner.hide('dispatch-tasks');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      this.tasksArray = response;
      this.spinner.hide('dispatch-tasks');
      this.loaded = true;
    });
  }

  checkDueDate(dueDate: string) {
    let date1 = new Date(moment().format('YYYY-MM-DDT00:00:00'));
    let date2 = new Date(dueDate);
    let Difference_In_Time = date2.getTime() - date1.getTime();
    return Math.round(Difference_In_Time / (1000 * 3600 * 24));
  };

  
  daysLeft(value: number) {
    if(value < 0) {
      return 'Expired'
    }
    else if(value === 1) {
      return `${value} day left`;
    }
    else {
      return `${value} days left`;
    }
  };

  openFileInNewTab(obj: any) {
    obj.isOpenFileMenu = !obj.isOpenFileMenu;
    this.sharedService.downloadPreviewFile(obj.name, obj.data);
  };

  downloadFile(obj: any) {
    obj.isOpenFileMenu = !obj.isOpenFileMenu;
    let source: string = `data:application/octet-stream;base64,${obj.data}`;
    const downloadLink = document.createElement('a');
    const fileName = obj.name;
    downloadLink.href = source;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  openTaskNoteDialog(element: ITask) {
    this.dialog.open(TaskNoteDialogComponent, {
      disableClose: true,
      autoFocus: false,
      panelClass: 'task-note-dialog-container',
      data: {element: element, noteRequired: false}
    });
  };

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  };

  trackByTask(index, item){
    return item.id; 
  };

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription?.unsubscribe();  
  };

}
