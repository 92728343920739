import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-request-days-deny-dialog',
  templateUrl: './request-days-deny-dialog.component.html',
  styleUrls: ['./request-days-deny-dialog.component.scss']
})
export class RequestDaysDenyDialogComponent {
  reasonForDenial: string = '';

  constructor(public dialogRef: MatDialogRef<RequestDaysDenyDialogComponent>) { }

  submitData() {
    this.dialogRef.close(this.reasonForDenial);
  };
}
