import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReportsService } from '@app/modules/reports/services/reports.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, Subscription, throwError } from 'rxjs';
import { ReportAmortizationTableComponent } from './report-amortization-table/report-amortization-table.component';
import * as XLSX from 'xlsx';
import { FormatNumPipe } from '@app/modules/shared/pipes/format-num.pipe';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { MatDialog } from '@angular/material/dialog';
import moment = require('moment');
import { CreateUpdateAmortizationComponent } from './create-update-amortization/create-update-amortization.component';

@Component({
  selector: 'app-report-amortization',
  templateUrl: './report-amortization.component.html',
  styleUrls: ['./report-amortization.component.scss']
})
export class ReportAmortizationComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[56].data;
  reportPermissions: any = this.rulesService.UserData[59].data;
  @ViewChild(ReportAmortizationTableComponent) childComponent: any;

  //Date 
  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  };

  //Table data
  tableData: any[] = [];

  //Excel config
  xlsxConfig: any = [
    {columnName: 'Bank Name', selected: true},
    {columnName: 'Truck / Trailer', selected: true},
    {columnName: 'Type of Equipment', selected: true},
    {columnName: 'Company Loan', selected: true},
    {columnName: 'Loan Amount', selected: true},
    {columnName: 'Monthly Pay', selected: true},
    {columnName: 'Purchased Date', selected: true},
    {columnName: 'End Date', selected: true},
    {columnName: 'Pay Off Amount Left', selected: true},
    {columnName: 'Interest Rate', selected: true},
    {columnName: 'Year', selected: true},
    {columnName: 'VIN#', selected: true}
  ];

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private transformService: TransformService,
              private rulesService: RulesService,
              private dialog: MatDialog,
              private spinner: NgxSpinnerService,
              private reportsService: ReportsService,
              private formatNumPipe: FormatNumPipe) { }

  ngOnInit(): void {
    console.log(this.reportPermissions[0].sectionArray)
    this.getTableData();
  };

  getTableData() {
    this.tableData = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('truck-amortization');
    this.subscription = this.reportsService.getAmortizationData()
    .pipe(catchError((err: any) => {
      this.spinner.hide('truck-amortization');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.tableData = response;
      this.spinner.hide('truck-amortization');
      this.loaded = true;
    });
  };

  createUpdateAmortizationDialog() {
    if(this.reportPermissions[0].sectionArray[0].allowed) {
      let dialogRef: any = this.dialog.open(CreateUpdateAmortizationComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'component-dialog-container',
        data: {editMode: false, data: {}}
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.tableData.push(result);
          this.tableData = [...this.tableData];
        }
      })
    }
    else {
      this.showForbiddenMesage();
    }
  };

  exportToExcel(columnsConfig: any[]) {
    if(this.permissions[0].sectionArray[50].allowed) {
      const excelTable: any[] = [];
      for(let i = 0; i < this.tableData.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, this.tableData[i].BankName, columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.tableData[i].TruckTrailer, columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, this.tableData[i].TypeofEquipment, columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, this.tableData[i].CompanyLoan, columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, `$${this.formatNumPipe.transform(this.tableData[i].LoanAmount, 0)}`, columnsConfig[4].selected);
        this.transformService.selectedColumn(obj, columnsConfig[5].columnName, `$${this.formatNumPipe.transform(this.tableData[i].MonthlyPay, 0)}`, columnsConfig[5].selected);
        this.transformService.selectedColumn(obj, columnsConfig[6].columnName, this.transformService.transformDateFormat(this.tableData[i].PurchasedDate, 'YYYY-MM-DD'), columnsConfig[6].selected);
        this.transformService.selectedColumn(obj, columnsConfig[7].columnName, this.transformService.transformDateFormat(this.tableData[i].EndDate, 'YYYY-MM-DD'), columnsConfig[7].selected);
        this.transformService.selectedColumn(obj, columnsConfig[8].columnName, `$${this.formatNumPipe.transform(this.tableData[i].PayOffAmountLeft, 0)}`, columnsConfig[8].selected);
        this.transformService.selectedColumn(obj, columnsConfig[9].columnName, this.formatNumPipe.transform(this.tableData[i].InterestRate, 2), columnsConfig[9].selected);
        this.transformService.selectedColumn(obj, columnsConfig[10].columnName, this.tableData[i].Year, columnsConfig[10].selected);
        this.transformService.selectedColumn(obj, columnsConfig[11].columnName, this.tableData[i].VINNO, columnsConfig[11].selected);
        excelTable.push(obj);                                                  
      };
      let footerObjSum: any = {};
      this.transformService.selectedColumn(footerObjSum, columnsConfig[0].columnName, '', columnsConfig[0].selected);
      this.transformService.selectedColumn(footerObjSum, columnsConfig[1].columnName, '', columnsConfig[1].selected);
      this.transformService.selectedColumn(footerObjSum, columnsConfig[2].columnName, '', columnsConfig[2].selected);
      this.transformService.selectedColumn(footerObjSum, columnsConfig[3].columnName, '', columnsConfig[3].selected);
      this.transformService.selectedColumn(footerObjSum, columnsConfig[4].columnName, `$${this.formatNumPipe.transform(this.childComponent.filterMetaData.countObj.sumOfLoanAmount, 0)}`, columnsConfig[4].selected);
      this.transformService.selectedColumn(footerObjSum, columnsConfig[5].columnName, `$${this.formatNumPipe.transform(this.childComponent.filterMetaData.countObj.sumOfMonthlyPay, 0)}`, columnsConfig[5].selected);
      this.transformService.selectedColumn(footerObjSum, columnsConfig[6].columnName, '', columnsConfig[6].selected);
      this.transformService.selectedColumn(footerObjSum, columnsConfig[7].columnName, '', columnsConfig[7].selected);
      this.transformService.selectedColumn(footerObjSum, columnsConfig[8].columnName, `$${this.formatNumPipe.transform(this.childComponent.filterMetaData.countObj.sumOfPayOffAmount, 0)}`, columnsConfig[8].selected);
      this.transformService.selectedColumn(footerObjSum, columnsConfig[9].columnName, this.formatNumPipe.transform(this.childComponent.filterMetaData.countObj.sumOfInterestRate, 2), columnsConfig[9].selected);
      this.transformService.selectedColumn(footerObjSum, columnsConfig[10].columnName, '', columnsConfig[10].selected);
      this.transformService.selectedColumn(footerObjSum, columnsConfig[11].columnName, '', columnsConfig[11].selected);

      let footerObjAvg: any = {};
      this.transformService.selectedColumn(footerObjAvg, columnsConfig[0].columnName, '', columnsConfig[0].selected);
      this.transformService.selectedColumn(footerObjAvg, columnsConfig[1].columnName, '', columnsConfig[1].selected);
      this.transformService.selectedColumn(footerObjAvg, columnsConfig[2].columnName, '', columnsConfig[2].selected);
      this.transformService.selectedColumn(footerObjAvg, columnsConfig[3].columnName, '', columnsConfig[3].selected);
      this.transformService.selectedColumn(footerObjAvg, columnsConfig[4].columnName, `$${this.formatNumPipe.transform(this.childComponent.filterMetaData.countObj.avgNumOfLoanAmount, 0)}`, columnsConfig[4].selected);
      this.transformService.selectedColumn(footerObjAvg, columnsConfig[5].columnName, `$${this.formatNumPipe.transform(this.childComponent.filterMetaData.countObj.avgNumOfMonthlyPay, 0)}`, columnsConfig[5].selected);
      this.transformService.selectedColumn(footerObjAvg, columnsConfig[6].columnName, '', columnsConfig[6].selected);
      this.transformService.selectedColumn(footerObjAvg, columnsConfig[7].columnName, '', columnsConfig[7].selected);
      this.transformService.selectedColumn(footerObjAvg, columnsConfig[8].columnName, `$${this.formatNumPipe.transform(this.childComponent.filterMetaData.countObj.avgNumOfPayOffAmount, 0)}`, columnsConfig[8].selected);
      this.transformService.selectedColumn(footerObjAvg, columnsConfig[9].columnName, this.formatNumPipe.transform(this.childComponent.filterMetaData.countObj.avgNumOfInterestRate, 2), columnsConfig[9].selected);
      this.transformService.selectedColumn(footerObjAvg, columnsConfig[10].columnName, '', columnsConfig[10].selected);
      this.transformService.selectedColumn(footerObjAvg, columnsConfig[11].columnName, '', columnsConfig[11].selected);

      excelTable.push(footerObjSum);  
      excelTable.push(footerObjAvg);  
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'truck-amortization.xlsx');
    }
    else {
      this.showForbiddenMesage();
    }
  }

  clearFilters() {
    this.childComponent.clearFilters();
  };

  //Show forbidden message
  showForbiddenMesage() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe(); 
  };

}
