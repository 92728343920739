import { Pipe, PipeTransform } from '@angular/core';
import moment = require('moment');

@Pipe({
  name: 'statementsTable'
})
export class StatementsTablePipe implements PipeTransform {

  transform(array: any[]): any[] {

    for(let i = 0; i < array.length; i++) {
      array[i].showPaycheck = true;
      const paymentDate = moment(array[i].pay_date);
      const currentDate = moment();
      const daysDifference: number = currentDate.diff(paymentDate, 'days');
      if(daysDifference <= 14 && daysDifference > 8) {
        const date = new Date();
        const optionsDateTime: any = {timeZone: 'America/Chicago', year: 'numeric', month: '2-digit', day: '2-digit' };
        const currentDateInChicago = new Intl.DateTimeFormat('en-US', optionsDateTime).format(date);
        const otherDateInChicago = paymentDate.format('MM/DD/YYYY');
        const optionsTime: any = {timeZone: 'America/Chicago', hour: 'numeric', hour12: false};
        const chicagoHour = new Intl.DateTimeFormat('en-US', optionsTime).format(date);
        if(!(currentDateInChicago === otherDateInChicago && parseInt(chicagoHour, 10) >= 8)) {
          array[i].showPaycheck = false;
        } 
      }

    }
    return array;
  }

}
