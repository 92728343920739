import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { LinechartFilterPipe } from '@app/modules/dispatch-reward-system/pipes/linechart-filter.pipe';
import { DispatchRewardSystemService } from '@app/modules/dispatch-reward-system/services/dispatch-reward-system.service';
import { FormatNumPipe } from '@app/modules/shared/pipes/format-num.pipe';
import moment = require('moment');
import { ApexAxisChartSeries, ApexChart, ApexXAxis, ApexYAxis, ApexDataLabels, ApexGrid, ApexStroke } from 'ng-apexcharts';
import { Subscription } from 'rxjs';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  yaxis: ApexYAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  stroke: ApexStroke | any;
  markers: any;
  colors: any;
  tooltip: any;
};

@Component({
  selector: 'quick-stats-linechart',
  templateUrl: './quick-stats-linechart.component.html',
  styleUrls: ['./quick-stats-linechart.component.scss']
})
export class QuickStatsLinechartComponent implements OnDestroy {
  @ViewChild('chartContainer') chartContainer!: ElementRef;
  public chartOptions: Partial<ChartOptions> | any;
  //Graph data
  graphDataArray: any[] = [];
  selectedCardsObj: any = {   
    grossPerTruckSelected: false, grossSeriesArray: [],
    rpmPerTruckSelected: false, rpmSeriesArray: [],
    mileagePerTruckSelected: false, mileageSeriesArray: []
  };
  configObj: any = {
    'grossPerTruckSelected': 'grossPerTruckSelected', 'grossPerTruckUnselected': 'grossPerTruckSelected',
    'rpmPerTruckSelected': 'rpmPerTruckSelected', 'rpmPerTruckUnselected': 'rpmPerTruckSelected',
    'mileagePerTruckSelected': 'mileagePerTruckSelected', 'mileagePerTruckUnselected': 'mileagePerTruckSelected'
  };

  //Filters
  filtersArray: any[] = [[], [], [], [], [], ''];

  @Input() set changeSelection(value: string) {
    this.selectedCardsObj[this.configObj[value]] = !this.selectedCardsObj[this.configObj[value]];
    if(this.chartOptions) {
      this.calculateSeriesData(this.linechartFilterPipe.transform(this.graphDataArray, this.filtersArray));
    }
    else {
      this.getGraphData();
    }
  };

  @Input() set quickStatsFilters(filtersArray: any[]) {
    this.filtersArray = filtersArray;
    this.calculateSeriesData(this.linechartFilterPipe.transform(this.graphDataArray, this.filtersArray));
  };

  currentWeekBlink: number = -1;

  //Date
  dateObj: any = {
    startDate: moment().subtract(10, "weeks").startOf('isoWeek').format('YYYY-MM-DD'),
    endDate: moment().endOf('isoWeek').format('YYYY-MM-DD')
  };

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private formatNumPipe: FormatNumPipe, 
              private linechartFilterPipe: LinechartFilterPipe,
              private dispatchRewardSystemService: DispatchRewardSystemService) { }

  changeDate(event: any) {
    this.dateObj.startDate = event.startDate;
    this.dateObj.endDate = event.endDate;
    this.getGraphData();
  };

  getGraphData() {
    const startDate: string = moment(this.dateObj.startDate).startOf('isoWeek').format('YYYY-MM-DD');
    const endDate: string =  moment(this.dateObj.endDate).endOf('isoWeek').format('YYYY-MM-DD');
    let isCurrentWeek: boolean = moment().isSameOrBefore(moment(this.dateObj.endDate), 'isoWeek');
    this.subscription = this.dispatchRewardSystemService.getRewardsDataByDate(isCurrentWeek, startDate, endDate)
    .subscribe((response: any) => {
      this.graphDataArray = response;
      this.calculateSeriesData(this.linechartFilterPipe.transform(this.graphDataArray, this.filtersArray));
    })
  };

  calculateSeriesData(data: any) {
    let sortedData: any = data.sort((a: any, b: any) => {
      return <any>new Date(a.target_from) - <any>new Date(b.target_from);
    });
    this.currentWeekBlink = -1;
    const group: any = sortedData.reduce((r: any, o: any) => {
      const periodName: string = `${moment(o.target_from).startOf('isoWeek').format('MMM DD')}-${moment(o.target_to).endOf('isoWeek').format('DD')}`;
      if(r[periodName]) {
        r[periodName].gross += o.gross;
        r[periodName].mileage += o.mileage;
        r[periodName].driversArray.push(o);
      } 
      else {
        r[periodName] = {
          categories: periodName, 
          gross: o.gross ? o.gross : 0, 
          mileage: o.mileage ? o.mileage : 0,
          driversArray: o ? [o] : o
       };
      };
      
      return r;
    }, {});
    let currentWeek = `${moment().startOf('isoWeek').format('MMM DD')}-${moment().endOf('isoWeek').format('DD')}`;
    let allSeriesArray: any[] = Object.keys(group).map((key) => group[key]);
    const categoriesArray: string[] = [];
    this.selectedCardsObj.grossSeriesArray = [];
    this.selectedCardsObj.rpmSeriesArray = [];
    this.selectedCardsObj.mileageSeriesArray = [];
    for(let i = 0; i < allSeriesArray.length; i++) {
      if(allSeriesArray[i].categories === currentWeek) {
        this.currentWeekBlink = i;
      }
      categoriesArray.push(allSeriesArray[i].categories);
      this.selectedCardsObj.grossSeriesArray.push(allSeriesArray[i].gross / allSeriesArray[i].driversArray.length);
      this.selectedCardsObj.rpmSeriesArray.push(allSeriesArray[i].gross / allSeriesArray[i].mileage);
      this.selectedCardsObj.mileageSeriesArray.push(allSeriesArray[i].mileage / allSeriesArray[i].driversArray.length);
    };
    //Init chart
    this.initGraph(categoriesArray, this.showSelectedData);
  };

  //Init graph
  initGraph(categories: any[], seriesData: any) {
    this.chartOptions = {
      series: seriesData,
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          }
        },
        height: 250,
        type: "line",
        zoom: {
          enabled: false
        },
        fontFamily: 'Poppins'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight",
        width: 1,
      },
      grid: {
        row: {
          colors: ["transparent", "transparent"],
          opacity: 0.5
        }
      },
      xaxis: {
        categories: categories
      },
      yaxis: this.addYAxis(seriesData),
      markers: {
        size: 5
      },
      colors: ['#0030FF', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'],
      tooltip: {
        enabled: true,
        inverseOrder: false,
        style: {
          fontSize: '12px'
        },
      }
    };
    if(seriesData.length > 0 && this.currentWeekBlink !== -1) {
      this.blinkMarker(this.currentWeekBlink);
    }
  };

  //Y axis graph
  addYAxis(array: any) {
    let yAxisArray: any[] = [];
    let colors: string[] = ['#0030FF', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'];
    for(let i = 0; i < array.length; i++) {
      let obj: any;
      if(i === 0) {
        obj = {
        seriesName: array[i].name[0], axisTicks: {show: true}, axisBorder: {show: false, color: colors[i]},
        labels: {style: {colors: colors[i]}, formatter: (num: number) => { 
            if(array[i].name === 'Gross per truck') {
              return `$${this.formatValue(Math.round(num))}`;
            }
            if(array[i].name === 'Rate per mile') {
              let value: any = this.formatNumPipe.transform(num, 2);
              return `$${this.formatValue(value)}`;
            }
            if(array[i].name === 'Mileage per truck') {
              return `${this.formatValue(Math.round(num))} mi`;
            }
          }}
        }
      } 
      else {
        obj = {
          seriesName: array[i].name[0], opposite: true, axisTicks: {show: true}, axisBorder: {show: false, color: colors[i]},
          labels: {style: {colors: colors[i]}, formatter: (num: number) => {
            if(array[i].name === 'Gross per truck') {
              return `$${this.formatValue(Math.round(num))}`;
            }
            if(array[i].name === 'Rate per mile') {
              let value: any = this.formatNumPipe.transform(num, 2);
              return `$${this.formatValue(value)}`;
            }
            if(array[i].name === 'Mileage per truck') {
              return `${this.formatValue(Math.round(num))} mi`;
            }
          }}
        }
      }

      yAxisArray.push(obj);
    }

    return yAxisArray;
  };

  blinkMarker(index: number) {
    setTimeout(() => {
      const element = this.chartContainer.nativeElement;
      const marker = element.querySelectorAll('.apexcharts-marker')?.[index];
      marker?.classList.add('blinking')
    }, 1000);
  };

  formatValue(value: number) {
    return value.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  get showSelectedData(): any {
    const seriesArray: any[] = [];
    if(this.selectedCardsObj.grossPerTruckSelected) {
      seriesArray.push({name: 'Gross per truck', data: this.selectedCardsObj.grossSeriesArray});
    };
    if(this.selectedCardsObj.rpmPerTruckSelected) {
      seriesArray.push({name: 'Rate per mile', data: this.selectedCardsObj.rpmSeriesArray});
    };
    if(this.selectedCardsObj.mileagePerTruckSelected) {
      seriesArray.push({name: 'Mileage per truck', data: this.selectedCardsObj.mileageSeriesArray});
    };
    return seriesArray;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}
